import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import server from '../../services/server';
import app from '../../services/app';
import TableFilters from '../../components/tablefilters/index';
import TradeNotFound from '../../components/tradeNotFound/index';
import './usercalls.scss';
import '../../components/standard/table.scss';

class UserCalls extends Component {
  constructor(props) {
    super(props);
  }

  PlayRecord = () => {
    return (
      <li className="short">
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.7157 0 0 6.71573 0 15C0 23.2843 6.7157 30 15 30ZM13 21.9282L22 16.7321C23.3334 15.9622 23.3334 14.0378 22 13.2679L13 8.07181C11.6666 7.302 10 8.26425 10 9.80386V20.1962C10 21.7357 11.6666 22.698 13 21.9282Z" fill="#008EDE"/>
        </svg>
      </li>
    )
  }

  async componentDidMount () {
    $(".pmore").on("click", function () {
      $(".nmid").removeClass("nmid").addClass("not-master");
      let f = $(this).data("find");
      if($(this).data("text").trim() == "+") {
        $("."+f).removeClass("not-master").addClass("nmid");
        $(this).data("text", "-");
      } else {
        $("."+f).removeClass("nmid").addClass("not-master");
        $(this).data("text", "+");
      }
    });
    setTimeout(() => {
      $(".pmore").each((k, v) => {
        let f = $(v).data("find");
        if($("."+f).length == 0) {
          $(v).hide();
        } else {
          $(v).text($("."+f).length);
        }
      });
    }, 250);
  }

  render () {
    let active = parseInt(this.props.active);
    let xid = 0, nmid = 0;

  	return (
      <div className={"tab-row profile-visits"+(active ? ' _active' : '')} id="tab-row-visits">

        <ul className="table-header for-visits" style={{marginBottom: "8px"}}>
          <li className="len">USER ID</li>
          <li className="len">FULLNAME</li>
          <li className="len">EMAIL</li>
          <li className="len">PHONE NUMBER</li>
          <li className="len">VISIT DATE</li>
        </ul>

        {
          this.props.visits.length > 0 ?
          this.props.visits.map((user) => {
            return (
            <ul className={"table-body for-visits"+(xid != user.user_id ? "" : " nmid not-master not-master-"+nmid)} key={`${Math.random()} ${Math.random()}`}>
              <li className="len"><Link className="txt-info" to={"/usersprofile/"+user.user_id}>{app.uid(user.user_id)}</Link></li>
              <li className="len"><Link className="txt-info" to={(user.utype ? "/adminsprofile/" : "/usersprofile/")+user.user_id}>{(user.first_name+" "+user.last_name).ucwords()}</Link></li>
              <li className="len"><span className="txt-light">{user.email}</span></li>
              <li className="len"><span className="txt-light">{user.phone_number}</span></li>
              <li className="len"><span className="txt-light">{user.vtime}</span></li>
              {xid != user.user_id ? <b className="pmore" data-find={"not-master-"+(nmid+1)} data-text="+"></b> : ""}
              {nmid = xid != user.user_id ? nmid + 1 : nmid, xid = user.user_id, ""}
            </ul>
          )}) : <div className='trade-history'>
              <div className='t-history-container'>
                <TradeNotFound text="No profiles visited" />
              </div>
            </div>
        }

      </div>
	 )
	}

}

export default UserCalls;