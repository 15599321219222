import React, { Component } from 'react';
import { connect } from 'react-redux';
import { leftNavData, adminLeftNavData } from '../../utils/dummyData';
import Lnav1 from '../../themes/images/tradeDashboard/l_nav1.svg';
import { NavLink } from 'react-router-dom';
import app from '../../services/app';
import './index.scss';

class OutterLeftNav extends Component {
  constructor(props) {
    super(props);

    this.navClickedStyles = {
      width: '60px',
      alignItems: 'center',
      overflow: 'hidden',
    };

    this.state = {
      nClicked: true,
    }

    this.listStyles = {
      width: '35px !important',
    };
  }

  urlName (p = null) {
    return (p == null ? window.location.pathname : p).replace("/", "").toLowerCase();
  }

  nClick = (e) => {
    this.props.handleOutterClick(e);
    this.setState({nClicked: this.props.outterNavClicked});
  }

  render() {
    const activeStyle = {
      backgroundColor: '#00A880',
      borderRadius: '5px !important',
    };

    let isAdmin    = this.props.isAdmin;
    let navData    = isAdmin ? adminLeftNavData : leftNavData;
    let nClicked   = this.props.out;
    let badge      = this.props.badge;
    let page       = window.location.pathname.replace("/", "").toLowerCase();
    let isclk      = app.isVerified() || app.isAdmin();

    if(app.isAdmin() && !app.super()) {
      navData = navData.filter((nd) => nd.name != "createadmin");
    }

    // if(!app.master()) {
      navData = navData.filter((nd) => !(nd.name == "calllogs" || nd.name == "salesfunnel" || nd.name == "activities"));
    // }
    
    if(!app.master()) {
      navData = navData.filter((nd) => nd.name != "instruments");
    }
    
    if(!app.super()) {
      navData = navData.filter((nd) => nd.name != "settings");
    }

    if(app._aff_()) {
      navData = navData.filter((nd) => !(nd.name == "home" || nd.name == "settings" || nd.name  == "chats" || nd.name == "finance" || nd.name == "create" || nd.name == "documents" || nd.name == "news" || nd.name == "trades"));
    }

    return (
      <div
        className={'outter-left'+(isclk ? "" : " --disabled")}
        style={nClicked ? this.navClickedStyles : null}
      >
        <ul className={'left-nav'+(isAdmin ? (app._aff_() ? ' isAdmin _af' : ' isAdmin') : '')}>
          <li onClick={this.props.tlnav} className='outter-ham'>
            <img src={Lnav1} alt='' />
          </li>
          {navData.map((nav, idx) => (nav.name != 'chats' || (nav.name == 'chats' && app.permit("View chat"))) ? (
            <li
              key={`${idx}-1`}
              onClick={
                nav.name === 'trade'
                  ? this.props.handleNavClick
                  : nav.name === 'transactions'
                  ? this.props.handleTransactionNavClick
                  : null
              }
              style={{width: nClicked ? '40px' : null}}
              className={'link-icons '+nav.name+(page.toLowerCase() == nav.path.replace("/", "").toLowerCase() ? ' active' : '')}
            >
            {
              /*(
                (nav.name == 'trade' && page == 'trade') ||
                (nav.name == 'market' && page == 'market') ||
                (nav.name == 'transactions' && page == 'transactions')
              ) && isclk*/
              isclk && (nav.name.toLowerCase() != "home" && nav.name.toLowerCase() != "chats") && app.cpage() != "chats" ? (
              <NavLink
                to={nav.path}
                activeStyle={activeStyle}
                className={nav.className}
                style={
                  nClicked ? this.props.listStyles : null
                }
              >
                <img src={nav.imageUrl} alt='nav-logo' />
                {badge > 0 && nav.name.toLowerCase() == "chats" ? <span id="badge-cnt-i">{badge}</span> : null}
                {!nClicked ? (
                  <p className='outter-lnav-text'>{nav.text}</p>
                ) : null}
              </NavLink>
            ) : isclk ? (
              <a
                href={nav.path}
                activeStyle={activeStyle}
                className={nav.className}
                style={
                  nClicked ? this.props.listStyles : null
                }
              >
                <img src={nav.imageUrl} alt='nav-logo' />
                {badge > 0 && nav.name.toLowerCase() == "chats" ? <span id="badge-cnt-i">{badge}</span> : null}
                {!nClicked ? (
                  <p className='outter-lnav-text'>{nav.text}</p>
                ) : null}
              </a>
            ) : <a
                activeStyle={activeStyle}
                className={nav.className}
                style={
                  nClicked ? this.props.listStyles : null
                }
              >
                <img src={nav.imageUrl} alt='nav-logo' />
                {!nClicked ? (
                  <p className='outter-lnav-text'>{nav.text}</p>
                ) : null}
              </a>}
            </li>
          ) : null)}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ outterNav }) => ({
  outterNavClicked: outterNav,
});

export default connect(mapStateToProps)(OutterLeftNav);
