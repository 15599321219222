import React, { Component } from 'react';
import $ from 'jquery';
import Container from '../container/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import RestrictionNav from '../../components/restrictionNav/index';
import Spinner from '../../components/spinner/index';
import RProfile from '../../components/transferLead/rprofile';
import CancelIcon from '../../themes/images/cancel.svg';
import country from 'country-list-js';
import permissions from '../../utils/permissions-all';
// import permissions from '../createadmin/permissions.json';
import server from '../../services/server';
import app from '../../services/app';
import './index.scss';

window.restriction_list = "";

class Restrictions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upds: 0,
      active_id: 0,
      permitted: [],
      rprofile: false,
      profile_data: '',
      profiles: [],
      active_profile: '',
      active_list: [],
    };

    this.permitted = [];
    this.showSpinner = false;
    this.profile = app.profile();
    this.id = this.profile.user_id;
    this.permissions   = require("../createadmin/permission.json");
  }

  async componentDidMount() {
    this.getRest();
    $("#save-all-changes-btn").on("click", () => {
      let pr = this.state.profiles;
      let act = this.state.active_list;
      let _pr = [];
      pr.forEach((pv, pk) => {
        if(pv.name == this.state.active_profile) {
          pv.list = this.checkPermit();
          act = this.checkPermit();
        }
        _pr.push(pv);
      })
      this.setState({rprofile: true, profile_data: this.checkPermit(), profiles: _pr, active_list: act});
    });
  }

  getRest = async () => {
    let permissions = await server.getRest();
    this.setState({profiles: permissions.data.data});
  }

  changeProfile = (e) => {
    let val = e.target.value;
    this.setState({active_profile: val});
    let pro = this.state.profiles.filter((p) => p.name == val);
    if(pro.length) {
      this.setState({active_list: pro[0].list.split(","), active_id: pro[0].id});
    }
  }

  checkPermit = (e, alt = '') => {

    let permitted = [];
    let permitteds = [];
    $("#save-all-changes-btn").parent().css({display: "inline-block"});

    if(e && alt.length) {
      document.getElementById(alt).checked = !e.target.checked;
    }
    $(".p-check").each((k, v) => {
      if(v.checked) {
        permitted.push($(v).data("permit"));
        permitteds.push($(v).data("permit").toLowerCase().replace(/\s/g, "_"));
        $(v).parents("li").find("span.permission").css({color: "#ffa500"});
      } else {
        $(v).parents("li").find("span.permission").css({color: "#fff"});
      }
    });
    this.permitted = window.restriction_list = permitted;
    return e == 2 ? permitted : permitteds.join(",");
  }

  deleteRest = async () => {
    let id = this.state.active_id;
    this.setState({showLoader: true});
    try {
      await server.deleteRest(id);
      this.setState({rprofile: false, showLoader: false, callback: true, callbackTxt: 'Delete successful'});
      this.changeProfile(this.state.active_profile);
      this.setState({active_id: 0, permitted: [], profile_data: ''});
      await this.getRest();
    } catch (e) {
      this.setState({rprofile: false, showLoader: false});
    }
  }

  render() {

    let fetched = [];
    let upds = this.state.upds;
    let perm = this.permissions;
    let perms = window.restriction_list;

    let _perm = (prs, pst='', alt=-1) => {
      let id = prs.toLowerCase().replace(/\s/g, "_");
      if(alt > -1) {
        alt = pst.toLowerCase().replace(/\s/g, "_");
      } else {
        alt = '';
      }
      return (
        <li key={Math.random()+' '+Math.random()}>
          <label for={id} style={{cursor: "pointer", position: "relative", width: "100%"}}>
            <span className="permission" id={'spa-'+id}>{prs}</span>
            <span className="toggle">
              <span className="switch">
                <input type="checkbox" data-permit={prs.toLowerCase()} id={id} defaultChecked={
                  this.state.active_profile.length && this.state.active_list.length ? this.state.active_list.indexOf(id) > -1 : false
                } className="p-check" onChange={(e) => this.checkPermit(e, alt)} />
                <span className="slider round"></span>
              </span>
            </span>
          </label>
        </li>
      )
    }

    return (
      <Container>
        {
          this.state.rprofile ? 
          <RProfile
            cancel={() => {
              this.setState({rprofile: false});
              // , active_profile: '', active_list: []});
              // $("#prolist").val("");
            }}
            sending={() => this.setState({showLoader: true})}
            success={() => { this.setState({rprofile: false, showLoader: false, callback: true, callbackTxt: 'Saved successful'}); this.getRest(); }}
            name={this.state.active_profile}
            data={this.state.profile_data}
          /> : null
        }

        <div className='restrictions-section'>
          <Spinner showSpinner={this.showSpinner} />
          <Breadcrumbs breads={"Home, Restrictions"} />
          <div className="full-screen">
            <div className="table-filters" style={{margin: "0 4em 0 2em"}}>
              <div className="search-container select-box" style={{width: "250px"}}>
                <select id="prolist" onChange={this.changeProfile}>
                  <option value="">- Select restriction profile -</option>
                  {
                    this.state.profiles.map((rv, rk) => {
                      return (
                        <option value={rv.name}>{rv.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              
              {this.state.active_profile.length ?
              <svg style={{position: "relative", top: "10px", left: "10px", cursor: "pointer"}} onClick={() => this.deleteRest()} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="red" />
              </svg> : null}
            </div>

            <div className="main">
              <div className="not-overscroll">
              <table>
                <tr>
                {
                  "1,2,3".split(",").map((i, j) => (
                    <td>
                      {Object.keys(perm).map((p, k) => {
                        let ki = (k%3)+1;
                        return i == ki && fetched.indexOf(k) < 0 ?
                          (<>
                            {fetched.push(k) && null}
                            <h3>{p.toUpperCase()}</h3>
                            <ul className="p-list section">
                              {perm[p].map((ps, pk) => {
                                return typeof ps == "string" ? _perm(ps) : <>{_perm(ps[0], ps[1], 0)}{_perm(ps[1], ps[0], 1)}</>})}
                            </ul>
                          </>) : null
                        }
                      )}
                    </td>
                  ))
                }
                </tr>
              </table>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default Restrictions;
