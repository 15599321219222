import React, { Component } from 'react';
import Container from '../container/index';
import Pagination from '../../components/Pagination/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';
import TableFilters from '../../components/tablefilters/index';

import ProfileDetails from './profiledetails.jsx';
import ProfileAccounts from './profileaccounts.jsx';
import ProfilePayments from './profilepayments.jsx';
import TradingActivities from './tradingactivities.jsx';
import UserFiles from './userfiles.jsx';
import UserNotes from './usernotes.jsx';
import ProfileMeetings from './profilemeetings.jsx';
import UserTasks from './usertasks.jsx';
import UserCalls from './usercalls.jsx';
import UserEmails from './useremails.jsx';
import bcall from '../../themes/images/book-call.png';
import Spinner from '../../components/spinner/index';
import UsersProfile from  './userprofile.jsx';
import server from '../../services/server';
import app from '../../services/app';
import { ConfirmModal, CallBack, MsgAlert } from '../../components/popups/index';

import '../../components/standard/standard.scss';
import './index.scss';

class UsersProfileList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navi: 1,
      profile: null,
      showLoader: true,
      userTab: "Profile",
      uid: '',
      message: '',
      admins: [],
      callback: false,
      callbackTxt: '',
      counter: 0
    }

    window.callbackTxt = "";
    window.showCallback = true;

  }

  async componentDidMount () {
    this.getAllAdmins();
    this.refreshTab(this.state.userTab, 0);
  }

  getAllAdmins = async () => {
    try {
      let users = await server.getAllAdmins();
      window.listofadmins = users.data;
      this.setState({admins: users.data});
    } catch(e) {
      return e;
    }
  }

  refreshTab = async (t, c = 1) => {
    if(c && window.showCallback) {
      this.setState({callback: true, callbackTxt: window.callbackTxt.length ? window.callbackTxt : 'Profile Updated'});
    }

    window.callbackTxt = "";
    window.showCallback = true;

    this.setState({showLoader: true});
    let u = this.props.match.params.user_id;
    try {
      let profile = app._aff_() ? await server.getUser(u, "v", app.profile()["source"]) : await server.getUser(u, "v");
      if(profile.data.status) {
        let _p = profile.data.profile;
        _p.notes = [];
        _p.tasks = [];
        _p.todos.forEach((p, i) => {
          if(p.type > 0) {
            _p.tasks.push(p);
          } else {
            _p.notes.push(p);
          }
        });
        this.setState({profile: _p, uid: u, showLoader: false, userTab: t, counter: ++this.state.counter});
      } else {
        this.setState({message: "User not found!", showLoader: false, userTab: t, counter: ++this.state.counter});
      }
    } catch (e) {
      this.setState({message: "User not found!", showLoader: false, userTab: t, counter: ++this.state.counter});
    }
  }

  selectActiveTab = (e) => {
    try {
      if(e.target.getAttribute("name")) {
        let name = e.target.getAttribute("name");
        if(name.length) this.setState({userTab: name});
      }
    } catch (e) {
      return e;
    }
  }

  render() {
    const { navi, profile, counter } = this.state;
    let lct = this.state.counter ? { position: "absolute",	right: "3em",	top: "0em" } : {marginTop: "20%"};
        lct.display = this.state.showLoader ? 'block' : 'none';

    return (
      <Container>
      
      <Spinner showSpinner={this.state.showLoader} />

      <CallBack
        head="Success"
        show={this.state.callback}
        text={this.state.callbackTxt}
        cancel={(e) => this.setState({callback: false})}
      />
      
      <MsgAlert
        message={this.state.message}
        cancel={(e) => {
          this.setState({message: ""});
          window.history.back();
        }}
      />
      
      {profile ? <UsersProfile profile={profile} counter={counter} /> : null}
      {profile ?
        <div className="col-12" id="users-container">
          <div className="users-section-right shared">
            <Breadcrumbs breads={"Home, Desks, Users, "+(this.state.userTab)} />
            {/* +(app.master() ? ", Calls, Emails, Tasks" : "") */}
            <a href={"tel:"+profile.phone_number}><img className='bcall' src={bcall} alt='' /></a>
            <Ptab tabs={"Profile, Accounts, Transaction History,"+(app._aff_() ? "" : " Trading Activity, Files,")+" Notes"} handleClick={this.selectActiveTab} active={this.state.userTab} />

            {this.state.userTab.toLowerCase() === "profile" && profile  ? <ProfileDetails active="1" profile={profile} uid={this.state.uid} refresh={() => this.refreshTab("Profile")} load={() => this.setState({showLoader: !this.state.showLoader})} />              : null}
            {this.state.userTab.toLowerCase() === "accounts"            ? <ProfileAccounts active="1" profile={profile} accounts={profile.accounts} uid={this.state.uid} refresh={() => this.refreshTab("Accounts")} load={() => this.setState({showLoader: !this.state.showLoader})} />   : null}
            {this.state.userTab.toLowerCase() === "transaction history" ? <ProfilePayments active="1" history={profile.history} to={profile.account_number} accounts={profile.accounts} uid={this.state.uid} refresh={() => this.refreshTab("Transaction History")} load={() => this.setState({showLoader: !this.state.showLoader})} />   : null}
            {this.state.userTab.toLowerCase() === "trading activity"    ? <TradingActivities active="1" trade={profile.trade} accounts={profile.accounts} uid={this.state.uid} refresh={() => this.refreshTab("Trading Activity")} load={() => this.setState({showLoader: !this.state.showLoader})} /> : null}
            {this.state.userTab.toLowerCase() === "files"               ? <UserFiles active="1" files={profile.documents} uid={this.state.uid} refresh={() => this.refreshTab("Files")} load={() => this.setState({showLoader: !this.state.showLoader})} />         : null}
            {this.state.userTab.toLowerCase() === "notes"               ? <UserNotes active="1" notes={profile.notes} uid={this.state.uid} refresh={() => this.refreshTab("Notes")} load={() => this.setState({showLoader: !this.state.showLoader})} />         : null}
            {this.state.userTab.toLowerCase() === "calls"               ? <UserCalls active="1" calls={null} uid={this.state.uid} refresh={() => this.refreshTab("Calls")} load={() => this.setState({showLoader: !this.state.showLoader})} />         : null}
            {this.state.userTab.toLowerCase() === "emails"              ? <UserEmails active="1" profile={profile} emails={profile.emails} uid={this.state.uid} refresh={() => this.refreshTab("Emails")} load={() => this.setState({showLoader: !this.state.showLoader})} />        : null}
            {this.state.userTab.toLowerCase() === "meeting"             ? <ProfileMeetings active="1" meetings={profile.meetings} uid={this.state.uid} refresh={() => this.refreshTab("Meeting")} load={() => this.setState({showLoader: !this.state.showLoader})} />   : null}
            {this.state.userTab.toLowerCase() === "tasks"               ? <UserTasks active="1" tasks={profile.tasks} uid={this.state.uid} refresh={() => this.refreshTab("Tasks")} load={() => this.setState({showLoader: !this.state.showLoader})} />         : null}

          </div>
        </div> : null}
      </Container>
    );
  }
};

export default UsersProfileList;
