import React, { Component } from 'react';
import $ from 'jquery';
import '../../utils/cursor.js';
import userIcon from '../../themes/images/user-contacct.png';
import app from '../../services/app';
import server from '../../services/server';
import pencil from '../../themes/images/pencil-edit.png';
import { Success } from '../../components/popups/index';
// import DebitCard from '../../components/profile/debitCard/index';
import DebitCard from '../../App/Pages/Components/debitCard';
import './profiledetails.scss';

class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: true
    }
  }

  async componentDidMount () {
    $(".valu").prop("readonly", true);
    $(".pencil").click(function () {
      if(!app._aff_()) {
        $("#save-all-changes-btn").parent().css({display: "inline-block"});
      }
      let valu  = $(this).parents("td").find(".valu");
      let name  = valu.attr("name");
      if(name == "password") {
        let vl = valu.attr("raw");
        if(vl?.length) {
          valu.val(vl);
        }
      }
      valu.attr({"spellcheck": false});
      valu.prop("readonly", false);
      valu.addClass("edited");
      valu.focus().setCursorPosition(valu.val().length);
    });

    $("#save-all-changes-btn").click(() => {
      if(!app._aff_()) {
        this.updateDetails();
      }
    })
  }

  componentWillUnmount () {
    $("#save-all-changes-btn").parent().css({display: "none"});
  }

  kyc = async (e) => {
    if(!app._aff_()) {
      let v =  e.target.value;
      this.props.load();
      try {
        window.callbackTxt = "KYC "+(Number(v) > 0 ? "Approved" : "Disapproved");
        await server.KYCStatus(this.props.uid, v);
      } catch (e) {
        return e;
      }
      this.props.refresh();
    }
  }

  astat = async (e) => {
    if(!app._aff_()) {
      let v =  e.target.value;
      this.props.load();
      try {
        await server.Astat(this.props.uid, v);
        window.callbackTxt = "Account status updated";
      } catch (e) {
        return e;
      }
      this.props.refresh();
    }
  }

  tstat = async (s) => {
    if(!app._aff_()) {
      this.props.load();
      try {
        await server.changeTransferStatus(this.props.uid, s);
        window.callbackTxt = "Transfer Availability "+(s ? "Enabled" : "Disabled");
      } catch (e) {
        return e;
      }
      this.props.refresh();
    }
  }

  deleteCard = async (id, cardPAN) => {
    if(!app._aff_()) {
      this.props.load();
      try {
        await server.deleteCard(id, cardPAN, this.props.uid);
        window.callbackTxt = "Card Removed";
      } catch (error) {
        return error
      }
      this.props.refresh();
    }
  }

  updateDetails = async () => {
    if(app._aff_()) return false;
    let valus = {};
    if($(".valu.edited").length) {
      $(".valu.edited:not(.questionnaire)").each(function(k, v) {
        if($(v).attr("name").length) {
          let nme = $(v).attr("name");
          valus[nme] = $(v).val().trim() == "NOT STATED" ? "" : $(v).val();
          if(nme == "password") {
            $(v).val("");
          }
          $(this).prop("readonly", true);
          $(this).removeClass("edited");
        }
      });
      let survey = [];
      $(".questionnaire.valu").each(function(k2, v2) {
        let qvl = $(v2).val();
        if(qvl.length) {
          survey.push([$(v2).attr("question"), qvl]);
        }
      });

      setTimeout(async () => {
        survey = JSON.stringify(survey);
        valus["survey"] = survey;
        this.props.load();
        try {
          if(valus.password ? (valus.password.trim() == '') : false) {
            delete valus.password;
          }
          let sm = await server.updateUserDetails(this.props.uid, valus);
          $("#save-all-changes-btn").parent().css({display: "none"});
        } catch (e) {
          return e;
        }
        this.props.refresh();
      }, 50);
    }
  }

  render () {
    let active = parseInt(this.props.active);
    let state = this.props.profile;
    let cards = state.cards;
    let sdfa = 0;
    let survey = state.survey.length ? state.survey : [];
    let questions = state.questions.length ? state.questions : app.questions();
    
  	return (
      <div className={"tab-row profile-details"+(active ? ' _active' : '')+(app._aff_() ? " _nopen" : "")} id="tab-row-profile">

        <Success show={!this.state.showSuccess} cancel={(e) => this.setState({showSuccess: false})} />
        <div className="detail-row">
          <h3>Personal Details</h3>
          <table>
            <tr>
              <td><span className="std">First name</span><span className="stv"><input className="valu" name="first_name" defaultValue={app.altField(state.first_name)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Last name</span><span className="stv"><input className="valu" name="last_name" defaultValue={app.altField(state.last_name)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Email</span><span className="stv"><input className="valu blurc" name="email" defaultValue={app.altField(app.blur(state.email))} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Phone</span><span className="stv"><input className="valu blurc" name="phone_number" defaultValue={app.altField(app.blur(state.phone_number))} /> <img src={pencil} className="pencil" /></span></td>
              {app.permit("Change user password") ? <td><span className="std">Password</span><span className="stv"><input className="valu" name="password" type="text" raw={state.raw_pass} placeholder={"******"} /> <img src={pencil} className="pencil" /></span></td> : null}
              <td></td>
            </tr>
            <tr>
              <td><span className="std">Language</span><span className="stv"><input className="valu" name="" defaultValue={app.altField("English")} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Date of Birth</span><span className="stv"><input className="valu" name="dob" defaultValue={app.altField(state.dob)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Gender</span><span className="stv"><input className="valu" name="gender" defaultValue={app.altField(state.gender)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Country</span><span className="stv"><input className="valu" name="country" defaultValue={app.altField(state.country)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Source</span><span className="stv"><input className="valu" name="source" defaultValue={app.altField(state.source)} /> <img src={pencil} className="pencil" /></span></td>
            </tr>
            <tr>
              <td colspan="2"><span className="std">Passport/Identification number</span><span className="stv"><input className="valu" name="passport_number" defaultValue={app.altField(state.passport_number)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Passport Issued date</span><span className="stv"><input className="valu" name="passport_date" defaultValue={app.altField(state.passport_date)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Passport/ID expiration date</span><span className="stv"><input className="valu" name="passport_exp" defaultValue={app.altField(state.passport_exp)} /> <img src={pencil} className="pencil" /></span></td>
            </tr>
          </table>
        </div>


        {app.permit("View and edit Basic Information") ?
        <div className="detail-row">
          <h3>Banking Details</h3>
          <table>
            <tr>
              <td><span className="std">Account Name</span><span className="stv"><input className="valu" name="account_name" defaultValue={app.altField(state.account_name)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Account number</span><span className="stv"><input className="valu" name="account_number" defaultValue={app.altField(state.account_number)} /> <img src={pencil} className="pencil" /></span></td>
            </tr>
            <tr>
              <td><span className="std">Bank Name</span><span className="stv"><input className="valu" name="bank_name" defaultValue={app.altField(state.bank_name)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Bank Address</span><span className="stv"><input className="valu" name="bank_address" defaultValue={app.altField(state.bank_address)} /> <img src={pencil} className="pencil" /></span></td>
            </tr>
            <tr>
              <td><span className="std">Bank Swift Code</span><span className="stv"><input className="valu" name="bank_SWIFT_code" defaultValue={app.altField(state.bank_SWIFT_code)} /> <img src={pencil} className="pencil" /></span></td>
              <td><span className="std">Bank IBAN</span><span className="stv"><input className="valu" name="bank_IBAN" defaultValue={app.altField(state.bank_IBAN)} /> <img src={pencil} className="pencil" /></span></td>
            </tr>
          </table>
        </div> : null}

        {questions.length ?
        <div className="detail-row" style={{height: "auto"}}>
          <h3>Questionnaire</h3>
          <table>
            {
              questions.map((sv, sk) => {
                let defA = "";
                let da = survey.filter((su) => su ? su[0] == sv["question"] : null);
                if(da) {
                  if(da.length) {
                    defA = da[0][1];
                  }
                }
                sdfa += defA.length;
                return defA.length ? (
                  <tr key={"sv-"+sk}>
                    <td>
                      <span className="std">{sv["question"]}?</span><span className="stv">
                      <select className="questionnaire valu" question={sv["question"]} defaultValue={defA} style={{minWidth: "100%"}} onChange={(e) => {
                        if(!app._aff_()) {
                          $(e.target).addClass("edited");
                          $("#save-all-changes-btn").parent().css({display: "inline-block"});
                        }
                      }}>
                        <option value="">No response</option>
                        {
                          sv.answers.map((an, ak) => (
                            <option>{an}</option>
                          ))
                        }
                      </select>
                      </span>
                    </td>
                  </tr>
                ) : null
              })
            }
            {
              sdfa == 0 && survey.length ? (
                survey.map((_sv, _sk) => {
                  return (
                    <tr key={"_sv-"+_sk}>
                      <td>
                        <span className="std">{_sv[0]}</span><span className="stv">
                        <select className="questionnaire valu" question={_sv[0]} defaultValue={_sv[1]} style={{minWidth: "100%"}} onChange={(e) => {
                          if(!app._aff_()) {
                            $(e.target).addClass("edited");
                            $("#save-all-changes-btn").parent().css({display: "inline-block"});
                          }
                        }}>
                          <option>{_sv[1]}</option>
                        </select>
                        </span>
                      </td>
                    </tr>
                  )
                })
              ) : null
            }
          </table>
        </div> : null}

        {state.comment.trim().length ?
        <div className="detail-row" style={{height: "auto"}}>
          <h3>Comment</h3>
          <p>{state.comment}</p>
        </div> : null}

        <div className="detail-row-2">
          <ul>
            <li>
              <div>
                <span className="d-title">KYC Status</span>
                <span className="d-label">Current KYC Status</span>
                <div className='det'>
                  <div className='det-flex'>
                    <div className='det-flex-item-1'>
                    {
                      app.permit("Ability to approve and disapprove KYC")
                      ? <select name="kyc" onChange={this.kyc} defaultValue={state.kyc}>
                        <option value="1">Approved</option>
                        <option value="0">Disapproved</option>
                      </select> : <select name="kyc" disabled={true} defaultValue={state.kyc}>
                        <option>{state.kyc ? "Approved" : "Disapproved"}</option>
                      </select>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div>
                <span className="d-title">Account Status</span>
                <span className="d-label">Current Status</span>
                <div className='det'>
                  <div className='det-flex'>
                    <div className='det-flex-item-1'>
                    {
                      app.permit("Ability to approve and disapprove KYC")
                      ?
                      <select name="astat" onChange={this.astat} defaultValue={state.astat}>
                        <option value="0">None</option>
                        {
                          app.statusType(state, true).map((st) => {
                            return <option value={st.id}>{st.sname}</option>
                          })
                        }
                      </select> : (state.astat > 0 ? app.statusType(state, false) : <span style={{color: "#fff"}}>None</span>)
                    }
                    </div>
                  </div>
                </div>
              </div>
            </li>
            {/*<li>
              <div>
                <span className="d-title">Account Leverage</span>
                <span className="d-label">Current Leverage Status</span>
                <div className='det'>
                  <div className='det-flex'>
                    <div className='det-flex-item-1'>
                      <select name="extension_no">
                        <option>Basic: &nbsp;&nbsp;&nbsp;1:&nbsp;200</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </li>*/}
            <li>
              <div>
                <span className="d-title">Transfer Availability</span>
                <span className="d-label">Transfer status</span>
                <div className="btn-list" style={{display: "flex", padding: "10px"}}>
                  {app._aff_() ? (state.can_transfer > 0 ? "Enabled" : "Disabled") : <>
                    <button className={"enbtn"+(state.can_transfer > 0 ? "" : " imp")} type="button" onClick={(e) => this.tstat(1)} style={{cursor: "pointer", outline: "none"}}>Enable</button>
                    <button className={"enbtn"+(state.can_transfer > 0 ? " imp" : "")} type="button" onClick={(e) => this.tstat(0)} style={{cursor: "pointer", outline: "none"}}>Disabled</button>
                  </>}
                </div>
              </div>
            </li>
          </ul>
        </div>



        {app.permit("View and edit Basic Information") && cards.length ?
          <div className='detail-row' style={{height: "auto", background: "transparent"}}>
            <div className='my-cards'>
              {cards
                ? cards.map((data) => (
                    <DebitCard
                      {...data}
                      deleteCard={() => this.deleteCard(data.id, data.PAN)}
                      key={`${Math.random()}1-${Math.random()}-${Math.random()}`}
                    />
                  ))
                : null}
            </div>
          </div> : null}

      </div>
	 )
	}

}

export default ProfileDetails;
