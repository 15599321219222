import React from "react";
import "./index.scss";
import app from '../../../services/app';
import Wallet from "../../../themes/images/tradeDashboard/wallet.svg";

const FinancialDetails = ({ balance, handleClick }) => {
  return (
    <div className="financial-details">
      <div className="financial-info">
        <div className="wallet-section">
        {/*<img src={Wallet} alt="wallet-icon" />*/}
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.8802 7.88237H18.4044V5.02347C18.4044 3.85448 17.4528 2.90698 16.2879 2.90698H15.8491L15.8532 1.84464C15.8532 1.0571 15.2133 0.417236 14.4258 0.417236H1.73503C0.787531 0.417236 0.0123052 1.18016 0 2.12765C0 2.13586 0 2.14406 0 2.15226V16.1555C0 16.9431 0.639869 17.5829 1.4274 17.5829H16.292C17.461 17.5829 18.4085 16.6313 18.4085 15.4665V12.6035H18.8843C19.4996 12.6035 20 12.103 20 11.4878V8.99804C19.9959 8.38278 19.4955 7.88237 18.8802 7.88237ZM1.73503 1.40165H14.4258C14.6678 1.40165 14.8687 1.59853 14.8687 1.84053L14.8646 2.90698H1.73503C1.32075 2.90698 0.984413 2.57064 0.984413 2.15637C0.984413 1.74209 1.32075 1.40165 1.73503 1.40165ZM17.42 15.4624C17.42 16.0858 16.9114 16.5944 16.2879 16.5944H1.4233C1.1813 16.5944 0.980312 16.3975 0.980312 16.1514V3.71912C1.21001 3.82987 1.46431 3.8914 1.73503 3.8914H16.2879C16.9114 3.8914 17.42 4.40001 17.42 5.02347V7.88237H14.0607C13.4454 7.88237 12.945 8.38278 12.945 8.99804V11.4878C12.945 12.103 13.4454 12.6035 14.0607 12.6035H17.42V15.4624ZM19.0115 11.4878C19.0115 11.5616 18.9541 11.619 18.8802 11.619H14.0648C13.991 11.619 13.9336 11.5616 13.9336 11.4878V8.99804C13.9336 8.92831 13.991 8.86678 14.0648 8.86678H18.8802C18.95 8.86678 19.0115 8.92421 19.0115 8.99804V11.4878Z" fill={app.custom("primary")} />
        </svg>

          <h4>Financial Details</h4>
        </div>
        <div className="financial-balance">
          <p>Balance</p>
          <h3>{balance}</h3>
        </div>
      </div>
      <button onClick={handleClick}>Add a Card</button>
    </div>
  );
};

export default FinancialDetails;
