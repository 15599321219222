import $ from 'jquery';
import app from './app';

const socketPlug = async () => {
  window.WebSocketPlug = window.WebSocketPlugged ? window.WebSocketPlug : await new WebSocket(app.hostURL("socket", 1));

  window.WebSocketPlug.addEventListener('open', async () => {
    const try_again = async () => {
      setTimeout(() => {
        console.log("attempting to conecct");
        if(window.WebSocketPlug.readyState) {
          window.WebSocketPlugged = true;
          console.log("Conected");
          // if(app.cpage() == "chats") {
          //   $(window).trigger("chatRenewSocket");
          // } else {
            $(window).trigger("renewSocket");
          // }
          $(".App-accs, .page-header.balance").css({opacity: 1});
        } else {
          window.WebSocketPlugged = false;
          try_again();
        }
      }, 500);
    };
    try_again();
  });

  $(window).on("reconnect", () => {
    return socketPlug();
  });
  
  window.WebSocketPlug.onclose = async (e) => {
    window.WebSocketPlugged = false;
    setTimeout(() => {
      socketPlug();
    }, 1000);
  };
};

export default socketPlug;