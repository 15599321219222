import React, { Component, createRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import $ from 'jquery';
import moment from 'moment';
import ChartModule from '../chartModule/chart';
import server from '../../services/server';
import app from '../../services/app';

class Chart extends Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = createRef();
    this.chart = createRef();
    this.resizeObserver = createRef();
    this.profile = app.profile();
    this.pair = '';
    this.data = [];
    this.loadSeries = true;
    this.currentGrpahType = "candle";
    this.lineDataSeries = [];
    this.historyData = [];
    this.seriesIterator = 0;
    this.loadHistory = 0;
    this.lastPlotable = {};
    this.duplicator = "";
    this.graphSwitcher = false;
    this.lastServerResponse = [];
    this.dataPlotSeries = [];
    this.historySeries = [];
    this.historySeriesPair = "";
    this.realTimeListener = true;
    this.currentPairData = null;
    this.lastFetch = null;
    this.checkingAnalysis = false;
    this.chartData1 = {graph: "candle", pair: "", level: "1d"};

    this.state = {
      selectedOption: 'crypto',
      currentPairs: [],
      allPairs: app.allPairs(),
      pair: '',
      analysis: null,
      selectedPair: '',
      beginner: localStorage.getItem("avariz_graph") ? false : true,
      currentPairData: null,
      buy: 0,
      sell: 0,
      spread: 0,
      high: 0,
      low: 0,
      col: '12',
      confirmtext: "",

      historyLevel: "1M",
      historyLevel1: "1D",
      historyLevel2: "1M",
      historyLevel3: "1M",
      historyLevel4: "1M",
      historyLevel5: "1M",
      historyLevel6: "1M",

      intervalLevel: "",
      intervalLevel1: "",
      intervalLevel2: "",
      intervalLevel3: "",
      intervalLevel4: "",
      intervalLevel5: "",
      intervalLevel6: "",

      graphType1: "candle",
      graphType2: "candle",
      graphType3: "candle",
      graphType4: "candle",
      graphType5: "candle",
      graphType6: "candle",

      selectedOption1: this.props.pair ? this.props.pair.type : 'forex',
      selectedOption2: 'forex',
      selectedOption3: 'forex',
      selectedOption4: 'forex',
      selectedOption5: 'forex',
      selectedOption6: 'forex',

      pair1: this.props.pair ? this.props.pair.pair : "",
      pair2: "",
      pair3: "",
      pair4: "",
      pair5: "",
      pair6: "",

      buyandsellModal: false,
      buyandsellAct: 'buy',
      buyandsellConfirmed: false,
      showLoader: false,
      instruments: []
    };

  }

  switchGraphTypeTo = async (type) => {
    this.currentGrpahType  = type;
    this.graphSwitcher     = true;
    this.chart.current.removeSeries(this.chartSeries);
    this.loadHistorical(this.state.historyLevel);
  }

  loadHistorical = async (h = null) => {
    h = h ? h : this.state.historyLevel;
    this.setGraphType(this.currentGrpahType, 0);

    setTimeout(() => { $("#switch-history").removeClass("_active"); }, 10);
    let upm = {"1d": [2, "hours"], "1w": [7, "days"], "1m": [1, "months"], "6m": [6, "months"], "1y": [12, "months"]};
        upm = upm[h.toLowerCase()];

    if(this.setGraphType(this.currentGrpahType, 3)) {
      this.setState({showLoader: true});
      this.loadSeries = false;
      this.setState({historyLevel: h});
      this.chart.current.applyOptions({
        timeScale: {
          rightOffset: 12,
          barSpacing: 3,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: '#fff000',
          visible: true,
          timeVisible: true,
          secondsVisible: false,
        },
      });

      this.forceUpdate();

      try {

        const convertDateToAnotherTimeZone = (date, timezone) => {
          const dateString = date.toLocaleString('en-US', {
            timeZone: timezone
          });
          return new Date(dateString);
        }

        const getOffsetBetweenTimezonesForDate = (date, timezone1, timezone2) => {
          const timezone1Date = convertDateToAnotherTimeZone(date, timezone1);
          const timezone2Date = convertDateToAnotherTimeZone(date, timezone2);
          return timezone1Date.getHours() - timezone2Date.getHours();
        }

        let graphOffset = 0, graphOff = 0;
        let pairMaster  = this.treatPair(this.pair);

        this.historyData = [];
        this.historySeries = [];

        if(h.toLowerCase() != "1d") {
          this.historySeriesPair = "";

          this.loadSeries = false;
          this.seriesIterator = 0;

          let history = await server.historicalData(pairMaster, "1d", {
            from: moment().subtract(upm[0], upm[1]).unix(),
            to: moment().unix()
          });
          this.setState({showLoader: false});

          let data = history.data.result;

          const _off = getOffsetBetweenTimezonesForDate(new Date, Intl.DateTimeFormat().resolvedOptions().timeZone, data.meta.exchangeTimezoneName);
          graphOff  = (_off+1)*3600;

          for (let r = 0; r < data.timestamp.length; r++) {
            let plt = {
              Date:    data.timestamp[r]+graphOff,
              Open:    data.indicators.quote[0].open[r],
              High:    data.indicators.quote[0].high[r],
              Low:     data.indicators.quote[0].low[r],
              Close:   data.indicators.quote[0].close[r],
              Volume:  data.indicators.quote[0].volume[r]
            }
            this.historyData.push(this.graphData2(plt, pairMaster));
          }

          if(this.loadHistory > 0) {
            this.chart.current.removeSeries(this.chartSeries);
            this.setGraphType(this.currentGrpahType, 0);
            this.plotGraph(this.historyData);
            this.chart.current.timeScale().fitContent();
            this.chart.current.timeScale().scrollToPosition(1, true);
          } else {
            this.duplicator = $('.chart').html();
            this.chart.current.removeSeries(this.chartSeries);
            this.setGraphType(this.currentGrpahType, 0);
            this.plotGraph(this.historyData);
            this.chart.current.timeScale().fitContent();
            this.chart.current.timeScale().scrollToPosition(1, true);
          }
          ++this.loadHistory;
          return true;
        }

        this.historySeriesPair = pairMaster;

        let check_for_update = async (pair, firstUpdate = false) => {
          if(this.historySeriesPair == pair) {
            setTimeout(async () => {
              console.log("-- checking_for_update for", pair);
              if(this.historySeriesPair == pair) {
                try {
                  let _history = this.graphSwitcher ? this.lastServerResponse : await server.historicalData(pair, "1m", {
                    from: moment().subtract(2, "hour").unix(),
                    to: moment().unix()
                  });
                  this.lastServerResponse = _history;
                  let _data               = _history.data.result;
                  this.graphSwitcher      = false;
                  this.setState({showLoader: false});

                  const offset = getOffsetBetweenTimezonesForDate(new Date, Intl.DateTimeFormat().resolvedOptions().timeZone, _data.meta.exchangeTimezoneName);
                  graphOffset  = (offset+1)*3600;

                  let _plotHistory = () => {
                    if(this.historySeriesPair == pair) {
                      let prelength = this.historySeries.length;
                      this.historySeriesPair = pair;
                      this.historySeries = [];
                      
                      try {
                        for (let x = 0; x < _data.timestamp.length; x++) {
                          let lastOpen = x == (_data.timestamp.length - 1);
                          let _plt;
                          if(lastOpen) {
                            let gst = app.guessTimate(_data.indicators.quote[0].open[x], lastOpen);
                            _plt = {
                              Date:  _data.timestamp[x]+graphOffset, Open: gst, High: gst, Low: gst, Close: gst, Volume: _data.indicators.quote[0].volume[x]
                            };
                          } else {
                            _plt = {
                              Date:  _data.timestamp[x]+graphOffset,
                              Open:  app.guessTimate(_data.indicators.quote[0].open[x], lastOpen),
                              High:  _data.indicators.quote[0].high[x],
                              Low:  _data.indicators.quote[0].low[x],
                              Close:  _data.indicators.quote[0].close[x],
                              Volume:  _data.indicators.quote[0].volume[x]
                            }
                          }
                          let plot = this.graphData2(_plt, pair);
                          this.historySeries.push(plot);
                        }
                      } catch (error) {
                        return null;
                      }

                      if(this.historySeries.length) {
                        this.loadSeries = true;
                        this.seriesIterator = 0;
                        this.chart.current.removeSeries(this.chartSeries);
                        this.setGraphType(this.currentGrpahType, 0);
                        this.plotGraph(this.historySeries);
                        // this.plotGraph(this.historyData.concat(this.historySeries));

                        if(firstUpdate) {
                          this.chart.current.timeScale().setVisibleRange({
                            from: _data.timestamp[parseInt(_data.timestamp.length/2)]+graphOffset,
                            to: _data.timestamp[_data.timestamp.length - 1]+graphOffset
                          });
                        } else {
                          if(prelength < this.historySeries.length) {
                            this.chart.current.timeScale().scrollToPosition(1, true);
                          }
                        }
                      }
                    }
                  }
                  if(this.historySeriesPair == pair) {
                    _plotHistory();
                    if(this.historySeries.length) {
                      setTimeout(() => {
                        if(this.historySeriesPair == pair) {
                          _plotHistory();
                        }
                      }, 2.65 * 1000);
                    check_for_update(pair);
                  } else {
                    console.log("No data for", pair, "oo");
                  }
                }
                } catch (e) {
                  check_for_update(pair);
                  console.log("-- Update ERR");
                  throw e;
                  return e;
                }
              }
            }, firstUpdate ? 0 : 5 * 1000);
          }
        }

        check_for_update(pairMaster, true);

      } catch(e) {
        this.setState({showLoader: false});
        return e;
      }
      this.setState({showLoader: false});
    }
  }

  setGraphType = async (type, no = 1) => {
    this.seriesIterator = 0;
    var option = {
        upColor: '#00B061',
        downColor: '#FF3031',
        scaleMargins: { bottom: 0.4, top: 0.4 },
        entireTextOnly: true,
        borderDownColor: '#FF3031',
        borderUpColor: '#00B061',
        wickDownColor: '#c4c4c4',
        wickUpColor: '#c4c4c4',
      };
    if(no) {
      this.chart.current.removeSeries(this.chartSeries);
    }
    this.currentGrpahType = type;
    if(type == "candle") {
      this.chartSeries = this.chart.current.addCandlestickSeries(option);
    } else if(type == "line") {
      this.chartSeries = this.chart.current.addLineSeries(option);
    } else if(type == "area") {
      this.chartSeries = this.chart.current.addAreaSeries(option);
    } else if(type == "bar") {
      this.chartSeries = this.chart.current.addBarSeries(option);
    } else if(type == "hist") {
      this.chartSeries = this.chart.current.addHistogramSeries(option);
    }

    this.chart.current.applyOptions({
        watermark: {
            color: 'rgba(67, 95, 118, 0.4)',
            visible: true,
            text: '',
            fontSize: 24,
            horzAlign: 'left',
            vertAlign: 'bottom',
        },
        priceScale: {
            autoScale: true,
            alignLabels: true,
            drawTicks: true,
            scaleMargins: { bottom: 0.1, top: 0.2 }
        },
        localization: {
          locale: 'en-US',
          priceFormatter: (price) => {
            return Number(String(price).substr(0, 7));
          }
        },
    });

    return true;

  }

  switchGraphType = (id) => {
    let el = document.getElementById(id);
    if(el.className == "_active") {
      el.classList.remove("_active");
    } else {
      el.classList.add("_active");
    }
  }

  setHistoryGraph = (id) => {
    let el = document.getElementById(id);
    if(el.className == "_active") {
      el.classList.remove("_active");
    } else {
      el.classList.add("_active");
    }
  }

  componentWillUnmount() {
    this.realTimeListener = false;
  }

  componentWillUpdate() {
    const stockToDisplay = this.props.hotStocks.filter((pair) => {
      if(pair.pair) {
        return pair.pair.toLowerCase().match(this.pair.toLowerCase()) || this.pair.toLowerCase() == pair.pair.toLowerCase();
      }
    });

    if(stockToDisplay.length) {
      if(stockToDisplay[0] != this.state.currentPairData) {
        this.currentPairData = stockToDisplay[0];
      }
    }

  }

  socketInit = () => {
    window.WebSocketPlug.addEventListener('message', ({data}) => {
      try {
        let message = JSON.parse(`${data}`);
        let payload = message.payload;
        switch(message.event) {
          case "GET_CONVERSION":
          if(payload.user == app.id() && payload.account == app.account() && payload.preload && payload.pair == this.state.pair1) {
            let analysis = { conversion_1: payload.conversion_1, conversion_2: payload.conversion_2 };
            this.checkingAnalysis = false;
            this.setState({
              analysis: analysis
            });
            console.log(analysis, "<<<<<<<<<<<<<<<");
          }
          break;
        }
      } catch (e) {
        throw e;
      }
    });
  }

  async componentDidMount() {
    this.realTimeListener = true;

    const stockToDisplay = this.props.hotStocks.filter((pair) =>
      pair.pair.toLowerCase().match(this.pair.toLowerCase()),
    );

    if(stockToDisplay.length) {
      this.currentPairData = stockToDisplay[0];
    }

    $(window).on("renewSocket", () => this.socketInit());
    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }

    $(window).on("resetBuySellpair", () => {
      if(this.props.pair) {
        this.resetAnalysis(this.props.pair.pair, this.props.pair.type.toLowerCase());
      }
    });

    this.setState({ showLoader: true });
    this.plotGraphData();
  }

  plotGraphDataInit = async () => {
    try {
      let allPairs  = this.state.allPairs;
      let opts      = Object.keys(allPairs).join(",");
      let forex     = "forex";

      if(!Object.keys(allPairs).length || opts != app.data("asset")) {
        const req   = await server.getAllPairs();
        allPairs    = req.data;
        forex       = Object.keys(allPairs)[0];
        app.allPairs(allPairs);
      }

      const instruments = Object.keys(allPairs);
      this.pair         = allPairs[forex][0];
      this.checkingAnalysis = false;

      this.setState({
        analysis:       null,
        allPairs:       allPairs,
        selectedOption: forex,
        selectedOption1: forex,
        currentPairs:   allPairs[forex],
        selectedPair:   allPairs[forex][0],
        pair1:          allPairs[forex][0],
        instruments:    instruments
      });

    } catch (error) {
      return error.message;
    }
  }

  treatPair = (pair) => {
    if(pair == undefined) {
      return this.pair;
    }
    return pair.indexOf(" ") > -1 ? pair.split(" ")[0].trim() : pair.trim();
  }

  graphData = (data) => {
    return {
      time: data.when / 1000,
      open: parseFloat(data.open),
      high: parseFloat(data.high),
      low: parseFloat(data.low),
      close: parseFloat(data.close),
      ask: parseFloat(data.ask),
      spread: parseFloat(data.spread),
      bid: parseFloat(data.bid),
      pair: data.pair,
    };
  }

  graphData2 = (data, pair) => {
    let ret = {
      time: data.Date,
      open:  parseFloat(data.Open),
      high:  parseFloat(data.High),
      low:   parseFloat(data.Low),
      close: parseFloat(data.Close),
      ask:   parseFloat(data.Open),
      spread:parseFloat(parseFloat(data.High) - parseFloat(data.Low)),
      bid: parseFloat(data.Open),
      pair: pair,
    };
    return ret;
  }

  plotGraphData = async (p = "") => {
    if(!p.trim().length) {
      await this.plotGraphDataInit();
    }
  }

  plotGraph = (data) => {
    if (typeof data === 'object' && this.treatPair(data.pair) === this.treatPair(this.pair)) {
      let plot_data = data;
      let not_raw   = Object.keys(plot_data).indexOf("time") > -1;

        if(this.currentGrpahType == "candle" || this.currentGrpahType == "bar") {
          this.seriesIterator += 1;
          this.chartSeries.setData(plot_data);
        } else {
          let plots = [];
          for (var i = 0; i < plot_data.length; i++) {
            let plot = {time: plot_data[i].time, value: plot_data[i].open, color: "#03cf9e"};
            plots.push(plot);
          }
          this.seriesIterator += 1;
          this.chartSeries.setData(plots);
        }
      
    }
  }

  handleOptionsChange = (e) => {
    this.pair = this.state.allPairs[e.target.value.toLowerCase()][0];
    this.currentPairData = null;
    this.checkingAnalysis = false;
    this.setState({
      analysis: null,
      currentPairData: null,
      selectedOption: e.target.value,
      currentPairs: this.state.allPairs[e.target.value.toLowerCase()],
      selectedPair: this.pair,
    });

    this.setGraphType(this.currentGrpahType, 1);
    this.plotGraphData(this.state.selectedPair);
  };

  setNewPairData = (e) => {
    this.pair = e.target.value;
    this.setState({ selectedPair: e.target.value });
    // this.chart.current.removeSeries(this.chartSeries);
    this.setGraphType(this.currentGrpahType, 1);
    this.plotGraphData(this.state.selectedPair);
  }

  addComparism = () => {
    for (var i = 2; i <= 6; i++) {
      if(!$(".chart-section-"+i).length) {
        $(window).trigger("_closeTrade");
        switch(i) {
          case 2:
            this.setState({pair2: this.state.selectedPair});
          break;
          case 3:
            this.setState({pair3: this.state.selectedPair});
          break;
          case 4:
            this.setState({pair4: this.state.selectedPair});
          break;
          case 5:
            this.setState({pair5: this.state.selectedPair});
          break;
          case 6:
            this.setState({pair6: this.state.selectedPair});
          break;
        }
        break;
      }
    }
    setTimeout(() => {
      this.setState({col: $(".multiple-chart-section").length > 1 ? "6" : "12"});
    }, 0);
  }

  resetAnalysis = (p, s, r = false) => {
    this.checkingAnalysis = false;
    this.setState({pair1: p, selectedOption1: s, analysis: null});
    if(r) {
      this.props.onPairChange(p);
    }
  }

  closeComparism = (c) => {
    switch(c) {
      case 2:
        this.setState({pair2: ""});
      break;
      case 3:
        this.setState({pair3: ""});
      break;
      case 4:
        this.setState({pair4: ""});
      break;
      case 5:
        this.setState({pair5: ""});
      break;
      case 6:
        this.setState({pair6: ""});
      break;
    }
    setTimeout(() => {
      this.setState({col: $(".multiple-chart-section").length > 1 ? "6" : "12"});
    }, 0);
  }

  render() {
    window.hotStockData = this.props.hotStocks;
    if(this.state.pair1.length) {
      const stockToDisplay = this.props.hotStocks.filter((pair) => {
        if(pair.pair) {
          return pair.pair.toLowerCase().match(this.state.pair1.toLowerCase()) || this.state.pair1.toLowerCase() == pair.pair.toLowerCase();
        }
      });
      if(stockToDisplay.length) {
        this.currentPairData = stockToDisplay[0];
      }
    }

    const _currentPairData = {
      info:   this.currentPairData ? this.currentPairData        : {},
      closed: this.currentPairData ? this.currentPairData.closed : true,
      buy:    this.currentPairData ? this.currentPairData.ask    : this.state.buy,
      sell:   this.currentPairData ? this.currentPairData.bid    : this.state.sell,
      high:   this.currentPairData ? this.currentPairData.high   : this.state.high,
      low:    this.currentPairData ? this.currentPairData.low    : this.state.low,
      type:   this.currentPairData ? this.currentPairData.type   : this.state.selectedOption,
      ask_up: this.currentPairData ? this.currentPairData.ask_up : 1,
      bid_up: this.currentPairData ? this.currentPairData.bid_up : 1,
    complete: this.currentPairData ? this.currentPairData.complete : 0,
      spread: this.currentPairData ? (this.currentPairData.high - this.currentPairData.low) : this.state.spread
    }

    return (
      <div className='trade-comp-container'>
        <div className="row" style={{display: "flex"}}>
          <div className="col-md-12">
            {this.state.pair1.length ?
              <ChartModule
                {...this.props}
                col={this.state.col}
                beginner={this.state.beginner}
                beginnerChange={(e) => {
                  let c = e.target.checked;
                  if(c) {
                    localStorage.setItem("avariz_graph", 1);
                  } else {
                    localStorage.removeItem("avariz_graph");
                  }
                  this.setState({beginner: c});
                  window.location.href = "";
                  // $(window).trigger("destroyGraph");
                }}
                instruments={this.state.instruments}
                tvs={_currentPairData?.info["tv_symbol"]}
                changePair={this.resetAnalysis}
                key={this.state.historyLevel1+"-"+this.state.intervalLevel1+"-"+this.state.pair1+"-1"} // +"-"+this.state.beginner
                chartKey={this.state.historyLevel1+"-"+this.state.intervalLevel1+"-"+this.state.pair1}
                changeInterval={(i) => this.setState({intervalLevel1: i})}
                changeLevel={(l, i) => this.setState({historyLevel1: l, intervalLevel1: i})}
                changeGraph={(g) => this.setState({graphType1: g})}
                selectedOption={this.state.selectedOption1}
                intervalLevel={this.state.intervalLevel1}
                historyLevel={this.state.historyLevel1}
                addComparism={this.addComparism}
                graph={this.state.graphType1}
                pair={this.state.pair1}
                auto={this.props.auto}
                ki={1}
              />
            : null }
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;