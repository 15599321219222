import React from "react";
import $ from 'jquery';
import { NavLink } from 'react-router-dom';
import wallet from "../../../themes/images/Wallet.svg";
import show from "../../../themes/images/Show.svg";
import app from '../../../services/app';
import "./hover.scss";

const Hover = ({ src, name, email, balance, logout }) => {
  let isclk = app.isVerified() || app.isAdmin();
  let setbu = Intl.supportedValuesOf ? true : false;

  return (
    <div className="profile-dropdown">
      <div className="section1">
        <img src={src} alt="" />
        <div>
          <h3>{name}</h3>
          <p className="grey">{email}</p>
        </div>
      </div>
      <div className="section2">
        <ul>
          {/* <li onClick={() => {localStorage.setItem("TSelected", "deposit"); }}><img src={deposit} /><a className="navtotransaction" href={isclk ? "/Transactions" : "/Book"}>Deposit Funds</a></li>
          {
            app.profile()["can_transfer"] ? <li onClick={() => {localStorage.setItem("TSelected", "transfer"); }}><img src={transfer} /><a className="navtotransaction" href={isclk ? "/Transactions" : "/Book"}>Transfer Funds</a></li>
            : null
          } */}
          <li><span className="grey">{app.l("FINANCE")}</span></li>
          <li style={{position: "relative"}}><h3><img src={wallet} className="wall" /> ${app.nwc(balance)}</h3> {/*<img src={show} alt="" className="show" />*/}</li>
          <li><NavLink to="/Transactions">{app.l("Make Transaction")}</NavLink></li>
        </ul>
      </div>
      <div className="section3">
        <ul>
          {setbu ? <li style={{marginBottom: "10px"}} onClick={() => {
            $(window).trigger("toggleSettings");
          }}><span>{app.l("Settings")}</span></li> : null}
          <li style={{marginBottom: "10px"}}><NavLink to="/Profile">{app.l("Profile")}</NavLink></li>
          <li style={{marginBottom: "10px"}} onClick={logout}>{app.l("Logout")}</li>
        </ul>
      </div>
    </div>
  );
};

export default Hover;