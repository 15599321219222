import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import app from '../../services/app';
import downVlv from '../../themes/images/down.png';

class EditTrade extends Component {
  constructor(props) {

    super(props);

    let crate = this.props.trade.current_rate;

    this.state = {
      cvert: crate,
      loading: crate > 0 ? false : true,
      errorMessage: crate > 0 ? '' : 'Loading current rate...',
      forex: 0,
      erate: 0,
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("ain")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  componentDidMount () {
    $(window).on("renewSocket", () => this.socketInit());

    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }

    $("#e-rate").val(this.props.trade.order_rate);

    let crate = this.props.trade.current_rate;
    
    if(window.WebSocketPlugged && this.props.trade.type.toLowerCase() == "forex") {
      window.WebSocketPlug.send(JSON.stringify({"event": "GET_CONV", "payload": {
        account:   "*",
        user:      app.id(),
        type:      this.props.trade.type,
        pair:      this.props.trade.instrument.trim(),
      }}));
    }

    if(this.props.trade.type.toLowerCase() != "forex") {
      this.setState({loading: false});
    }

    $("#e-profit").val(this.props.trade.estim_profit);

    if(String(this.props.trade.estim_rate).length) {
      $("#e-rate").val(this.props.trade.estim_rate);
      $("#trange").val(this.props.trade.estim_time);
      $("#r-settings").val(this.props.trade.estim_repeat);
    } else {
      $("#e-rate").val(this.props.trade.order_rate);
    }

    this.setState({forex: this.props.trade.type.toLowerCase() == "forex" ? 1 : 0});
  }

  socketInit = () => {
    window.WebSocketPlug.addEventListener('message', ({data}) => {
      try {
        let message = JSON.parse(`${data}`);
        let payload = message.payload;
        switch(message.event) {
          case "GET_CONV":
          if(payload.user == app.id() && payload.account == "*" && payload.pair == this.props.trade.instrument.trim()) {
            // console.log(payload.cvert, "====c");
            this.setState({cvert: payload.cvert, loading: false, errorMessage: ''});
          }
          break;
        }
      } catch (e) {
        throw e;
      }
    });
  }

  btnSave = async () => {
    let trade = this.props.trade;
    if($("#e-rate").val() < 0) {
      this.setState({errorMessage: "Custom rate cannot be less than zero. Please use a lower P/L range"});
      return false;
    }

    if($("#e-profit").val().length && $("#e-rate").val().length) {} else {
      return this.setState({errorMessage: "Please fill all necessary fields"});
    } this.setState({loading: true});

    try {
      let estim = await server.editTrade(trade.user_id, trade.id, {
        "ep":     parseFloat($("#e-profit").val()),
        "er":     parseFloat($("#e-rate").val()),
        "time":   $("#trange").val(),
        
        "repeat": $("#r-settings").val(),
        "crate":  trade.current_rate
      });
      if(estim.status == 200 && estim.data.status) {
        this.props.cancel();
      } else {
        this.setState({errorMessage: estim.data.message});
      }
    } catch (error) {
      return error.message;
    }
    this.setState({loading: false});
  }

  btnReset = async () => {
    let trade = this.props.trade;
    this.setState({loading: true});
    try {
      let estim = await server.resetTrade(trade.user_id, trade.id);
      if(estim.status == 200 && estim.data.status) {
        this.props.cancel();
      } else {
        this.setState({errorMessage: estim.data.message});
      }
    } catch (error) {
      return error.message;
    }
    this.setState({loading: false});
  }

  trange = (e) => {
    let v = e.target.value.toLowerCase();
  }

  erate = (p) => {
        p = this.props.trade.mode.trim().toLowerCase() == "buy" ? p : p * -1;
    let trade = this.props.trade;
    let erate = trade.order_rate;
    let crl = String(trade.current_rate).split(".");
        crl = crl.length > 1 ? crl[1].length : 0;

    let cvert = this.state.cvert > 0 ? this.state.cvert : this.props.trade.current_rate;

    if(cvert) {
      if(this.state.forex) {
        if(parseFloat(trade.unit_pd) > 0) {
          erate = (p / parseFloat(trade.volume) * (parseFloat(trade.unit_pd) / 100)) + parseFloat(trade.order_rate);
        } else {
          erate = parseFloat(((p / cvert) + Number(trade.lots)) * (trade.order_rate / Number(trade.lots)));
        }
        
        $("#e-rate").val(erate.toFixed(crl));
        if(erate < 0) {
          $("._tcb").addClass("fade");
        } else {
          $("._tcb").removeClass("fade");
        }
        // this.setState({erate: erate});
      } else {
        let erate = parseFloat(((parseFloat(p) + (trade.order_rate * Number(trade.lots))) * trade.order_rate) / (trade.order_rate * Number(trade.lots)));
        $("#e-rate").val(erate.toFixed(crl));
        if(erate < 0) {
          $("._tcb").addClass("fade");
        } else {
          $("._tcb").removeClass("fade");
        }
        // this.setState({erate: erate});
        // console.log(erate, "=(", parseFloat(p), "+", trade.order_rate * Number(trade.lots), ")*", trade.order_rate, "/", (trade.order_rate * Number(trade.lots)));
      }
    }
  }

  render () {
    const { cancel, trade } = this.props;

    return (
      <div className='overlay ain' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6 align="center">Edit for ({trade.volume}) volume lots of ({trade.instrument})</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <div className="i-col">
                  <label>Actual Profit($)</label>
                  <input className="" id="c-profit" type="number" readOnly={true} defaultValue={Number(trade.real_profit).toFixed(2)} />
                </div>
                <div className="i-col">
                  <label>Custom Profit($)<span className="requ">*</span></label>
                  <input className="" id="e-profit" type="number" onChange={(e) => this.erate(e.target.value)} />
                </div>
                <div className="i-col">
                  <label>Actual rate</label>
                  <input className="" id="c-rate" type="number" readOnly={true} value={trade.current_rate} />
                </div>
                <div className="i-col">
                  <label>Custom rate</label>
                  <input className="" id="e-rate" type="number" readOnly={true} />
                  {/* value={this.state.erate.toFixed(crl)}*/}
                </div>
                <div className="i-col">
                  <label>Make changes<span className="requ">*</span></label>
                  <select className="select-box" id="trange" defaultValue={trade.estim_time}>
                    <option value="5">Next 5 mins</option>
                    <option value="10">Next 10 mins</option>
                    <option value="15">Next 15 mins</option>
                    <option value="30">Next 30 mins</option>
                    <option value="60">Next 1 hours</option>
                    <option value="120">Next 2 hours</option>
                  </select>
                </div>
                <div className="i-col">
                  <label>Repeat sequence<span className="requ">*</span></label>
                  <select className="select-box" id="r-settings" defaultValue={trade.estim_repeat}>
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                  </select>
                </div>
                {this.state.errorMessage.length ? (this.state.errorMessage == 'Loading current rate...' ? <span className='txt-default'>Loading current rate...</span> : <span className='err'>{this.state.errorMessage}</span>) : null}
                {trade.custom ? <p style={{justifyContent: "space-between", margin: "0px"}} className='_tcb'>
                  <button disabled={this.state.loading} className="sacc" style={{background: "rgb(234 255 250)", color: "#03cf9e", marginRight: "10px"}} onClick={this.btnReset}>RESET</button>
                  <button disabled={this.state.loading} className="sacc" style={{marginLeft: "10px"}} onClick={this.btnSave}>UPDATE</button>
                </p> : <button disabled={this.state.loading} className="sacc _tcb" onClick={this.btnSave}>Save settings</button>}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default EditTrade;
