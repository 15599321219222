import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import downVlv from '../../themes/images/down.png';
import server from '../../services/server';
import app from '../../services/app';


class Exp extends Component {
  constructor(props) {

    super(props);

    this.state = {
      errorMessage: '',
      active: true,
      sent: false
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("ass")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  render () {
    const { cancel } = this.props;

    return (
      <div className='overlay ass' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal' style={{overflow: "hidden"}}>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
                <h2 style={{fontSize: "22px", marginBottom: "15px"}}>Select fields to export</h2>
                <p className="inps" style={{marginTop: "5px"}}>
                    <span>
                        <div className="check-row _r2"><label class="checkbox-container cc">First name <input type="checkbox" data-key="First name" checked data-val="first_name" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Last name <input type="checkbox" data-key="Last name" checked data-val="last_name" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Email <input type="checkbox" data-key="Email" checked data-val="email" /><span class="checkmark"></span></label></div>
                    </span>
                    <span>
                        <div className="check-row _r2"><label class="checkbox-container cc">Gender <input type="checkbox" data-key="Gender" data-val="gender" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Phone number <input type="checkbox" data-key="Phone number" data-val="phone_number" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Assigned Admin <input type="checkbox" data-key="Assigned Admin" data-val="aid" /><span class="checkmark"></span></label></div>
                    </span>
                    <span>
                        <div className="check-row _r2"><label class="checkbox-container cc">Date of Reg. <input type="checkbox" data-key="Date of Reg." data-val="create_time" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Country <input type="checkbox" data-key="Country" data-val="country" /><span class="checkmark"></span></label></div>
                        <div className="check-row _r2"><label class="checkbox-container cc">Source <input type="checkbox" data-key="Source" data-val="source" /><span class="checkmark"></span></label></div>
                    </span>

                    <button className="sacc" onClick={() => {
                        let ret = [], ret2 = [];
                        $(".check-row._r2 input[type=checkbox]:checked").each((k, v) => {
                            ret.push($(v).data("val"));
                            ret2.push($(v).data("key"));
                        })
                        this.props.success([Object.values(ret), Object.values(ret2)]);
                    }}>Export</button>
                </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Exp;