import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Container from '../container/index';
import UsersTable from  './userstable.jsx';
import '../../components/standard/standard.scss';
import './index.scss';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';
import transfer from "../../themes/images/transfer-2.svg";
import TableFilters from '../../components/tablefilters/index';
import TradeNotFound from '../../components/tradeNotFound/index';
import exportIcon from "../../themes/images/export.png";
import check from '../../themes/images/check-mark.png';
import Pagination from '../../components/paginationTwo/index';
import Spinner from '../../components/spinner/index';
import TransferUser from '../../components/transferLead/index';
import bcall from '../../themes/images/book-call.png';
import Assign from '../../components/re-assign/index';
import Exp from '../../components/re-assign/exp';
import { ConfirmModal, CallBack } from '../../components/popups/index';
import '../../components/standard/table.scss';
import server from '../../services/server';
import app from '../../services/app';


class Users extends Component {
  constructor(props) {
    super(props);

    let leads = this.props.location.pathname.toLowerCase().split("/")[1] == "leads";
    this.state = {
      page_no: 1,
      callback: false,
      page_size: app.maxrow,
      filter: '',
      ccf: '',
      uid_clist: [],
      desk: props.match.params.desk || "all",
      assign: false,
      users: [],
      cflags: [],
      checkone: [],
      leads,
      checkall: false,
      admins: [],
      clients: [],
      exp: false,
      showLoader: true,
      data: [],
      delete: 0,
      astat: 0,
      desks: [],
      callback: false,
      callbackTxt: '',
      cfil: '',
      soft_delete: "all",
      utype: "all",
      restore: false,
      confirmID: '',
      confirmModal: false,
      transferUser: false,
      transferData: null
    }
  }

  async componentDidMount() {
    await this.getDesks();
    await this.getAllAdmins();
    await this.getAllUsers();
    if(app.master()) {
      await this.getAllClients();
    }

    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });
  }
  
  getDesks = async () => {
    try {
      let d = await server.getDesks();
      this.setState({desks: d.data.desks});
    } catch(e) {
      return e;
    }
  }

  getAllUsers = async () => {
    try {
      let users = await server.getAllUsers(null, this.state.leads);
      this.setState({users: users.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  getAllAdmins = async () => {
    try {
      let users = await server.getAllAdmins();
      this.setState({admins: users.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  deleteUser = async () => {
    this.setState({showLoader: true});
    try {
      let del = await server.deleteUser(this.state.confirmID);
      this.setState({showLoader: false, callback: true, callbackTxt: 'Delete successfull'});
      await this.getAllUsers();
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  restoreUser = async () => {
    this.setState({showLoader: true});
    try {
      let del = await server.restoreUser(this.state.confirmID);
      this.setState({showLoader: false, callback: true, callbackTxt: 'Restore successfull'});
      await this.getAllUsers();
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  accManager = (aid) => {
    let assto = this.state.admins.filter((o) => o.user_id == aid);
    return assto.length ? (assto[0].first_name+" "+assto[0].last_name).ucwords() : "-";
  }

  checkAll = (e) => {
    this.setState({checkall: e.target.checked, checkone: []});
  }

  checkOne = (e, uid) => {
    if(this.state.checkall) {
      return false;
    }
    let checkone = this.state.checkone;
    if(e.target.checked) {
      let _checkone = Object.values(checkone);
      if(_checkone.indexOf(uid) < 0) {
        checkone.push(uid);
      }
    } else {
      checkone = checkone.filter((cv) => cv == uid);
    }
    this.setState({checkone, checkall: false});
  }

  reAssAll = () => {
    if($(".check-row.r input[type=checkbox]:is(:checked)").length) {
      let cones = [];
      $(".check-row.r input[type=checkbox]:is(:checked)").each((ck, cr) => {
        cones.push($(cr).data("uid"));
      });
      cones = Object.values(cones);
      let eus = this.state.users.filter((eu) => cones.indexOf(eu.user_id) > -1);
      if(eus.length) {
        this.setState({uid_clist: eus, assign: true, data: eus[0]});
      }
    } else {
      this.setState({uid_clist: []});
    }
  }

  exportCsv = (d = true, rdata = null) => {
    if(d) {
      return this.setState({exp: true});
    } this.setState({exp: false});
    let eus = this.state.users;
    if(this.state.checkone.length) {
      let _checkone = Object.values(this.state.checkone);
      eus = eus.filter((eu) => _checkone.indexOf(eu.user_id) > -1);
    } else if(this.state.checkall && $(".check-row.r input[type=checkbox]:not(:checked)").length) {
      let cones = [];
      $(".check-row.r input[type=checkbox]:not(:checked)").each((ck, cr) => {
        cones.push($(cr).data("uid"));
      });
      cones = Object.values(cones);
      eus = eus.filter((eu) => cones.indexOf(eu.user_id) < 0);
    }
    const export_data = [rdata[1]];
    rdata = rdata[0];
    eus.forEach((v, k) => {
      let pdata = [];
      for (let i = 0; i < rdata.length; i++) {
        let _pdt = v[rdata[i]];
        if(rdata[i] == "aid") {
          _pdt = this.accManager(_pdt);
        }
        pdata.push(_pdt);
      }
      export_data.push(pdata);
    });
    let csvContent = "data:text/csv;charset=utf-8," + export_data.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  render() {
    let { page_no, page_size, users, astat, checkone, desk, desks, ccf, filter, cfil, soft_delete, utype, clients } = this.state;
    let _checkone = Object.values(checkone);

    filter = filter.toLowerCase();
    cfil = cfil.toLowerCase();
    ccf = ccf.toLowerCase(); 

    if(cfil.length && cfil != "all") {
      users = users.filter((c) => {
        return (
          c.host.toLowerCase().match(cfil)
        );
      });
    }

    if(ccf.length && ccf != "all") {
      users = users.filter((c) => {
        return (c.country_code.toLowerCase() == ccf);
      });
    }

    if(desk != "all") {
      users = users.filter((c) => {
        return (
          c.lang == desk
        )
      });
    }

    if(filter.length) {
      users = users.filter((c) => {
        return (
          c.first_name.toLowerCase().match(filter.toLowerCase()) ||
          c.last_name.toLowerCase().match(filter.toLowerCase()) ||
          c.email.toLowerCase().match(filter.toLowerCase()) ||
          c.source.toLowerCase().match(filter.toLowerCase()) ||
          c.phone_number.toLowerCase().match(filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(filter.toLowerCase()) ||
          (c.first_name + " " + c.last_name).toLowerCase().match(filter.toLowerCase()) ||
          (c.last_name + " " + c.first_name).toLowerCase().match(filter.toLowerCase())
        );
      });
    }

    if(astat > 0) {
      users = users.filter((c) => {
        return c.astat == astat;
      });
    }

    if(soft_delete != "all") {
      users = users.filter((c) => {
        return c.soft_delete == soft_delete;
      });
    }

    let max_rows = users.length;
    let stt = (page_no-1)*page_size;
    let max = stt+page_size;
        max = max > max_rows ? max_rows : max;
      users = users.slice(stt, max > max_rows ? max_rows : max);

    return (
      <Container>
      <Spinner showSpinner={this.state.showLoader} />
      <div className="col-12" id="users-container">
        <div className="users-section-right">
          <Breadcrumbs breads={"Home, Desks, "+(this.state.leads ? "Leads" : "Users")} />
          <h1 className="page-title">{this.state.leads ? 'Leads' : 'Users'}</h1>
          <TableFilters table="users" desks={desks} leads={this.state.leads} clients={this.state.clients}
            cch={(e) => {
              this.setState({cfil: e.target.value});
            }} ccd={(e) => {
              this.setState({desk: e.target.value});
            }} sst={(e) => {
              this.setState({astat: e.target.value});
            }} ccf={(e) => {
              this.setState({ccf: e.target.value, page_no: 1});
            }}
            export={this.state.checkall || this.state.checkone.length ? this.exportCsv : null}
            assign={() => {
              this.reAssAll();
            }}
            desk={desk}
            sd={(e) => this.setState({soft_delete: e.target.value, page_no: 1})} search={(e) => this.setState({filter: e.target.value})} />
          
          {
            this.state.assign ?
              <Assign
                cancel={() => this.setState({assign: false})}
                success={() => { this.setState({assign: false, callback: true, callbackTxt: 'Assigned successfully'}); this.getAllUsers(); }}
                data={this.state.data}
                admins={this.state.admins}
                users={this.state.uid_clist}
              /> : null
          }

          {
            this.state.exp ?
              <Exp
                cancel={() => this.setState({exp: false})}
                success={(rd) => { this.exportCsv(false, rd); }}
              /> : null
          }

            {
              this.state.transferUser ? 
              <TransferUser
                cancel={() => this.setState({transferUser: false})}
                sending={() => this.setState({showLoader: true})}
                success={() => { this.setState({transferUser: false, showLoader: false, callback: true, callbackTxt: 'Transfer successful'}); this.getAllUsers(); }}
                data={this.state.transferData}
              /> : null
            }

            <ConfirmModal
              head={(this.state.delete > 0 ? "Permanently delete" : (this.state.restore ? "Restore" : "Delete"))+" this user?"}
              text="Click YES to confirm"
              show={this.state.confirmModal}
              cancel={() => this.setState({confirmModal: false})}
              confirm={() => { this.setState({confirmModal: false, delete: 0}); this.state.restore ? this.restoreUser() : this.deleteUser(); }}
            />

            <CallBack
              head="Success"
              show={this.state.callback}
              text={this.state.callbackTxt}
              cancel={(e) => this.setState({callback: false})}
            />

            <ul className="table-header">
              <li className="check-row"><label class="checkbox-container"><input type="checkbox" onChange={(e) => this.checkAll(e)} /><span class="checkmark"></span></label></li>
              <li className='spres'>USER ID</li>
              <li>FULLNAME</li>
              <li>PHONE</li>
              {/* <li>PRESENCE</li> */}
              <li>COUNTRY</li>
              <li>{app._aff_() ? "FTD" : "BALANCE"}</li>
              <li>ACC. MANAGER</li>
              {/* <li>SOURCE</li> */}
              <li>ACTION</li>
            </ul>

            { users.length > 0 ?
              users.map((user) => (
                <ul className="table-body" key={`${Math.random()} ${Math.random()}`}>
                  <li className="check-row r"><label class="checkbox-container"><input type="checkbox" data-uid={user.user_id} onChange={(e) => this.checkOne(e, user.user_id)} defaultChecked={this.state.checkall || _checkone.indexOf(user.user_id) > -1} /><span class="checkmark"></span></label></li>
                  <li className='spres'><Link className="txt-info" to={"/usersprofile/"+user.user_id}>{app.uid(user.user_id)}</Link>{app.presence(user, true)}</li>
                  <li className='spres'><Link className="txt-info" to={"/usersprofile/"+user.user_id}>{(user.first_name+" "+user.last_name).ucwords()}</Link>{app.statusType(user, false)}</li>
                  <li><span className="txt-default blurc">{app.blur(user.phone_number)}</span></li>
                  {/* <li>{app.presence(user, false)}</li> */}
                  {/* <li><span className="txt-default">{user.create_time.length ? (app.cleanDate(user.create_time).split(" ")[0]+" "+app.cleanDate(user.create_time).split(" ")[1]) : "NOT STATED"}</span></li> */}
                  <li style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end'}}>{user.country.length ? user.country : '-'}{user.source.length ? <><br /><small style={{color: "#FFA500", fontSize: "12px"}}>Source: {user.source}</small></> : ''}</li>
                  <li><span className="txt-success">{app._aff_() ? (user.bal > 0.00 ? "YES" : "-") : <>{app.currency()}{user.bal.toFixed(2)}</>}</span></li>
                  <li><span className="txt-default">{user.aid.length ? this.accManager(user.aid) : '-'}</span></li>
                  {/* <li><span className="txt-default">{user.source == 'undefined' ? "CRM" : (user.source.length ? user.source : "-")}</span></li> */}
                  <li>
                    <a href={"tel:"+user.phone_number}><img className='bcall-2' src={bcall} alt='' /></a>
                    {app._aff_() ? null : <>
                    {user.utype == -1 || user.leads == 1 ?
                      <img src={transfer} alt="" style={{height: "22px", width: "22px", marginLeft: "4px", marginRight: "5px", cursor: "pointer"}}
                        onClick={() => {
                          this.setState({transferUser: true, transferData: user});
                        }}
                      /> : null}
                    {user.soft_delete > 0 ? (
                      <>
                        <img src={check} onClick={() => { this.setState({confirmID: user.user_id, confirmModal: true, restore: true})}} style={{height: "18px", width: "18px", marginLeft: "4px", marginRight: "10px", cursor: "pointer"}} />
                        <svg onClick={() => this.setState({confirmID: user.user_id, confirmModal: true, restore: false, delete: user.soft_delete})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="red"/>
                        </svg>
                      </>
                    ) : (
                      <>
                        <img src={exportIcon} onClick={() => {
                          // this.reAssAll();
                          this.setState({data: user, assign: true});
                        }} className="tb-action" width="20" height="20" style={{position: "relative", left: "-2px"}} />
                        <svg onClick={() => this.setState({confirmID: user.user_id, confirmModal: true, restore: false})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#FFE602"/>
                        </svg>
                      </>
                    )}</>}
                  </li>
                </ul>
              )) : null
            }
            {/*<TradeNotFound text={"No assigned user"} />*/}
            <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>
        </div>
      </div>
      </Container>
    );
  }
};

export default Users;
