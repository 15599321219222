import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Container from '../container/index';
import Pagination2 from '../../components/pagination2/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';
import server from '../../services/server';
import app from '../../services/app';
import TableFilters from '../../components/tablefilters/index';
import UsersTable from  '../../containers/users/userstable.jsx';

import '../../components/standard/standard.scss';
import './index.scss';

class Lists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navi: 1,
      desk: props.match.params.desk || "all",
      stat: {users: '-', admins: '-', leads: '-'},
      time: {users: '', admins: '', leads: ''}
    }

  }

  selectList = (e) => {
    
  }

  async componentDidMount () {
    this.getUstat();
  }

  getUstat = async () => {
    try {
      let stat = await server.getUstat(this.state.desk);
      this.setState({stat: stat.data.stat, time: stat.data.time, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  render() {
    const { navi, desk } = this.state;
    let _nos = app.super() || app.permit("Create admin") ? "(3)" : "(2)";
    return (
      <Container>
      <div className="col-12" id="lists-container">
        <div className="lists-section-right">
            <Breadcrumbs breads="Home, Desks, Desk" />

            <h1 className="page-title">Desk</h1>

            <Ptab
              tabs={"Desk library "+_nos} handleClick={() => {}} active={"Desk library "+_nos} />

              <ul className="table-header">
                <li>NAME</li>
                <li>SIZE</li>
                {/* <li>TYPE</li> */}
                <li>LAST UPDATED</li>
              </ul>

            <Link to={"/Users/"+desk}>
              <ul className="table-body _users">
                <li><span className="txt-light">Users</span></li>
                <li><span className="txt-light">{this.state.stat.users}</span></li>
                {/* <li><span className="txt-light">Automatic</span></li> */}
                <li><span className="txt-light">{this.state.time.leads ? moment(this.state.time.leads).calendar() : '-'}</span></li>
              </ul>
            </Link>

            <Link to={"/Leads/"+desk}>
              <ul className="table-body _users">
                <li><span className="txt-light">Leads</span></li>
                <li><span className="txt-light">{this.state.stat.leads}</span></li>
                {/* <li><span className="txt-light">Automatic</span></li> */}
                <li><span className="txt-light">{this.state.time.users ? moment(this.state.time.users).calendar() : '-'}</span></li>
              </ul>
            </Link>

            {app.super() || app.permit("Create admin") ?
              <Link to={"/Admins/"+desk}>
                <ul className="table-body _admins">
                  <li><span className="txt-light">Admins</span></li>
                  <li><span className="txt-light">{this.state.stat.admins}</span></li>
                  {/* <li><span className="txt-light">Automatic</span></li> */}
                  <li><span className="txt-light">{this.state.time.admins ? moment(this.state.time.admins).calendar() : '-'}</span></li>
                </ul>
              </Link>
            : null}
        </div>
      </div>
      </Container>
    );
  }

  Thesvg = () => {
    return (
      <>
      <svg className="fil-u" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0L11.1962 6H0.803848L6 0Z" fill="#C4C4C4"/>
      </svg>
      <svg className="fil-d" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 6Z" fill="#C4C4C4"/>
      </svg>
    </>
    )
  }
};

export default Lists;
