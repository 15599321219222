import React, { Component, createRef } from 'react';
import Container from '../container/index';
import { createChart, CrosshairMode } from 'lightweight-charts';
import calendar from "../../themes/images/calendar-search.png";
import dash0 from '../../themes/images/dash0.PNG';
import server from '../../services/server';
import app from '../../services/app';
import $ from 'jquery';
import moment from 'moment';
import './canvasjs.min.js';
import './utils.js';
import './analyser.js';
import './index.scss';



class Dashboard extends Component {
	constructor(props) {		
		super(props);
	    
	    this.chartContainerRef = createRef();
	    this.chart = createRef();

	    this.state = {
	    	active: 'transactions',
	    	plot: {}
	    }
	}

	replot = () => {
	    this.chart.current = createChart(this.chartContainerRef.current, {
	      width:  this.chartContainerRef.current.clientWidth,
	      height: this.chartContainerRef.current.clientHeight,
	      layout: {
	        backgroundColor: '#00606640',
	        textColor: 'rgba(255, 255, 255, 0.9)',
	      },
	      grid: {
	        vertLines: {
	          color: '#00606640',
	        },
	        horzLines: {
	          color: '#00606640',
	        },
	      },
	      rightPriceScale: {
	        visible: true,
	      },
	      crosshair: {
	        mode: CrosshairMode.Normal,
	      },
	      priceScale: {
	        borderColor: '#00606640',
	      },
	      timeScale: {
	        borderColor: '#00606640',
	        timeVisible: true,
	        secondsVisible: false,
	      },
	    });

		const chart = this.chart.current;
		const lineSeries = chart.addAreaSeries();
		lineSeries.setData(this.state.plot[this.state.active]);
		this.chart.current.timeScale().fitContent();
		this.chart.current.timeScale().scrollToPosition(1);
	}

	async componentDidMount() {
		// let gdt = await server.dashboardData();
		// let plot = {
		// 	transactions: gdt.data.transactions,
		// 	trade: 		  gdt.data.trade,
		// 	users: 		  [],
		// 	sales: 		  [],
		// 	leads: 		  []
		// };

		// this.setState({plot: plot});
		// this.replot();

	    // this.chart.current.applyOptions({
	    //     watermark: {
	    //         color: 'rgba(67, 95, 118, 0.4)',
	    //         visible: true,
	    //         text: '',
	    //         fontSize: 24,
	    //         horzAlign: 'left',
	    //         vertAlign: 'bottom',
	    //     },
	    //     priceScale: {
	    //         autoScale: true,
	    //         alignLabels: true,
	    //         drawTicks: true,
	    //         scaleMargins: { bottom: 0.1, top: 0.2 }
	    //     },
	    //     localization: {
	    //       locale: 'en-US',
	    //       priceFormatter: (price) => {
	    //         return Number(String(price).substr(0, 7));
	    //       }
	    //     },
	    // });

		// var presets = window.chartColors;
		// var utils = window.Samples.utils;
		// var inputs = {
		// 	min: -100,
		// 	max: 100,
		// 	count: 8,
		// 	decimals: 2,
		// 	continuity: 1
		// };

		// function generateData(config) {
		// 	return utils.numbers(Chart.helpers.merge(inputs, config || {}));
		// }

		// function generateLabels(config) {
		// 	return utils.months(Chart.helpers.merge({
		// 		count: inputs.count,
		// 		section: 3
		// 	}, config || {}));
		// }

		// var options = {
		// 	maintainAspectRatio: false,
		// 	spanGaps: false,
		// 	elements: {
		// 		line: {
		// 			tension: 0.4
		// 		}
		// 	},
		// 	plugins: {
		// 		filler: {
		// 			propagate: false
		// 		}
		// 	},
		// 	scales: {
		// 		xAxes: [{
		// 			display: false,
		// 			ticks: {
		// 				autoSkip: false,
		// 				maxRotation: 0
		// 			}
		// 		}],
		// 		yAxes: [{
		// 			display: false
		// 		}]
		// 	}
		// };
		// utils.srand(1000);
		// new Chart('canvas', {
		// 	type: 'line',
		// 	data: {
		// 		labels: generateLabels(),
		// 		datasets: [{
		// 			backgroundColor: utils.transparentize(presets.yellow),
		// 			borderColor: presets.yellow,
		// 			data: gdata,
		// 			label: 'Transactions',
		// 			fill: 'start'
		// 		}]
		// 	},
		// 	options: Chart.helpers.merge(options, {
		// 		title: {
		// 			text: '',
		// 			display: false
		// 		},
		// 		elements: {
		// 			line: {
		// 				tension : 0.4
		// 			}
		// 		}
		// 	})
		// });

		// eslint-disable-next-line no-unused-vars
		function randomize() {
			var seed = utils.rand();
			Chart.helpers.each(Chart.instances, function(chart) {
				utils.srand(seed);

				chart.data.datasets.forEach(function(dataset) {
					dataset.data = generateData();
				});

				chart.update();
			});
		}
















		var lineChartData = {
			labels: ['', '', '', '', '', '', ''],
			datasets: [{
				label: 'Online',
				borderColor: window.chartColors.green,
				backgroundColor: window.chartColors.green,
				fill: true,
				data: [
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor()
				],
				yAxisID: 'y-axis-1',
			}, {
				label: 'Offline',
				borderColor: window.chartColors.red,
				backgroundColor: window.chartColors.red,
				fill: true,
				data: [
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor(),
					randomScalingFactor()
				],
				yAxisID: 'y-axis-2'
			}]
		};

		var ctx = document.getElementById('ooff').getContext('2d');
		window.myLine = Chart.Line(ctx, {
			data: lineChartData,
			options: {
				responsive: true,
				hoverMode: 'index',
				stacked: false,
				title: {
					display: false,
					text: 'Chart.js Line Chart - Multi Axis'
				},
				scales: {
					yAxes: [{
						type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: false,
						position: 'left',
						id: 'y-axis-1',
					}, {
						type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
						display: false,
						position: 'right',
						id: 'y-axis-2',

						// grid line settings
						gridLines: {
							drawOnChartArea: false, // only want the grid lines for one axis to show up
						},
					}],
				}
			}
		});


		function randomScalingFactor () {
			return Math.round(Math.random() * 100);
		};

		var config = {
			type: 'pie',
			data: {
				datasets: [{
					data: [
						randomScalingFactor(),
						randomScalingFactor(),
						randomScalingFactor(),
						randomScalingFactor(),
						randomScalingFactor(),
					],
					backgroundColor: [
						window.chartColors.red,
						window.chartColors.orange,
						window.chartColors.yellow,
						window.chartColors.green,
						window.chartColors.blue,
					],
					label: 'Dataset 1'
				}],
				labels: [
					'Red',
					'Orange',
					'Yellow',
					'Green',
					'Blue'
				]
			},
			options: {
				responsive: true
			}
		};
		var ctx = document.getElementById('chart-area').getContext('2d');
		window.myPie = new Chart(ctx, config);

	}
	render() {
		return (
	      <Container>
		      <div className="col-12" id="dash-container">

				
				<div className="can-tab">
					<ul>
						<li className={this.state.active == "transactions" 	? "active" : ""} onClick={() => { this.setState({active: 'transactions'}); this.replot(); }}>Transactions</li>
						<li className={this.state.active == "users" 		? "active" : ""} onClick={() => { this.setState({active: 'users'}); this.replot(); }}>Users</li>
						<li className={this.state.active == "sales" 		? "active" : ""} onClick={() => { this.setState({active: 'sales'}); this.replot(); }}>Sales</li>
						<li className={this.state.active == "leads" 		? "active" : ""} onClick={() => { this.setState({active: 'leads'}); this.replot(); }}>Leads</li>
						<li className={this.state.active == "trades" 		? "active" : ""} onClick={() => { this.setState({active: 'trades'}); this.replot(); }}>Trades</li>
					</ul>
				</div>

				<div id="mcanvas">
					<div ref={this.chartContainerRef}></div>
					<img src={calendar} className="cdar" />
					{/*<canvas id="canvas"></canvas>*/}
				</div>

				<div id="section-2">
					<div className="mcan-1">
						<img src={calendar} className="cdar" />
						<canvas id="ooff"></canvas>
					</div>
					<div className="mcan-2">
						<img src={calendar} className="cdar" />
						<canvas id="chart-area"></canvas>
					</div>
				</div>
				
				{/*
					<br />
					<br />
					<button id="randomizeData">Randomize Data</button>
					<button id="addDataset">Add Dataset</button>
					<button id="removeDataset">Remove Dataset</button>
					<button id="addData">Add Data</button>
					<button id="removeData">Remove Data</button>
				*/}
			</div>
	      </Container>
	    )
	}
}
export default Dashboard;