import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import downVlv from '../../themes/images/down.png';

class AddAccount extends Component {
  constructor(props) {

    super(props);

    let info = false;


    this.state = {
      errorMessage: '',
      account: '0',
      balance: '',
      account_name: ''
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("__fav")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  btnSave = async () => {
    let data = {
      ac: this.state.account,
      ba: parseFloat(document.getElementById("tw-bal").value),
      am: parseFloat(document.getElementById("tw-amt").value),
      ps: document.getElementById("tw-pass").value
    }
    let uid = this.props.uid;
    if(data.ac.trim().length == 0 || data.ba == 0 || !(data.am > 0) || data.ps.trim().length == 0) {
      this.setState({errorMessage: "Please fill all fields"});
      return false;
    } else if(data.am >= data.ba) {
      this.setState({errorMessage: "Amount can not exceed Free Margin"});
      return false;
    }
    this.props.sending();
    try {
      this.setState({errorMessage: ""});
      const acc = await server.withdrawFunds(data.am, "USD", data.ac, this.props.to, true, data.ps, uid);
      this.props.unsending();
      if(acc.status == 200 && acc.data.success) {
        window.showCallback = false;
        this.props.sent();
        this.props.cancelClick();
        this.props.refresh();
      } else {
        this.setState({errorMessage: acc.data.message});
      }
    } catch (error) {
      this.props.unsending();
      return error.message;
    }
  }

  render () {
    const { accounts, cancelClick, confirmClick } = this.props;
    const { information, account, balance } = this.state;
    let bal = accounts.filter((acc) => acc.account_name == account);
        bal = bal.length && account.length ? bal[0].balance : '0.00';
    return (
      <div className='overlay __fav' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancelClick} />
            <div className='bsell-modal-content'>
              {/*<h6>Select a trading account</h6>*/}
              <h6>Create Withdrawal request</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <label>Select trading account</label>
                <select className="accs" id="tw-acc" value={this.state.account} onChange={(e) => this.setState({account: e.target.value})}>
                  <option value="0">---</option>
                  {
                    accounts.map((acc) => {
                      return acc.account_type.toLowerCase() == "live" ? (
                      <option value={acc.account_name} selected={acc.account_name==this.state.account ? "selected" : false} key={`${Math.random()}-${Math.random()}`}>{acc.account_name.ucwords()}</option>
                    ) : null})
                  }
                </select>
                <label>Account Free Margin</label>
                <input className="accs" id="tw-bal" type="text" placeholder='0.00' value={bal} readonly />
                <label>Withdraw amount</label>
                <input className="accs" id="tw-amt" type="number" placeholder='0.00' />
                <label>Enter Admin Password</label>
                <input className="accs" required id="tw-pass" type="password" />
                {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}
                <button className="sacc" onClick={this.btnSave}>Submit</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default AddAccount;
