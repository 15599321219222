import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Container from '../Container/Index';
import $ from 'jquery';
import TableFilters from '../../components/tablefilters/index';
import Pagination from '../../components/Pagination/index';
import server from '../../services/server';
import app from '../../services/app';
import { ConfirmModal } from '../../components/popups/index';
import addstory from '../../themes/images/addstory.png';
import cancel from '../../Assets/cancel.svg';
import leftA from '../../Assets/leftA.svg';
import leftB from '../../Assets/leftB.svg';
import rightA from '../../Assets/rightA.svg';
import rightB from '../../Assets/rightB.svg';
import './News.scss';

class News extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedNews: null,
            showSpinner: false,
            showLoader: false,
            hotStocks: [],
            inews: [],
            featured: [],
            searchR: [],
            abc: "",
            news: [],
            max_rows: 1,
            page_no: 1,
            similar: [],
            readMore: false,
            activeNews: null,
            confirmID: 0,
            confirmModal: false
        }
    }

    async componentDidMount () {
        this.fetchNews();
    }


    fetchNews = async () => {
        this.setState({ showLoader: true });
        try {
            let data = await server.fetchNews();
            this.setState({news: data.data.news, inews: data.data.news, showLoader: false});
        } catch (error) {
            this.setState({ showLoader: false });
            if (!error.response) {
                return error.message;
            }
        }
    }

    deleteNews = async (nid) => {
        this.setState({ showLoader: true });
        try {
            let del = await server.deleteNews(nid);
            window.location.href = "";
        } catch (e) {
            return e;
        }
        this.setState({ showLoader: false });
    }

    showNews = (p) => {
        let stt = (p-1)*5;
        let max = stt+5;
            max = max > this.state.max_rows ? this.state.max_rows : max;
        this.setState({news: this.state.inews.slice(stt, max > this.state.max_rows ? this.state.max_rows : max), page_no: p});
    }

    searchNews = val => {
        let results = [];
        val = val.trim();
        if(val.length) {
            this.state.inews.forEach((news, i) => {
            let pos = news.title.toLowerCase().search(val.trim().toLowerCase());
            news["i"] = i;
            if(pos > -1 && results.length < 7) results.push(news);
            });
        }
        this.setState({searchR: results});
    }

    showSearchedNews = () => {
        return this.state.searchR;
    }

    processNews = (news) => {
        let _news = [];
        news.forEach((val, key) => {
            val["i"] = key;
            if(val["image_original"]) {
            val["image_mini"] = val["image_original"];
            } else if(val["image_thumbnail"]) {
            val["image_mini"] = val["image_thumbnail"];
            } else if(val["cover"]) {
            val["image_mini"] = {url: val["cover"]};
            val["fulltext"] = val["content"];
            val["summary"] = val["content"].length > 50 ? val["content"].substr(0, 50)+"..." : val["content"];
            }
            _news.push(val);
        });
        return _news;
    }

    readNews = async (i, h = -1) => {
        $("#news-container").scrollTop(0);
        this.setState({searchR: []});
        let activeNews = this.state.inews[i];
        let news = activeNews;
        let link = this.state.inews[i].href;
        console.log(link);
        this.setState({ showLoader: true });
        try {
            let pull = await server.pullup(link);
            if(pull.status == 200 && pull.data.length) {
                news["readMore"] = pull.data.replace(/(href="http)/g, 'style="color: #1FCF65 !important;" target="_blank" href="http');
                this.setState({ activeNews : news });
            }
            this.setState({ showLoader: false });
        } catch (error) {
            this.setState({ showLoader: false });
            if (!error.response) {
            return error.message;
            }
        }
    }

    readMore = async (link) => {
        this.setState({ showLoader: true });
        try {
            if(this.state.activeNews.category == "all") {
            let news = this.state.activeNews;
            news["readMore"] = news.fulltext.replace(/(href="http)/g, 'style="color: #1FCF65 !important;" target="_blank" href="http');
            this.setState({ activeNews : news, showLoader: false });
            } else {
            let fulltext = await server.loadLink(link);
            if(fulltext.status == 200 && fulltext.data.length) {
                let news = this.state.activeNews;
                news["readMore"] = fulltext.data.replace(/(href="http)/g, 'style="color: #1FCF65 !important;" target="_blank" href="http');
                this.setState({ activeNews : news });
            }
            this.setState({ showLoader: false });
            }
        } catch (error) {
            this.setState({ showLoader: false });
            if (!error.response) {
            return error.message;
            }
        }
    }

    scrollLA = () => {
        $('.scrollNews').animate( { scrollLeft: '-=516' }, 1000);
        this.setState({abc: "left"});
    }

    scrollRA = () => {
        $('.scrollNews').animate( { scrollLeft: '+=516' }, 1000);
        this.setState({abc: "right"});
    }
    
    render () {
        let inews = this.state.inews.slice(3, this.state.inews.length);
        let abc = this.state.abc;
        return (
            <Container id="news-container-user">

                {(this.state.activeNews) ? (
                    <div className="news-section-left">
                        {/* <TableFilters
                            table="news"
                            keyUp={(e) => this.searchNews(e.target.value)}
                            results={this.state.searchR}
                            showSearchedNews={this.showSearchedNews}
                            readNews={this.readNews}
                        /> */}
                        <img src={cancel} alt="" onClick={() => this.setState({activeNews: null})} className="cancel" />
                        <img src={this.state.activeNews.poster} alt="" className="ifm" />
                        <h1 className="news-header">{this.state.activeNews.head}</h1>
                        <div className="news-content" dangerouslySetInnerHTML={{__html: this.state.activeNews.readMore}} />
                    </div>
                ) : 

                <div className="col-12" id="news-container">
                    <div className='loader-container' style={{ display: this.state.showLoader ? 'block' : 'none' }}>
                        <div className='loader'></div>
                    </div>
                    {this.state.inews.length ?
                    <div className="page-title">
                        <h3 className="h-title">Latest News</h3>
                    </div>: null}

                    <ul id="latnews">
                        { this.state.news.map((news, key) => (
                            <li>
                                <img src={news.poster} alt="" style={{width: "auto"}} loading="lazy" onClick={() => this.readNews(key)} />
                                <div className="ctxt">
                                    <h4>{news.summary}</h4>
                                    <button className="read-more btn btn-primary" onClick={() => this.readNews(key)}>Read More</button>
                                </div>
                            </li>
                            ))
                        }
                    </ul>

                    {this.state.featured.length ?
                    <div className="page-title">
                        <h3 className="h-title">
                            <span>Featured News</span>
                            <span className="ar"><img src={abc == "left" ? leftB : leftA} alt="" onClick={this.scrollLA} /><img src={abc == "right" ? rightB : rightA} alt="" onClick={this.scrollRA} /></span>
                        </h3>
                    </div>: null}
                    
                    <div className="similar-stories alt">
                        <ul className="stories scrollNews">
                        {this.state.featured.map((news, key) => (
                            <li className="todays-li">
                                <div className="ctn">
                                    <img src={news.poster} alt="" className="ss-thumbnail" loading="lazy" />
                                    <div className="right">
                                        <h3 className="n-title">{news.summary}</h3>
                                        {/* <span className="n-summary">{news.summary}</span> onClick={() => this.readNews(news.i, 0)} */}
                                        <button className="read-more btn btn-primary" onClick={() => this.readNews(news.i)}>Read More</button>
                                    </div>
                                </div>
                            </li>
                        ))
                        }
                        </ul>
                    </div>
                </div>}
            </Container>
        );
    }
}

export default withRouter(News);