import React from 'react';
import $ from 'jquery';
import app from '../../services/app';
import './index.scss';

const Spinner = ({ showSpinner }) => {
  if(app.isAdmin()) {
    return showSpinner ? (
      <div class="c-header">
        <div class="c-header-loader">
          <div class="c-slidingLoader">
            <div class="c-slidingLoader-inner">
            </div>
          </div>
        </div>
      </div>
    ) : null;
  } else {
    return (
      <div className='my-spinner'>
        {showSpinner ? (
          <div className='overlay spin'>
            <div className='spinner'></div>
          </div>
        ) : null}
      </div>
    )
  }
};

export default Spinner;