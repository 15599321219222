import React, { Component } from 'react';
import $ from 'jquery';
import { Link, withRouter } from 'react-router-dom';
import server from '../../../services/server';
import app from '../../../services/app';
import Spinner from '../../../components/spinner/index';
import { Closed, MsgAlert } from '../../../components/popups/index';
import TradeNotFound from '../../../components/tradeNotFound/index';
import fullscreen from '../../../themes/images/tradeDashboard/fullscreen.svg';
import efullscreen from '../../../themes/images/tradeDashboard/exit-fullscreen.svg';
import dr from '../../../Assets/dr.svg';
import ug from '../../../Assets/ug.svg';
import EditTrade from '../../../components/editTrade/index';
import gear from '../../../themes/images/gear.png';
import './TradeHistory.scss';

function gri (min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
}

window.PREVIOUS_RATE = Array();

class TradeHistory extends Component {

    constructor (props) {
        super(props);
        this.state = {
          type: 0,
          showSpinner: false,
          showCallBack: false,
          message: '',
          showClosed: false,
          page_size: app.maxrow,
          page_no: 1,
          cancel: 0,
          etrade: false,
          etradata: null,
          filterPair: '',
          filterType: ''
        }
    }

    async componentDidMount () {
      $('.trade-history').on('fullscreenchange webkitfullscreenchange mozfullscreenchange', () => {
        if(!document.fullscreenElement) {
          $('.trade-history').removeClass("exit");
          $('.fullscreen.f1').addClass("active");
          $('.fullscreen.f2').removeClass("active");
        }
      });
    }

    fullscreen = () => {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        $('.trade-history').removeClass("exit");
        $('.fullscreen.f1').addClass("active");
        $('.fullscreen.f2').removeClass("active");
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        var element = $('.trade-history').get(0);
        $('.trade-history').addClass("exit");
        $('.fullscreen.f1').removeClass("active");
        $('.fullscreen.f2').addClass("active");
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      }
    }

    cancelTrade = async (id, account, uid = null) => {
      if(!app.isAdmin() && app.daysToGo(true) < 0) {
        return this.setState({showCallBack: true, message: "An error occured|Cannot cancel trade please contact the Admin."});
      }
        this.setState({showSpinner: true});
        try{
          let close = await server.cancelTrade(id, account, uid);
          if(close.status == 200) {
            $(window).trigger("refreshMod");
            if(!app.isAdmin()) {
              const gp = await server.getProfile();
              app.profile(gp.data.profile);
              $(".ndfxbal").trigger("_refresh");
            }
            this.setState({showSpinner: false, showClosed: true, cancel: 1});
          } else {
            this.setState({showSpinner: false, showClosed: false});
          }
        } catch(e) {
          this.setState({showSpinner: false, showClosed: false});
          return e;
        }
    }
    
    closeTrade = async (id, account, cr, uid = null) => {
        if(!app.isAdmin() && app.daysToGo(true) < 0) {
          return this.setState({showCallBack: true, message: "An error occured|Cannot close trade please contact the Admin."});
        }
        this.setState({showSpinner: true});
        try{
          let close = await server.closeTrade(id, account, cr, uid);
          if(close.status == 200) {
            // $s(window).trigger("refreshMod");
            if(!app.isAdmin()) {
              const gp = await server.getProfile();
              app.profile(gp.data.profile);
              $(".ndfxbal").trigger("_refresh");
            }
            this.setState({showSpinner: false, showClosed: true, cancel: 0});
          } else {
            this.setState({showSpinner: false, showClosed: false});
          }
        } catch(e) {
          this.setState({showSpinner: false, showClosed: false});
          return e;
        }
    }

    profit = (p) => {
      let pr;
      if(p > 0) {
        pr = app.currency();
      } else if(p < 0) {
        pr = '-'+app.currency();
      } else {
        pr = app.currency();
      }
      let profit = p < 0 ? -1 * Number(p) : Number(p);
      return (
        <span className={"pl "+(p > 0 ? "profit" : "loss")} title={pr+app.coma(profit)}>{pr+app.coma(profit.toFixed(2))}<img className="arw" src={p > 0 ? ug : dr} /></span>
      )
    }

    crate = (r, id) => {
        var up = false;
        if(window.PREVIOUS_RATE[id]) {
          up = parseFloat(r) > parseFloat(window.PREVIOUS_RATE[id]);
        }
        setTimeout(() => { window.PREVIOUS_RATE[id] = r; }, 100);
        return (
          <span className={"cr "+(up ? "up" : "down")}>{r}<img className="arw" src={up ? ug : dr} /></span>
        )
    }

    render () {
        let { type, showCallBack, message } = this.state;
        let { trades, loaded, favs, mobile } = this.props;

        if(type > -1) {
            trades = trades.filter((tr) => parseInt(tr.order_status) == type);
        }

        return (
        <>
            <Spinner showSpinner={this.state.showSpinner} />
            <Closed show={this.state.showClosed} cancel={(e) => this.setState({showClosed: false})} type={this.state.cancel} />
            {this.state.etrade && app.isAdmin() ? <EditTrade trade={this.state.etradata} cancel={() => this.setState({etrade: false, etradata: null})} /> : null}
            {showCallBack ? <MsgAlert cancel={(e) => this.setState({showCallBack: false})} message={message} /> : null}

            <div className={"trade-history "+(mobile ? "imobile" : "not-imobile")}>
              {mobile ? 
                <div className="apcc">
                    <select className="App-trds" style={{cursor: "pointer"}} onChange={(e) => {
                      this.setState({type: e.target.value});
                    }} defaultValue={type}>
                      {
                        ["Open trades", "Pending trades", "Closed trades"].map((typ, tyk) => {
                          return (
                            <option key={"tyk-"+tyk} value={tyk}>{typ}</option>
                          )
                        })
                      }
                    </select>
                </div> :
                <ul className="trade-stabs">
                    <li className={type == 0 ? "active secondary-color" : ""} onClick={() => this.setState({type: 0})}>{app.l("Open trades")}</li>
                    <li className={type == 1 ? "active secondary-color" : ""} onClick={() => this.setState({type: 1})}>{app.l("Pending trades")}</li>
                    <li className={type == 2 ? "active secondary-color" : ""} onClick={() => this.setState({type: 2})}>{app.l("Closed trades")}</li>
                    <li className={type == -1 ? "active secondary-color" : ""} onClick={() => this.setState({type: -1})}>{app.l("Trading history")}</li>
                </ul>}

                {mobile ? null : <>
                  <img src={fullscreen} className="fullscreen f1 active" onClick={() => this.fullscreen()} />
                  <img src={efullscreen} className="fullscreen f2" onClick={() => this.fullscreen()} />
                </>}

                {mobile ?
                  <ul className="tmob-hist">
                  {trades.map((t, k) => {
                      let kiy = "trd-"+k, md = k%2 == 1, up = gri(0, 1), pr = gri(0, 1);
                      return (
                        <li key={kiy}>
                          <div>
                            <span className="coll lft"><h4>{t.instrument}</h4><small>{t.type.ucwords()}</small></span>
                            <span className="coll rgt">
                              <small>Profit</small>
                              <span className="pr">{this.profit(t.profit)}</span>
                              <time>{app.split_me(app.c_date_time(t.create_time), ", ", 1)}</time>
                            </span>
                          </div>
                          <div>
                            <span className="coll lft"><small>Rate</small><span className="rt">{t.trade_when.length ? t.trade_when : t.order_rate}</span></span>
                            <span className="coll rgt"><small>Commission</small><span className="pr">{app.currency()}{t.commission.length ? app.coma(Number(t.commission).toFixed(2)) : "0.00"}</span></span>
                          </div>
                          <div>
                            <span className="coll lft"><small>{type == 2 || t.order_status == 2 ? "Closed" : "Current"} Rate</small>{type == 2 || t.order_status == 2 ? <span className="rt">{t.close_rate}</span> : this.crate(Number(t.custom ? t.specific_rate : t.current_rate), "trades-"+t.id+"-"+t.instrument)}</span>
                            <span className="coll rgt"><small>Required Margin</small><span className="pr">{app.currency()}{app.coma(t.order_price)}</span></span>
                          </div>
                          <div>
                            <span className="coll lft"><small>S/L</small><span>{t.stop_loss.length ? t.stop_loss : "--"}</span></span>
                            <span className="coll rgt"><small>T/P</small><span>{t.take_profit.length ? t.take_profit : "--"}</span></span>
                          </div>
                          <div>
                            <span className="coll lft"><span className={"btn "+(t.mode.toUpperCase() == "BUY" ? "b" : "s")}>{t.mode.ucwords()}</span></span>
                            <span className="coll rgt">
                              {type == 0 || type == 1 ? <button onClick={(e) => {
                                if(type == 0) {
                                  this.closeTrade(t.id, t.account, t.current_rate, t.user_id);
                                } else {
                                  this.cancelTrade(t.id, t.account, t.user_id);
                                }
                              }} className="btn app-btn cl-btn">{type == 0 ? 'Close' : 'Cancel'}</button> : null}
                            </span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                : <div className={"table-responsive"+(app.isAdmin() || favs == 0 ? " isa" : "")} id="table-resi-resp">
                    <table className={"table table-hover"+(type == 0 || type == 1 ? "" : " alt")}>
                        <thead>
                            <tr>
                                <th>INSTRUMENT</th>
                                <th className="m2">TYPE</th>
                                <th className="m2">TIME</th>
                                <th>PRICE</th>
                                <th>RATE</th>
                                <th>COMMISSION</th>
                                <th>S/L</th>
                                <th>T/P</th>
                                <th className="imin">{type == 2 ? "CLOSE RATE" : type == -1 ? "CLOSE RATE" : "CURRENT RATE"}</th>
                                <th>PROFIT</th>
                                <th>DETAIL</th>
                                {type == 0 || type == 1 ? <th style={{minWidth: app.isAdmin() ? "105px" : "50px"}}>ACTION</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {trades.map((t, k) => {
                                let kiy = "trd-"+k, md = k%2 == 1, up = gri(0, 1), pr = gri(0, 1);
                                return (
                                    <tr key={kiy}>
                                        <td>{t.instrument}</td>
                                        <td className="m2"><span className="dt">{t.type.toUpperCase()}</span>{t.auto_tr ? <span className='aut'>Auto Trader</span> : null}</td>
                                        <td className="m2">
                                          <span className="dt">{app.split_me(app.c_date_time(t.create_time), ",", 0)}</span>
                                                         <time>{app.split_me(app.c_date_time(t.create_time), ",", 1)}</time>
                                        </td>
                                        <td><span className="pr">{app.currency()}{app.coma(t.order_price)}</span></td>
                                        <td><span className="rt">{t.trade_when.length ? t.trade_when : t.order_rate}</span></td>
                                        <td><span className="pr">{app.currency()}{t.commission.length ? app.coma(Number(t.commission).toFixed(2)) : "0.00"}</span></td>
                                        <td>{t.stop_loss.length ? t.stop_loss : "--"}</td>
                                        <td>{t.take_profit.length ? t.take_profit : "--"}</td>
                                        <td className="imin">{type == 2 || t.order_status == 2 ? <span className="rt">{t.close_rate}</span> : this.crate(Number(t.custom ? t.specific_rate : t.current_rate), "trades-"+t.id+"-"+t.instrument)}</td>
                                        <td>{this.profit(t.profit)}</td>
                                        <td><span className={t.mode.toUpperCase() == "BUY" ? "b" : "s"}>{t.mode.toUpperCase()}</span></td>
                                        {type == 0 || type == 1 ? <td className={t.is_closed ? 'p_closed ac' : ''} style={{minWidth: app.isAdmin() ? "105px" : "50px"}}>
                                            <button onClick={(e) => {
                                              if(t.is_closed && !app.isAdmin()) {
                                                this.setState({showCallBack: true, message: t.instrument+" market is closed|Please contact support or wait till market is open."});
                                              } else {
                                                if(type == 0) {
                                                  this.closeTrade(t.id, t.account, t.current_rate, t.user_id);
                                                } else {
                                                  this.cancelTrade(t.id, t.account, t.user_id);
                                                }
                                              }
                                            }} className="btn app-btn cl-btn">{type == 0 ? 'Close' : 'Cancel'}</button>
                                          {(app.isAdmin() && app.permit("View and edit trades")) || app.master() ? (<img src={gear} onClick={(e) => this.setState({etrade: true, etradata: t})} className="edit-trade" />) : null}
                                        </td> : null}
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                    {loaded && !trades.length ? <TradeNotFound text={"Empty"} nobox /> : null}
                    {loaded || trades.length ? null : <div className='loader-container' style={{ display : 'block', overflow: "hidden" }}><div className='loader' style={{margin: "0 auto"}}></div></div>}
                </div>}
            </div>
        </>
        );
    }
}

export default TradeHistory;