import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import '../../components/standard/userprofile.scss';
import userDp from '../../themes/images/dummydp.png';
import files from "./files.svg";
import notes from '../../themes/images/profile/notes.png';
import tasks from '../../themes/images/profile/tasks.png';
import email from '../../themes/images/profile/email.png';
import calls from '../../themes/images/profile/calls.png';
import down from '../../themes/images/profile/down.svg';
import meetings from '../../themes/images/profile/meetings.png';
import app from '../../services/app';

class UsersProfile extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.profile;

    let perc = 0;
    
    if(this.state.dod.length) perc += 25;
    if(this.state.bank_card.length) perc += 25;
    if(this.state.identity_proof.length) perc += 25;
    if(this.state.residence_proof.length) perc += 25;

    this.state.perc = perc;
    this.perc = perc;
  }

  pTabs = (p) => {
    $("#ptb--"+p).trigger("click");
    setTimeout(() => {
      if($("#ptb--"+p+"--action").length) {
        $("#ptb--"+p+"--action").trigger("click");
      }
    }, 250);
  }

  async componentWillUpdate () {}

  btnDown = (e) => {
    if(this.state.perc > 0) {
      window.open(this.state.dod);
      setTimeout(() => {
        window.open(this.state.bank_card);
        setTimeout(() => {
          window.open(this.state.identity_proof);
          setTimeout(() => {
            window.open(this.state.residence_proof);
          }, 1000);
        }, 1000);
      }, 1000);
    }
  }

  accManager = (aid) => {
    if(window.listofadmins && aid.length) {
      let assto = window.listofadmins.filter((o) => {
        return o.user_id == aid
      })[0];
      return (assto.first_name+" "+assto.last_name).ucwords();
    } else {
      return "----";
    }
  }

  render () {
    let { aid } = this.state;
    let perc = 0;
    if(this.props.profile.dod.length) perc += 25;
    if(this.props.profile.bank_card.length) perc += 25;
    if(this.props.profile.identity_proof.length) perc += 25;
    if(this.props.profile.residence_proof.length) perc += 25;

    return (
      <div className="user-profile-body">
        <img src={(this.props.profile.profile_image.length ? this.props.profile.profile_image+"?"+this.state.modify_time : userDp)} className="udp" alt="" />
        {/*<span class={"presence "+(this.state.online ? "online" : "offline")}></span>*/}
        <h2 className="proflle-name">{this.state.first_name+" "+this.state.last_name}</h2>
        <h6 className="user-id">{app.uid(this.state.user_id)}</h6>

        {/*<ul className="profile-actions">
          <li onClick={() => this.pTabs("files")}><img src={files} /><span>Files</span></li>
          <li onClick={() => this.pTabs("notes")}><img src={notes} /><span>Notes</span></li>
          <li onClick={() => this.pTabs("tasks")}><img src={tasks} /><span>Tasks</span></li>
          <li onClick={() => this.pTabs("emails")}><img src={email} /><span>Emails</span></li>
          <li onClick={() => this.pTabs("calls")}><img src={calls} /><span>Calls</span></li>
        </ul>*/}

        <div className="user-info">
          {/*<svg className="drp-down" width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.8469 0.816516L11.0167 0.65937L11.1865 0.816516L12.1698 1.72654L12.3681 1.91003L12.1698 2.09351L6.66981 7.18348L6.5 7.34063L6.33019 7.18348L0.830195 2.09351L0.63193 1.91003L0.830195 1.72654L1.81353 0.816516L1.98333 0.65937L2.15314 0.816516L6.5 4.83932L10.8469 0.816516Z" fill="#03CF9E" stroke="#03CF9E" stroke-width="0.5"/>
          </svg>*/}

          <h4>About User</h4>

          <ul>
            <li><span className="min-100">Account type :</span> Basic</li>
            <li><span className="min-100">Date of Birth :</span> {app.altField(this.state.dob)}</li>
            <li><span className="min-100">Gender :</span> {this.state.gender}</li>
            <li><span className="min-100">Phone :</span> <font className="blurc">{app.blur(this.state.phone_number)}</font></li>
            <li><span className="min-100">Email :</span> <font className="blurc" >{app.blur(this.state.email.length > 20 ? this.state.email.substr(0, 19)+"..." : this.state.email)}</font></li>
            <li><span className="min-100">Country :</span> {this.state.country}</li>
            <li><span className="min-100">City :</span> {app.altField(this.state.city)}</li>
            <li><span className="min-100">Presence :</span> {this.state.online ? "Online" : "Offline"}</li>
            <li><span className="min-100">Reg. Date :</span> {this.state.create_time.length ? (app.cleanDate(this.state.create_time).split(" ")[0]+" "+app.cleanDate(this.state.create_time).split(" ")[1]) : "NOT STATED"}</li>
          </ul>

          <span className="txt-default" style={{fontSize: "13px"}}>KYC Progress</span>
          <div class="w3-light-grey w3-round-xlarge">
            <div class={"w3-container w3-color w3-round-xlarge p-"+perc} style={{width: perc+"%"}}>{perc}%</div>
          </div>

          <table>
            <tr>
              <td>
                <span className="txt-default">Account status</span>
                <span className={"td-"+(this.props.profile.kyc ? "b" : "v")}>{this.props.profile.kyc ? "" : "NOT "}VERIFIED</span>
              </td>
              <td>
                <span className="txt-default">Total Free Margin</span>
                <span className="td-b">{app.currency()} {this.state.balance}</span>
              </td>
            </tr>
            {
              app._aff_() ? null :
              <tr>
                <td className="full" align="center">
                  <button className="btn-down" style={{cursor: "pointer", outline: "none"}} disabled={!perc} onClick={this.btnDown}><img src={down} style={{position: "relative", top: "2px"}} /> Download KYC</button>
                </td>
              </tr>
            }
            <tr>
              <td className="full">
                <span className="txt-default">ACCOUNT MANAGER</span>
                <span className="td-b">
                  {aid.length ? <Link style={{color: "inherit"}} to={"/adminsprofile/"+aid}>{this.accManager(aid)}</Link> : 'Super Admin'}
                </span>
              </td>
            </tr>
          </table>
        </div>

      </div>
    );
  }
}

export default UsersProfile;
