import React, { Component } from 'react';
// import './index.scss';
import $, { data } from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import downVlv from '../../themes/images/down.png';
import server from '../../services/server';
import app from '../../services/app';

class TransferUser extends Component {
  constructor(props) {

    super(props);

    this.state = {
      errorMessage: '',
      active: this.props.data.leads > 0 ? "user" : "lead",
      sent: false
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("ass")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  btnSave = async () => {
    if(!$("#ycomment").val().trim().length) {
      return this.setState({errorMessage: 'Please type a comment'});
    }
    this.setState({errorMessage: '', sent: true});
    let data = {
      c: $("#ycomment").val(),
      i: this.props.data.id,
      l: 0,
      u: 0,
      // l: this.state.active == "lead" ? 1 : 0,
      // u: this.state.active == "lead" ? -1 : 0
    };
    try {
      this.props.sending();
      let u = await server.transferU(data, this.props.data.user_id);
      this.props.success();
    } catch (e) {
      this.setState({sent: false});
      return e;
    }
    this.props.cancel();
    this.setState({sent: false});
  }

  render () {
    const { cancel, data } = this.props;
    const { active } = this.state;

    return (
      <div className='overlay ass' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6 style={{marginBottom: "1em"}} className="cpri">Transfer to Users</h6>
              {/* <ul className="imarket">
                <li className={active == "lead" ? '_active' : ''} onClick={() => this.setState({active: "lead"})}><span>Transfer to Lead</span></li>
                <li className={active == "lead" ? '' : '_active'} onClick={() => this.setState({active: "user"})}><span>Transfer to Users</span></li>
              </ul> */}
              <div className="form-group">
                <textarea className="form-control" id="ycomment" style={{backgroundColor: "transparent", color: "#fff", borderColor: "#606060", height: "120px"}} placeholder="Comment">{data.comment}</textarea>
              </div>
              {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}
              <div className="form-group">
                <button className="btn btn-block sacc" style={{width: "100%", marginTop: "15px", color: "#fff"}} onClick={this.btnSave} disabled={this.state.sent}>Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default TransferUser;