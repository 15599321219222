import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Container from '../container/index';
import Pagination2 from '../../components/pagination2/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';

import _do from '../../Assets/setup/dom.svg';
import _ap from '../../Assets/setup/ap.svg';
import _sv from '../../Assets/setup/sv.svg';
import _cr from '../../Assets/setup/cr.svg';
import _ac from '../../Assets/setup/ac.svg';
import _st from '../../Assets/setup/_st.svg';
import _em from '../../Assets/setup/em.svg';
import _ca from '../../Assets/setup/ca.svg';
import _cu from '../../Assets/setup/cu.svg';
import _bu from '../../Assets/setup/bug.png';
import _lo from '../../Assets/setup/lo.svg';

import server from '../../services/server';
import app from '../../services/app';

import '../../components/standard/standard.scss';
import './setup.scss';

class Setup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navi: 1,
    }
  }

  render() {
    const { navi } = this.state;
    return (
      <Container>
      <div className="col-12" id="setup-container">
        <div className="setup-section-right">
            <Breadcrumbs breads="Home, Settings" />

            <h1 className="page-title">App settings</h1>
            <Ptab tabs="Settings (11)" handleClick={() => {}} active="Settings (11)" />

            <ul className="table-header">
              <li>NAME</li>
              <li>CONTENT</li>
              <li>SETTINGS</li>
              {/* <li>LAST UPDATED</li> */}
            </ul>

            <Link to="/Settings/Setup">
            <ul className="table-body">
                <li><img src={_do} alt='' className='se-icon' /><span className="txt-light">Domain setup</span></li>
                <li><span className="txt-light"><i>Company's name, Subdomain, Email credentials, Home link, Logo</i></span></li>
                <li><span className="txt-light">Updated</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Platform">
            <ul className="table-body">
                <li><img src={_ap} alt='' className='se-icon' /><span className="txt-light">Platform settings</span></li>
                <li><span className="txt-light"><i>Demo account, Deposit botton, Auto-trader, Notifier, Verification</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Email">
            <ul className="table-body">
                <li><img src={_em} alt='' className='se-icon' /><span className="txt-light">Email templates</span></li>
                <li><span className="txt-light"><i>Register, Change password, Forgot password</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Account">
            <ul className="table-body">
                <li><img src={_ac} alt='' className='se-icon' /><span className="txt-light">Account types</span></li>
                <li><span className="txt-light"><i>Name, Minimum deposit, leverage, signup bonus</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Status">
            <ul className="table-body">
                <li><img src={_st} alt='' className='se-icon' /><span className="txt-light">Account Status</span></li>
                <li><span className="txt-light"><i>Status, Badge</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Payment">
            <ul className="table-body">
                <li><img src={_cr} alt='' className='se-icon' /><span className="txt-light">Payment settings</span></li>
                <li><span className="txt-light"><i>Bank transfer, Credit card, Bitcoin transfer</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Currency">
            <ul className="table-body">
                <li><img src={_cu} alt='' className='se-icon' /><span className="txt-light">Account currency</span></li>
                <li><span className="txt-light"><i>USD, GBP, EUR</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Questionnaire">
            <ul className="table-body">
                <li><img src={_sv} alt='' className='se-icon' /><span className="txt-light">Questionnaire</span></li>
                <li><span className="txt-light"><i>Bank transfer, Credit card, Bitcoin transfer</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Restrictions">
            <ul className="table-body">
                <li><img src={_lo} alt='' className='se-icon' /><span className="txt-light">Restrictions</span></li>
                <li><span className="txt-light"><i>Profile, documents, finance, user</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="/Settings/Subscription">
            <ul className="table-body">
                <li><img src={_ca} alt='' className='se-icon' /><span className="txt-light">Subscription</span></li>
                <li><span className="txt-light"><i>Due date, renewal</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <Link to="#/BugReport">
            <ul className="table-body" style={{opacity: 0.6}}>
                <li><img src={_bu} alt='' className='se-icon' /><span className="txt-light">Bug Report</span></li>
                <li><span className="txt-light"><i>Having any troubles? Let us know</i></span></li>
                <li><span className="txt-light">Default</span></li>
                {/* <li><span className="txt-light">-</span></li> */}
            </ul>
            </Link>

            <br />
            <br />

        </div>
      </div>
      </Container>
    );
  }

  Thesvg = () => {
    return (
      <>
        <svg className="fil-u" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 0L11.1962 6H0.803848L6 0Z" fill="#C4C4C4"/>
        </svg>
        <svg className="fil-d" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 6L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 6Z" fill="#C4C4C4"/>
        </svg>
      </>
    )
  }
};

export default Setup;