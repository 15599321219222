import React from 'react';
import './index.scss';

const RestrictionNav = ({
  selectedTab,
  handleClick,
  minimizeSideBar,
  restrictionNav,
}) => {
  return (
    <ul
      className={`restriction-nav ${
        minimizeSideBar ? 'trans-nav-min' : null
      } ${!restrictionNav ? 'hide-trans-nav' : null}`}
    >
      <li className={("first selected-trans-tab")+(minimizeSideBar ? ' minimize-trans' : '')} onClick={handleClick}>
        <div>{selectedTab}</div>
      </li>
    </ul>
  );
};

export default RestrictionNav;
