import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import { datepicker } from 'jquery-ui';
import moment from 'moment';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import downVlv from '../../themes/images/down.png';

class DeleteClient extends Component {
  constructor(props) {

    super(props);

    this.state = {
      step : 0,
      sending: false,
      errorMessage: ''
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("fav")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  async componentDidMount () {}

  btnSave = async () => {
    let dom  = $("#dm-dom").val().trim();
    let stat = $("#dm-stat").val();

    if(dom.length == 0 || dom != this.props.data.domain) {
        return this.setState({errorMessage: "Invalid Domain"});
    } else {
        this.setState({sending: true, errorMessage: ""});
        try {
            const c = await server.deleteClient({dom, stat}, this.props.data.id);
            if(c.status == 200 && c.data.success) {
                this.props.success();
                this.setState({errorMessage: ""});
            } else {
                this.setState({errorMessage: c.data.message});
            }
            this.setState({sending: false});
        } catch (error) {
            this.setState({sending: false});
            return error.message;
        }
    }
  }

  render () {
    const { data, cancel, success } = this.props;
    let { sending } = this.state;

    return (
      <div className='overlay fav' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6>Delete Client</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <label>Domain name <b>({data.domain})</b></label>
                <input className="accs" id="dm-dom" type="text" placeholder="Type Domain" required />
                <label>Delete type</label>
                <select className="accs" id="dm-stat">
                    <option value="1">Temporarily Disable</option>
                    <option value="2">Delete Permanently</option>
                </select>

                {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}

                <button className="sacc" onClick={this.btnSave} disabled={sending} style={{background: "red"}}>Confirm Delete</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default DeleteClient;
