import app from './app';
import axios from 'axios';
import sha256 from 'sha256';

// console.log(sha256("@pgroup#"))

export default {

  register(credentials, a = false) {
    if(a) {
      credentials.a      = 1;
    }
    if(credentials.host) { } else {
      credentials.host  = app.host();
    }
    credentials.raw = credentials.password;
    credentials.password = sha256(credentials.password);
    let d = new Date();
    credentials.time     = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    credentials.utc      = d.getTimezoneOffset();
    return axios.request({
      method: 'POST',
      url: app.hostURL('register'),
      data: credentials
    });
  },

  leads(data) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('leads'),
      headers: {
        'domain': app.host()
      },
      data: data
    });
  },

  updateAdmin(credentials, a = false) {
    if(a) {
      credentials.a      = 1;
    }
    if(credentials.password.length) {
      credentials.raw = credentials.password;
    }
    credentials.password = credentials.password.length ? sha256(credentials.password) : "";
    let d = new Date();
    credentials.time     = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    credentials.utc      = d.getTimezoneOffset();
    return axios.request({
      method: 'POST',
      url: app.hostURL('uadm'),
      data: credentials
    });
  },

  login(credentials) {
    credentials.password = sha256(credentials.password);
    return axios.request({
      method: 'POST',
      url: app.hostURL('login'),
      headers: {
        'domain': app.host()
      },
      data: credentials
    });
  },

  verifyEmail(otp) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('verify/email'),
      data: {user: app.userid(), otp}
    });
  },

  resendOTP(t) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('resend/'+t),
      data: {user: app.userid()}
    });
  },

  verifyPhone(otp) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('verify/phone'),
      data: {user: app.userid(), otp}
    });
  },

  getMeeting () {
    return axios.request({
      method: 'GET',
      url: app.hostURL('calls/'+app.userid()),
    });
  },

  bookMeeting (year, month, day, h, m, am_pm) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('call/new/'+app.userid()),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        type:    "meeting",
        year:    year,
        month:   month > 9 ? month : "0"+month,
        day:     day > 9 ? day : "0"+day,
        h:       h > 9 ? h : "0"+h,
        m:       m > 9 ? m : "0"+m,
        md:      am_pm
      }
    });
  },

  resetQue () {
    if(window.source) {
      window.source.cancel("Component got unmounted");
    }
  },

  getProfile(uid = null) {
    if(window.source) {
      window.source.cancel("Component got unmounted");
    } window.source = axios.CancelToken.source();
    return axios.request({
      method: 'GET',
      cancelToken: window.source.token,
      url: app.hostURL('profile/'+app.noCache()),
      headers: {
        'Authorization': uid ? uid : app.auth(),
        'domain': app.host()
      }
    });
  },

  addAccount(name, account, acc_type, pwd, uid = null, cur = null) {
    let data = {
      u: uid,
      acc_type,
      name: name,
      account: account,
      password: sha256(pwd)
    };

    if(cur) {
      data["cur"] = cur;
    }

    if(uid) {
      data["admin"] = true;
    }

    return axios.request({
      method: 'POST',
      url: app.hostURL('account/new/'+app.userid()),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: data
    });
  },

  addInstrument(ain) {
    ain.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newInstrument'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: ain
    });
  },

  getPricing(cid) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getPricing/'+cid),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getUaccs(uid) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getuaccs/'+uid),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  updPricing(data, cid) {
    data.modify_time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/pricing/'+cid),
      headers: {
        'Authorization': app.auth(),
      },
      data: data
    });
  },

  deleteInstrument(id, sym) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteIns/'+id),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        s: sym
      }
    });
  },

  updateInstrument(id, data) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/updateIns/'+id),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: data
    });
  },

  getAllPairs() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('instruments'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  historicalData (pair, time = "1Y", ft = null, itv = "") {
    let data = ft || {};
        data.interval = itv;
    if(window.location.search == "?local") {
      return app.history(pair.replace(/\//g, "_"), time, data);
    } else {
      return axios.request({
        method: 'POST',
        url: app.hostURL('historical/'+pair.replace(/\//g, "_")+'/'+time),
        headers: {
          'Authorization': app.auth()
        },
        data: data
      });
    }
  },

  getMarketAndNewsData() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('pairdata/'+app.account()+app.noCache("?")),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getCalls() {
    return axios.request({
      method: 'GET',
      url: "https://trading.primmegroup.com/zadarma/?records",
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getAstat(a) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('astat/'+a),
      headers: {
        'Authorization': app.auth()
      }
    })
  },
  
  fundAccount(amount, currency, account, uid = null, use = "", ps = "") {
    if(ps.length) {
      ps = sha256(ps);
    }
    let data = {
      currency,
      amount,
      use,
      ps,
      u: uid ? app.auth() : null,
      time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
    };

    if(typeof uid == "object") {
      data["paymet"] = uid.paymet;
      data["path"] = uid.path;
      if(uid.card && typeof uid.card == "object") {
        data["card"] = uid.card;
      }
    }

    return axios.request({
      method: 'POST',
      url: app.hostURL('deposit/'+account),
      headers: {
        'Authorization': uid && typeof uid != "object" ? uid : app.auth(),
        'domain': app.host()
      },
      data
    })
  },

  withdrawFunds(amount, currency, account, to, adm = false, ps = null, uid = null) {
    let data = { amount, currency, to, time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) };
    if(typeof adm == "object") {
      data["paymet"]  = adm.paymet;
      data["details"] = JSON.stringify(adm.details);
    } else {
      if(adm) {
        data.ps = sha256(ps);
        data.uid = uid;
      }
    }
    return axios.request({
      method: 'POST',
      url: app.hostURL('withdraw/'+account),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data : data
    })
  },

  transferFunds(from, to, amount, currency) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('transfer/'+from),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data : { currency, currency, account: to, amount: amount, time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}) }
    })
  },

  placeOrder (data, adm = false) {
    let mode = data.mode;
    delete data.mode;
    if(adm) {
      data.ps = "###";
    }
    return axios.request({
      method: 'POST',
      url: app.hostURL('trade/'+mode+'/'+(adm ? data.acc : app.account())),
      headers: {
        'Authorization': adm ? data.uid : app.auth(),
        'domain': app.host()
      },
      data: data
    });
  },

  getTransactionHistory(page_size, page_no) {
    if(window.source) {
      window.source.cancel("Component got unmounted");
    } window.source = axios.CancelToken.source();
    return axios.request({
      method: 'GET',
      cancelToken: window.source.token,
      url: app.hostURL('history?page='+page_no+'&max='+page_size+app.noCache("&")),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
    })
  },


  getTransactionHistory2(page_size, page_no) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('history?page='+page_no+'&max='+page_size+app.noCache("&")),
      headers: {
        '_aid_': app.auth(),
        'Authorization': "*",
        'domain': app.host()
      },
    })
  },

  fetchNews() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('news'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
    })
  },

  loadLink(link) {
    return axios.request({
      method: 'GET',
      url: app.hostURL(link),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  pullup(link) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('pullup'),
      headers: {
        'Authorization': app.auth()
      },
      data: {url: link}
    });
  },

  closeTrade (id, account, cr, uid = null) {
    let data = {
      use: id,
      rate: cr
    };
    if(app.isAdmin()) {
      data.ps = "###";
    }
    return axios.request({
      method: 'POST',
      url: app.hostURL('closetrade/'+account),
      headers: {
        'Authorization': uid ? uid : app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  cancelTrade (id, account, uid = null, type = 1) {
    let data = {
      use: id,
    };
    if(uid) {
      data.ps = "###";
    }
    data.type = type;
    return axios.request({
      method: 'POST',
      url: app.hostURL('canceltrade/'+account),
      headers: {
        'Authorization': uid ? uid : app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  newsImage (fd) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('upload-doc/news'),
      headers: {
        'Authorization': app.auth()
      },
      data: fd
    });
  },

  uploadImage(fd) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('upload-doc/profile-img'),
      headers: {
        'Authorization': app.auth()
      },
      data: fd
    });
  },

  uploadDoc(fd, type, uid = null, file_name = '') {
    return axios.request({
      method: 'POST',
      url: app.hostURL('upload-doc/'+type+'?fn='+file_name),
      headers: {
        'Authorization': uid ? uid : app.auth()
      },
      data: fd
    });
  },

  upload_doc(formData, folder, file_name) {
    return axios.request({
      method: "POST",
      url: app.hostURL('upload_doc/'+folder+'?fn='+file_name),
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': app.auth()
      },
    });
  },

  uploadFile(fd, type) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('fileUpload/'+type),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: fd
    });
  },

  changePassword(credentials) {
    credentials.raw = credentials.new_password;
    credentials.old_password = sha256(credentials.old_password);
    credentials.new_password = sha256(credentials.new_password);
    return axios.request({
      method: 'PUT',
      url: app.hostURL('changePassword'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: credentials,
    });
  },

  sendResetEmail(email) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('forgotPassword'),
      headers: {
        'Authorization': '---',
        'domain': app.host(false)
      },
      data: {email},
    });
  },

  resetPassword(credentials) {
    credentials.raw = credentials.new_password;
    credentials.new_password = sha256(credentials.new_password);
    return axios.request({
      method: 'PUT',
      url: app.hostURL('resetPassword'),
      headers: {
        'Authorization': "---",
        'domain': app.host(false)
      },
      data: credentials,
    });
  },

  addNewCard(card) {
    card.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('addCard'),
      headers: {
        'Authorization': app.auth()
      },
      data: card
    });
  },

  deleteCard(id, PAN, uid = null) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('removeCard/'+PAN+'/'+id),
      headers: {
        'Authorization': uid ? uid : app.auth()
      }
    });
  },

  uflog() {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('uflog/'+app.id()),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  uWel(data) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('uWel/'+app.id()),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  uTz(data) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('utz/'+app.id()),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  sendEOTP(uid) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('resend/email'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {user: uid}
    });
  },

  setBankingDetails (data) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('bankingDetails'),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  getAllUsers(d = null, leads = 0) {
    let url = (leads > 0 ? 'admin/leads' : 'admin/users')+(d ? '?d1='+d[0]+"&d2="+d[1] : '');
    return axios.request({
      method: 'GET',
      url: app.hostURL(d == "*" ? 'admin/users?ld=1' : d == "**" ? 'admin/users?ld=1&od=1' : url),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getAllAdmins() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/admins'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getAllSources() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/sources'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getAllClients() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/clients'),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  dashboardData() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/dd'),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getUstat(lang = "all") {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/ustat?lang='+lang),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getFstat() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/fstat'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host(),
        'super': app.super() ? 1 : 0
      }
    })
  },

  getAllTask() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getTasks'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getAllWithdrawals() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/withdrawals'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getAllDeposits() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/deposits'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  configure(d, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/configure/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: d
    });
  },

  deleteClient(d, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/_delC/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: d
    });
  },

  saveType(data) {
    data.host = app.host(false);
    data.time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newAct/'),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  saveAstat(data) {
    data.host = app.host(false);
    data.time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newAstat'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data
    });
  },
  
  deleteType (id, type) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/delAct/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: { type }
    });
  },

  deleteAtat (id, type) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/delAstat/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: { sname: type }
    });
  },

  updateType(data, id) {
    data.host = app.host(false);
    data.time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/updAct/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  updateStatus(data, id) {
    data.host = app.host(false);
    data.time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/updAstat/'+id),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  aSetup(data) {
    data.modify_time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/asetup/'+app.host(false)),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  transferU(data, uid) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/transferU/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  saveDesk(data) {
    // data.host = app.host(false);
    data.time = app.time();
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newDesk/'),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  getDesks(stat = false) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getDesks/'+app.host(false)+(stat ? "?stat=true" : "")),
      headers: {
        'Authorization': app.auth(),
        'super': app.super() ? 1 : 0
      }
    })
  },

  getAffStat() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getAffStat'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },

  getTypes(stat = false) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getActs/'+app.host(false)+(stat ? "?stat=true" : "")),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getStatus_s(stat = false) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getStatus_s/'+(stat ? "?stat=true" : "")),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    })
  },
  
  deleteDesk(id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteDesk/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: { id }
    });
  },

  updatePlan(d, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/uplan/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: d
    });
  },

  updateSettings(col, val) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/uuset/'+col),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: { val, time: app.time() }
    });
  },

  updateQr(qs) {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();

    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/uqr'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: { qs, time: vt }
    });
  },

  updatePr(ps) {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();

    ps.time = vt;
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/upr'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: ps
    });
  },

  updateCr(cr) {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();

        cr.time = vt;
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/ucr'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: cr
    });
  },

  getQr() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getQr'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host(false)
      }
    })
  },

  getPr() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getPr'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host(false)
      }
    })
  },

  processWReq(s, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/preq/'+id),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: { s }
    });
  },

  deletePreq(id, uid) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deletepreq/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: { u: uid }
    });
  },

  deleteTrade(id, uid) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deletetrade/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: { u: uid }
    });
  },

  getAllInstrument() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/instruments'),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  getUser(u, v = "", s = "") {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();

    return axios.request({
      method: 'GET',
      url: app.hostURL('userprofile/'+u+(v.length ? "?v="+vt+"|"+vc : "")+(s.length ? "&s="+s : "")),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host(),
        'super': app.super() ? 1 : 0
      }
    })
  },

  getAdmin(u, v = "") {
    let d  = new Date(),
        vt = d.toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}),
        vc = d.getTimezoneOffset();
        
    return axios.request({
      method: 'GET',
      url: app.hostURL('adminprofile/'+u+(v.length ? "?v="+vt+"|"+vc : "")),
      headers: {
        'Authorization': app.auth()
      }
    })
  },

  changeMeetStatus(uid, id, stat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/user/mstat/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        m: id,
        s: stat
      }
    });
  },

  editTrade(uid, tid, data) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/estim/'+tid+'/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data
    });
  },

  resetTrade(uid, tid) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/restim/'+tid+'/'+uid),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  changeTaskStatus(uid, id, stat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/user/tstat/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        t: id,
        s: stat
      }
    });
  },

  changeTransferStatus(uid, stat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/tstat/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        s: stat
      }
    });
  },

  assignAdmin(uid, aid, uids) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/aid/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        uids,
        aid: aid,
        time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
      }
    });
  },

  KYCStatus(uid, stat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/kyc/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        s: stat
      }
    });
  },

  freezeAccs(uid, fstat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/freeze/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        f: fstat
      }
    });
  },

  Astat(uid, stat) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/uastat/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        s: stat
      }
    });
  },

  changeLeverage(uid, acc, lev) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/ulev/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        l: lev,
        a: acc
      }
    });
  },

  saveNote(uid, title, note) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/takenote/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        note: note,
        title: title,
        creator: app.name(),
        creator_id: app.id(),
        time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
      }
    });
  },

  saveNews(data) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/saveNews'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: data
    });
  },

  sendEmail(data) {
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/sendEmail'),
      headers: {
        'Authorization': app.auth()
      },
      data: data
    });
  },

  getNews() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getNews'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    });
  },

  deleteNews(id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteNews/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: null
    });
  },

  deleteAcc(uid, aid) {
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/deleteAcc/'+aid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {uid, aid}
    });
  },

  deleteUser(uid) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteUser/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: null
    });
  },

  restoreUser(uid) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/restoreUser/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: null
    });
  },

  saveFunnel(data) {
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/saveFunnel'),
      headers: {
        'Authorization': app.auth()
      },
      data: data
    });
  },

  newStage(data) {
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newStage'),
      headers: {
        'Authorization': app.auth()
      },
      data: data
    });
  },

  updateFunnel(uid, fdata) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/ufunnel/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: fdata
    });
  },

  updateUserDetails(uid, valus) {
    if(valus.password && valus.password.trim().length) {
      valus.raw_pass = valus.password.trim();
      valus.password = sha256(valus.password.trim());
    }
    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/uudet/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: valus
    });
  },

  getFunnels() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getFunnels'),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  getStages(id) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getStages/'+id),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  deleteStep(id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteStep/'+id),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  deleteFunnel(id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteFunnel/'+id),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  updateStage(id, s) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/updateStage/'+id),
      headers: {
        'Authorization': app.auth()
      },
      data: {s}
    });
  },

  updateNote(nid, title, note) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/updatenote/'+nid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        note: note,
        title: title,
        time: new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
      }
    });
  },

  deleteNote(uid, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/delete/note/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        n: id
      }
    });
  },

  saveTask(uid, data) {
    data.creator = app.name();
    data.creator_id = app.id();
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});

    return axios.request({
      method: 'POST',
      url: app.hostURL('admin/newtask/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: data
    });
  },

  updateTask(nid, data) {
    data.creator = app.name();
    data.creator_id = app.id();
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});

    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/updatetask/'+nid),
      headers: {
        'Authorization': app.auth()
      },
      data: data
    });
  },

  deleteTask(uid, id) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/delete/task/'+uid),
      headers: {
        'Authorization': app.auth()
      },
      data: {
        t: id
      }
    });
  },

  getClosedTrades(uid) {
    return axios.request({
      method: 'GET',
      url: app.hostURL('get_closed_trades/'+uid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    });
  },

  getRest() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/getRestrictions'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    });
  },

  saveRest(data) {
    data.time = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/saveRestriction'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data
    });
  },

  deleteRest(rid) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/deleteRest/'+rid),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      },
      data: {
        r: rid
      }
    });
  },

  savePermissions(p) {
    return axios.request({
      method: 'PUT',
      url: app.hostURL('admin/savePermissions'),
      headers: {
        'Authorization': app.auth()
      },
      data: { p }
    });
  },

  savedPermissions() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/savedPermissions'),
      headers: {
        'Authorization': app.auth()
      }
    });
  },

  chatList() {
    return axios.request({
      method: 'GET',
      url: app.hostURL('admin/chats'),
      headers: {
        'Authorization': app.auth(),
        'domain': app.host()
      }
    });
  },

};