import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import app from '../../services/app';
import downVlv from '../../themes/images/down.png';

class NewDesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      clients: []
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("des")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  btnSave = async () => {
    let name = document.getElementById("tr-name").value.trim();
    let lang = document.getElementById("tr-lang").value.trim();
    let host = document.getElementById("tr-host").value.trim();

    // let pass = document.getElementById("tr-pass").value.trim(); && pass.length > 0

    if(name.length > 0 && lang.length > 0 && host.length > 0) {
      this.props.sending();
      try {
        const desk = await server.saveDesk({name, lang, host});
        this.props.sending();
        if(desk.status == 200) {
          this.props.sent();
        } else {
          this.props.unsending();
          this.setState({errorMessage: desk.data.message});
        }
      } catch (error) {
        this.props.unsending();
        return error.message;
      }
    } else {
      this.setState({errorMessage: "Please fill all fields"});
    }
  }

  getAllClients = async () => {
    let clients = await server.getAllClients();
    this.setState({clients: clients.data});
  }

  async componentDidMount () {
    if(app.master()) {
      await this.getAllClients();
    }
  }

  render () {
    const { cancel, success } = this.props;
    return (
      <div className='overlay des' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6>Create Desk</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <label>Desk name</label>
                <input className="accs" id="tr-name" type="text" />
                {app.master() ? <>
                  <label>Client</label>
                  <select name="host" className="accs" id="tr-host">      
                    {this.state.clients.map((cl) => (<option key={"cl-"+cl.id}>{cl.domain}</option>))}
                  </select>
                </> : null}
                <label>Desk Language</label>
                <select className="accs" id="tr-lang" data-placeholder="Choose a Language...">
                  <option value="Afrikaans">Afrikaans</option>
                  <option value="Albanian">Albanian</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Armenian">Armenian</option>
                  <option value="Basque">Basque</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Bulgarian">Bulgarian</option>
                  <option value="Catalan">Catalan</option>
                  <option value="Cambodian">Cambodian</option>
                  <option value="Chinese (Mandarin)">Chinese (Mandarin)</option>
                  <option value="Croatian">Croatian</option>
                  <option value="Czech">Czech</option>
                  <option value="Danish">Danish</option>
                  <option value="Dutch">Dutch</option>
                  <option value="English">English</option>
                  <option value="Estonian">Estonian</option>
                  <option value="Fiji">Fiji</option>
                  <option value="Finnish">Finnish</option>
                  <option value="French">French</option>
                  <option value="Georgian">Georgian</option>
                  <option value="German">German</option>
                  <option value="Greek">Greek</option>
                  <option value="Gujarati">Gujarati</option>
                  <option value="Hebrew">Hebrew</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Hungarian">Hungarian</option>
                  <option value="Icelandic">Icelandic</option>
                  <option value="Indonesian">Indonesian</option>
                  <option value="Irish">Irish</option>
                  <option value="Italian">Italian</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Javanese">Javanese</option>
                  <option value="Korean">Korean</option>
                  <option value="Latin">Latin</option>
                  <option value="Latvian">Latvian</option>
                  <option value="Lithuanian">Lithuanian</option>
                  <option value="Macedonian">Macedonian</option>
                  <option value="Malay">Malay</option>
                  <option value="Malayalam">Malayalam</option>
                  <option value="Maltese">Maltese</option>
                  <option value="Maori">Maori</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Mongolian">Mongolian</option>
                  <option value="Nepali">Nepali</option>
                  <option value="Norwegian">Norwegian</option>
                  <option value="Persian">Persian</option>
                  <option value="Polish">Polish</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Punjabi">Punjabi</option>
                  <option value="Quechua">Quechua</option>
                  <option value="Romanian">Romanian</option>
                  <option value="Russian">Russian</option>
                  <option value="Samoan">Samoan</option>
                  <option value="Serbian">Serbian</option>
                  <option value="Slovak">Slovak</option>
                  <option value="Slovenian">Slovenian</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Swahili">Swahili</option>
                  <option value="Swedish ">Swedish </option>
                  <option value="Tamil">Tamil</option>
                  <option value="Tatar">Tatar</option>
                  <option value="Telugu">Telugu</option>
                  <option value="Thai">Thai</option>
                  <option value="Tibetan">Tibetan</option>
                  <option value="Tonga">Tonga</option>
                  <option value="Turkish">Turkish</option>
                  <option value="Ukrainian">Ukrainian</option>
                  <option value="Urdu">Urdu</option>
                  <option value="Uzbek">Uzbek</option>
                  <option value="Vietnamese">Vietnamese</option>
                  <option value="Welsh">Welsh</option>
                  <option value="Xhosa">Xhosa</option>
                </select>
                {/* <label>{this.props.admin ? 'Enter Admin' : 'Confirm'} Password</label>
                <input className="accs" required id="tr-pass" type="password" /> */}
                {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}
                <button className="sacc" onClick={this.btnSave}>Save</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default NewDesk;
