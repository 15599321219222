import React, { Component } from 'react';
import $ from 'jquery';
import Moment from 'react-moment';
import moment from 'moment';
import { NavLink as Link } from 'react-router-dom';
import Container from '../container/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import { ConfirmModal } from '../../components/popups/index';
import Nav from '../../components/nav/index';
import calendar from "./calendar.svg";
import Spinner from '../../components/spinner/index';
import CancelIcon from '../../themes/images/cancel.svg';
import country from 'country-list-js';
import permissions from '../../utils/permissions';
import server from '../../services/server';
import app from '../../services/app';
import './index.scss';
import './settings.scss';
import '../../components/chartModule/switchbox.scss';

let previewImg = (dis, img) => {
  if(img.length) {
    if(dis.files[0].type.split("/")[0] != "image") {
      dis.value = "";
      return alert("File must be an image");
    }
    var oFReader = new FileReader();
    oFReader.readAsDataURL(dis.files[0]);
    oFReader.onload = function (a) {
        img.attr("src", a.target.result);
    };
  }
}


class Settings extends Component {
  constructor(props) {
    super(props);

    let tabs = (this.props.match.params.tab || "setup").toLowerCase();
    
    let tablist = {
      "setup": ['SETUP'],
      "platform": ['DEMO ACCOUNT', 'DEPOSIT BUTTON', 'AUTO TRADER', 'NOTIFIER', 'VERIFICATION BYPASS'],
      "email": ['EMAIL'],
      "account": ['ACCOUNT TYPES'],
      "status": ['ACCOUNT STATUS'],
      "payment": ['PAYMENT SETTINGS'],
      "currency": ['ACCOUNT CURRENCIES'],
      "questionnaire": ['QUESTIONNAIRE'],
      "subscription": ['SERVER FEE'],
    };
    let show = tablist[tabs];
    let stb = show[0];

    if(this.props.history.location.search == "?dm") {
      stb = "DEMO ACCOUNT";
    } else if(this.props.history.location.search == "?dp") {
      stb = "DEPOSIT BUTTON";
    } else if(this.props.history.location.search == "?by") {
      stb = "VERIFICATION BYPASS";
    } else if(this.props.history.location.search == "?qr") {
      stb = "QUESTIONNAIRE";
    } else if(this.props.history.location.search == "?ps") {
      stb = "PAYMENT SETTINGS";
    } else if(this.props.history.location.search == "?mt") {
      stb = "AUTO TRADER";
    } else if(this.props.history.location.search == "?nt") {
      stb = "NOTIFIER";
    } else if(this.props.history.location.search == "?cr") {
      stb = "ACCOUNT CURRENCIES";
    }

    this.state = {
      tabs,
      name: '',
      s_bonus: '',
      d_bonus: '',
      at_id: 0,
      min: '',
      lev: '',
      spread: '',
      currency: [],
      confirmModal: false,

      confirmID: 0,
      confirmTYPE: '',

      payment_link: app.data("plink"),
      company: app.data("title"),
      home: app.data("home"),
      efa: app.data("efa"),
      efp: '',

      atypes: [],
      stypes: [],
      demo_acc: '',
      dep_btn: '',
      mat_btn: '',

      selectedTab: stb,
      _selectedTab: stb,
      country_names: [],
      showSpinner: false,
      showRestrictions: false,
      questionnaire: [],
      wallets: [
        {crypto: "", wallet: ""}
      ],

      kyct: 0,
      eotp: 0,
      potp: 0,

      nat: app.data("nat"),
      byp: app.data("byp"),

      sname: '',
      badge: '',
      st_id: 0,

      transfer: 1,
      credit: 1,
      crypto: 1,
      plink: 0,
      acc_no: '',
      acc_name: '',
      bank_name: '',
      iban: '',
      swift: ''

    };

    this.showSpinner = false;
    this.profile = app.profile();
    this.id = this.profile.user_id;
    this.permissions = [];
  }

  async componentDidMount() {
    let dis = this;
    
    this.atypes();
    this.stypes();
    this.getQr();
    this.getPr();

    var country = require('country-list-js');
    var country_names = country.names().sort();
    this.setState({country_names: country_names, country: country_names[0]});

     $("[data-trigger]").click(function () {
        let t = $(this).data("trigger");
        $("[name="+t+"]").trigger("click");
     });

     $("[type=file]").change(function (e) {
        var $n = $(this).attr("name"), $t;
        if($t = $("[data-trigger="+$n+"]"), $t.length) {
          previewImg(this, $t);
        }
        dis.uLogo(e);
     });

     this.updateCur(true, app.data("afc").split(","));
  }

  updateCur = (u = false, c = []) => {
    let cur = c;
    if(u === true) {
      cur.forEach((v, k) => {
        $("#switch-box-"+v)[0].checked = true;
      });
    } else {
      $("._scur").each((k, v) => {
        if($(v)[0].checked) {
          cur.push($(v).attr("name"));
        }
      });
    }
    this.setState({currency: cur});
  }

  atypes = async () => {
    try {
      let types = await server.getTypes();
      this.setState({atypes: types.data.atypes, showLoader: false, loaded: true});
    } catch(e) {
      return e;
    }
  }

  stypes = async () => {
    try {
      let types = await server.getStatus_s();
      this.setState({stypes: types.data.atypes, showLoader: false, loaded: true});
    } catch(e) {
      return e;
    }
  }

  getQr = async () => {
    let questionnaire = app.questions();

    try {
      let qr = await server.getQr();
      let qs = qr.data.qs;
      this.setState({questionnaire: qs.length ? qs : questionnaire, showLoader: false, loaded: true});
    } catch(e) {
      return e;
    }
  }

  getPr = async () => {
    try {
      let pr = await server.getPr();
      let ps = pr.data.ps;
      if(ps && typeof ps == "object") {
        this.setState({transfer: ps.transfer, credit: ps.credit, crypto: ps.crypto, plink: ps.plink, payment_link: ps.payment_link, acc_no: ps.acc_no, acc_name: ps.acc_name, bank_name: ps.bank_name, iban: ps.iban, swift: ps.swift, wallets: ps.wallets });
        ["acc_no", "acc_name", "bank_name", "iban", "swift"].forEach((v, k) => { $("[name="+v+"]").val(ps[v]); });
        $("[name=transfer]")[0].checked   = ps["transfer"] > 0;
        $("[name=credit]")[0].checked    = ps["credit"] > 0;
        $("[name=crypto]")[0].checked    = ps["crypto"] > 0;
        $("[name=plink]")[0].checked    = ps["plink"] > 0;
        $("[name=payment_link]").val(ps.payment_link);
      }
    } catch(e) {
      return e;
    }
  }

  setPr = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      let {transfer, credit, crypto, plink, payment_link, acc_no, acc_name, bank_name, iban, swift, wallets} = this.state;
      await server.updatePr({transfer, credit, crypto, plink, payment_link, acc_no, acc_name, bank_name, iban, swift, wallets: JSON.stringify(this.state.wallets) });
      window.location.href = "?ps";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  
  setCr = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      let { currency } = this.state;
      await server.updateCr({afc: currency.join(",")});
      window.location.href = "?cr";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  toggleTabs = (e) => {
    this._toggleTabs(e.currentTarget.querySelector('div').innerHTML.trim());
  }

  _toggleTabs = (tab) => {
    this.setState({
      selectedTab: tab.toLowerCase(),
      _selectedTab: tab
    });
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  uLogo = async (e) => {
    this.setState({ showSpinner: true });
    const current = e.target.files[0];
    const fd = new FormData();
    fd.append('logo.png', current, current.name);
    try {
      $.ajax({
          type: "POST",
          url: app.hostURL('uplogo/'+app.host(false).replace(/\./g, "_")),
          dataType: 'json',
          data: fd,
          processData: false,
          contentType: false,
      }).done(function (_d) {
          console.log(_d);
          if(_d.success) {
              window.location.href = "";
          } else {
              alert("Something went wrong");
          }
      });
    } catch (error) {
      console.log(error);
    }
    this.setState({ showSpinner: false });
  }

  aSetup = async (e) => {
    e && e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      let { company, home, efa, efp } = this.state;
      let submit = { company, home, efa, efp };
      let st = await server.aSetup(submit);
      window.location.href = "";
    } catch (error) {
      this.setState({ showSpinner: false });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  rSet = () => {
    $("input").val("");
    this.setState({at_id: 0});
    $("[name=name]").attr("readonly", false);
    $(".at-create").text("CREATE");
    this.atypes();
  }

  rSet2 = () => {
    window.location.href = "";
    // $("input").val("");
    // this.setState({st_id: 0});
    // $("[name=sname]").attr("readonly", false);
    // $(".at-create").text("CREATE");
    // this.stypes();
  }

  saveType = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      let { name, s_bonus, d_bonus, min, lev, spread, at_id } = this.state;
      let submit = { name, s_bonus, d_bonus, min, lev, spread };
      let st = at_id > 0 ? await server.updateType(submit, at_id) : await server.saveType(submit);
      this.rSet();
    } catch (error) {
      this.setState({ showSpinner: false });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  saveType2 = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      let { sname, badge, st_id } = this.state;
      let submit = { sname, badge, st_id };
      let st = st_id > 0 ? await server.updateStatus(submit, st_id) : await server.saveAstat(submit);
      this.rSet2();
    } catch (error) {
      this.setState({ showSpinner: false });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  dType = async (id, type) => {
    this.setState({ showSpinner: true, confirmModal: false });
    let sa = this.state._selectedTab == "ACCOUNT STATUS";
    try {
      if(sa) {
        await server.deleteAtat(id, type);
        this.rSet2();
      } else {
        await server.deleteType(id, type);
        this.rSet();
      }
    } catch (error) {
      this.setState({ showSpinner: false });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  demAcc = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateSettings("demo_acc", $("#demo_acc").val());
      window.location.href = "?dm";
    } catch (error) {
      this.setState({ showSpinner: false });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  verByp = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateSettings("ver_byp", $("#ver_byp").val());
      window.location.href = "?by";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  depBtn = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateSettings("dep_btn", $("#dep_btn").val());
      window.location.href = "?dp";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  matBtn = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateSettings("mat", $("#mat_btn").val());
      window.location.href = "?mt";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  natBtn = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateSettings("nat", $("#nat_btn").val());
      window.location.href = "?nt";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  doNothing = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    // try {
    //   await server.updateSettings("mat", $("#mat_btn").val());
    //   window.location.href = "?mt";
    // } catch (error) {
    //   this.setState({ showSpinner: !this.state.showSpinner });
    //   if (!error.response) {
    //     return error;
    //   }
    //   const errorMessage = error.response.data.message;
    // }
    this.setState({ showSpinner: false });
  }

  qnr = async (e) => {
    e.preventDefault();
    this.setState({ showSpinner: true });
    try {
      await server.updateQr(JSON.stringify(this.state.questionnaire));
      window.location.href = "?qr";
    } catch (error) {
      this.setState({ showSpinner: !this.state.showSpinner });
      if (!error.response) {
        return error;
      }
      const errorMessage = error.response.data.message;
    }
    this.setState({ showSpinner: false });
  }

  newQuest = (q, i) => {
    let ind = i;
    return (
      <>
        <div className="col-12 _alt" key={"q-"+i}>
          <div className="row">
            <div className="col-6">
              <div className="form-group" style={{position: "relative"}}>
                <label>Question {i+1}</label>
                <textarea className="form-control" name={"q-"+i} onChange={(e) => {
                  this.setQuestion(i, e);
                }}>{q.question}</textarea>
                <span className="clfg" onClick={() => {
                  let st = $(".qfs")[0].scrollTop;
                  let qs = this.state.questionnaire.filter((qt, qk) => qk != ind);
                  this.setState({questionnaire: []});
                  setTimeout(() => {
                    this.setState({questionnaire: qs});
                    $(".qfs")[0].scrollTop = st;
                  }, 10);
                }}>&#10006;</span>
              </div>
            </div>
          </div>
          {q.answers.map((a, j) => this.newOpt(a, i, j))}
          <button className="btn btn-primary btn-sm ans" type="button" onClick={() => {
            let qs = this.state.questionnaire;
            qs[i]["answers"].push("");
            this.setState({questionnaire: qs});
          }}>Add new option</button>
        </div>
        <hr className="shr" />
      </>
    );
  }

  newOpt = (a, i, j) => {
    return (
      <>
        <br />
        <div className="row" key={"a-"+i+"-"+j}>
          <div className="col-6">
            <div className="form-group" style={{position: "relative"}}>
              <label>Option {j+1}</label>
              <input type="text" className="form-control" name={"q"+i+"-o"+j} defaultValue={a} onChange={(e) => {
                this.setAnswer(i, j, e);
              }} required />
              <span className="clfg" onClick={() => {
                let st = $(".qfs")[0].scrollTop;
                let qs = this.state.questionnaire;
                qs[i] = {
                  question: qs[i]["question"],
                  answers: qs[i]["answers"].filter((at, ak) => ak != j)
                }
                this.setState({questionnaire: []});
                setTimeout(() => {
                  this.setState({questionnaire: qs});
                  $(".qfs")[0].scrollTop = st;
                }, 10);
              }}>&#10006;</span>
            </div>
          </div>
        </div>
      </>
    )
  }

  wallet = (w, k) => {
    return (
      <>
        <div className='col-12 _alt' key={"wal-"+k}>
          <div className='row'>
            <div className="col-3">
              <input type='text' className="form-control" name={'wn-'+k} placeholder="Crypto Wallet Name" defaultValue={w.crypto} onChange={(e) => {
                this.setWallet(k, "crypto", e);
              }} autoComplete="new-password" required />
            </div>
            <div className="col-1 ar-r">{'=>'}</div>
            <div className="col-7">
              <input type='text' className="form-control" name={'wa-'+k} placeholder="Crypto Wallet Address" defaultValue={w.wallet} onChange={(e) => {
                this.setWallet(k, "wallet", e);
              }} autoComplete="new-password" required />
            </div>
            <div className="col-1 ar-r" style={{justifyContent: "flex-start", cursor: "pointer"}}>
              <span style={{color: "#ff5722"}} onClick={() => {
                let wl = this.state.wallets.filter((wt, wk) => wk != k);
                this.setState({wallets: []});
                setTimeout(() => {
                  this.setState({wallets: wl});
                }, 10);
              }}>&#10006;</span>
            </div>
          </div>
        </div>
        <br />
      </>
    );
  }

  setWallet = (k, name, e) => {
    let wl = this.state.wallets;
    wl[k][name] = e.target.value;
    this.setState({ wallets: wl });
  }

  setQuestion = (k, e) => {
    let qs = this.state.questionnaire;
    qs[k]["question"] = e.target.value;
    this.setState({ questionnaire: qs });
  }

  setAnswer = (k, j, e) => {
    let qs = this.state.questionnaire;
    qs[k]["answers"][j] = e.target.value;
    this.setState({ questionnaire: qs });
  }

  render() {
    let ps = this.permissions;
    let no = parseInt(ps.length / 2);
    let p1 = ps.slice(0, no);
    let p2 = ps.slice(no);
    let { atypes, stypes, showSpinner, questionnaire, wallets, tabs, currency } = this.state;
    let pts = this.state.transfer + this.state.credit + this.state.crypto;

    return (
      <Container>
      <div className="col-12" id="cadmin-container">
        <div className='createadmin-section setapsect'>
          
          <ConfirmModal
            head={"Delete "+this.state.confirmTYPE+"?"}
            text="Click YES to confirm"
            show={this.state.confirmModal}
            cancel={() => this.setState({confirmModal: false})}
            confirm={() => this.dType(this.state.confirmID, this.state.confirmTYPE)}
          />

          <Spinner showSpinner={showSpinner} />
          <Breadcrumbs breads={"Home, Settings, "+this.state._selectedTab.ucwords()} />
          <Nav selectedTab={this.state._selectedTab} handleClick={this.toggleTabs} tabs={tabs}/>

          <div className='createadmin-box' style={{height: "calc(100vh - 255px)"}}>
            <div className='deposit' style={{display: this.state._selectedTab == "ACCOUNT TYPES" ? "block" : "none", flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
              <form className='settings-action' onSubmit={this.saveType} style={{flexDirection: "column"}}>
                <h3>Account Type(s)</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" name="name" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <div className="form-group">
                        <label>Minimum deposit({app.currency()})</label>
                        <input type="text" className="form-control" name="min" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <div className="form-group">
                        <label>Leverage</label>
                        <input type="number" className="form-control" name="lev" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </div><br />
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Signup Bonus({app.currency()})</label>
                        <input type="number" className="form-control" name="s_bonus" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <div className="form-group">
                        <label>First Deposit Bonus(%)</label>
                        <input type="text" className="form-control" name="d_bonus" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <div className="form-group">
                        <label>Spread<small>(coming soon)</small></label>
                        <input type="text" className="form-control" name="spread" onChange={this.handleInputChange} />
                      </div>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn at-create'>CREATE</button>
                      </div>
                    </div>
                </div>
              </form>
              <br />
              <br />
              <div className="table-responsive tb-ac-set">
                <table className={"table table-hover"}>
                  <thead>
                    <tr>
                      <th>NAME</th>
                      <th>Min. deposit</th>
                      <th>Leverage</th>
                      <th>Signup Bonus</th>
                      <th>First Deposit Bonus</th>
                      <th>Spread</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {atypes.length ? null :
                    <tr>
                      <td>BASIC</td>
                      <td>{app.currency()}0</td>
                      <td>1:200</td>
                      <td>{app.currency()}0</td>
                      <td>0%</td>
                      <td>0.1</td>
                      <td><small>Default</small></td>
                    </tr>}
                    {atypes.map((at) => {
                      return (
                        <tr>
                          <td>{at.name}</td>
                          <td>{app.currency()}{at.min}</td>
                          <td>1:{at.lev}</td>
                          <td>{app.currency()}{at.s_bonus}</td>
                          <td>{at.d_bonus}%</td>
                          <td>{at.spread.length ? at.spread : '--'}</td>
                          <td>
                            <svg className="tb-action" width="20" height="14" viewBox="0 0 20 14" fill="none" onClick={() => {
                              this.setState({at_id: at.id, name: at.name, min: at.min, lev: at.lev, s_bonus: at.s_bonus, d_bonus: at.d_bonus, spread: at.spread});
                              $("[name=name]").val(at.name);
                              $("[name=name]").attr("readonly", true);
                              $("[name=min]").val(at.min);
                              $("[name=lev]").val(at.lev);
                              $("[name=s_bonus]").val(at.s_bonus);
                              $("[name=d_bonus]").val(at.d_bonus);
                              $("[name=spread]").val(at.spread);
                              $(".at-create").text("UPDATE");
                            }} style={{cursor: "pointer"}}>
                              <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#03CF9E"/>
                            </svg>
                            <svg onClick={() => this.setState({confirmID: at.id, confirmTYPE: at.name, confirmModal: true})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" style={{marginLeft: 10, cursor: "pointer"}}>
                              <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#d14" />
                            </svg>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            
            <div className='deposit' style={{display: this.state._selectedTab == "ACCOUNT STATUS" ? "block" : "none", flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
              <form className='settings-action' onSubmit={this.saveType2} style={{flexDirection: "column"}}>
                <h3>Account Status</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Staus Name</label>
                        <input type="text" className="form-control" name="sname" onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <div className="form-group">
                        <label>Badge (Color code)</label>
                        <input type="color" className="form-control" name="badge" style={{height: "40px", color: "pointer"}} onChange={this.handleInputChange} required />
                      </div>
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <button type='submit' className='btn sb-btn at-create' style={{marginTop: "30px"}}>CREATE</button>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <br />
              <div className="table-responsive tb-ac-set">
                <table className={"table table-hover"}>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Status</th>
                      <th>Badge</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {stypes.length ? null :
                    <tr>
                      <td colspan="4" style={{textAlign: "center"}}>Empty</td>
                    </tr>}
                    {stypes.map((st, k) => {
                      return (
                        <tr key={'st-'+st.id}>
                          <td>{k+1}</td>
                          <td>{st.sname}</td>
                          <td><span style={{background: st.badge, height: "25px", width: "25px", display: "block"}}></span></td>
                          <td>
                            <svg className="tb-action" width="20" height="14" viewBox="0 0 20 14" fill="none" onClick={() => {
                              this.setState({st_id: st.id, sname: st.sname, badge: st.badge});
                              $("[name=sname]").val(st.sname);
                              $("[name=badge]").val(st.badge);
                              $(".at-create").text("UPDATE");
                            }} style={{cursor: "pointer"}}>
                              <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#03CF9E"/>
                            </svg>
                            <svg onClick={() => this.setState({confirmID: st.id, confirmTYPE: st.sname, confirmModal: true})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" style={{marginLeft: 10, cursor: "pointer"}}>
                              <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#d14" />
                            </svg>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "DEMO ACCOUNT" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.demAcc} style={{flexDirection: "column"}}>
                <h3>Demo Account</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Toggle Demo Account</label>
                        <select className="form-control nopac" name="demo_acc" id="demo_acc" defaultValue={Number(app.data("dod").split(",")[1])} onChange={this.handleInputChange} required>
                          <option value="1">Activate</option>
                          <option value="0">De-activate</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "VERIFICATION BYPASS" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.verByp} style={{flexDirection: "column"}}>
                <h3>Verification bypass</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Account Verification Bypass</label>
                        <select className="form-control nopac" name="ver_byp" id="ver_byp" defaultValue={Number(app.data("byp"))} onChange={this.handleInputChange} required>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "DEPOSIT BUTTON" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.depBtn} style={{flexDirection: "column"}}>
                <h3>Deposit Button</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Toggle Deposit Button</label>
                        <select className="form-control nopac" name="dep_btn" id="dep_btn" defaultValue={Number(app.data("dod").split(",")[0])} onChange={this.handleInputChange} required>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "AUTO TRADER" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.matBtn} style={{flexDirection: "column"}}>
                <h3>Manual/Auto Trader</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Toggle auto trader tab</label>
                        <select className="form-control nopac" name="mat_btn" id="mat_btn" defaultValue={Number(app.data("mat"))} onChange={this.handleInputChange} required>
                          <option value="1">Enable</option>
                          <option value="0">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "NOTIFIER" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.natBtn} style={{flexDirection: "column"}}>
                <h3>Notify Users on admin's activity</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-3">
                      <div className="form-group">
                        <label>Show admin's notification</label>
                        <select className="form-control nopac" name="mat_btn" id="nat_btn" defaultValue={Number(app.data("nat"))} onChange={this.handleInputChange} required>
                          <option value="1">Enabled</option>
                          <option value="0">Disabled</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "VERIFIER" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.doNothing} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                {/* <hr className="shr" /> */}
                <h3>Verification Process</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "350px"}}>{this.state.eotp ? "Enable" : "Disable"} Email Verification</label>
                        <label className="switch-box" htmlFor={"switch-box-eotp"}>
                          <input type="checkbox" className="switch-check" name="eotp" id={"switch-box-eotp"} defaultChecked={this.state.eotp} onChange={(e) => {
                              this.setState({eotp: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "350px"}}>{this.state.potp ? "Enable" : "Disable"} Phone number Verification</label>
                        <label className="switch-box" htmlFor={"switch-box-potp"}>
                          <input type="checkbox" className="switch-check" name="potp" id={"switch-box-potp"} defaultChecked={this.state.potp} onChange={(e) => {
                            this.setState({potp: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "350px"}}>{this.state.kyct ? "Automatic" : "Manual"} KYC Verification</label>
                        <label className="switch-box" htmlFor={"switch-box-kyct"}>
                          <input type="checkbox" className="switch-check" name="kyct" id={"switch-box-kyct"} defaultChecked={this.state.kyct} onChange={(e) => {
                            this.setState({kyct: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="shr" />

                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn' disabled={pts == 0}>UPDATE</button>
                      </div>
                    </div>
                </div>
                <br />
              </form>
            </div>
            

            <div className='deposit' style={{display: this.state._selectedTab == "ACCOUNT CURRENCIES" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.setCr} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                <h3>Available Currency</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>USD ($)</label>
                        <label className="switch-box" htmlFor={"switch-box-usd"}>
                          <input type="checkbox" className="switch-check _scur" name="usd" id={"switch-box-usd"} onChange={this.updateCur} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>EUR (€)</label>
                        <label className="switch-box" htmlFor={"switch-box-eur"}>
                          <input type="checkbox" className="switch-check _scur" name="eur" id={"switch-box-eur"} onChange={this.updateCur} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>GBP (£)</label>
                        <label className="switch-box" htmlFor={"switch-box-gbp"}>
                          <input type="checkbox" className="switch-check _scur" name="gbp" id={"switch-box-gbp"} onChange={this.updateCur} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {currency.length == 0 ? <div className="row">
                    <div className="col-6" style={{color: "red", textAlign: "left"}}>At least 1 currency must be enabled!</div></div>
                  : null}
                </div>

                <hr className="shr" />
                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn' disabled={currency.length == 0}>UPDATE</button>
                      </div>
                    </div>
                </div>
                <br />
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "PAYMENT SETTINGS" ? "block" : "none"}}>
              <form className='settings-action' onSubmit={this.setPr} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                {/* <hr className="shr" /> */}
                <h3>Available Payment Types</h3>
                <div className="col-12 _alt">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>Bank Transfer</label>
                        <label className="switch-box" htmlFor={"switch-box-transfer"}>
                          <input type="checkbox" className="switch-check" name="transfer" id={"switch-box-transfer"} defaultChecked={this.state.transfer} onChange={(e) => {
                              this.setState({transfer: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>Credit Card</label>
                        <label className="switch-box" htmlFor={"switch-box-credit"}>
                          <input type="checkbox" className="switch-check" name="credit" id={"switch-box-credit"} defaultChecked={this.state.credit} onChange={(e) => {
                            this.setState({credit: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>Bitcoin Transfer</label>
                        <label className="switch-box" htmlFor={"switch-box-crypto"}>
                          <input type="checkbox" className="switch-check" name="crypto" id={"switch-box-crypto"} defaultChecked={this.state.crypto} onChange={(e) => {
                            this.setState({crypto: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label style={{width: "200px"}}>Payment Link</label>
                        <label className="switch-box" htmlFor={"switch-box-plink"}>
                          <input type="checkbox" className="switch-check" name="plink" id={"switch-box-plink"} defaultChecked={this.state.plink} onChange={(e) => {
                            this.setState({plink: e.target.checked ? 1 : 0});
                          }} />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {pts == 0 ? <div className="row">
                    <div className="col-6" style={{color: "red", textAlign: "left"}}>At least 1 payment type must be enabled!</div></div>
                  : null}
                </div>

                <hr className="shr" />

                <h3>Bank Transfer Details</h3>
                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-3">
                      <label>Account n<u>o</u></label>
                      <input type='text' className="form-control" name='acc_no' onChange={this.handleInputChange} autoComplete="new-password" required />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Account Name</label>
                      <input type='text' className="form-control" name='acc_name' onChange={this.handleInputChange} autoComplete="new-password" required />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Bank Name</label>
                      <input type='text' className="form-control" name='bank_name' onChange={this.handleInputChange} autoComplete="new-password" required />
                    </div>
                  </div>
                </div>
                <br />
                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-3">
                      <label>Bank IBAN</label>
                      <input type='text' className="form-control" name='iban' onChange={this.handleInputChange} autoComplete="new-password" required />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Bank swift code</label>
                      <input type='text' className="form-control" name='swift' onChange={this.handleInputChange} autoComplete="new-password" required />
                    </div>
                  </div>
                </div>
                <br />

                <hr className="shr" />

                <h3>Bitcoin Transfer Wallet</h3>
                {wallets.map((w, k) => this.wallet(w, k))}

                <button className="btn btn-primary btn-sm ans" type="button" onClick={() => {
                  let wl = wallets;
                  wl.push({crypto: "", wallet: ""});
                  this.setState({wallets: wl});
                }}>Add new wallet</button>

                <hr className="shr" />
                
                  <div className='col-12 _alt'>
                    <div className='row'>
                      <div className="col-6">
                        <label>Payment link ({this.state.plink ? <>required</> : <>optional</>})</label>
                        <input type='text' className="form-control" name='payment_link' onChange={this.handleInputChange} autoComplete="new-password" />
                      </div>
                    </div>
                  </div>
                  
                <hr className="shr" />

                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn' disabled={pts == 0}>UPDATE</button>
                      </div>
                    </div>
                </div>
                <br />
                <br />
              </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "QUESTIONNAIRE" ? "block" : "none"}}>
              <form className='settings-action qfs' onSubmit={this.qnr} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                <h3 style={{marginBottom: "0"}}>QUESTIONNAIRE</h3>
                <hr className="shr" />

                {questionnaire.map((q, ki) => this.newQuest(q, ki))}
              
                <button className="btn btn-primary btn-sm ans" type="button" onClick={() => {
                  let qs = this.state.questionnaire;
                  qs.push({question: "", answers: ["", ""]});
                  this.setState({questionnaire: qs});
                }}>Add new question</button>

                {questionnaire.length ?
                  <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn at-create'>UPDATE</button>
                      </div>
                    </div>
                  </div> : null}
                  <br />
                  <br />
              </form>
            </div>
            
            <div className='deposit' style={{display: this.state._selectedTab == "SETUP" ? "block" : "none"}}>
              <form className='settings-action' autoComplete="new-password" onSubmit={this.aSetup} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                <h3>SETUP</h3>

                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-3">
                      <label>Company's Name</label>
                      <input type='text' className="form-control" name='company' onChange={this.handleInputChange} autoComplete="new-password" defaultValue={app.data("title")} />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Subdomain<small>(NOT EDITABLE)</small></label>
                      <input type='text' className="form-control" name='subdomain' onChange={this.handleInputChange} autoComplete="new-password" defaultValue={app.host(false)} readOnly />
                    </div>
                  </div>
                </div>
                <br />
                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-3">
                      <label>Expiry Date<small>(CONTACT ADMIN)</small></label>
                      <input type='text' className="form-control" name='exp' onChange={this.handleInputChange} defaultValue={app.data("exp")} readOnly />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Plan<small>(CONTACT ADMIN)</small></label>
                      <input type='text' className="form-control" name='exp' onChange={this.handleInputChange} defaultValue={app.data("plan").toUpperCase()} readOnly />
                    </div>
                  </div>
                </div>
                <br />
                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-3">
                      <label>Email forwarding address</label>
                      <input type='text' className="form-control" name='efa' onChange={this.handleInputChange} defaultValue={app.data("efa")} />
                    </div>
                    <div className="col-1"></div>
                    <div className="col-3">
                      <label>Password</label>
                      <input type='password' className="form-control" name='efp' onChange={this.handleInputChange} />
                    </div>
                  </div>
                </div>
                <br />
                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-7">
                      <label>Home link</label>
                      <textarea className="form-control" name="home" onChange={this.handleInputChange} defaultValue={app.data("home")}></textarea>
                    </div>
                  </div>
                </div>

                <img data-trigger="logo" src={app.custom("logo")} className="cs_logo" />
                <input type="file" name="logo" style={{display: "none"}} />

                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
                </form>
            </div>
            
            <div className='deposit' style={{display: this.state._selectedTab == "EMAIL" ? "block" : "none"}}>
              <form className='settings-action' autoComplete="new-password" onSubmit={(e) => {
                e.preventDefault();
                this.eTemp();
                return false;
              }} style={{flexDirection: "column", height: "calc(100vh - 255px - 45px)", overflowX: "hidden", overflowY: "auto"}}>
                <h3>EMAIL</h3>

                <div className='col-12 _alt'>
                  <div className='row'>
                    <div className="col-7">
                      <label>Welcome text</label>
                      <textarea className="form-control" name="content" onChange={this.handleInputChange} defaultValue={"Hi User\n\nYou've successfully completed your registration.\nYou are just one step away from activating your account."} rows="7" style={{padding: "1em"}}></textarea>
                    </div>
                  </div>
                </div>
                <br />

                <div className='col-12 _alt' style={{marginTop: "2em", width: "100%"}}>
                    <div className="row">
                      <div className="col-3">
                        <button type='submit' className='btn sb-btn'>UPDATE</button>
                      </div>
                    </div>
                </div>
                </form>
            </div>

            <div className='deposit' style={{display: this.state._selectedTab == "SERVER FEE" ? "flex" : "none", justifyContent: "center", alignItems: "center", height: "100%"}}>
              <center>
                <strong style={{fontSize: "40px"}}>Due Date: {app.data("exp")}</strong><br />
                <strong style={{fontSize: "30px", marginTop: "15px"}}><img src={calendar} style={{width: "30px", height: "50px", marginRight: "10px", position: "relative", top: "-4px"}} />{app.daysToGo()}</strong>
              </center>
            </div>

          </div>
        </div>
        </div>
      </Container>
    );
  }
}

export default Settings;
