import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import copy from '../../Assets/copy.svg';
import Container from '../container/index';
import '../../components/standard/standard.scss';
import './index.scss';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';
import TableFilters from '../../components/tablefilters/index';
import check from './icv.png';
import Pagination from '../../components/paginationTwo/index';
import Spinner from '../../components/spinner/index';
import Configure from '../../components/configure/c';
import DeleteClient from '../../components/configure/d.jsx';
import { ConfirmModal, CallBack } from '../../components/popups/index';
import '../../components/standard/table.scss';
import gear from '../../themes/images/gear.png';
import server from '../../services/server';
import app from '../../services/app';


class Clients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page_no: 1,
      callback: false,
      page_size: app.maxrow,
      filter: '',
      configure: false,
      users: [],
      admins: [],
      copied: "",
      showLoader: true,
      data: [],
      callback: false,
      callbackTxt: '',
      soft_delete: "all",
      utype: "all",
      deleteModal: false,
      deleteId: null,
      restore: false,
      confirmID: '',
      confirmModal: false,
    }
  }

  async componentDidMount() {
    await this.getAllUsers();

    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });
  }

  getAllUsers = async () => {
    this.setState({showLoader: true});
    try {
      let clients = await server.getAllClients();
      this.setState({users: clients.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  uplan = async (v, i) => {
    this.setState({showLoader: true});
    try {
      let up = await server.updatePlan(v, i);
      this.setState({showLoader: false});
      await this.getAllUsers();
    } catch(e) {
      return e;
    }
  }

  copy = (id) => {
      /* Get the text field */
      var copyText = document.getElementById(id);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
      this.setState({copied: copyText.value});
  }

  render() {
    let { page_no, page_size, users, filter, copied, utype } = this.state;

    filter = filter.toLowerCase();

    if(filter.length) {
      users = users.filter((c) => {
        return (
          c.email.toLowerCase().match(filter.toLowerCase()) ||
          c.phone.toLowerCase().match(filter.toLowerCase()) ||
          c.company.toLowerCase().match(filter.toLowerCase()) ||
          c.domain.toLowerCase().match(filter.toLowerCase()) ||
          (c.name).toLowerCase().match(filter.toLowerCase())
        );
      });
    }
    // if(soft_delete != "all") {
    //   users = users.filter((c) => {
    //     return c.soft_delete == soft_delete;
    //   });
    // }

    let max_rows = users.length;
    let stt = (page_no-1)*page_size;
    let max = stt+page_size;
        max = max > max_rows ? max_rows : max;
      users = users.slice(stt, max > max_rows ? max_rows : max);

    return (
      <Container>
      <Spinner showSpinner={this.state.showLoader} />
      <div className="col-12" id="users-container">
        <div className="users-section-right">
          <Breadcrumbs breads="Home, Clients" />
          <h1 className="page-title">Clients</h1>
          <TableFilters table="clients" search={(e) => this.setState({filter: e.target.value})} />
          {
              this.state.configure ?
                <Configure
                  cancel={() => this.setState({configure: false})}
                  success={() => { this.setState({configure: false}); this.getAllUsers(); }}
                  data={this.state.data}
                /> : null
            }

            {
                this.state.deleteModal ?
                  <DeleteClient
                    cancel={() => this.setState({deleteModal: false})}
                    success={() => { this.setState({deleteModal: false}); this.getAllUsers(); }}
                    data={this.state.data}
                  /> : null
              }

            <ul className="table-header cent">
              <li>NAME</li>
              <li>EMAIL</li>
              <li>PHONE</li>
              <li>COMPANY</li>
              <li>DOMAIN</li>
              <li>PLAN</li>
              <li>DATE</li>
              <li>ACTION</li>
            </ul>

            { users.length > 0 ?
              users.map((user) => {
                // let o = user.paid == 1 || user.dns.length > 0 || user.domain == "root.devfx.co";
                let o = user.paid == 1 || user.dns.length == 0 > 1;
                if(user.domain == "root.devfx.co") {
                  o = false;
                }
                let d = parseInt(user.duration) > 0 ? parseInt(user.duration) : 1;
                return (
                <ul className="table-body cent" key={`${Math.random()} ${Math.random()}`} style={{opacity: o ? 0.65 : 1}}>
                  <li><img src={user.logo} alt='' style={{height: "20px", width: "auto", marginLeft: "-25px"}} />&nbsp;&nbsp;{user.name.ucwords()}</li>
                  <li><span className="txt-success">{user.email}</span></li>
                  <li><span className="txt-info">{(user.phone.trim().charAt(0) == "+" ? "" : "+")+user.phone}</span></li>
                  <li><span className="txt-info">{user.company}</span></li>
                  <li>
                      <a className={"txt-"+(o ? "info" : "success")} href={"https://"+user.domain} target="_blank">{user.domain}</a>
                      {o ? null : <img src={check} className="veri" />}
                  </li>
                  <li>
                    {
                      user.custom > 0 ? <span style={{cursor: "pointer"}} title={'CUSTOM '+user.plan.toUpperCase()}>CUSTOM</span> :
                      <select className="lev" onChange={(e) => this.uplan({p: e.target.value, d: user.domain}, user.id)}>
                        <option value="buck" selected={user.plan.toUpperCase() == "BUCK"}>BUCK</option>
                        <option value="stack" selected={user.plan.toUpperCase() == "STACK"}>STACK</option>
                        <option value="brick" selected={user.plan.toUpperCase() == "BRICK"}>BRICK</option>
                        <option value="stick" selected={user.plan.toUpperCase() == "STICK"}>STICK</option>
                      </select>
                    }
                  </li>

                  <li><span className="txt-default">{ user.domain == "root.devfx.co" ? "*" : (
                    user.create_time.length ? (
                      <>
                        <small>
                          {moment(user.create_time.split(" ")[0]).format('DD/MM/YYYY')}&nbsp;-&nbsp;
                          {user.expiration.length ? user.expiration : moment(user.create_time).add(d, 'M').format('DD/MM/YYYY')}
                        </small>
                      </>
                  ) : "NOT STATED"
                  )}</span></li>
                  <li>
                    { user.domain == "root.devfx.co" ? "*" : <>
                      <Link to={"/Pricing/"+user.id} className="ed-ea" style={{color: "#fff"}}>Features</Link>
                      <img src={gear} onClick={() => { this.setState({data: user, configure: true}); }} style={{width: "18px", height: "19px", cursor: "pointer"}} className="edit-trade" />
                      <input type="text" style={{display: "none"}} value={app.hostURL("campaign/?_="+user.id)} id={"cam_link_"+user.id} />
                      <img src={copy} className="copy" onClick={() => this.copy("cam_link_"+user.id)} style={{width: "20px", height: "20px", marginLeft: "6px", cursor: "pointer", opacity: copied == app.hostURL("campaign/?_="+user.id) ? 0.4 : 1}} title={copied == app.hostURL("campaign/?_="+user.id) ? "Copied" : "Campaign link"} />
                      <svg onClick={() => this.setState({deleteID: user.id, data: user, deleteModal: true})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="red" />
                      </svg>
                    </>}
                  </li>
                </ul>
              )}) : null
            }
            <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>
        </div>
      </div>
      </Container>
    );
  }
};

export default Clients;
