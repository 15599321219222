import $ from 'jquery';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
// import logo from '../../Assets/logo.svg';
import server from '../../services/server';
import app from '../../services/app';
import { CallBack } from '../../components/popups/index';
import Spinner from '../../components/spinner/index';
import './Auth.scss';

let logo = app.custom("logo");

class Login extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        hlb: false,
        email: '',
        password: '',
        rtx: 'Registration successful',
        email_otp: '',
        confirm_password: '',
        showCallBack: false,
        errorMessage: '',
        checked: false,
        canreg: false,
        justReg: false,
        showSpinner: false,
        signinError: null,
        conti: false,
        pt: "password",
        npt: "password",
        rnpt: "password"
      };
    }
  
    handleClick = (e) => {
      this.setState({
        checked: !this.state.checked,
      });
    };
  
    clearErrors = () => {
      this.setState({ signinError: null });
    };

    togglePT = (f, pt) => {
      this.setState({
        [f]: pt == "text" ? "password" : "text",
      });
    }
  
    loginUser = async (e) => {
      e && e.preventDefault();
      let pg = window.location.pathname.replace("/", "").toLowerCase();
      if(pg.length == 0) {
        pg = "login";
      }

      this.clearErrors();
  
      const { email, password, confirm_password, email_otp, hlb } = this.state;

      if(pg == "login") {
        try {
          let cre = {username: email, password: password};
          if(hlb && !e) { cre.au = true; } else {
            this.setState({ showSpinner: true });
          }
          let response = await server.login(cre);
          const user   = response.data.profile;
          let isAdmin  = false;
    
          if(response.data.ia || isAdmin) {
            isAdmin = true;
            localStorage.setItem('avariz_ia', response.data.ia);
          }
    
          if(!window.location.host.match("localhost:")) {
            console.log(user.host, window.location.host, "xx");
            if(user.host != window.location.host) {
              localStorage.clear();
              this.setState({ signinError: app.l("Invalid Credential :("), justReg: false, showSpinner: false });
              return false;
            }
          }
          app.profile(user);
          const accounts = app.accounts();
          this.setState({ showSpinner: false });
          window.location.href = isAdmin ? '/Desks' : '/Trade';
        } catch (error) {
          if(hlb && !e) {
            window.location.href = "/login";
          }
          this.setState({ showSpinner: false });
          if (!error.response) {
            return error;
          }
          const errorMessage = error.response.data.message;
          this.setState({ signinError: app.l(errorMessage), justReg: false });
        }
      } else {
        try {
          this.setState({ showSpinner: true });
          if(pg == "changepassword") {
            if(password != confirm_password) {
              return this.setState({ signinError: app.l("Passwords do not match"), justReg: false, showSpinner: false });
            }
            await server.resetPassword({ email, otp: parseInt(email_otp), new_password: password});
            window.location.href = "/Login?rest";
          } else {
            await server.sendResetEmail(email);
            this.props.history.push("/ChangePassword");
          }
          this.setState({ showSpinner: false });
          this.props.history.push(pg == "changepassword" ? "/Login?rest" : "/ChangePassword");
        } catch (error) {
          this.setState({ showSpinner: false });
          if (!error.response) {
            return error;
          }
          const errorMessage = error.response.data.message;
          this.setState({ signinError: errorMessage, justReg: false });
        }
      }
    };
  
    handleChange = (e) => {
      const {
        target: { value, name },
      } = e;
  
      this.setState({
        [name]: value,
      });
    };
  
    componentDidMount () {
      $("title").html(app.custom("name")+" - LOGIN");
      
      let ists = false;
      if(this.props.location.search.length) {
        if(this.props.location.search.indexOf("=") > -1) {
          let sp = this.props.location.search.split("=");
          if(sp[0].toLowerCase() == "?_ts") {
            ists = true;
            $("[name='email']").val(sp[1].trim());
            this.setState({email: sp[1].trim(), conti: true});
          }
        }
      }

      if(!ists) {
        if(this.props.location.search == "?registered") {
          this.setState({justReg: true, showCallBack: true, rtx: "Registration successful"});
        } else if(this.props.location.search == "?rest") {
          this.setState({justReg: false, showCallBack: true, rtx: "Password updated successfully"});
        } else if(this.props.location.search == "?logout") {
          window.location.href = "Login";
        } else if(this.props.location.search.match(/@/)) {
          this.setState({email: this.props.location.search.replace("?", ""), password: "***", hlb: true});
          setTimeout(() => {
            this.loginUser(false);
          }, 500);
        }
      }
      localStorage.clear();
    }

    render () {
      const { showSpinner, signinError, justReg, canreg, rtx, pt, npt, rnpt, hlb, conti } = this.state;
      let rg    = window.location.pathname.replace("/", "");
      let pg    = window.location.pathname.replace("/", "").toLowerCase();
      
      if(pg.length == 0) { pg = "login"; }

      let lop  = pg == "login" ? app.l((hlb ? 'Welcome' : 'Login')+' to your '+app.custom("name").ucwords()+' portfolio') : app.l(pg == "changepassword" ? 'Reset your '+app.custom("name").ucwords()+' password' : "Verify your account");
      let rest = {marginTop: "1em", display: "flex", justifyContent: "center", alignItems: "center", color: "var(--custom_color)"};

      return this.props.location.search == "?logout" ? null : (
        <div className="App">          
        <Spinner showSpinner={showSpinner} />
        <CallBack show={this.state.showCallBack} cancel={(e) => this.setState({showCallBack: false})} head={"Success"} text={rtx} />
        <div className="App-body outside login-page">
            <a href={app.data("home")} target="_blank"><img className="App-logo" src={logo} alt="" /></a>
            
            <div className="container">
            <div className="row">
                <h3 className="lop">{lop}</h3>
                <div className="col sign-fill center">
                <div className="card lgn">
                {hlb ? false :
                <div className="card-header">
                  {
                    pg == "login" ?
                      <div className="row">
                        <Link to="/Register" className="col label">{app.l("Sign up")}</Link>
                        <Link to="/Login" className="col label active">{app.l("Log in")}</Link>
                      </div>
                    : 
                    <div className="row">
                      <Link to="/Login" className="col label">{app.l("Log in")}</Link>
                      <Link to={"/"+rg} className="col label active">{app.l(pg == "changepassword" ? "Reset password" : "Forgot password")}</Link>
                    </div>
                  }
                </div>}
                <div className="card-body">
                  {hlb ?
                    <div className='form-group'>
                      <label><b className='_prepin'>Preparing your account</b></label>
                      <div class="_progress">
                        <div class="color"></div>
                      </div>
                    </div> :
                    <form align="left" className="dform" onSubmit={this.loginUser}>
                    <div className="form-group">
                      <label>{app.l("Email", true)}</label>
                      <input type="text" name="email" className="form-control" onChange={this.handleChange} required />
                    </div>
                    {
                      pg == "login" ?
                      <div className="form-group" style={{position: "relative"}}>
                        <label>{app.l("Password", true)}</label>
                        <input type={pt} name="password" className="form-control" onChange={this.handleChange} required />
                        <svg onClick={() => this.togglePT("pt", pt)} className="tb-action" style={{position: "absolute", bottom: "15px", cursor: "pointer", right: "10px"}} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#ffffffc2" />
                        </svg>
                      </div> : pg == "changepassword" ? <>
                        <div className="form-group">
                          <label>{app.l("OTP")}</label>
                          <input type="number" name="email_otp" className="form-control" onChange={this.handleChange} required />
                        </div>
                        <div className="form-group" style={{position: "relative"}}>
                          <label>{app.l("NEW PASSWORD")}</label>
                          <input type={npt} name="password" className="form-control" onChange={this.handleChange} required />
                          <svg onClick={() => this.togglePT("npt", npt)} className="tb-action" style={{position: "absolute", bottom: "15px", cursor: "pointer", right: "10px"}} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#ffffffc2" />
                          </svg>
                        </div>
                        <div className="form-group" style={{position: "relative"}}>
                          <label>{app.l("RE-ENTER NEW PASSWORD")}</label>
                          <input type={rnpt} name="confirm_password" className="form-control" onChange={this.handleChange} required />
                          <svg onClick={() => this.togglePT("rnpt", rnpt)} className="tb-action" style={{position: "absolute", bottom: "15px", cursor: "pointer", right: "10px"}} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#ffffffc2" />
                          </svg>
                        </div>
                      </> : null
                    }
                    
                    {justReg ? <p className='success'>{"Registration successful"}</p> : <p className='error'>{signinError}</p>}

                    <div className="form-group" style={{marginTop: "1em"}}>
                        <button type="submit" className={"btn btn-warning btn-lg btn-block app-btn"+(conti ? " blk" : "")}>{app.l(pg == "login" ? (conti ? "Continue" : "Log in") : (pg == "changepassword" ? "Reset" : "Send password reset email"))}</button>
                        {pg == "login"
                          ? <Link to="/ForgotPassword" style={rest}>{app.l("Forgot Password")}</Link>
                          : <Link to="/Register" style={rest}>{app.l("Don't have an account?")}</Link>}
                    </div>
                    </form>}
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
    }
}

export default withRouter(Login);
