import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import SideBar from '../../components/sideBar2/index';
import OpenTrade from '../../components/openTrade/index';
import ClosedTrade from '../../components/closedTrade/index';
import PendingTrade from '../../components/pendingTrade/index';
import TradeHistory from '../../components/tradeHistory/index';
import Balance from '../../components/balance/index';
import BuyandsellModal from '../../components/buyandsellModal/index';
import BsConfirmationModal from '../../components/bsConfirmationModal/index';
import con_buysell from '../../themes/images/con_buysell.png';
import Spinner from '../../components/spinner/index';
import server from '../../services/server';
import app from '../../services/app';
import Breadcrumbs from '../../components/breadcrumbs/index';
import TableFilters from '../../components/tablefilters/index';
import { saveUserProfile } from '../../redux/actions/index';
import Container from '../container/index';
import './index.scss';

class TradeDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
      currentTab: 'Open Trades',
      showSpinner: true,
      showNav: true,
      hideNav: false,
      open_pl: 0,
      equity: 0,
      cfil: '',
      margin: 0,
      loaded: false,
      filterOptions: ['ALL TYPES'],
      accounts: [],
      clients: [],
      users: [],
      _users: [],

      all_trades: this.props.uid ? this.props.trades : [],

      ot_trades: [],
      open_trades: [],
      closed_trades: [],
      pending_trades: [],
      profile: app.profile(),
      hotStocks: [],
      favouritePairs: [],
      _favouritePairs: [],
      favourites: [],

      buyandsellAct: 'buy',
      buyandsellModal: false,
      buyandsellModalInfo: false,
      buyandsellConfirmed: false
    };

    this.realTimeListener = true;
    this.retryCounter = 0;

    this.profile = app.profile();
  }

  componentWillUnmount() {
    this.realTimeListener = false;
  }

  getAllUsers = async () => {
    let _users = await server.getAllUsers();

    let u = [];
    _users.data.forEach((uv, uk) => {
      u.push(uv["user_id"]);
    });

    this.setState({users: _users.data, _users: u});
  }

  getClosedTrades = async (uid = null) => {
    let getc = await server.getClosedTrades(uid);
    let ot_trades = getc.data.trades;
    this.setState({ ot_trades });
  }

  async componentDidMount() {
    this.getAllClients();
    this.getAllUsers();
    this.getClosedTrades();

    $(window).on("renewSocket", () => this.socketInit());
    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }

    this.setState({ showNav: true });
    this.realTimeListener = true;
    this.setState({ selectedAccount: app.accountDetail(), accounts: app.accounts() });
    window.addEventListener('resize', this.updateDimensions);
    
    let dis = this;
    $(document).delegate(".cl-trd-sx", "click", () => {
      dis.getClosedTrades();
    });

    setTimeout(() => {
      let filterOptions = Object.keys(app.allPairs());
      let f = [];
      for (var i = 0; i <= filterOptions.length; i++) {
        f[i] = (i == 0 ? "ALL TYPES" : filterOptions[i - 1]).toUpperCase();
      }
      this.setState({filterOptions : f});
    }, 1000);
  }


  socketInit = () => {
    window.WebSocketPlug.addEventListener('message', ({data}) => {
      try {
        let message = JSON.parse(`${data}`);
        let payload = message.payload;
        let payl_hist = [];
        switch(message.event) {
          case "PAIR_DATA":
            this.setState({ hotStocks: payload, showLoader: false, showSpinner: false });
          break;
          case "TRADE_HISTORY":
            payl_hist = payload.history;
            payl_hist = payl_hist.concat(this.state.ot_trades);
            if(payload.user == app.id() && payload.admin) {
              if(this.props.uid) {
                let trs = payl_hist.filter((h) => h.user_id.trim() == this.props.uid.trim() && parseInt(h.order_status) === this.props.tyval), atr = [];

                trs.forEach((trade, i) => {
                  let rate = this.state.hotStocks.filter((pair) => {
                    if(pair.pair) {
                      return pair.pair.toLowerCase().match(trade.instrument.toLowerCase()) || trade.instrument.toLowerCase() == pair.pair.toLowerCase();
                    }
                  });
                  if(rate.length) {
                    let brate = app.floatFormat(rate ? rate[0].ask : 0);
                    let srate = app.floatFormat(rate ? rate[0].bid : 0);
                    trade.current_rate = trade.mode == "buy" ? brate : srate;
                    trade.real_rate = trade.current_rate;
                    atr.push(trade);
                  }
                });
                this.setState({ all_trades: atr, loaded: true, showSpinner: false });
              } else {
                let hist = app.super() ? payl_hist : (this.state._users.length ? payl_hist.filter((hi, hk) => { return this.state._users.indexOf(hi.user_id) > -1 }) : []);
                this.setState({ all_trades: hist, loaded: true, showSpinner: false });
                // console.log(hist.length, "=========");
                this.populateTrades(hist);
              }
            }
          break;
        }
      } catch (e) {
        throw e;
      }
    });
  }

  populateTrades = (trades = false) => {
    let all_trades = trades ? trades : this.state.all_trades;
    let open_trades = [];
    let closed_trades = [];
    let pending_trades = [];
    let open_pl = 0;
    let margin = 0;

    all_trades.forEach((trade, i) => {
      let rate = this.state.hotStocks.filter((pair) => {
        if(pair.pair) {
          return pair.pair.toLowerCase().match(trade.instrument.toLowerCase()) || trade.instrument.toLowerCase() == pair.pair.toLowerCase();
        }
      });
      if(rate.length) {
        let brate = app.floatFormat(rate ? rate[0].ask : 0);
        let srate = app.floatFormat(rate ? rate[0].bid : 0);
        trade.current_rate = trade.mode == "buy" ? brate : srate;
        trade.real_rate = trade.current_rate;

        // if(trade.custom) {
        //   trade.current_rate = rate[0].custom_rate;
        // }

        if(trade.order_status == 0) {
          open_pl += Number(trade.profit);
          margin += Number(trade.required_margin);
          open_trades.push(trade);
        }
        if(trade.order_status == 1) {
          pending_trades.push(trade);
        }
        if(trade.order_status == 2) {
          closed_trades.push(trade);
        }
      }
    });

    this.setState({
      open_trades:       open_trades,
      pending_trades:    pending_trades,
      closed_trades:     closed_trades,
    });
  }

  updateDimensions = () => {
    if (window.innerWidth > 600 && this.state.currentTab === 'Balance') {
      this.setState({ currentTab: 'Trade' });
    }
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  showMainLoader = () => {
    this.setState({showSpinner: !this.state.showSpinner});
  }

  toggleSideBar = () => {
    this.setState({ clicked: !this.state.clicked, hideNav: !this.state.hideNav });
  }

  handleClick = (e) => {
    this.setState({
      currentTab: e.currentTarget.querySelector('p').innerHTML, hideNav: !this.state.hideNav
    });
    $(".link-icons.trade").click();
  }

  handleNavClick = () => {
    this.setState({ showNav: !this.state.showNav });
  }

  render() {
    let { currentTab, hotStocks, showSpinner, loaded, cfil, open_trades, closed_trades, pending_trades } = this.state;
    cfil = cfil.toLowerCase();

    return this.props.uid ? <TradeHistory {...this.props} history={this.state.all_trades} /> : (
      <Container>
        <div className="col-12" id="instrument-container">
          <div className="users-section-right">
          <Breadcrumbs breads={"Home, Trades, "+currentTab} />
          <Spinner showSpinner={showSpinner} />
          {/* <h1 className="page-title">Trades</h1> */}
          <TableFilters
            table="trades"
            clients={this.state.clients}
            currentTab={this.state.currentTab}
            filterOptions={this.state.filterOptions}
            switchTo={(i) => this.setState({currentTab: i})}
            cch={(e) => { this.setState({cfil: e.target.value}); }}
            acount={currentTab === 'Open Trades' ? open_trades.length : currentTab === 'Closed Trades' ? closed_trades.length : currentTab === 'Pending Trades' ? pending_trades.length : 0}
          />
            <div className='trade-section'>
              <div className='right' style={{ width: '100%' }}>
                <div className='trade-comp-container' style={{padding: 0}}>
                  {currentTab === 'Open Trades' ? (
                    <OpenTrade filterOptions={this.state.filterOptions} admin={true} loaded={loaded} history={this.state.open_trades} cfil={this.state.cfil} />
                  ) : currentTab === 'Closed Trades' ? (
                    <ClosedTrade filterOptions={this.state.filterOptions} admin={true} loaded={loaded} history={this.state.closed_trades} cfil={this.state.cfil} />
                  ) : currentTab === 'Pending Trades' ? (
                    <PendingTrade filterOptions={this.state.filterOptions} admin={true} loaded={loaded} history={this.state.pending_trades} cfil={this.state.cfil} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveUserProfile: (profile) => dispatch(saveUserProfile(profile)),
});

export default withRouter(connect(null, mapDispatchToProps)(TradeDashboard));
