import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import OutterTopNav from '../../components/outterTopNav/index';
import OutterLeftNav from '../../components/outterLeft/index';
import { toggleSideNav, toggleOutterNav, toggleTransactionNav } from '../../redux/actions/index';
import { saveUserProfile } from '../../redux/actions/index';
import server from '../../services/server';
import warning from "../../themes/images/warning.png";
import socketPlug from '../../services/emit';
import app from '../../services/app';
import $ from 'jquery';
import './index.scss';

window.ac_badge = 0;
let blur_timeout = null;
let dpage = window.location.pathname.replace("/", "").toLowerCase();
let isOnApp = ["book", "trade", "accounts", "profile", "forgotPassword", "changePassword", "market", "news", "transactions"].indexOf(dpage) > -1;
if(isOnApp && !app.isAdmin()) {
  (async () => {
    if(app.loggedIn()) {
      setTimeout(() => {
        // $("#_sphs_").remove();
      }, 100);
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
    } else {
      window.location.href = "/login";
      process.exit(0);
    }
  })();
} else {
  // $("#_sphs_").remove();
}

class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      badge: 0,
      out: true,
      idle: false,
      selectedAccount: app.isAdmin() ? [] : app.accountDetail(),
    };

    this.profile   = app.profile();
    this.id        = app.id();
    this.isAdmin   = app.isAdmin();
    this.audio     = new Audio(require("../../themes/sounds/new.ogg").default);
    this.eudio = new Audio(require("../../themes/sounds/eudio.mp3").default);
    this.notify    = new Audio(require("../../themes/sounds/note.ogg").default);
    this.props.saveUserProfile(this.profile);
  }

  btimeout = () => {
      this.setState({idle: true});
      $(this.eudio)[0].play();
      setTimeout(() => {
          if(this.state.idle) {
              window.location.href = "/Login?_ts="+app.profile()["email"];
          }
      }, 1000 * 60);
  }

  async componentDidMount() {
    socketPlug();
    let dis = this;
    $(window).on("renewSocket", () => this.socketInit());
    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }

    $(window).on("blur", () => {
        blur_timeout = setTimeout(this.btimeout, 1000 * 60 * 15);
    });

    $(window).on("focus", () => {
        clearTimeout(blur_timeout);
    });
  }

  socketInit = async () => {
    if(this.isAdmin) {
      if(window.WebSocketPlugged) {
        window.WebSocketPlug.addEventListener('message', ({data}) => {
          try {
            let message = JSON.parse(`${data}`);
            let payload = message.payload;
            if(message.event == "ADMIN_UNREAD") {
            // console.log(message.event, message);
            }
            switch(message.event) {
              case "ADMIN_UNREAD":
                if(payload.count > 0) {
                  if(payload.count != window.ac_badge) {
                    $(this.audio)[0].play();
                    window.ac_badge = payload.count;
                  }
                }
                this.setState({badge: payload.count});
              break;
              case "IS_OFFLINE":
                app.switch_presence(payload.id, 0);
              break;
              case "IS_ONLINE":
                app.switch_presence(payload.id, 1);
              break;
              // case "NEW_CHAT":
              //   if(payload.badge > 0 && window.location.pathname.replace("/", "").toLowerCase() == "chats") {
              //     this.setState({badge: payload.badge});
              //   }
              // break;
            }
          } catch (e) {
            return e;
          }
        });
      }

      $(".maxrow").change(function () {
        app.setMaxrow($(this).val());
        if(window.NO_AUTO_PAGER) {
          $(window).trigger("resetPager");
        } else {
          window.location.reload();
        }
      });
      
      if(!window.__toggleOutterNav) {
        this.props.toggleOutterNav();
        window.__toggleOutterNav = true;
      }
      $("html").attr("style", 'min-width: 1400px; overflow: auto !important; background: #004044;');
    } else {
      if(window.WebSocketPlugged) {
        window.WebSocketPlug.addEventListener('message', async ({data}) => {
          try {
            let message = JSON.parse(`${data}`);
            let payload = message.payload;
            // console.log(message.event, message.event == "AUTO_REFRESH");
            if(message.event == "AUTO_REFRESH" && !app.isAdmin()) {
              const gp = await server.getProfile();
              app.profile(gp.data.profile);
              window.location.href = app.isVerified() ? "" : "/Profile";
            }
          } catch (e) {
            return e;
          }
        });
      }
    }
  }

  gmc = () => {
    if(window.WebSocketPlugged) {
        let clr = window.nclear || false;
        let nid = window.lastnid > 0 ? window.lastnid : (app.profile()["notifications"] || []).length ? app.profile()["notifications"][0]["id"] : 0;
        if(clr) {
          window.nclear = false;
        }
        let data = {
          "event": "ADMIN_UNREAD",
          "payload": {
            admin:   true,
            user:    app.id(),
            nclear : clr,
            lastnid: nid,
            nflag  : window.nread
          }
        };
        if(window.ADMIN_UNREAD != data) {
          window.ADMIN_UNREAD = data;
          window.WebSocketPlug.send(JSON.stringify(data));
        }
    }
  }

  logout = async () => {
    try {
      let vs = localStorage.getItem("avariz_vs") || 0;
      let cs = localStorage.getItem("avariz_cs") || 0;
      localStorage.clear();
      if(vs) localStorage.setItem("avariz_vs", vs);
      if(cs) localStorage.setItem("avariz_cs", cs);
      // this.props.history.push('/Login?logout');
      window.location.href = '/Login';
    } catch (error) {
      
    }
  }

  render() {
    const userId = app.id();
    if (!(userId || false)) return <Redirect to="/Login" />
    const { first_name, last_name, profile_image } = this.props.userProfile;
    let isAdmin = this.isAdmin;
    const selectedAccount = app.isAdmin() ? [] : app.accountDetail();
    let balance = isAdmin ? 0 : selectedAccount.balance;
    let real_balance = isAdmin ? 0 : selectedAccount.real_balance;
    let main_balance = Number(Number(balance) + Number(real_balance)).toFixed(2);
    let id = isAdmin ? 0 : selectedAccount.account_id;
    let { idle } = this.state;


    return (
      <div className={'dash-container'+(app.isAdmin() ? " admin"+(app._aff_() ? " _daff" : "") : "")+((app.isAdmin() && app.permit("Blur contact information (email and phone number)")) || app._aff_() ? "" : " blurable")}>
        <OutterLeftNav
          isAdmin={isAdmin}
          badge={this.state.badge}
          handleNavClick={this.props.toggleSideNav}
          handleOutterClick={this.props.toggleOutterNav}
          handleTransactionNavClick={this.props.toggleTransactionNav}
          tlnav={() => {
            this.setState({out: !this.state.out});
            setTimeout(() => {
              $(window).trigger("resize");
            });
          }}
          out={this.state.out}
        />
        {idle ? <div className='are-you-still-logged-in'>
            <div><span>Are you still logged in?</span><button onClick={() => {
                this.setState({idle: false});
            }}>Yes</button></div>
        </div> : null}
        <div className={`right ${this.state.out ? 'smaller-right' : ''}`}>
          <OutterTopNav
            isAdmin={isAdmin}
            profileImage={profile_image}
            email={app.email()}
            firstName={first_name}
            lastName={last_name}
            balance={balance}
            real_balance={real_balance}
            main_balance={main_balance}
            handleLogout={this.logout}
          />
          {/* {this.props.children} */}
          {app.daysToGo(true) > 0 ? this.props.children : <center style={{position: "relative", top: "35%", fontSize: "22px"}}><img src={warning} alt="" style={{width: "20px"}} /> Subscription Expired<br />please contact the admin to renew contract.</center>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ outterNav, userProfile }) => ({
  outterNav,
  userProfile,
});

const mapDispatchToProps = (dispatch) => ({
  toggleSideNav: () => dispatch(toggleSideNav()),
  toggleOutterNav: () => dispatch(toggleOutterNav()),
  saveUserProfile: (profile) => dispatch(saveUserProfile(profile)),
  toggleTransactionNav: () => dispatch(toggleTransactionNav()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));
