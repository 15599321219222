import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import folder from '../../themes/images/folder.png';
import file from '../../themes/images/file.png';
import down from '../../themes/images/profile/down.svg';
import view from '../../themes/images/view.png';
import loader from "./loader.svg";
import server from '../../services/server';
import app from '../../services/app';
import { ImageView } from '../../components/popups/index';
import './userfiles.scss';

class UserFiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: false,
      iview: false,
      src: null,
      folder: null,
      format: {profile_image: "Profile pictures", dod: "DOD", identity_proof: "Identity proof", residence_proof: "Residence proof", bank_card: "Card"},
      formatt: {profile_image: "Profile image", dod: "Declaration of deposit", identity_proof: "Proof of Identity", residence_proof: "Proof of Residence", bank_card: "Bank Card"},
      formattt: {profile_image: "poc_img", dod: "doc_dod", identity_proof: "doc_poi", residence_proof: "doc_por", bank_card: "doc_card"}
    }
  }

  componentDidMount () {
    $(window).on("appBack", () => {
      this.setState({files: false});
    });
  }

  handleDocChange = async (e, folder, name = null) => {
    const current = e.target.files[0];
    const fd = new FormData();
    fd.append('profile_doc.png', current, current.name);
    try {
      $(".new-upd").css({display: "none"});
      $(".new-upd-2").css({display: "block"});
      let pi = await server.uploadDoc(fd, folder, this.props.uid);
      this.props.refresh();
      $(".new-upd-2").css({display: "none"});
      $(".new-upd").css({display: "block"});
    } catch (error) {
      window.location.href = "";
      return error.message;
    }
  }

  render () {
    let active = parseInt(this.props.active);
    let docs = this.props.files;
    let name = this.state.formattt[this.state.folder];

  	return (
      <div className={"tab-row profile-files"+(active ? ' _active' : '')} id="tab-row-files">

      <ImageView
        src={this.state.src}
        show={this.state.iview}
        cancel={() => this.setState({iview: false})}
      />

      {this.state.files ? (

        <ul className="for-files">
          <li style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <h1>{this.state.format[this.state.folder]}</h1>
            {app.permit("Upload KYC files") ? <a href="javascript:void(0)" className="new-upd" onClick={() => $("#updfor-"+this.state.folder).click()}>+ Upload</a> : null}
            <a href="javascript:void(0)" className="new-upd-2">
            <img src={loader} id={"verify-img-"+this.state.folder} alt="verify-status" className="vstat" /> Uploading...</a>
          </li>
          <input type="file" name={name} style={{display: "none"}} onChange={(e) => this.handleDocChange(e, this.state.folder == "profile_image" ? "profile-img" : this.state.folder, name)} id={`${"updfor-"+this.state.folder}`} />
          {
            docs.map((d) => (
              d.source == this.state.folder ? <li>
                <img src={file} />
                <div className="f-det">
                  <span className="f-name">{this.state.formatt[this.state.folder]}</span>
                  <span className="f-date">Uploaded {d.create_time}</span>
                </div>
                {app.permit("View and download Documents") ? <a href={d.path} download className="down-btn"><img src={down} style={{position: "relative", top: "-1px", left: "-5px"}} /> Download</a> : null}
                <button className="view-btn" onClick={() => this.setState({iview: true, src: d.path})}><img src={view} style={{position: "relative", top: "-1px", left: "-5px"}} /> View</button>
              </li> : null
            ))
          }
        </ul>
      ) : (
        <ul className="for-folders">
          {
            Object.keys(this.state.format).map((f) => (
              <li onClick={() => this.setState({files: !this.state.files, folder: f})}><img src={folder} /><span>{this.state.format[f]}</span></li>
            ))
          }
          {/*<li onClick={() => this.setState({files: !this.state.files})}><img src={folder} /><span>Profile pictures</span></li>
          <li onClick={() => this.setState({files: !this.state.files})}><img src={folder} /><span>Cards</span></li>
          <li onClick={() => this.setState({files: !this.state.files})}><img src={folder} /><span>DOD</span></li>
          <li onClick={() => this.setState({files: !this.state.files})}><img src={folder} /><span>07. 03. 2020</span></li>*/}
        </ul>
      )}
      </div>
	 )
	}

}

export default UserFiles;