import React from "react";
import $ from 'jquery';
import server from '../../../services/server';
import app from '../../../services/app';
import Verified from "../../../themes/images/tradeDashboard/verifiedOne.svg";
import loader from "../loader.svg";
import Unverified from "../../../themes/images/tradeDashboard/verifiedTwo.svg";
import "./index.scss";

const handleDocChange = async (e, folder, name = null) => {
  const current = e.target.files[0];
  const fd = new FormData();
  fd.append('profile_doc.png', current, current.name);
  try {
    $("#verify-img-"+folder).attr("src", loader);
    let pi = await server.uploadDoc(fd, folder);
    const gp = await server.getProfile();
    app.profile(gp.data.profile);
    window._veripopcon = name;
    $(window).trigger("veripopcon");
  } catch (error) {
    window.location.href = "";
    return error.message;
  }
}

const VerificationItem = ({ verified, itemHead, itemContent, folder, name, buttonText }) => {
  return (
    <div className="verification-item">
      <div className="doc-img-info-section">
        <img src={verified ? Verified : Unverified} id={"verify-img-"+folder} alt="verify-status" />
        {/*
          verified ?
            <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 0.0947266C8.97203 0.0947266 0 9.06676 0 20.0947C0 31.1227 8.97203 40.0947 20 40.0947C31.028 40.0947 40 31.1227 40 20.0947C39.9993 9.06676 31.028 0.0947266 20 0.0947266ZM20 36.8711C10.7493 36.8711 3.22358 29.3454 3.22358 20.094C3.22358 10.8434 10.7493 3.31759 20 3.31759C29.2507 3.31759 36.7764 10.8441 36.7764 20.0947C36.7764 29.3454 29.2507 36.8711 20 36.8711Z" fill={app.custom("primary")} />
              <path d="M26.3754 13.7172L17.04 23.0533L13.6239 19.6372C12.9949 19.0075 11.9744 19.0075 11.3454 19.6372C10.7156 20.2663 10.7156 21.2867 11.3454 21.9158L15.9011 26.4715C16.2156 26.786 16.6285 26.944 17.0407 26.944C17.4529 26.944 17.8651 26.7867 18.1796 26.4715L28.6546 15.9964C29.2837 15.3666 29.2837 14.3469 28.6546 13.7172C28.0249 13.0881 27.0051 13.0881 26.3754 13.7172Z" fill={app.custom("primary")} />
            </svg>
          :
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.3754 0.622431L7.03997 9.95854L3.62394 6.54251C2.99487 5.91273 1.97444 5.91273 1.34538 6.54251C0.715602 7.17157 0.715602 8.192 1.34538 8.82106L5.90105 13.3767C6.21558 13.6913 6.6285 13.8492 7.04069 13.8492C7.45288 13.8492 7.86508 13.692 8.17961 13.3767L18.6546 2.9017C19.2837 2.27192 19.2837 1.25221 18.6546 0.622431C18.0249 -0.0066307 17.0051 -0.0066307 16.3754 0.622431Z" fill={app.custom("primary")} />
            </svg>
        */}
        <div className="doc-info-section">
          <p className="verify-item-head">{itemHead}</p>
          <p className="verify-item-content">{itemContent}</p>
        </div>
      </div>
      <div className="verification-btn-section">
        <label htmlFor={`${itemHead}1`}>
          <p>{(verified ? 'Re-' : '')+buttonText}</p>
        </label>
        {folder
          ? <input type="file" name={name} onChange={(e) => handleDocChange(e, folder, name)} id={`${itemHead}1`} />
          : null
        }
        {/* style={{ background: verified ? '#C4C4C4' : null}} disabled={verified} */}
      </div>
    </div>
  );
}

export default VerificationItem;
