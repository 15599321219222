import React, { Component } from 'react';
import TableFilters from '../../components/tablefilters/index';
import TradeNotFound from '../../components/tradeNotFound/index';
import Pagination from '../../components/Pagination/index';
import Pagination2 from '../../components/paginationTwo/index';
import app from '../../services/app';
import server from '../../services/server';
import './profilepayments.scss';
import '../../components/standard/table.scss';
import '../../components/history/index.scss';
import Wreq from '../../components/wreq/index';
import { ConfirmModal } from '../../components/popups/index';

class ProfilePayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ctype: 'all',
      page_size: 8,
      page_no: 1,
      ttype: "all",
      confirmID: 0,
      confirmREF: '',
      confirmSTATUS: 0,
      confirmModal: false,
      filter: '',
      wreq: false
    }
  }

  handleClick = (e, i) => {
    document.querySelectorAll(".transaction-history-record").forEach(function(el) {
      el.classList.remove("_active");
    });
    document.getElementById(i).classList.add("_active");
  }

  paginationChange = (p) => {
    this.setState({ page_no: p });
  };

  withStat = (status, ref, id) => {
    this.setState({confirmID: id, confirmREF: ref, confirmSTATUS: status, confirmModal: true});
  }

  processReq = async () => {
    this.setState({confirmModal: false});
    try {
      let pr = await server.processWReq(this.state.confirmSTATUS, this.state.confirmID);
      if(pr.data.status) {
        window.callbackTxt = "Request is now "+app.status(this.state.confirmSTATUS);
        window.showCallback = true;
      } else {
        alert(pr.data.message);
      }
      this.props.refresh();
    } catch(e) {
      return e;
    }
  }

  render () {
    let active = parseInt(this.props.active);
    let filter = this.state.filter;
    let accounts = this.props.accounts;

    let { page_size, page_no } = this.state;

    let history = this.props.history;
    if(this.state.ttype != 'all') {
      history  = history.filter((h) => h.type.toLowerCase() === this.state.ttype);
    }

    if(filter.length) {
      history = history.filter((c) => {
        return (
          c.account_to.toLowerCase().match(filter.toLowerCase()) ||
          c.account_from.toLowerCase().match(filter.toLowerCase()) ||
          c.reference.toLowerCase().match(filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(filter.toLowerCase())
        );
      });
    }

    if(this.state.ctype != "all") {
      history = history.filter((c) => {
        return c.status == this.state.ctype;
      });
    }

  let max_rows = history.length;
  let stt = (page_no-1)*page_size;
  let max = stt+page_size;
      max = max > max_rows ? max_rows : max;
  history = history.slice(stt, max > max_rows ? max_rows : max);

  	return (
      <div className={"transaction-history"+(active ? ' _active' : '')} id="tab-row-payments">

        <ConfirmModal
          head={"Update withdrawal request"}
          text={"Click YES to confirm update of request "+this.state.confirmREF+" to "+app.status(this.state.confirmSTATUS)}
          show={this.state.confirmModal}
          cancel={() => this.setState({confirmModal: false})}
          confirm={() => this.processReq()}
        />

        {this.state.wreq ?
        <Wreq
          accounts={accounts}
          uid={this.props.uid}
          to={this.props.to}
          sending={() => this.props.load()}
          unsending={() => this.props.load()}
          refresh={() => this.props.refresh()}
          cancelClick={() => this.setState({wreq: false})}
          sent={() => this.setState({showSpinner : false})}
        /> : null}

        <TableFilters
          table="payments"
          search={(e) => this.setState({filter: e.target.value})}
          type={this.state.ttype.toLowerCase()}
          ttype={(val) => this.setState({ttype: val.toLowerCase() == 'withdrawal' ? 'withdraw' : val.toLowerCase(), ctype: "all"})}
          ctype={(e) => this.setState({ctype: e.target.value})}
          wreq={() => {
            this.setState({wreq: true});
          }}
        />

        <div className='t-history-container' style={{marginTop: "-20px"}}>
          <ul className='transaction-history-header' style={{marginTop: "2em", marginBottom: "1em", background: "#006066", borderRadius: "10px"}}>
            <li className="small-trans">S/N</li>
            <li className="trans-type">Transaction type</li>
            <li className="trans-date">Date</li>
            <li className='t-from'>Account(From)</li>
            <li className='t-to'>Account(To)</li>
            <li className="small-trans">Amount</li>
            <li>{this.state.ttype == "withdraw" ? "Status" : "Reference"}</li>
          </ul>

        { history.length ?
          history.map((transaction, idx) => {
            return (
            <ul
              className={'transaction-history-record '+(idx == 0 ? ' _active' : '')}
              id={'transaction-history-record-'+idx}
              key={`${Math.random()}-${Math.random()}`}
              onClick={(e) => this.handleClick(e, 'transaction-history-record-'+idx)}
              style={{opacity: transaction.status < 0 ? "0.6" : 1}}
            >
              <div className="tab-sn"><div>{idx + (page_size * (page_no - 1)) + 1}</div></div>
              <li className="small-trans">{idx + (page_size * (page_no - 1)) + 1}</li>
              <li className="trans-type"><span className="th">Transaction type</span><span className="td"><button className={"brn ttype"+(transaction.type.toLowerCase() != "deposit" ? " "+transaction.type.toLowerCase() : " ")}>{transaction.type.toUpperCase()}</button></span></li>
              <li className="trans-date"><span className="th">Date</span><span className="td" style={{fontSize: "85%"}}>{app.cleanDate(transaction.create_time)}</span></li>
              <li className='t-from'><div><span className="th">Account(From)</span><span className="td">{transaction.type.toLowerCase() == "deposit" ? '---' : transaction.account_from}</span></div></li>
              <li className='t-to'><span className="th">Account(To)</span><span className="td">{transaction.account_to}</span></li>
              <li className="small-trans"><span className="th">Amount</span><span className="td">{transaction.amount}</span></li>
              <li>
                <span className="th">{this.state.ttype == "withdraw" ? "Status" : "Reference"}</span>
                <span className="td">{this.state.ttype == "withdraw" || transaction.type.toLowerCase() == "withdraw" ?
                (transaction.status < 0 || transaction.status == 2 ? app.status(transaction.status) : 
                  <select className="lev" onChange={(e) => this.withStat(e.target.value, transaction.reference, transaction.id)}>
                    <option value="0" selected={transaction.status == "0"}>{app.status(0)}</option>
                    <option value="1" selected={transaction.status == "1"}>{app.status(1)}</option>
                    <option value="2" selected={transaction.status == "2"}>{app.status(2)}</option>
                    <option value="-1" selected={transaction.status == "-1"}>{app.status(-1)}</option>
                  </select>)
                : transaction.reference}</span>
              </li>
            </ul>
          )}) : <TradeNotFound text={"No transaction"} />
        }

        <Pagination2 length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={this.paginationChange} />

        </div>
      </div>
	 )
	}

}

export default ProfilePayments;