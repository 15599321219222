import React, { Component } from 'react';
// import './index.scss';
import $, { data } from 'jquery';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import downVlv from '../../themes/images/down.png';
import server from '../../services/server';
import app from '../../services/app';

class RProfile extends Component {
  constructor(props) {

    super(props);

    this.state = {
      errorMessage: '',
      active: this.props.data.leads > 0 ? "user" : "lead",
      sent: false,
      rname: '',
    };

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("ass")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  btnSave = async () => {
    if(!$("#rname").val().trim().length) {
      return this.setState({errorMessage: 'Please type a name'});
    }
    this.setState({errorMessage: '', sent: true});
    let data = {
      name: $("#rname").val().trim(),
      list: this.props.data
    };
    try {
      this.props.sending();
      await server.saveRest(data);
      this.props.success();
    } catch (e) {
      this.setState({sent: false});
      return e;
    }
    this.props.cancel();
    this.setState({sent: false});
  }

  render () {
      let rname = this.state.rname;
      const { cancel, name, data } = this.props;
      if(!rname.length) {
        rname = name;
      }

    return (
      <div className='overlay ass' onClick={this.popupOut}>
        <div className='modal-section' style={{width: "400px"}}>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6 style={{marginBottom: "1em"}} className="cpri">Restriction profile name</h6>
              <div className="form-group">
                <input className="form-control" id="rname" onChange={(e) => {
                    this.setState({rname: e.target.value});
                }} defaultValue={name} style={{backgroundColor: "transparent", color: "#fff", borderColor: "#606060", height: "50px"}} placeholder="" />
              </div>
              {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}
              <div className="form-group">
                <button className="btn btn-block sacc" style={{width: "100%", marginTop: "15px", color: "#fff"}} onClick={this.btnSave} disabled={this.state.sent}>{name.length && rname == name ? 'Update' : 'Save'}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default RProfile;