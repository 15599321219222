import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink as Link } from 'react-router-dom';
import Container from '../container/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import RestrictionNav from '../../components/restrictionNav/index';
import Spinner from '../../components/spinner/index';
import CancelIcon from '../../themes/images/cancel.svg';
import country from 'country-list-js';
import permissions from '../../utils/permissions';
import server from '../../services/server';
import app from '../../services/app';
import { CallBack } from '../../components/popups/index';
import './index.scss';

window.dis_restrictions = "";

class CreateAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      uid: '',
      phone: '',
      gender: 'Male',
      country: '',
      lang: '',
      desks: [],
      profile: '',
      source: '',
      auto_source: '',
      profiles: [],
      sources: [],
      active_profile: '',
      clients: [],
      host: '',
      password: '',
      countryCode: '',
      confirmPassword: '',
      extension_no: '0383193930 - 101',

      firstNameError: null,
      lastNameError: null,
      emailError: null,
      showCallBack: false,
      restError: null,
      scsError: null,
      passwordError: null,
      phoneNumberError: null,
      confirmPasswordError: null,

      // selectedTab: "support specialist",
      // _selectedTab: "Support Specialist",
      selectedTab: "Admin",
      _selectedTab: "Admin",
      country_names: [],
      showSpinner: false,
      showRestrictions: false,
    };

    this.showSpinner = false;
    this.profile = app.profile();
    this.id = this.profile.user_id;
    this.permissions = [];
    this.restrictions = "";
  }

  async componentDidMount() {

    this.getDesks();
    this.getRest();
    this.getAllSources();
    if(app.master()) {
      await this.getAllClients();
    }

    var dis = this;
    this.permissions   = require("./permission.json");

    var country = require('country-list-js');
    var country_names = country.names().sort();
    this.setState({country_names: country_names, country: country_names[0]});

    $(document).delegate(".mh5", "click", function () {
      // $(".sbhd").removeClass("sbhd").addClass("sub-h5");
      let f = $(this).data("find");
      if($(this).find("b").text().trim() == "+") {
        $("."+f).removeClass("sub-h5").addClass("sbhd");
        $(this).find("b").text("-");
      } else {
        $("."+f).removeClass("sbhd").addClass("sub-h5");
        $(this).find("b").text("+");
      }
    });

    $(document).delegate(".r-check", "change", function () {
      let rs = [];
      
      $(".r-check").each((k, v) => {
        if(v.checked) {
          rs.push(v.value);
        }
      });

      this.restrictions = window.dis_restrictions = rs.join(",");
    });

    $(document).delegate(".r-check", "change", function () {
      let rs = [];
      
      $(".r-check").each((k, v) => {
        if(v.checked) {
          rs.push(v.value);
        }
      });

      this.restrictions = window.dis_restrictions = rs.join(",");
    });

    let u = this.props.match.params.user_id || false;

    if(u) {
      this.setState({uid: this.props.match.params.user_id, showSpinner: true});
      let profile = await server.getUser(u);
      let _p = profile.data.profile;

      $("#ea-firstName").val(_p.first_name);
      $("#ea-lastName").val(_p.last_name);
      $("#ea-email").val(_p.email);
      $("#ea-phone").val(_p.phone_number);
      $("#ea-country").val(_p.country);
      $("#ea-gender").val(_p.gender.ucwords());

      this.setState({showSpinner: false, selectedTab: _p.role.toLowerCase(), _selectedTab: _p.role.ucwords()});

      this.restrictions = window.dis_restrictions = _p.restrictions;

      if(_p.restrictions.length) {
        let rest = _p.restrictions.split(",");

        rest.forEach((v, k) => {
          // $("#"+v).prop("checked", true);
          // $("#"+v).attr("checked", true);
          // document.getElementById(v).checked = true;
        });
      }

      this.setState({firstName: _p.first_name, lastName: _p.last_name, email: _p.email, phone: _p.phone_number, country: _p.country, gender: _p.gender.ucwords()});
    }
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data});
    } catch(e) {
      return e;
    }
  }

  getAllSources = async () => {
    try {
      let sources = await server.getAllSources();
      let scs = sources.data.sources;
      let source = scs.length >= 1 ? scs[0] : "";
      this.setState({sources: scs, auto_source: ""}); // source
    } catch(e) {
      return e;
    }
  }

  getDesks = async () => {
    try {
      let d = await server.getDesks();
      this.setState({desks: d.data.desks});
      if(d.data.desks.length) {
        this.setState({lang: d.data.desks[0]["lang"]});
      }
    } catch(e) {
      return e;
    }
  }

  toggleTabs = (e) => {
    this._toggleTabs(e.currentTarget.querySelector('div').innerHTML.trim());
  }

  createAdmin = (i = 0) => {
    this.setState({showRestrictions: false});
    if(i > 0) {
      this.restrictions = "!*";
    }
    setTimeout(() => {
      this.submitForm(false);
    }, 100);
  }

  _toggleTabs = (tab) => {
    this.setState({
      selectedTab: tab.toLowerCase(),
      _selectedTab: tab
    });
  }



  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  getRP = () => {
    let val = $("#active_profile").val();
    if(val.length && val != "*") {
      let pro = this.state.profiles.filter((p) => p.name == val);
      if(pro.length) {
        return pro[0].list;
      }
    }
    return val;
  }

  submitForm = async (e, step = 1) => {
    e && e.preventDefault();
    let {
      firstName,
      lastName,
      phone,
      uid,
      lang,
      country,
      gender,
      email,
      host,
      source,
      password,
      auto_source,
      active_profile,
      confirmPassword,
      extension_no,
      selectedTab
    } = this.state;
    const nameRegex = /^[a-zA-Z]{3,}$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    let restrictions = window.dis_restrictions;

    this.clearErrors();

    let err = false;
    if (!nameRegex.test(firstName)) {
      err = true;
      this.setState({ firstNameError: 'Invalid first name specified' });
    }
    if (!nameRegex.test(lastName)) {
      err = true;
      this.setState({ lastNameError: 'Invalid last name specified' });
    }
    if (email.length < 5) {
      err = true;
      this.setState({ emailError: 'Invalid email' });
    }
    if (phone.length < 6) {
      err = true;
      this.setState({ phoneNumberError: 'Invalid phone number' });
    }
    if(uid.length && password.length) {
        if(!confirmPassword.length) {
            this.setState({ confirmPasswordError: 'Invalid confirm password' });
        }
    } else {
      if(uid.length && !password.length) { } else {
        if (!password.length) {
          err = true;
          this.setState({ passwordError: 'Invalid password' });
          if(!confirmPassword.length) {
              this.setState({ confirmPasswordError: 'Invalid confirm password' });
          }
        }
      }
    }
    if(this.getRP() == "") {
      err = true;
      this.setState({ restError: 'invalid profile' });
    }

    if (password !== confirmPassword) {
      err = true;
      this.setState({ confirmPasswordError: 'Passwords must match' });
    }
    if(this.getRP() != '_aff_') {
      source = '';
    }
    if(this.getRP() == '_aff_' && source.trim().length == 0) {
      err = true;
      this.setState({ scsError: 'invalid Source' });
    }

    // if(step < 1)  {
    //   if(err) return null;
    //   return this.setState({showRestrictions: true});
    // }

    if (!err) {

      this.setState({ showSpinner: true });

      // let nph = phone.trim();
      //     nph = nph.charAt(0) == 0 ? nph.slice(1) : nph;
      // let phone_number = countryCode+""+nph;

      try {
        let rest = this.getRP() == "*" ? "!*" : this.getRP();
        let submit = {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phone, // phone_number,
          password,
          update: true,
          gender,
          host,
          lang,
          source,
          rpro: $("#active_profile").val() || "",
          country: country,
          extension_no,
          auto_source,
          uid,
          permissions: rest == "!*" ? "*" : rest,
          restrictions : rest,
          role: selectedTab,
        };

        if(!uid.length) {
          submit.aid = app.userid();
        }

        const response = uid.length ? await server.updateAdmin(submit, true) : await server.register(submit, true);
        this.setState({showCallBack: true, showSpinner: false});
        setTimeout(() => {
          window.location.href = "";
        }, 2000);

      } catch (error) {
        this.setState({ showSpinner: false });
        if (!error.response) {
          return error;
        }

        const errorMessage = error.response.data.message;

        if (errorMessage.toLowerCase().match('password')) {
          this.setState({ passwordError: errorMessage });
        }

        if (errorMessage.toLowerCase().match('username')) {
          this.setState({ emailError: errorMessage });
        }
      }
    }
  }

  clearErrors = () => {
    this.setState({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      restError: null,
      scsError: null,
      phoneNumberError: null,
      genderError: null,
      passwordError: null,
      confirmPasswordError: null,
    });
  }

  getRest = async () => {
    let permissions = await server.getRest();
    this.setState({profiles: permissions.data.data});
  }

  render() {
    let ps = this.permissions;
    let PHEAD = Object.keys(ps);

    return (
      <Container>
      <div className="col-12" id="cadmin-container">
        <CallBack show={this.state.showCallBack} cancel={(e) => this.setState({showCallBack: false})} head={"Account "+(this.state.uid.length ? "Updated" : "Created")} text={"Admin account "+this.state.email+" "+(this.state.uid.length ? "updated" : "created")+" successfully"} />
        {this.state.showSpinner ? <Spinner showSpinner={this.state.showSpinner} /> : null}
        <div className='createadmin-section'>
          {this.state.uid.length ?
            <Breadcrumbs breads={"Home, Admins, Edit Admin/"+this.state.uid+", "+(this.state._selectedTab)} /> :
            <Breadcrumbs breads={"Home, Create, Create Admin, "+(this.state._selectedTab)} />
          }

          <RestrictionNav selectedTab={this.state.selectedTab} handleClick={this.toggleTabs} />

          <div className={"half-screen"+(this.state.showRestrictions ? "" : " __hide")}>
            <div className="header">
              <h1>Restrictions</h1>
              <img src={CancelIcon} className="cancelIcon" onClick={() => this.setState({showRestrictions: false})} />
            </div>
            <div className="main">
              <div className="overscroll">
                {
                  PHEAD.map((p, pk) => {
                    return (
                      <>
                        <h3 className="mh5" data-find={'h5-'+pk} style={{position: 'relative', cursor: 'pointer'}}>{p}
                          <b className="pmore tw" data-find={'h5-'+pk} data-text="+">+</b>
                        </h3>
                        <ul className={"p-list sub-h5 h5-"+pk}>
                          {
                            ps[p].map((c, ck) => {
                              let rw_id = "rest_"+pk+"_"+ck;
                              if(typeof c == "string") {
                                return (<li key={Math.random()+' '+Math.random()}>
                                  <label for={app.rval(c)}>
                                  {/* checked={Object.values(window.dis_restrictions.split(",")).indexOf(app.rval(c)) > -1}*/}
                                    <input type="checkbox" className="form_control r-check" id={app.rval(c)} value={app.rval(c)} defaultChecked={Object.values(window.dis_restrictions.split(",")).indexOf(app.rval(c)) > -1} /> {c}
                                  </label>
                                </li>);
                              } else {
                                return <li key={Math.random()+' '+Math.random()}>
                                        {c.map((b, bk) => (<label for={app.rval(b)} style={{width: "49%", cursor: "pointer"}}><input type="radio" id={app.rval(b)} name={rw_id} value={app.rval(b)} className="form_control r-check" defaultChecked={Object.values(window.dis_restrictions.split(",")).indexOf(app.rval(b)) > -1} /> {b}</label>))}
                                      </li>
                              }
                            })
                          }
                        </ul>
                      </>
                    )
                  })
                }

                </div>
                <div className='deposit-flex' style={{marginBottom: "0", marginTop: "10px"}}>
                  {/* disabled={!this.state.restrictions.length} */}
                  <input type='button' value={this.state.uid.length ? 'Update' : 'Save'} className='restriction-submit-btn' style={{width: "100px", outline: "none"}} onClick={() => this.createAdmin()} />
                  <span id="s-all" onClick={() => this.createAdmin(2)}><a href="javascript:void(0)">{this.state.uid.length ? 'Update' : 'Save'} with no Restriction</a></span>
                </div>
            </div>
          </div>

          <div className='createadmin-box'>
            <div className='deposit'>
              <form className='restriction-action' autoComplete="new-password">

                <div className='deposit-flex'>
                  <div className='deposit-flex-item-1'>
                    <label>Firstname</label>
                    <div className='bal-sym'>
                      <input type='text' className="form-control" name='firstName' id="ea-firstName" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.firstNameError ? `*${this.state.firstNameError}` : null}</p>
                  </div>


                  <div className='deposit-flex-item-1 ml-10'>
                    <label>Lastname</label>
                    <div className='bal-sym'>
                      <input type='text' className="form-control" name='lastName' id="ea-lastName" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.lastNameError ? `*${this.state.lastNameError}` : null}</p>
                  </div>

                  <div className='deposit-flex-item-1 ml-10'>
                    <label>Email</label>
                    <div className='bal-sym'>
                      <input type='email' className="form-control" name='email' id="ea-email" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.emailError ? `*${this.state.emailError}` : null}</p>
                  </div>
                </div>

                <div className='deposit-flex'>
                  <div className='deposit-flex-item-1'>
                    <label>Phone Number</label>
                    <div className='bal-sym'>
                      <input type='text' className="form-control" name='phone' id="ea-phone" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.phoneNumberError ? `*${this.state.phoneNumberError}` : null}</p>
                  </div>

                  <div className='deposit-flex-item-1 ml-10'>
                    <label>Country</label>
                    <select name="country" className="form-control" id="ea-country" onChange={this.handleInputChange} value={this.state.country}>
                      {
                        this.state.country_names.map((cn) => (
                          <option key={Math.random()+'_'+Math.random()}>{cn}</option>
                        ))
                      }
                    </select>
                  </div>

                  <div className='deposit-flex-item-1 ml-10'>
                    <label>Gender</label>
                    <select name="gender" className="form-control" id="ea-gender" onChange={this.handleInputChange}>
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                </div>

                <div className='deposit-flex'>
                  <div className='deposit-flex-item-1'>
                    <label>Password</label>
                    <div className='bal-sym'>
                      <input type='password' className="form-control" name='password' id="ea-password" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.passwordError ? `*${this.state.passwordError}` : null}</p>
                  </div>

                  <div className='deposit-flex-item-1 ml-10'>
                    <label>Confirm Password</label>
                    <div className='bal-sym'>
                      <input type='password' className="form-control" name='confirmPassword' id="ea-confirmPassword" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                    </div>
                    <p className='red'>{this.state.confirmPasswordError ? `*${this.state.confirmPasswordError}` : null}</p>
                  </div>

                  {app.master() ? <div className='deposit-flex-item-1 ml-10'>
                    <label>Client</label>
                    <select name="host" className="form-control" onChange={this.handleInputChange}>      
                    {
                      this.state.clients.map((cl) => (
                        <option key={"cl-"+cl.id}>{cl.domain}</option>
                      ))
                    }
                    </select>
                  </div> : <div className='deposit-flex-item-1 ml-10'>
                    <label>Desk</label>
                    <select name="lang" className="form-control" onChange={this.handleInputChange}>      
                    {
                      this.state.desks.map((de) => (
                        <option key={"de-"+de.id}>{de.lang}</option>
                      ))
                    }
                    </select>
                  </div>}
                </div>

                <div className='deposit-flex'>
                  <div className='deposit-flex-item-1'>
                    <label>Restriction Profile</label>
                    <select name="profile" className="form-control" onChange={this.handleInputChange} id="active_profile" style={{marginBottom: "10px"}}>
                    <option key={"pr-null"} value="">- Select restriction profile - </option>
                    {
                      this.state.profiles.map((pr) => (
                        <option key={"pr-"+pr.id}>{pr.name}</option>
                      ))
                    }
                    <option key={"pr-aff"} value="_aff_">Affiliate admin</option>
                    <option key={"pr-pri"} value="_pri_">Private admin</option>
                    <option key={"pr-super"} value="*">Super admin</option>
                    </select>
                    <p className='red'>{this.state.restError ? `*${this.state.restError}` : null}</p>
                  </div>
                  
                  {this.state.profile == '_aff_' ?
                    <div className='deposit-flex-item-1 ml-10'>
                      <label>Affiliate Source</label>
                      <div className='bal-sym'>
                        <input type='text' className="form-control" name='source' id="adm_source" onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                      </div>
                      <p className='red'>{this.state.scsError ? `*${this.state.scsError}` : null}</p>
                    </div> : <div className='deposit-flex-item-1 ml-10'>
                      <label>Target Source</label>
                      {/* <div className='bal-sym'><input type='text' className="form-control" name='auto_source' id="ea-auto_source" onChange={this.handleInputChange} autoComplete="new-password" Value="" /></div> */}
                      <select name="auto_source" className="form-control" onChange={this.handleInputChange} id="ea-auto_source" style={{marginBottom: "10px"}}>
                        <option key="sri-0" value="">- Empty -</option>
                        {
                          this.state.sources.map((sr, sk) => (
                            <option key={"sr-"+sk}>{sr}</option>
                          ))
                        }
                      </select>
                    </div>
                  }
                  <div className='deposit-flex-item-1 ml-10'></div>
                </div>

                <div className='deposit-flex' style={{marginTop: "1em", opacity: this.state.showSpinner ? 0.5 : 1}}>
                  <input type='button' value='SAVE' className='restriction-submit-btn' onClick={() => {
                    if(!this.state.showSpinner) {
                      this.submitForm(false, 0)
                    }
                  }} />
                  {/*<input type='submit' value='NEXT' className='restriction-submit-btn' />*/}
                </div>
              </form>
            </div>
          </div>
        </div>
        </div>
      </Container>
    );
  }
}

export default CreateAdmin;
