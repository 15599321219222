import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import CloseBtn from '../../../themes/images/close.svg';
import MasterCard from '../../../themes/images/mastercard.svg';
import server from '../../../services/server';
import CreditCard from './creditCard';
import Spinner from '../../../components/spinner/index';
import './addCard.scss';
import app from '../../../services/app';

class AddCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      number: '',
      exp: '',
      cvv: '',
      showSpinner: false,
    };

    this.profile = app.profile();
  }

  handleChange = (e) => {
    console.log(e.target.value);
    const {
      target: { name, value },
    } = e;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { name, number, exp, cvv } = this.state;
    const { email } = this.profile;

    this.setState({ showSpinner: true });

    try {
      await server.addNewCard({
        PAN: number,
        valid_thru: exp.toString().split('-').reverse().join('-'),
        card_name: name.ucwords(),
        cvv
      });
      await this.props.refresh();
      this.setState({ showSpinner: false });
    } catch (error) {
      this.setState({ showSpinner: false });
      return error.message;
    }
  };

  render() {
    const { showAddCardModal, handleClick } = this.props;
    const { showSpinner } = this.state;

    return showAddCardModal ?
            <div className='account-overlay accda'>
              <Spinner showSpinner={showSpinner} />
              <CreditCard submit={async (card) => {
                this.setState({ showSpinner: true });
                try {
                  await server.addNewCard({
                    PAN: card.cnum,
                    valid_thru: card.month+"-"+card.year,
                    card_name: card.cname,
                    cvv: card.cvc
                  });
                  await this.props.refresh();
                  this.setState({ showSpinner: false });
                } catch (error) {
                  this.setState({ showSpinner: false });
                  return error.message;
                }
              }} />
              <small className='smol err' style={{color: "#D14", display: "none"}}></small>
              <div className="row acc-ft">
                  <div className="col-full">
                      <button className="btn btn-warning btn-lg btn-block app-btn contid alt close" type="button" onClick={handleClick}>Close</button>
                      <button className="btn btn-warning btn-lg btn-block app-btn contid alt" type="button" onClick={() => {
                          $("#submit_card_details").trigger("click");
                      }}>Add Card</button>
                  </div>
              </div>
            </div> : null
            
    // return (
    //   <div>
    //     <Spinner showSpinner={showSpinner} />
    //     {showAddCardModal ? (
    //       <div className='account-overlay'>
    //         <form className='account-modal' onSubmit={this.handleSubmit}>
    //           <img
    //             src={CloseBtn}
    //             alt=''
    //             onClick={handleClick}
    //             className='account-close-btn'
    //           />
    //           <h4>Add a card</h4>

    //           <div className='account-form-section'>
    //             <label>Card Holder Name</label>
    //             <input
    //               type='text'
    //               name='name'
    //               className="form-control"
    //               onChange={this.handleChange}
    //               required
    //             />
    //           </div>

    //           <div className='account-form-section'>
    //             <label>Card Number</label>
    //             <input
    //               type='number'
    //               name='number'
    //               className="form-control"
    //               onChange={this.handleChange}
    //               required
    //             />
    //           </div>

    //           <div className='account-form-flex'>
    //             <div className='account-form-section month'>
    //               <label>MONTH</label>
    //               <input
    //                 type='number'
    //                 name='month'
    //                 className="form-control"
    //                 onChange={this.handleChange}
    //                 required
    //               />
    //             </div>
    //             <div className='account-form-section year'>
    //               <label>YEAR</label>
    //               <input
    //                 type='number'
    //                 name='year'
    //                 className="form-control"
    //                 onChange={this.handleChange}
    //                 required
    //               />
    //             </div>
    //             <div className='account-form-section cvv'>
    //               <label>CVV</label>
    //               <input
    //                 type='number'
    //                 name='cvv'
    //                 className="form-control"
    //                 onChange={this.handleChange}
    //                 required
    //               />
    //             </div>
    //           </div>

    //           <div className='account-form-submit'>
    //             <input type='submit' value='Add Card' />
    //           </div>
    //         </form>
    //       </div>
    //     ) : null}
    //   </div>
    // );
  }
}

export default AddCard;
