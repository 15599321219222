import React, { Component } from 'react';
import $ from 'jquery';
import Container from '../container/index';
import Ptab from '../../components/ptabs/index';
import { Link } from 'react-router-dom';
import server from '../../services/server';
import app from '../../services/app';
import Breadcrumbs from '../../components/breadcrumbs/index';
import '../../components/standard/standard.scss';
import '../../components/standard/table.scss';
import check from '../../themes/images/check-mark.png';
import Pagination from '../../components/paginationTwo/index';
import Spinner from '../../components/spinner/index';
import { ConfirmModal, CallBack } from '../../components/popups/index';
import TableFilters from '../../components/tablefilters/index';
import './index.scss';

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page_no: 1,
      callback: false,
      desk: props.match.params.desk || "all",
      page_size: app.maxrow,
      filter: '',
      assign: false,
      users: [],
      delete: 0,
      admins: [],
      clients: [],
      desks: [],
      assign: false,
      showLoader: true,
      data: [],
      cfil: '',
      callback: false,
      callbackTxt: '',
      soft_delete: "all",
      utype: "all",
      restore: false,
      confirmID: '',
      confirmModal: false,
    }
  }

  async componentDidMount () {
    await this.getDesks();
    this.getAllAdmins();
    if(app.master()) {
      await this.getAllClients();
    }

    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });
  }
  
  getDesks = async () => {
    try {
      let d = await server.getDesks();
      this.setState({desks: d.data.desks});
    } catch(e) {
      return e;
    }
  }

  getAllAdmins = async () => {
    try {
      let admins = await server.getAllAdmins();
      this.setState({admins: admins.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  deleteUser = async () => {
    this.setState({showLoader: true});
    try {
      let del = await server.deleteUser(this.state.confirmID);
      this.setState({showLoader: false, callback: true, callbackTxt: 'Delete successfull'});
      await this.getAllAdmins();
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  restoreUser = async () => {
    this.setState({showLoader: true});
    try {
      let del = await server.restoreUser(this.state.confirmID);
      this.setState({showLoader: false, callback: true, callbackTxt: 'Restore successfull'});
      await this.getAllAdmins();
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  render () {
    let { page_no, page_size, admins, desk, desks, cfil, filter, soft_delete, utype } = this.state;

    filter = filter.toLowerCase();

    cfil = cfil.toLowerCase();

    if(cfil.length && cfil != "all") {
      admins = admins.filter((c) => {
        return (
          c.host.toLowerCase().match(cfil)
        );
      });
    }

    if(desk != "all") {
      admins = admins.filter((c) => {
        return (
          c.lang == desk
        )
      });
    }

    if(filter.length) {
      admins = admins.filter((c) => {
        return (
          c.first_name.toLowerCase().match(filter.toLowerCase()) ||
          c.last_name.toLowerCase().match(filter.toLowerCase()) ||
          c.email.toLowerCase().match(filter.toLowerCase()) ||
          c.source.toLowerCase().match(filter.toLowerCase()) ||
          c.phone_number.toLowerCase().match(filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(filter.toLowerCase()) ||
          (c.first_name + " " + c.last_name).toLowerCase().match(filter.toLowerCase()) ||
          (c.last_name + " " + c.first_name).toLowerCase().match(filter.toLowerCase())
        );
      });
    }

    if(soft_delete != "all") {
      admins = admins.filter((c) => {
        return c.soft_delete == soft_delete;
      });
    }

    let max_rows = admins.length;
    let stt = (page_no-1)*page_size;
    let max = stt+page_size;
        max = max > max_rows ? max_rows : max;
      admins = admins.slice(stt, max > max_rows ? max_rows : max);

    return (
      <Container>
      <Spinner showSpinner={this.state.showLoader} />
        <div className="col-12" id="users-container">
          <div className="users-section-right">
            <Breadcrumbs breads="Home, Desks, Admins" />
            <h1 className="page-title">Admins</h1>
            <TableFilters table="admins" clients={this.state.clients} cch={(e) => {
              this.setState({cfil: e.target.value});
            }}  ccd={(e) => {
              this.setState({desk: e.target.value});
            }} desk={desk} desks={desks} sd={(e) => this.setState({soft_delete: e.target.value, page_no: 1})} search={(e) => this.setState({filter: e.target.value})} />

            <ConfirmModal
              head={(this.state.delete > 0 ? "Permanently delete" : (this.state.restore ? "Restore" : "Delete"))+" this admin?"}
              text="Click YES to confirm"
              show={this.state.confirmModal}
              cancel={() => this.setState({confirmModal: false})}
              confirm={() => { this.setState({confirmModal: false, delete: 0}); this.state.restore ? this.restoreUser() : this.deleteUser(); }}
            />

            <CallBack
              head="Success"
              show={this.state.callback}
              text={this.state.callbackTxt}
              cancel={(e) => this.setState({callback: false})}
            />

            <ul className="table-header">
              <li style={{width: "60px"}}>S/N</li>
              <li className="shot">ADMIN ID</li>
              <li>FULLNAME</li>
              <li>TYPE</li>
              <li>PHONE NUMBER</li>
              <li>EMAIL</li>
              {/*<li className="shot">LAST SEEN</li>*/}
              <li>ASSIGNED USERS</li>
              <li className="shot">ACTION</li>
              {/*<div className="check-row"><label class="checkbox-container"><input type="checkbox" /><span class="checkmark"></span></label></div>*/}
            </ul>

            {
              admins.map((av, idx) => (
                <ul className="table-body">
                  <li style={{width: "60px"}}><span className="txt-light">{idx + 1}</span></li>
                  <li className="shot"><Link className="txt-info" to={"/adminsprofile/"+av.user_id}>{app.uid(av.user_id)}</Link></li>
                  <li><Link className="txt-info" to={"/adminsprofile/"+av.user_id}>{av.first_name+" "+av.last_name}</Link></li>
                  <li><span className="txt-light">{av.role.ucwords()}</span></li>
                  <li><span className="txt-light">{av.phone_number}</span></li>
                  <li><span className="txt-light">{av.email}</span></li>
                  {/*<li className="shot"><span className="txt-light">9 hours ago</span></li>*/}
                  <li><span className="txt-default">{av.user_id == app.userid() ? "*" : av.assigned}</span></li>
                  <li className="shot">
                    {app.master(true) == av.email || av.user_id == app.userid() ? <span className="txt-default" style={{position: "relative", right: "10px"}}>*</span> : (av.soft_delete > 0 ? (
                      <>
                        <img src={check} onClick={() => this.setState({confirmID: av.user_id, confirmModal: true, restore: true})} style={{height: "18px", width: "18px", marginRight: "10px", cursor: "pointer"}} />
                        <svg onClick={() => this.setState({confirmID: av.user_id, confirmModal: true, restore: false, delete: av.soft_delete})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="red"/>
                        </svg>
                      </>
                    ) : (
                      <svg onClick={() => this.setState({confirmID: av.user_id, confirmModal: true, restore: false})} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#FFE602"/>
                      </svg>
                    ))}
                  </li>
                  {/*<div className="check-row"><label class="checkbox-container"><input type="checkbox" checked /><span class="checkmark"></span></label></div>*/}
                </ul>
              ))
            }

            <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>

          </div>
        </div>
        </Container>
        );
  }
}

export default Admins;