import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import app from '../../services/app';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import downVlv from '../../themes/images/down.png';

class AddAccount extends Component {
  constructor(props) {

    super(props);

    let info = false;

    let acc_types = app.data("atype").split(",");

    this.state = {
      step : 0,
      acc_types,
      errorMessage: '',
      dem : Number(app.data("dod").split(",")[1]) > 0
    };

    this.fireAccList = new CustomEvent('refreshAccList', {
      detail: {
        code: 200
      }
    });

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("fav")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  componentDidMount () {

  }

  btnSave = async () => {
    let nam = document.getElementById("tr-name").value;
    let sel = this.state.dem ? document.getElementById("tr-sel").value : "live";
    let acs = document.getElementById("tr-acs").value;
    let pas = document.getElementById("tr-pass").value;
    let cur = document.getElementById("tr-cur").value;
    // let cur = "USD";
    let uid = this.props.admin ? this.props.uid : null;
    this.props.sending();
    this.setState({errorMessage: ""});
    try {
      const acc = await server.addAccount(nam, sel, acs, pas, uid, cur);
      this.props.unsend();
      console.log("Reach 0");
      if(acc.status == 200 && acc.data.success) {
        console.log("Reach 1");
        window.showCallback = false;
        this.props.sent();
        this.props.cancelClick();
        this.props.showCreated(acc.data.account.account_id, acc.data.account.account_type);
        console.log("Reach 2");
      } else {
        this.setState({errorMessage: acc.data.message});
      }
    } catch (error) {
      console.log(error)
      this.props.unsend();
      return error.message;
    }
  }

  render () {
    const { cancelClick } = this.props;
    const { acc_types, dem } = this.state;
    return (
      <div className='overlay fav' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancelClick} />
            <div className='bsell-modal-content'>
              <h6>Create account</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <label>Account name</label>
                <input className="accs" id="tr-name" type="text" placeholder="Account name" />
                {dem ? <>
                <label>Trading account</label>
                <select className="accs" id="tr-sel"><option value="live">Live</option><option value="demo">Demo</option></select>
                </> : null}
                <label>Trading account type</label>
                <select className="accs" id="tr-acs">
                  {
                    acc_types.map((a, j) => {
                      return (
                        <option key={"at-"+a+"-"+j}>{a.split(":")[0].trim().ucwords()}</option>
                      )
                    })
                  }
                </select>
                <label>Trading account currency</label>
                <select className="accs" id="tr-cur">
                  {
                    app.symbol().map((cv, ck) => {
                      return Object.keys(cv)[0].toLowerCase() == "ngn" ? null : (
                        <option key={"cu-sk-"+Object.keys(cv)} value={Object.keys(cv)}>{Object.values(cv)}</option>
                      )
                    })
                  }
                </select>
                <label>{this.props.admin ? 'Enter Admin' : 'Enter your'} password</label>
                <input className="accs" required id="tr-pass" type="password" placeholder="Password" />

                {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}

                <button className="sacc" onClick={this.btnSave}>ADD ACCOUNT</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default AddAccount;
