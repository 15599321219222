import React, { Component } from 'react';
import './index.scss';
import $ from 'jquery';
import { datepicker } from 'jquery-ui';
import moment from 'moment';
import CancelIcon from '../../themes/images/cancel.svg';
import CancelImage from '../../themes/images/cancel.png';
import arrowBuyIcon from '../../themes/images/arrow-buy.png';
import arrowSellIcon from '../../themes/images/arrow-sell.png';
import upVlv from '../../themes/images/up.png';
import server from '../../services/server';
import downVlv from '../../themes/images/down.png';

class Configure extends Component {
  constructor(props) {

    super(props);

    let info = false;


    this.state = {
      step : 0,
      sending: false,
      errorMessage: ''
    };

    this.fireAccList = new CustomEvent('refreshAccList', {
      detail: {
        code: 200
      }
    });

  }

  popupOut = (e) => {
    if($(e.target).hasClass("overlay") && $(e.target).hasClass("fav")) {
      $(e.target).find(".modal-cancel").click();
    }
  }

  async componentDidMount () {
    $("#tr-stat").on("change", function () {
        if(Number($(this).val()) > 1) {
            // $("#tr-dns").val("");
            $("#tr-dns").removeAttr("readonly");
        } else {
            document.getElementById("tr-dns").readOnly = true;
            // document.getElementById("tr-dns").value = "";
        }
    });
    $("#tr-stat").val(this.props.data.paid);
    if(Number(this.props.data.paid) > 1) {
        $("#tr-dns").removeAttr("readonly");
    }
    if(this.props.data?.dns.length) {
        $("#tr-dns").val(this.props.data.dns);
    }

    $("#datePicker").on("change", function(e) {
      displayDateFormat($(this), '#expiration', $(this).val());
    });
    
    function displayDateFormat(thisElement, datePickerLblId, dateValue) {
      let dv = dateValue.length == 0 ? "" : (`${getDateFormat(new Date(dateValue))}`)
      $(thisElement).css("color", "rgba(0,0,0,0)")
        .siblings(`${datePickerLblId}`)
        .css({position: "absolute", left: "10px", top: "10px", width: $(this).width(), background: "#141517"})
        .text(dv);
        $(".expiration").text(dv);
    }
    
    function getDateFormat(dateValue) {
      let d = new Date(dateValue);
      // this pattern dd/mm/yyyy
      // you can set pattern you need
      let dstring = `${("0" + d.getDate()).slice(-2)}/${("0" + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`;
      return dstring;
    }
  }

  btnSave = async () => {
    let stat = $("#tr-stat").val();
    let dns  = $("#tr-dns").val();
    let exp  = $("#expiration").html();
    this.setState({sending: true});
    try {
        const c = await server.configure({
            s: stat,
            d: dns,
            e: exp,
            w: this.props.data.domain
        }, this.props.data.id);
        if(c.status == 200 && c.data.success) {
            this.props.success();
            this.setState({errorMessage: ""});
        } else {
            this.setState({errorMessage: c.data.message});
        }
        this.setState({sending: false});
    } catch (error) {
        this.setState({sending: false});
        return error.message;
    }
  }

  render () {
    const { data, cancel, success } = this.props;
    let { sending } = this.state;
    let d = parseInt(data.duration) > 0 ? parseInt(data.duration) : 1;
    let defExp = data.expiration.length ? data.expiration : moment(data.create_time).add(d, 'M').format('DD/MM/YYYY');

    return (
      <div className='overlay fav' onClick={this.popupOut}>
        <div className='modal-section'>
          <div className='bsell-modal'>
            <img src={CancelImage} alt='' className='modal-cancel' onClick={cancel} />
            <div className='bsell-modal-content'>
              <h6>Domain Configuration</h6>
              <p className="inps" style={{marginTop: "5px"}}>
                <label>DNS Target for <b>({data.domain})</b></label>
                <input className="accs" id="tr-dns" type="text" readOnly placeholder="Enter DNS tartget" />
                <label>Payment Status</label>
                <select className="accs" id="tr-stat">
                    <option value="1">Pending payment</option>
                    <option value="2">Verified Payment</option>
                </select>
                <label>Expiration <b><small className="expiration">({defExp})</small></b></label>
                <div className="date-selector" style={{position: "relative"}}>
                  <input id="datePicker" className="accs" type="date" onKeyDown={() => { return false; }} />
                  <span id="expiration" style={{"pointer-events": "none"}}></span>
                </div>

                {this.state.errorMessage.length ? <span className='err'>{this.state.errorMessage}</span> : null}

                <button className="sacc" onClick={this.btnSave} disabled={sending}>Update</button>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default Configure;
