import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import TradeNotFound from '../tradeNotFound/index';
import Spinner from '../../components/spinner/index';
import { CallBack } from '../../components/popups/index';
import Search from "../search/index";
import Filter from "../filter/index";
import Table from "../table/index";
import Pagination from '../Pagination/index';
import Pagination2 from '../paginationTwo/index';
import ins_up from '../../themes/images/ins-up.png';
import ins_down from '../../themes/images/ins-down.png';
import ai_n from '../../themes/images/ai-normal.png';
import server from '../../services/server';
import app from '../../services/app';
import { ConfirmModal, Closed } from '../../components/popups/index';
import EditTrade from '../../components/editTrade/index';
import ai_b from '../../themes/images/ai-bookmark.png';
import gear from '../../themes/images/gear.png';
import './index.scss';

let rset = "";
let vset = "";

class TradeHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false,
      showClosed: false,
      confirmationModal: false,
      confirmData: {},
      page_size: app.maxrow,
      page_no: 1,
      users: [],
      loaded: false,
      cancel: 0,
      etrade: false,
      etradata: null,
      filterPair: '',
      filterType: ''
    }
  }

  handleClick = (e, i) => {
    document.querySelectorAll(".t-history-record").forEach(function(el) {
      el.classList.remove("_active");
    });
    document.getElementById(i).classList.add("_active");
  }

  componentDidMount () {
    this.getAllUsers();
    $("#sfilter").keyup((e) => {
      this.handleChange(e);
    });

    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });

    $(window).on("rsetpn", () => {
      this.setState({page_no: 1});
    })

    if(this.props.admin) {
      $("#afilter").change((e) => {
        this.onChange(e);
      });
    }
  }

  getAllUsers = async () => {
    try {
      let users = await server.getAllUsers();
      this.setState({users: users.data});
    } catch(e) {
      return e;
    }
  }

  etrade = () => {

  }

  Profit = (p, s = 'USD') => {
    let cl, pr;
    if(p > 0) {
      pr = app.symbol(s);
      cl = 'td txt-success';
    } else if(p < 0) {
      pr = '-'+app.symbol(s);
      cl = 'td txt-danger';
    } else {
      pr = app.symbol(s);
      cl = 'td txt-default';
    }
    let profit = p < 0 ? -1 * Number(p) : Number(p);
    return (
      <span className={cl}>{pr+profit.toFixed(2)}</span>
    )
  }

  cancelTrade = async (id, account, uid = null, type = 1, del = false) => {
    this.setState({showSpinner: true});
    try{
      let close = await server.cancelTrade(id, account, uid, type);
      if(close.status == 200) {
        $(window).trigger("refreshMod");
        if(!this.props.admin) {
          const gp = await server.getProfile();
          app.profile(gp.data.profile);
          $(".balance").trigger("refresh");
        }
        this.setState({showSpinner: false, showClosed: !del, cancel: 1});
      } else {
        this.setState({showSpinner: false, showClosed: false});
      }
    } catch(e) {
      this.setState({showSpinner: false, showClosed: false});
      return e;
    }
  }

  closeTrade = async (id, account, cr, uid = null) => {
    this.setState({showSpinner: true});
    try{
      let close = await server.closeTrade(id, account, cr, uid);
      if(close.status == 200) {
        $(window).trigger("refreshMod");
        if(!this.props.admin) {
          const gp = await server.getProfile();
          app.profile(gp.data.profile);
          $(".balance").trigger("refresh");
        }
        this.setState({showSpinner: false, showClosed: true, cancel: 0});
      } else {
        this.setState({showSpinner: false, showClosed: false});
      }
    } catch(e) {
      this.setState({showSpinner: false, showClosed: false});
      return e;
    }
  }

  deleteTrade = async ({id, account, uid}) => {
    return await this.cancelTrade(id, account, uid, 2, true);
  }

  // deleteTrade = async ({id, uid}) => {
  //   this.setState({showSpinner: true});
  //   try{
  //     let close = await server.deleteTrade(id, uid);
  //     if(close.status == 200) {
  //       $(window).trigger("refreshMod");
  //       if(!this.props.admin) {
  //         const gp = await server.getProfile();
  //         app.profile(gp.data.profile);
  //         $(".balance").trigger("refresh");
  //       }
  //       this.setState({showSpinner: false, showClosed: true, cancel: 0});
  //     } else {
  //       this.setState({showSpinner: false, showClosed: false});
  //     }
  //   } catch(e) {
  //     this.setState({showSpinner: false, showClosed: false});
  //     return e;
  //   }
  // }

  handleChange = (e) => {
    this.setState({filterPair: e.target.value.trim().toLowerCase(), page_no: 1});
  }

  onChange = (e) => {
    this.setState({filterType: e.target.value.split(" ")[0].trim().toLowerCase(), page_no: 1});
  }

  perc = (m, s, i = true) => {
    if(i) {
      let p = Number(s) / Number(m) * 100;
      if(p >= 100 || m == s) {
        p = 100;
      }
      return p;
    } else {
      let p = Number(m) - Number(s);
          p = p+"minute"+(p > 1 ? "s" : "")+" left";
          return Number(m) > Number(s) ? p : "";
    }
  }

  render () {
  let { type, filterOptions, history, cfil, loaded } = this.props;
  let { page_no, page_size, confirmData, users, confirmationModal } = this.state;

  if(cfil) {
    if(cfil.length && cfil != "all") {
      history = history.filter((c) => {
        return (
          c.host.toLowerCase().match(cfil)
        );
      });
    }
  }

  if(history.length) {
    loaded = true;
  }

  if(this.props.admin && this.props.uid) {
    if(history.length == 0 && this.props.trades.length > 0) {
      history = this.props.trades;
    }
  }

  let ft = this.state.filterType;
  let fp = this.state.filterPair;

  let fil = this.props.userprofile || false;

  if(fil) {
    ft = this.props.filterType;
    fp = this.props.filterPair;
    if(fp.length || (ft.length && ft != "all")) {
      rset = ft+""+fp;
    }
  }

  if(rset.length) {
    if(vset != rset) {
      vset = rset;
      $(window).trigger("rsetpn");
    }
  }

  if(ft.length && ft != "all") {
    history = history.filter((pair) => {
      if(pair.type) {
        return pair.type.toLowerCase().match(ft) || ft == pair.type.toLowerCase();
      }
    });
  } else if(fp.length) {
    history = history.filter((pair) => {
      if(pair.type && pair.instrument && pair.mode) {
        return (
          (this.props.admin ? app.uid(pair.user_id).toLowerCase().match(fp) : false) ||
          pair.type.toLowerCase().match(fp) || fp == pair.type.toLowerCase() ||
          pair.instrument.toLowerCase().match(fp) || fp == pair.instrument.toLowerCase() ||
          pair.mode.toLowerCase().match(fp) || fp == pair.mode.toLowerCase()
        );
      }
    });
  }

  let _t = false;
  if(this.props.trades) {
    _t = this.props.trades.length > 0;
  }

  let max_rows = history.length;
  let stt = (page_no-1)*page_size;
  let max = stt+page_size;
      max = max > max_rows ? max_rows : max;
  let _history = history.slice(stt, max > max_rows ? max_rows : max);

  return (
    <div className="open-trades-container">

      <Spinner showSpinner={this.state.showSpinner} />
      <Closed show={this.state.showClosed} cancel={(e) => this.setState({showClosed: false})} type={this.state.cancel} />
      <ConfirmModal
          head={"Delete"}
          text={"Remove this trade?"}
          show={confirmationModal}
          cancel={() => this.setState({confirmationModal: false})}
          confirm={() => { this.setState({confirmationModal: false}); this.deleteTrade(confirmData); }}
      />

      {this.state.etrade && this.props.admin ? <EditTrade trade={this.state.etradata} cancel={() => this.setState({etrade: false, etradata: null})} /> : null}

      {
        fil || this.props.admin ? null :
        <div className="open-trades-container-top">
          <Search name="keyword" handleChange={this.handleChange} placeholder="Search here" />
          <Filter selectOptions={filterOptions} onChange={this.onChange} />
        </div>
      }
      <div className='trade-history'>
          <div className='t-history-container'>
            <ul className='t-history-header'>
            {this.props.admin ? <li>{this.props.noid ? "ACCOUNT" : "USER"}</li> : null}
              <li>INSTRUMENT</li>
              <li>TYPE</li>
              <li>TIME</li>
              <li>ORDER PRICE</li>
              <li>COMMISSION</li>
              <li>ORDER RATE</li>
              <li>S/L</li>
              <li>T/P</li>
              {type == 'open' ? (
                <>
                  <li>CURRENT RATE</li>
                  <li>PROFIT</li>
                  <li>DETAILS</li>
                  <li>ACTION</li>
                </>
              ) : null}
              {type == 'closed' ? (
                <>
                  <li>CLOSE RATE</li>
                  <li>CLOSE PRICE</li>
                  <li>DETAILS</li>
                  <li>ACTION</li>
                </>
              ) : null}
              {type == 'pending' ? (
                <>
                  <li>CURRENT RATE</li>
                  <li>DETAILS</li>
                  <li>ACTION</li>
                </>
              ) : null}
            </ul>
            {_history.map((order, key) => (
                <ul className={'t-history-record'+(key == 0 ? " _active" : "")} id={"t-history-record-"+key} onClick={(e) => this.handleClick(e, 't-history-record-'+key)}>
                  {this.props.admin ?
                    <li style={{display: "inline-flex", justifyContent: "center", flexDirection: "column", position: "relative", paddingLeft: users.length ? "10px" : null}} className={users.length ? 'spres' : null}>
                      <span className="th">{this.props.noid ? "ACCOUNT" : "USER"}</span>
                      <span className="td">
                      {this.props.noid ?
                      <>
                        <button className={"acc_type"+(order.account.split("-")[0].toLowerCase() == "live" ? " live" : "")}>{order.account.split("-")[0].toUpperCase()}</button><br />
                        <small className="time txt-info" style={{position: "relative", top: "5px", color: "#fff", width: "100%"}}>{order.account.split("-")[1]}</small>
                      </> : <Link className="txt-info" to={"/usersprofile/"+order.user_id}>{app.uid(order.user_id)}</Link>}</span>
                      {users.length ? app.presence(users.filter((u) => u.user_id == order.user_id)[0], true) : null}
                    </li> : null}
                  <li className="ins-name">
                  <img src={ins_down} className="ins_down" />
                  <img src={ins_up} className="ins_up" />
                  <span className="th">INSTRUMENT</span><span className="td">{order.instrument}</span></li>
                  <li><span className="th">TYPE</span><span className="td">{order.type.toUpperCase()}</span>{order.auto_tr ? <span className='td aut'>Auto Trader</span> : null}</li>
                  <li><span className="th">TIME</span><span className="td">{order.create_time.split(", ")[0]}<br /><small className="time">{order.create_time.split(", ")[1]}</small></span></li>
                  <li className="o-price"><span className="th">ORDER PRICE</span><span className="td">{app.symbol(order.currency)}{order.order_price}</span></li>
                  <li className="o-price"><span className="th">COMMISSION</span><span className="td">{app.symbol(order.currency)}{order.commission.length ? Number(order.commission).toFixed(2) : "0.00"}</span></li>
                  <li className="o-rate"><span className="th">ORDER RATE</span><span className="td">{type == 'pending' ? order.trade_when : order.order_rate}</span></li>
                  <li><span className="th">S/L</span><span className="td">{order.stop_loss.trim().length ? order.stop_loss : '-'}</span></li>
                  <li><span className="th">T/P</span><span className="td">{order.take_profit.trim().length ? order.take_profit : '-'}</span></li>
                  {type == 'open' ? (
                    <>
                      <li className="c-rate"><span className="th">CURRENT RATE</span>
                        {order.custom && this.props.admin ? (
                          <span className="td">
                            <span className='strike'>{order.current_rate}</span><br />
                            <font>{order.specific_rate}</font>
                          </span>
                        ) : (<span className="td">{order.custom ? order.specific_rate : order.current_rate}</span>)}
                      </li>
                        {order.custom && this.props.admin ? (
                          <li className="profit"><span className="th">PROFIT</span>
                            <span className='strike'>{this.Profit(order.real_profit, order.currency)}</span>
                            {this.Profit(order.profit, order.currency)}
                            {order.stop_time.length && Number(order.used_time) > 0 ?
                            <div className={"_progressbar"+(order.e_rev ? " r" : "")} title={this.perc(Number(order.estim_time), Number(order.used_time), false)}>
                              <div style={{width: this.perc(Number(order.estim_time), Number(order.used_time))+"%", background: Number(order.used_time) < Number(order.estim_time) ? "#ffa500" : (order.estim_repeat == 1 ? "#00a880" : "#00a880")}}></div>
                            </div> : null}
                          </li>
                        ) : (
                          <li className="profit"><span className="th">PROFIT</span>
                            {this.Profit(order.profit, order.currency)}
                          </li>
                        )}
                      <li className="d-sell"><span className="th">DETAILS</span><span className="td">{order.mode.toUpperCase()}</span></li>
                      <li><span className="th">ACTION</span><span className="td">
                      {!this.props.admin || (this.props.admin && app.permit("Close trade")) ? <button className="close-trade" onClick={(e) => this.closeTrade(order.id, order.account, order.current_rate, this.props.admin ? order.user_id : null)}>Close</button> : null}
                      {(this.props.admin && app.permit("View and edit trades") && app.book()) || app.master() ? (<img src={gear} onClick={(e) => this.setState({etrade: true, etradata: order})} className="edit-trade" />) : null}
                      </span></li>
                    </>
                  ) : null}
                  {type == 'closed' ? (
                    <>
                      <li className="c-rate"><span className="th">CLOSE RATE</span><span className="td">{order.close_rate}</span></li>
                      <li className="profit"><span className="th">CLOSE PRICE</span>
                        {order.custom && this.props.admin ? (
                          <>
                            <strike>{this.Profit(order.real_profit, order.currency)}</strike>{this.Profit(order.profit, order.currency)}
                          </>
                        ) : this.Profit(order.profit, order.currency)}
                      </li>
                      <li className="d-sell"><span className="th">DETAILS</span><span className="td">{order.mode}</span></li>
                      <li><span className="th">ACTION</span>
                      {app._aff_() ? null : <button className="close-trade" style={{backgroundColor: "#D14"}} onClick={(e) => this.setState({confirmationModal: true, confirmData: {id: order.id, account: order.account, uid: order.user_id}})}>Delete</button>}
                      </li>
                    </>
                  ) : null}
                  {type == 'pending' ? (
                    <>
                      <li className="c-rate"><span className="th">CURRENT RATE</span><span className="td">{order.current_rate}</span></li>
                      <li className="d-sell"><span className="th">DETAILS</span><span className="td">{order.mode.toUpperCase()}</span></li>
                      <li><span className="th">ACTION</span>
                      {
                      ! this.props.admin || (this.props.admin && app.permit("Close trade"))
                      ? (app._aff_() ? null : <button className="close-trade" onClick={(e) => this.cancelTrade(order.id, order.account, this.props.admin ? order.user_id : null)}>Cancel</button>)
                      : null
                      }
                      </li>
                    </>
                  ) : null}
                </ul>
              ))}
              {history.length ? (
                this.props.admin ?
                  <Pagination2 length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/> :
                  <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>
                )
              : this.props.admin ? null : <TradeNotFound text={"No "+type+" trade"} /> }
              {this.props.admin && !history.length && !_t ? <TradeNotFound text={"No "+type+" trade"} /> : null}
              
              {/* <div
                className='loader-container'
                style={{ display: !loaded && this.props.admin ? 'block' : 'none' }}
              >
                <div className='loader'></div>
              </div> */}
          </div>
      </div>
  </div>
  );
};
}

export default TradeHistory;
