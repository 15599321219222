import React from 'react';
import './index.scss';

const RestrictionNav = ({
  selectedTab,
  handleClick,
  minimizeSideBar,
  restrictionNav,
  tabs
}) => {
  let tablist = {
    "setup": ['SETUP'],
    "platform": ['DEMO ACCOUNT', 'DEPOSIT BUTTON', 'AUTO TRADER', 'NOTIFIER', 'VERIFICATION BYPASS'],
    "email": ['EMAIL'],
    "account": ['ACCOUNT TYPES'],
    "status": ['ACCOUNT STATUS'],
    "payment": ['PAYMENT SETTINGS'],
    "currency": ['ACCOUNT CURRENCIES'],
    "questionnaire": ['QUESTIONNAIRE'],
    "subscription": ['SERVER FEE'],
  }
  let show = tablist[tabs];
  let _tabs = ['SETUP', 'DEMO ACCOUNT', 'DEPOSIT BUTTON', 'AUTO TRADER', 'NOTIFIER', 'VERIFICATION BYPASS', 'EMAIL', 'ACCOUNT TYPES', 'ACCOUNT STATUS', 'PAYMENT SETTINGS', 'QUESTIONNAIRE', 'SERVER FEE'];

  selectedTab = show.indexOf(selectedTab) > -1 ? selectedTab : show[0];

  return (
    <ul
      className={`restriction-nav ${
        minimizeSideBar ? 'trans-nav-min' : null
      } ${!restrictionNav ? 'hide-trans-nav' : null}`}
    >
      {
        _tabs.map((tbv, tbk) => {
          return show.indexOf(tbv) > -1 ? (
            <li className={tbk+` ${selectedTab.match(tbv) ? 'selected-trans-tab' : null} ${minimizeSideBar ? 'minimize-trans' : null}`} onClick={handleClick}>
            <div>{tbv}</div>
          </li>
          ) : null
        })
      }
    </ul>
  );
};

export default RestrictionNav;
