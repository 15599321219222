import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Container from '../container/index';
import Spinner from '../../components/spinner/index';
import SearchIcon from '../../themes/images/micro.svg';
import Send from '../../themes/images/send.png';
import person1 from '../../themes/images/person1.png';
import ppl from '../../themes/images/paper-plane.png';
import CancelIcon from '../../themes/images/cancel.svg';
import bcall from '../../themes/images/book-call.png';
import DummyImage from '../../themes/images/tradeDashboard/t_nav3.png';
import loading from './loading.gif';
import server from '../../services/server';
import app from '../../services/app';
import './index.scss';

class Chats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      opacity: 0.2,
      message: '',
      active: false,
      messages: [],
      chatList: [],
      cache_mes: {},
      rawChatList: [],
      showInfo: false
    };

    this.profile = app.profile();
    this.id = this.profile.user_id;
    this.refreshMessage = null;
  }

  chatList = async () => {
    let cl = await server.chatList();
    this.setState({chatList: cl.data, rawChatList: cl.data});
    if(cl.data.length) {
      this.changeActive(cl.data[0]);
    }
  }

  changeActive = (cl) => {
    this.setState({active: cl, messages: [], message: ''});
    this.refreshMsg("change_active", cl);
  }

  onShow = () => {
    setTimeout(() => {
      let element = document.getElementById("messageList");
      element.scrollTop = element.scrollHeight - element.clientHeight;
      this.setState({ loaded: true });
    }, 0);
  }

  async componentDidMount () {
    await this.chatList();
    // $(window).on("chatRenewSocket", () => this.socketInit(0));
    $(window).on("renewSocket", () => this.socketInit(0));
    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }
  }

  async componentWillUnmount () {
    this.isViewable = false;
    // clearInterval(this.refreshMessage);
  }

  scrollDown = () => {
    setTimeout(() => {
      let elem = document.getElementById("messageList");
      if(elem) {
        if(elem.scrollHeight) {
          elem.scrollTop = elem.scrollHeight - elem.clientHeight;
        }
      }
    }, 250);
  }

  refreshMsg = (x = "blank", cl = null) => {
    let active = cl || this.state.active;
    let ml = cl ? false : this.state.messages;
    if(this.state.chatList.length > 0 && !active) {
      active = this.state.chatList[0];
    }
    if(window.WebSocketPlugged && active) {
      // console.log("checking for new messages for", active.user_id, "("+x+")");
      let data = {
        "event": "GET_MESSAGES2",
        "payload": {
          admin: true,
          user: active.user_id,
          lastnid: 0,
          x: x,
          last_id: ml ? (ml.length ? ml[ml.length - 1]["id"] : 0) : 0
        }
      }

      if(window.GET_MESSAGES2 != data) {
        window.GET_MESSAGES2 = data;
        window.WebSocketPlug.send(JSON.stringify(data));
      }

      if(Object.keys(this.state.cache_mes).length) {
        let _chm = this.state.cache_mes;
        let _uis = active.user_id;
        if(Object.keys(_chm).indexOf(_uis) > -1) {
          setTimeout(() => {
            this.scrollDown();
          }, 250);
        }
      }
    }
  }

  refreshFlag = (uid) => {
    this.refreshMsg("setflag");
    if(window.WebSocketPlugged) {
      window.WebSocketPlug.send(JSON.stringify({
        "event": "SET_FLAG",
        "payload": { user: uid }
      }));
    }
  }

  readReciept = (uid = null) => {
    if(uid) {
      let chatList = this.state.rawChatList;
      let chl = [];
      chatList.map((ch, ck) => {
        let c = ch;
        c["unflag"] = ch.user_id == uid ? true : Boolean(this.state.chatList[ck].unflag);
        chl.push(c);
      });
      this.setState({chatList: chl});
    }
    if(window.WebSocketPlugged) {
      window.WebSocketPlug.send(JSON.stringify({
        "event": "READ_RECIEPT",
        "payload": { user: this.state.active.user_id, last_id: this.state.messages.length ? this.state.messages[this.state.messages.length - 1]["id"] : 0}
      }));
    }
  }

  socketInit = (x = 1) => {
    this.refreshMsg("socketInit");

    window.fct = 0;
    window.WebSocketPlug.addEventListener('message', ({data}) => {
    if(this.state.opacity != 1) {
      this.setState({opacity: 1});
    }
    try {
      let message = JSON.parse(`${data}`);
      let payload = message.payload;

      // if(message.event != "NEW_NOTIFICATION" && message.event != "ADMIN_UNREAD") {
      //   console.log(message.event);
      // }
      // console.log(message.event, "--i")

      switch(message.event) {
        case "MESSAGES":
          if(payload.user == this.state.active.user_id && payload.messages.length) {
            let cache_mes = this.state.cache_mes;
            let lm = payload.messages;
            cache_mes[payload.user] = lm;
            this.setState({ messages: lm, cache_mes });
            this.readReciept(this.state.active.user_id);
            this.scrollDown();
            this.refreshMsg("messages");
          }
        break;
        case "NEW_MESSAGE":
          console.log("NEW_MESSAGE for", payload.user)
          if(payload.user == this.state.active.user_id && payload.messages.length) {
            let new_msgs = [];
            let cache_mes = this.state.cache_mes;
            payload.messages.forEach((m, k) => {
              if(!$("#msg-list-"+this.state.active.id+"-"+m.id).length) {
                new_msgs.push(m);
              }
            });
            let lm = this.state.messages.concat(new_msgs);
            cache_mes[payload.user] = lm;
            this.setState({ messages: lm, cache_mes });
            this.readReciept(this.state.active.user_id);
            this.scrollDown();
            this.refreshMsg("new_message");
          }
        break;
        case "NEW_CHAT":
          let chatList = this.state.rawChatList;
          
          let newKeys  = [];
          let newList  = [];
          let oldList  = [];

          for (let ip = 0; ip < payload.users.length; ip++) {
            let v = payload.users[ip];
            for (let ik = 0; ik < chatList.length; ik++) {
              if(v["user_id"] == chatList[ik]["user_id"]) {
                chatList[ik] = v;
                newKeys.push(ik);
                newList.push(chatList[ik]);
              }
            }
          }

          if(newKeys.length) {
            for (let cl = 0; cl < chatList.length; cl++) {
              let c = chatList[cl];
              if(!(newKeys.indexOf(cl) > -1)) {
                oldList.push(c);
              }
            }

            let newcl = newList.concat(oldList);
            this.setState({chatList: newcl, rawChatList: newcl, opacity: 1});
            this.readReciept(this.state.active.user_id);
          } else {
            this.setState({opacity: 1});
          }
          break;
        }
      } catch (e) {
        return e;
      }
    });
  }

  findClKey = (k_u) => {
    chatList = this.state.rawChatList;
    for (let ik = 0; ik < chatList.length; ik++) {
      if(k_u == chatList[ik]["user_id"]) {
        return ik;
      }
    }
    return -1;
  }

  newMessage = async () => {
    if(window.WebSocketPlugged) {
      let message = $("#messageInput").val().trim();
      if(message.length) {
        $("#messageInput").val("");
        window.WebSocketPlug.send(JSON.stringify({"event": "SEND_MESSAGE", "payload": {
          user:      this.state.active.user_id,
          message:   message,
          sent_from: "admin",
          email:     app.email(),
          aid:       app.userid(),
          sid:       app.userid(),
          time:      new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
        }}));
      }
    }
  }

  lmf = (lmf = '') => {
    if(lmf.indexOf('https://') > -1 || lmf.indexOf('http://') > -1) {
      return "Sent an [Image]";
    } return lmf;
  }

  render() {
    let clist = this.state.filter.length ? this.state.chatList.filter((c) => {
      return (
        c.first_name.toLowerCase().match(this.state.filter.toLowerCase()) ||
        c.last_name.toLowerCase().match(this.state.filter.toLowerCase()) ||
        (c.first_name + " " + c.last_name).toLowerCase().match(this.state.filter.toLowerCase()) ||
        (c.last_name + " " + c.first_name).toLowerCase().match(this.state.filter.toLowerCase())
      );
    }) : this.state.chatList;
    let _msg_ = this.state.messages;
    if(_msg_.length == 0 && this.state.active && Object.keys(this.state.cache_mes).length) {
      let _chm = this.state.cache_mes;
      let _uis = this.state.active.user_id;
      if(Object.keys(_chm).indexOf(_uis) > -1) {
        _msg_ = _chm[_uis];
      }
    }
    return (
      <Container>
        <Spinner showSpinner={this.state.opacity != 1} />
        <div className='chat-section'>
          <div className="c-list">

            <div className='c-search'>
              <input
                type='text'
                name='search'
                autoComplete="off"
                placeholder='Search'
                onChange={(e) => this.setState({filter: e.target.value})}
              />
              <img src={SearchIcon} alt='' />
            </div>

            <ul className="people">
            {
              [].concat(clist).sort((a, b) => a.last_mid > b.last_mid ? -1 : 1).map((cl, ck) => (
                <li
                  className={this.state.active && (cl.user_id == this.state.active.user_id) ? "active" : ""}
                  key={`li-${cl.user_id}`}
                  style={{opacity: this.state.opacity}}
                  onClick={() => this.changeActive(cl)}>
                  <img src={cl.profile_image.length ? cl.profile_image : DummyImage} style={{borderRadius: "50%"}} />
                  {cl.online ? <span className="online"></span> : null}
                  <div className="p-info">
                    <h4>{cl.first_name+" "+cl.last_name}</h4>
                    <span style={{
                      fontWeight: cl.unflag || cl.flag > 0 ? 'normal' : 'normal',
                      color: cl.unflag || cl.flag > 0 ? '#fff' : '#fff'
                    }}>{cl.last_message.trim().length ? this.lmf(cl.last_message.length > 35 ? cl.last_message.substr(0, 35)+"..." : cl.last_message) : <i>No message</i>}</span>
                    {cl.unflag || cl.flag > 0 || !cl.last_message.length ? null : <span className="gdot">*</span>}
                  {/* || cl.user_id == this.state.active.user_id*/}
                  </div>
                </li>
              ))
            }
            </ul>
          </div>
          <div className='c-main'>
            {
              this.state.active ? (
                <div className="section1">
                  <img src={this.state.active.profile_image.length ? this.state.active.profile_image : DummyImage} style={{borderRadius: "50%"}} />
                  <div className="p2-info">
                    <h4><Link className="txt-info" to={"/usersprofile/"+this.state.active.user_id}>{this.state.active.first_name+" "+this.state.active.last_name} ({this.state.active.email})</Link></h4>
                    <span>{this.state.active.online ? "Online" : "Offline"}</span>            
                    <a href={"tel:"+this.state.active.phone_number}><img className='bcall' style={{height: '30px', width: '30px'}} src={bcall} alt='' /></a>
                  </div>
                </div>
              ) : null
            }

            <div className="section2">

              <ul id="messageList">
                {_msg_.length > 0 ? _msg_.map((msg) => {
                  let iim = msg.message.indexOf('https://') > -1 || msg.message.indexOf('http://') > -1;
                  return (
                  <li className={(msg.sid.trim() == app.userid() ? "m-msg" : "y-msg")} id={"msg-list-"+this.state.active.id+"-"+msg.id} key={"msg-list-"+this.state.active.id+"-"+msg.id}>
                    <Link className="em" to={msg.sent_from == "user" ? "/usersprofile/"+msg.user_id : "/adminsprofile/"+msg.aid}>{msg.email}</Link>
                    {msg.sid.trim() == app.userid() ? <small className="m-time">{msg.create_time}</small> : null}
                    <div className="m-text">
                      {iim ? <img src={msg.message} style={{width: "100%"}} alt="Image" loading="lazy" /> : msg.message}
                    </div>
                    {msg.sid.trim() == app.userid() ? null : <small className="m-time">{msg.create_time}</small>}
                  </li>
                )}) : this.state.active ? (<li className="li-loading"><h4 style={{fontFamily: 'Poppins', color: "#DDD"}}>{this.state.active.last_message.trim().length ? <span className='lblink'>Loading messages ...</span> : "No messages yet"}</h4></li>) : (null)}
              </ul>

            </div>
            {app.permit("Start chat") ?
            <div className="section3">
              <div className="s3">
                <textarea type="text" spellcheck="false" id="messageInput" onKeyUp={(e) => { if(e.which == 13) this.newMessage(); }}></textarea>
                <button className="send" onClick={this.newMessage}><img src={Send} /></button>
              </div>
            </div>: null}

          </div>
        </div>
      </Container>
    );
  }
}

export default Chats;
