import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Container from '../container/index';
import Pagination2 from '../../components/pagination2/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import TradeNotFound from '../../components/tradeNotFound/index';
import Ptab from '../../components/ptabs/index';
import server from '../../services/server';
import app from '../../services/app';
import { ConfirmModal } from '../../components/popups/index';
import NewDesk from '../../components/newDesk/index';
import Spinner from '../../components/spinner/index';
import TableFilters from '../../components/tablefilters/index';
import UsersTable from  '../../containers/users/userstable.jsx';

import '../../components/standard/standard.scss';
import './index.scss';

class Desks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navi: 1,
      loaded: false,
      confirmID: 0,
      newDesk: false,
      confirmNAME: '',
      showLoader: true,
      confirmModal: false,
      desks: [],
      stat: {users: '', admin: '', leads: ''}
    }

  }

  async componentDidMount () {
    this.refresh();
  }

  refresh = async () => {
    try {
      let d = await server.getDesks(true);
      this.setState({desks: d.data.desks, showLoader: false, loaded: true});
    } catch(e) {
      return e;
    }
  }

  deleteDesk = async () => {
    try {
      await server.deleteDesk(this.state.confirmID);
      let des = this.state.desks.filter((d) => d.id != this.state.confirmID);
      this.setState({desks: des});
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  render() {
    const { desks, loaded } = this.state;
    return (
      <Container>
      <div className="col-12" id="lists-container">
        <div className="lists-section-right">
            <ConfirmModal
              head={"Delete "+this.state.confirmNAME+"?"}
              text="Click YES to confirm"
              show={this.state.confirmModal}
              cancel={() => this.setState({confirmModal: false})}
              confirm={() => { this.setState({confirmModal: false, showLoader: true}); this.deleteDesk(); }}
            />
            
          { this.state.newDesk ?
            <NewDesk
              sending={() => this.setState({showLoader: true})}
              unsending={() => this.setState({showLoader: false})}
              sent={(e) => { this.setState({newDesk: false, showLoader: false}); this.refresh(); }}
              cancel={(e) => this.setState({newDesk: false, showLoader: false})}
            /> : null }
            
            <Spinner showSpinner={this.state.showLoader} />
            <Breadcrumbs breads="Home, Desks" />

            <h1 className="page-title">Desks
              {app.master() ? <div className="btn-list">
                <button className="create-list" onClick={(e) => this.setState({newDesk: true})}>New Desk</button>
              </div> : null}
            </h1>

            <Ptab tabs="Desks library" handleClick={() => {}} active="Desks library" />

              <ul className="table-header">
                <li>NAME</li>
                <li>LANGUAGE</li>
                <li>SIZE</li>
                <li>LAST CREATED</li>
                <li>ACTION</li>
              </ul>

            {
              desks.map((d, k) => {
                return (
                  <Link to={"/Desk/"+d.lang}>
                    <ul className="table-body _users" key={"lk-"+k}>
                      <li><span className="txt-light">{d.name}</span></li>
                      <li><span className="txt-light">{d.lang}</span></li>
                      <li><span className="txt-light" id={"aff-nol-2"+(k > 0 ? '-'+k : '')}>{app._aff_() ? '-' : d.counter}</span></li>
                      <li><span className="txt-light">{d.last_created ? moment(d.last_created).calendar() : '-'}</span></li>
                      <li style={{opacity: 0.6}}>
                        {app._aff_() ? '-' :
                        <svg onClick={(e) => { e.preventDefault(); /* this.setState({confirmID: d.id, confirmNAME: d.name, confirmModal: true}); */ }} className="tb-action" width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#FFE602"/>
                        </svg>}
                      </li>
                    </ul>
                  </Link>
                )
              })
            }
            {desks.length == 0 && loaded ? <TradeNotFound text={"Your desk is empty"} /> : null}

        </div>
      </div>
      </Container>
    );
  }

  Thesvg = () => {
    return (
      <>
      <svg className="fil-u" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 0L11.1962 6H0.803848L6 0Z" fill="#C4C4C4"/>
      </svg>
      <svg className="fil-d" width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6L0.803849 -9.78799e-07L11.1962 -7.02746e-08L6 6Z" fill="#C4C4C4"/>
      </svg>
    </>
    )
  }
};

export default Desks;