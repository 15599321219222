import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
// import MessageBox from '../messageBox/index';
import NotificationBox from '../notifications/index';
// import HoverDropdown from '../hoverDropdown/index';
import userDp from '../../themes/images/dummydp.png';
import Tnav1 from "../../themes/images/tradeDashboard/t_nav1.svg";
import Tnav2 from "../../themes/images/tradeDashboard/t_nav2.svg";
import Tnav3 from "../../themes/images/tradeDashboard/t_nav3.png";
import Tnav4 from "../../themes/images/tradeDashboard/t_nav4.svg";
import newMessage from "../../themes/sounds/new.ogg";
import liveChat from "../../themes/images/live-chat.png";
import mute from '../../Assets/mute.svg';
import unmute from '../../Assets/unmute.svg';
import calendar from "./calendar.svg";
import { Logout } from '../../components/popups/index';
// import HeaderImage from "../../themes/images/avariz_logo.png";
import app from '../../services/app';
import server from '../../services/server';
import './index.scss';

let HeaderImage = app.custom("logo");

class OutterTopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      mbox: false,
      ibox: false,
      newNotice: 0,
      newMessage: 0,
      showLogout: false
    }
    this.audio = new Audio(require("../../themes/sounds/new.ogg").default);
    this.notify = new Audio(require("../../themes/sounds/note.ogg").default);
  }

  ring = (n) => {
    n = this.state.newMessage + Number(n);
    this.setState({newMessage: n});
    if(n > 0) {
      $(this.audio)[0].play();
    }
  }

  ring2 = (n) => {
    if(app._aff_()) {
      return false;
    }
    n = Number(n);
    this.setState({newNotice: n});
    if(n > 0 && !app.mute()) {
      $(this.notify)[0].play();
    }
  }

  onShow = () => {
    $(window).trigger("onShow");
    setTimeout(() => {
      let element = document.getElementById("messageList");
      element.scrollTop = element.scrollHeight - element.clientHeight;
      window.scrollChat = true;
    }, 250);
  }

  Stats = () => {
    return (
      <li className='aff_stat'>
        No of Leads
      </li>
    )
  }

  getAffStat = async () => {
    try {
      let fdata = await server.getAffStat();
      let data = fdata.data.stat;
      $("#aff-nol, #aff-nol-2").html(data.nol);
      $("#aff-ftd").html(data.ftd);
      setTimeout(() => {
        $("#aff-nol, #aff-nol-2").html(data.nol);
      }, 10);
    } catch (e) {
      console.log(e);
    }
  }

  umute = async () => {
    try {
      await server.updateSettings("mute", app.mute() ? 0 : 1);
      window.location.href = "";
    } catch (error) {
      const errorMessage = error;
      console.log(errorMessage);
    }
  }

  async componentDidMount () {
    if(app._aff_()) {
      this.getAffStat();
    }
  }

  render() {
    let perm = window.location.href.toLowerCase().split("/");
        perm = perm[perm.length - 1].match("restrictions");
    const { isAdmin, profileImage, email, firstName, lastName, balance, real_balance, main_balance, handleLogout } = this.props;
    return (
      <>
        <Logout show={this.state.showLogout} cancel={(e) => this.setState({showLogout: false})} confirm={handleLogout} />
        <div className='top-nav'>
          {isAdmin ? (null) : (
            <img src={HeaderImage} alt='' style={{width: "33px", height: "33px"}} />
          )}
          {isAdmin ? (app.master() ? null : app._aff_() ? <button className="timeout aff"><span>N<u>o</u> of leads: <b id="aff-nol">*</b></span><span>First time deposit: <b id="aff-ftd">*</b></span></button>: <button className="timeout"><img src={calendar} /> {app.daysToGo()}</button>) : null}
          {isAdmin ? (
            <ul className={'top-nav-list'+(isAdmin ? " _admin" : "")} style={{width: "auto", zIndex: 1}}>
              <li className={'dropdown'+(this.state.hover ? ' hover' : '')}>
                {/* {this.state.hover && (
                  <div className='overlay drop' onClick={() => this.setState({mbox: false, hover: false})}></div>
                )} */}
                <img src={profileImage ? profileImage : userDp} style={{border: "1px solid #fff"}} alt='' onMouseEnter={() => this.setState({hover: true})} onClick={() => this.setState({hover: true})}/>
              </li>
              <li style={{opacity: 1}} className='hide-mobile nav-aname'>
                <span className="fl"><Link to="/Profile" style={{color: "#fff"}}>{`${firstName} ${lastName}`}</Link></span>
                <span className="em"><Link to="/Profile" style={{color: "#fff"}}>{email}</Link></span>
              </li>
             {isAdmin && app.master() ? <li><Link to="/Clients" className="tcli">Clients</Link></li> : null}
             {isAdmin && app._aff_() ? <li><Link to="/Profile" className="tcli" style={{background: "#ffa500", fontWeight: "bold", width: "auto", paddingLeft: "1em", paddingRight: "1em"}}>{app.profile()["source"]}</Link></li> : null}
             {app._aff_() ? null : <>
              <li style={{opacity: 1, display: "none"}} id="sacli"><button className="save-changes" id="save-all-changes-btn" style={{cursor: "pointer"}}>Save&nbsp;<span>{perm ? 'As' : 'All Changes'}</span></button></li>
              <li style={{position: "relative", cursor: "pointer", marginLeft: "2em"}}>
                {this.state.ibox && (
                  <div className='overlay drop' onClick={() => { this.setState({newMessage: 0, mbox: false, hover: false, ibox: false}); }}></div>
                )}
                <img src={Tnav2} alt='' onMouseEnter={() => { if(!window.BuyandsellModalPopup) { this.setState({mbox: false, ibox: true, newNotice: 0}); }}} onClick={() => { window.BuyandsellModalPopup = false; this.setState({mbox: false, ibox: true, newNotice: 0}); }} />
                {/* {this.state.newNotice > 0 ? <span className="__newNotice"></span> : null} */}

                <NotificationBox
                  show={this.state.ibox}
                  ring={(n) => this.ring2(n)}
                />
              </li>
              {app.isAdmin() ? <li style={{marginLeft: "1em"}}><img src={app.mute() ? mute : unmute} style={{opacity: app.mute() ? 0.8 : 1}} onClick={this.umute} className='umute' alt='' /></li> : null}
              </>}
              <li onClick={(e) => this.setState({showLogout: true})} className="logout-btn" style={{marginLeft: "1em"}}><img src={Tnav4} alt='' /></li>
            </ul>
          ) : null}
        </div>
        {!app.isAdmin() && !app.isVerified() ? <marquee id="acfb">Awaiting Broker's confirmation</marquee> : null}
      </>
    );
  }
}

export default OutterTopNav;
