import React, { Component } from 'react';
import $ from 'jquery';
import TableFilters from '../../components/tablefilters/index';
import TradeHistory from '../../components/tradeHistory/index';
import CancelIcon from '../../themes/images/cancel.svg';
import Trades from '../trades/index';
import ai_n from '../../themes/images/ai-normal.png';
import ai_b from '../../themes/images/ai-bookmark.png';
import Chart from '../../components/chart/index';
import './tradingactivities.scss';
import '../../components/standard/table.scss';

window.current_accounts = null;

class TradingActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptions: ["ALL TYPES", "FOREX", "CRYPTO", "STOCK", "COMMODITIES", "INDICES"],
      trade: this.props.trade,
      type: 0,
      placeOrder: false,
      hotStocks: [],
      page_no: 1,
      filterPair: '',
      filterType: ''
    }
  }

  async componentDidMount () {

    $(window).on("refreshMod", () => {
      window.showCallback = false;
      this.props.refresh();
    });

    let dis = this;

    $(document).delegate(".trade-frame", "click", function (e) {
      if($(e.target).hasClass("trade-frame")) {
        dis.setState({placeOrder: false});
      }
    });

    $(window).on("renewSocket", () => this.socketInit());

    $(window).on("placedOrder", () => {
      setTimeout(() => {
        window.showCallback = true;
        this.setState({placeOrder: false});
        this.props.refresh();
      }, 500);
    });

    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }
  }

  socketInit = () => {
    window.WebSocketPlug.addEventListener('message', ({data}) => {
      try {

        let message = JSON.parse(`${data}`);
        let payload = message.payload;

        if(message.event == "PAIR_DATA") {
          this.setState({ hotStocks: payload });
        }

      } catch (e) {
        throw e;
      }
    });
  }

  changeType = (e) => {
    this.setState({type: parseInt(e.target.value)});
  }

  handleChange = (e) => {
    this.setState({filterPair: e.target.value.trim().toLowerCase(), page_no: 1});
  }

  onChange = (e) => {
    this.setState({filterType: e.target.value.split(" ")[0].trim().toLowerCase(), page_no: 1});
  }

  render () {
    let type   = parseInt(this.state.type);
    let trade  = this.state.trade.filter((p) => parseInt(p.order_status) === type);
    window.current_accounts = this.props.accounts;

    return (
      <div className="tab-row profile-trading _active" id="tab-row-trading">

        {this.state.placeOrder ?
          <div className="trade-frame">
            <div id="trd-acc-bl" style={{display: "flex", marginBottom: "-40px", position: "relative", paddingLeft: "10px", paddingRight: "10px", justifyContent: "space-between"}}>
              <span id="trd-acc-bal-spa" style={{color: "#fff", fontFamily: "Poppins", fontSize: "1.5em", fontWeight: "bolder"}}>$0</span>
              <img src={CancelIcon} alt='' className='modal-cancel' style={{cursor: "pointer", position: "absolute", zIndex: 100, zIndex: 100, position: "absolute", right: "10px", top: "10px"}} onClick={() => this.setState({placeOrder: false})} />
            </div>
            <Chart hotStocks={this.state.hotStocks} uid={this.props.uid} admin={true} accounts={this.props.accounts} />
          </div> : null}

        <TableFilters
          table="trade"
          filterOptions={this.state.filterOptions}
          handleChange={this.handleChange}
          changeType={this.changeType}
          onChange={this.onChange}
          uid={this.props.uid}
          placeOrder={() => {
            this.setState({placeOrder: true}, () => {
              window.current_acc = null;
              let bal = this.props.accounts[0].balance;
                  $("#trd-acc-bal-spa").html("$"+bal);
            });
          }}
        />

        <Trades
          noid={true}
          admin={true}
          uid={this.props.uid}
          type={["open", "pending", "closed"][type]}
          tyval={type}
          filterPair={this.state.filterPair}
          filterType={this.state.filterType}
          page_no={this.state.page_no}
          userprofile={true}
          trades={trade}
        />
      </div>
   )
  }

}

export default TradingActivities;