import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import copy from '../../Assets/copy.svg';
import UserAvatar from './userAvatar/index';
import { withRouter, Redirect } from 'react-router-dom';
import HorizontalBar from './passwordBar/index';
import Information from './information/index';
import { saveUserProfile, toggleAddCardModal } from '../../redux/actions/index';
import { verificationData, debitCardInfo } from '../../utils/dummyData';
import PasswordButton from './passwordButton/index';
import PasswordBox from './passwordBox/index';
import AccountDetails from './accountDetails/index';
import FinancialDetails from './financialDetails/index';
import AccountInfo from './accountInfo/index';
import DebitCard from './debitCard/index';
import VerificationGroup from './verificationGroup/index';
import Spinner from '../spinner/index';
import server from '../../services/server';
import app from '../../services/app';
import { CallBack } from '../../components/popups/index';
import AccountModal from './addAccountModal/index';
import './index.scss';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: {},
      showSpinner: false,
      oldPassword: '',
      newPassword: '',
      error: null,
      success: false,
      verified: false,
      imageUrl: '',
      image: '',
      showCallBack: false,
      showSmallSPinner: false,

      user_id: app.profile().user_id,
      profile_image: app.profile().profile_image,
      first_name: app.profile().first_name,
      last_name: app.profile().last_name,
      email: app.profile().email,
      phone_number: app.profile().phone_number,
      source: app.profile().source,

      password: '',
      confirmPassword: '',
      uid: app.id(),
    };

    this.profile         = app.profile();
    this.id              = app.id();
  }

  async componentDidMount() {
    if (!app.id().length) this.props.history.push('/Login');
    const gp = await server.getProfile();
    app.profile(gp.data.profile);
    this.profile         = app.profile();
    this.props.saveUserProfile(this.profile);
  }

  handleFileChange = async (e) => {
    const current = e.target.files[0];
    const fd = new FormData();
    fd.append('profile_doc.png', current, current.name);

    this.setState({ showSmallSPinner: true });
    try {
      let pi = await server.uploadImage(fd);
      const gp = await server.getProfile();
      app.profile(gp.data.profile);
      window.location.href = "";
      // this.props.saveUserProfile(gp.data.profile);
      // this.setState({ showSmallSPinner: false, profile_image: gp.data.profile.profile_image });
    } catch (error) {
      this.setState({ showSmallSPinner: false });
      return error.message;
    }
  };

  changePassword = async () => {
    this.clearErrors();
    const { oldPassword, newPassword, confirmPassword } = this.state;

    if (oldPassword === '') this.setState({ oldPasswordError: 'required' });
    if (newPassword === '') this.setState({ newPasswordError: 'required' });

    if (newPassword !== confirmPassword)
      this.setState({ confirmPasswordError: 'Passwords must match' });

    if (oldPassword && newPassword === confirmPassword) {
      try {
        this.setState({ showSpinner: true });

        const user_id = app.id();
        const email   = this.profile.email;

        await server.changePassword({old_password: oldPassword, new_password: newPassword});

        this.setState({
          showSpinner: false,
          success: true,
          newPassword: '',
          oldPassword: '',
          confirmPassword: '',
        });
        $("[name=newPassword], [name=oldPassword], [name=confirmPassword]").val("");
      } catch (error) {
        if (!error.response) {
          return error.message;
        }
        const errorMessage = error.response.data.message;
        this.setState({ showSpinner: false, error: errorMessage });
      }
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  clearErrors = () => {
    this.setState({
      error: null,
    });
  }

  UpdateAdm = async (e) => {
    e && e.preventDefault();
    const {
      first_name,
      last_name,
      email,
      phone_number,
      password,
      uid,
      source,
      confirmPassword
    } = this.state;
    const nameRegex = /^[a-zA-Z]{3,}$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    this.clearErrors();

    let err = false;
    if (!nameRegex.test(first_name)) {
      err = true;
      this.setState({ error: 'Invalid first name specified' });
    }
    if (!nameRegex.test(last_name)) {
      err = true;
      this.setState({ error: 'Invalid last name specified' });
    }
    if (email.length < 5) {
      err = true;
      this.setState({ error: 'Invalid email' });
    }
    if (phone_number.length < 6) {
      err = true;
      this.setState({ error: 'Invalid phone number' });
    }
    if(source == null || source.trim().length == 0) {
      err = true;
      this.setState({ error: 'Source cannot be empty' });
    }
    if(uid.length && password.length) {
        if(!confirmPassword.length) {
            this.setState({ error: 'Invalid confirm password' });
        }
    } else {
      if(uid.length && !password.length) { } else {
        if (!password.length) {
          err = true;
          this.setState({ passwordError: 'Invalid password' });
          if(!confirmPassword.length) {
              this.setState({ error: 'Invalid confirm password' });
          }
        }
      }
    }

    if (password !== confirmPassword) {
      err = true;
      this.setState({ error: 'Passwords must match' });
    }
    if(err) {
      return null;
    } else {

      this.setState({ showSpinner: true });

      try {
        let submit = {
          first_name,
          last_name,
          email,
          phone_number,
          password,
          uid,
          source
        };

        const response = await server.updateAdmin(submit, true);
        this.setState({showCallBack: true, showSpinner: false});
        const gp = await server.getProfile();
        app.profile(gp.data.profile);
        this.profile         = app.profile();
        this.props.saveUserProfile(this.profile);

      } catch (error) {
        this.setState({ showSpinner: false });
        if (!error.response) {
          return error;
        }

        const errorMessage = error.response.data.message;

        if (errorMessage.toLowerCase().match('password')) {
          this.setState({ error: errorMessage });
        }

        if (errorMessage.toLowerCase().match('username')) {
          this.setState({ error: errorMessage });
        }
      }
    }
  }

  copy = (id) => {
      /* Get the text field */
      var copyText = document.getElementById(id);

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText.value);
  }

  render() {

    const {
      showSpinner,
      profile_image,
      first_name,
      last_name,
      email,
      user_id,
      phone_number,
    } = this.state;

    let cid = app.data("cid");
    let rid = user_id.split("-")[0].toUpperCase();
    let lnk = "https://"+app.host(false)+"/Register?_rf="+(rid);

    let ank1 = "https://"+app.host(false)+"/s/?api=newlead&script=js&cid="+cid+"&id="+(rid);
    let ank2 = "https://"+app.host(false)+"/s/?api=newlead&script=php&cid="+cid+"&id="+(rid);

    let bnk1 = "https://"+app.host(false)+"/s/?api=leadstat&script=js&cid="+cid+"&id="+(rid);
    let bnk2 = "https://"+app.host(false)+"/s/?api=leadstat&script=php&cid="+cid+"&id="+(rid);

    let lsk1 = "https://"+app.host(false)+"/s/?api=leadsearch&script=js&cid="+cid+"&id="+(rid);
    let lsk2 = "https://"+app.host(false)+"/s/?api=leadsearch&script=php&cid="+cid+"&id="+(rid);

    let lss1 = "https://"+app.host(false)+"/s/?api=leadstatus&script=js&cid="+cid+"&id="+(rid);
    let lss2 = "https://"+app.host(false)+"/s/?api=leadstatus&script=php&cid="+cid+"&id="+(rid);

    return (
      <div className='profile-section-container'>
        {this.state.showSpinner ? <Spinner showSpinner={showSpinner} /> : null}
        <CallBack show={this.state.showCallBack} cancel={(e) => this.setState({showCallBack: false})} head="Profile Updated" text="Profile updated successfully" />

        <div className='profile-right-section admin'>
          <div className='profile-bg' style={{justifyContent: 'space-between', display: 'inline-flex', width: "100%"}}>
            <b className='red red2'>{this.state.error ? `*${this.state.error}` : ""}</b>
            <button className="btn-save end" type="button" onClick={this.UpdateAdm}>Update</button>
          </div>

          <div className='account-details-section profile-bg'>
            <UserAvatar
              admin={true}
              name={(this.state.first_name + " " + this.state.last_name).ucwords()}
              email={this.profile.email}
              role={this.profile.role}
              imageUrl={profile_image}
              handleChange={this.handleFileChange}
              showSpinner={this.state.showSmallSPinner}
            />
            <ul className="adm-ptab">
              <li>
                <div className="c">Email:</div>
                <div className="v"><input type="text" spellCheck="false" defaultValue={app.email()} readOnly="true" /></div>
              </li>
              <li>
                <div className="c">Phone:</div>
                <div className="v"><input type="text" spellCheck="false" defaultValue={this.profile.phone_number} name="phone_number" onChange={this.handleInputChange} /></div>
              </li>
              <li>
                <div className="c">Firstname:</div>
                <div className="v"><input type="text" spellCheck="false" defaultValue={this.state.first_name} name="first_name" onChange={this.handleInputChange} /></div>
              </li>
              <li>
                <div className="c">Lastname:</div>
                <div className="v"><input type="text" spellCheck="false" defaultValue={this.state.last_name} name="last_name" onChange={this.handleInputChange} /></div>
              </li>
              <li>
                <div className="c">Password:</div>
                <div className="v"><input type="password" name="password" placeholder="Password" onChange={this.handleInputChange} /></div>
              </li>
              <li>
                <div className="c">Confirm password:</div>
                <div className="v"><input type="password" name="confirmPassword" placeholder="Confirm password" onChange={this.handleInputChange} /></div>
              </li>
              <li>
                <div className="c">{app._aff_() ? "Affiliate ID" : "Referral id"}:</div>
                <div className="v" style={{position: "relative"}}>
                  <span id="">{rid}</span>
                  <input type="text" style={{display: "none"}} value={rid} id="sel_rspan" />
                  <img src={copy} className="copy" onClick={() => this.copy("sel_rspan")} />
                </div>
              </li>
              {
                app._aff_() ? 
                <li>
                  <div className="c">Soure(s):</div>
                  <div className="v"><input type="text" name="source" defaultValue={this.state.source} placeholder="Source" onChange={this.handleInputChange} /></div>
                </li> : null
              }
              {app._aff_() ?
                <>
                  <li style={{marginTop: "50px"}}>
                    <div className="c">New lead (JS):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={ank1} id="sel_rlink1" />
                      <a href={ank1} target="_blank" id="" className="rlk">{ank1}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink1")} /> */}
                    </div>
                  </li>
                  <li style={{borderBottom: "1px solid #ddd", marginBottom: "5px", paddingBottom: "15px"}}>
                    <div className="c">New lead (PHP):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={ank2} id="sel_rlink2" />
                      <a href={ank2} target="_blank" id="" className="rlk">{ank2}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink2")} /> */}
                    </div>
                  </li>
                  <li>
                    <div className="c">Leads data (JS):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={bnk1} id="sel_rlink3" />
                      <a href={bnk1} target="_blank" id="" className="rlk">{bnk1}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink3")} /> */}
                    </div>
                  </li>
                  <li style={{borderBottom: "1px solid #ddd", marginBottom: "5px", paddingBottom: "15px"}}>
                    <div className="c">Leads data (PHP):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={bnk2} id="sel_rlink4" />
                      <a href={bnk2} target="_blank" id="" className="rlk">{bnk2}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink4")} /> */}
                    </div>
                  </li>
                  <li>
                    <div className="c">Leads Search (JS):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={lsk1} id="sel_rlink3" />
                      <a href={lsk1} target="_blank" id="" className="rlk">{lsk1}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink3")} /> */}
                    </div>
                  </li>
                  <li style={{borderBottom: "1px solid #ddd", marginBottom: "5px", paddingBottom: "15px"}}>
                    <div className="c">Leads Search (PHP):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={lsk2} id="sel_rlink4" />
                      <a href={lsk2} target="_blank" id="" className="rlk">{lsk2}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink4")} /> */}
                    </div>
                  </li>
                  <li>
                    <div className="c">Leads Status (JS):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={lss1} id="sel_rlink3" />
                      <a href={lss1} target="_blank" id="" className="rlk">{lss1}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink3")} /> */}
                    </div>
                  </li>
                  <li>
                    <div className="c">Leads Status (PHP):</div>
                    <div className="v" style={{position: "relative"}}>
                      <input type="text" style={{display: "none"}} value={lss2} id="sel_rlink4" />
                      <a href={lss2} target="_blank" id="" className="rlk">{lss2}</a>
                      {/* <img src={copy} className="copy" onClick={() => this.copy("sel_rlink4")} /> */}
                    </div>
                  </li>
                </> : <li>
                  <div className="c">Referral link:</div>
                  <div className="v" style={{position: "relative"}}>
                    <input type="text" style={{display: "none"}} value={lnk} id="sel_rlink" />
                    <a href={lnk} target="_blank" id="" className="rlk">{lnk}</a>
                    <img src={copy} className="copy" onClick={() => this.copy("sel_rlink")} />
                  </div>
                </li>
              }
            </ul>
          </div>
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ userProfile, showAddCardModal }) => ({
  userProfile,
  showAddCardModal,
});

const mapDispatchToProps = (dispatch) => ({
  saveUserProfile: (profile) => dispatch(saveUserProfile(profile)),
  toggleAddCardModal: () => dispatch(toggleAddCardModal()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile),
);
