import React, { Component } from 'react';
import $ from 'jquery';
// import eruda from 'eruda';
import moment from 'moment';
import Moment from 'react-moment';
import { Link, withRouter } from 'react-router-dom';
import Container from '../Container/Index';
import TradeHistory from './Components/TradeHistory';
import Spinner from '../../components/spinner/index';
import Chart from '../../components/chart/chart';
import BsConfirmationModal from '../../components/bsConfirmationModal/index';
import { CallBack, ConfirmModal, Insufficient, MsgAlert } from '../../components/popups/index';
import con_buysell from '../../themes/images/con_buysell.png';
import SearchIcon from "../../themes/images/tradeDashboard/search.svg";
import server from '../../services/server';
import app from '../../services/app';
// import Chart from './Components/Chart';
import search from '../../Assets/search.svg';
import cancel from '../../Assets/cancel.svg';
import star from '../../Assets/star.svg';
import ait from '../../Assets/ait.svg';
import adw from '../../Assets/adw.svg';
import auw from '../../Assets/auw.svg';
import uvlv from '../../Assets/uvlv.svg';
import dvlv from '../../Assets/dvlv.svg';
import fstar from '../../Assets/fav.svg';

import closeP from '../../Assets/close.png';
import checkP from '../../Assets/check.png';
import power from '../../Assets/power.svg';

import bali from '../../Assets/btab/bal-i.svg';
import crei from '../../Assets/btab/cre-i.svg';

import mg from '../../Assets/btab/mg.svg';
import ml from '../../Assets/btab/ml.svg';
import opl from '../../Assets/btab/opl.svg';
import eqt from '../../Assets/btab/eqt.svg';

import bsell from '../../Assets/btab/bsell.svg';
import market from '../../Assets/btab/market.svg';
import order from '../../Assets/btab/order.svg';
import trade from '../../Assets/btab/trade.svg';
import wallet from '../../Assets/btab/wallet.svg';

import bsellA from '../../Assets/btab/bsell-active.svg';
import marketA from '../../Assets/btab/market-active.svg';
import orderA from '../../Assets/btab/order-active.svg';
import tradeA from '../../Assets/btab/trade-active.svg';
import walletA from '../../Assets/btab/wallet-active.svg';

import './Trade.scss';
import '../../mobile.scss';

var BUY_HIST = Array();
var SELL_HIST = Array();
window.FAKE_UID = "";
window.LAST_CRATE = null;
window.CS_RATE    = Array();
window.PREVIOUS_BUY_RATE = Array();
window.PREVIOUS_BUY_ARROW = Array();
window.PREVIOUS_SELL_RATE = Array();
window.PREVIOUS_SELL_ARROW = Array();


const guessTimate = (x, raw = false) => {
  let str = String(x).substring(0, 7);
  if(!raw) {
    return Number(str);
  }
  let chill = Math.floor((Math.random() * 100) + 1);
  if(chill <= 30) {
    return Number(str);
  }
  var y = Math.random();
      y = y < 0.5 ? Math.floor(y) : Math.ceil(y);
  let bfd = parseInt(str);
  let afd = str.split(".")[1];
  if(afd && afd.length > 1) {
    let scp = Math.floor(Math.random() * 5);
    let af_ = String(Number(afd)+(y ? scp : -scp));
    let rpt = afd.length-af_.length;
    let _fd = Number(String(bfd)+"."+(rpt > 0 ? "0".repeat(rpt) : "")+af_);
    return _fd;
  } else {
    return Number(str);
  }
}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

var _users = [];
var _udkey = "gr-dropdown-for-admin-"+Math.random()+"-"+Math.random();
var _taccounts = [];
var _acckeys = "t-accs-for-admin-"+Math.random()+"-"+Math.random();

class Trade extends Component {

  constructor(props) {
    super(props);

    let start = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    let param = this.props.history.location.search.replace(/\?/g, "").toLowerCase() || "";
    let profile = app.profile();
    let autoTrader = false;
    let pow = false;

    // delete profile.auto_trader;

    if(profile?.auto_trader?.length) {
      autoTrader = true;
      pow = true;
      start = profile.auto_trader;
    }

    this.state = {
      buy_mode: true,
      stop: false,
      take: true,
      only: false,
      active: "take",
      stl_val: 0,
      tkp_val: 0,
      trade_when: 0,
      required_m: 0,
      vlv: '0.01',
      lot_str: "",
      pip_str: "",
      analysis: false,
      conversion_1: 0,
      conversion_2: 0,
      loadedPair: null,

      autoTr: autoTrader,
      clist: true,
      clicked: false,
      autoTrader,
      currentTab: 'Trade',
      showSpinner: false,
      showNav: true,
      hideNav: false,
      BUY_HIST: [],
      head: '',
      btab: 'trade',
      showCallBack: '',
      users: [],
      taccounts: [],
      taccount: "",
      uid: 'null',
      uidSearchDrop: false,
      uid_filter: '',
      SELL_HIST: [],
      open_pl: 0,
      equity: 0,
      pow,
      confirMsg: '',
      confirmOrder: false,
      orderCallback: false,
      orderText: '',
      margin: 0,
      loaded: false,
      fmargin: 0,
      filter: '',
      real_balance: 0,
      message: "",
      start,
      isAdmin: this.props.admin ? false : true,
      filterOptions: ['ALL TYPES'],
      accounts: [],
      all_trades: [],
      buys_pair: false,
      open_trades: [],
      closed_trades: [],
      pending_trades: [],
      profile: profile,
      showSufficient: false,
      showInssufficient: false,
      selectedAccount: app.accountDetail(),
      selectedAccountVal: app.account(),
      hotStocks: [],
      favouritePairs: [],
      _favouritePairs: [],
      favourites: [],
      errorMessage: "",
      devMode: param == "dev",

      buyandsellAct: 'buy',
      buyandsellModal: false,
      buyandsellModalInfo: false,
      buyandsellConfirmed: false
    };

    this.realTimeListener = true;
    this.retryCounter = 0;

    this.profile = app.profile();
  }

  componentWillUnmount() {
    this.realTimeListener = false;
  }

  refBal = async () => {
    app.accounts([]);
    await this.getAllUsers();
    this.setState({selectedAccount: app.accountDetail(), accounts: app.accounts()});
  }

  async componentWillUnmount () {
    if(app.isAdmin()) {
      window.location.href = "";
    }
  }

  async componentDidMount() {
    let u = null;

    // alert(app.profile()["utc"])
    if(this.props.history.location.search.length) {
      u = this.props.history.location.search.indexOf("?id=") > -1;
      if(u) {
        u = this.props.history.location.search.split("?id=")[1].trim();
      }
    }
    // if(this.state.devMode) {
    //   eruda.init();
    // }
    if(app.isAdmin()) {
      app.accounts([]);
      await this.getAllUsers(u);
    }

    $(window).on("renewSocket", () => this.socketInit());
    if(window.WebSocketPlugged) {
      $(window).trigger("renewSocket");
    }
    this.realTimeListener = true;
    this.setState({ selectedAccount: app.accountDetail(), accounts: app.accounts() });

    window.addEventListener('resize', this.updateDimensions);

    $(".ndfxbal").on("_refresh", () => {
    
      let autoTrader = false;
      let profile = app.profile();
      if(profile?.auto_trader?.length) {
        autoTrader = true;
      }
      
      this.setState({profile, selectedAccount: app.accountDetail(), accounts: app.accounts(), autoTrader, autoTr: autoTrader});
      this.profile = profile;
    });

    setTimeout(() => {
      let filterOptions = Object.keys(app.allPairs());
      let f = [];
      for (var i = 0; i <= filterOptions.length; i++) {
        f[i] = (i == 0 ? "ALL TYPES" : filterOptions[i - 1]).toUpperCase();
      }
      this.setState({filterOptions : f});
    }, 1000);
  }
  
  getAllUsers = async (u = null) => {
    try {
      let users = await server.getAllUsers("**", this.state.leads);
      this.setState({users: users.data});
      if(u) {
        let user = users.data.filter((us) => us.user_id == u);
        if(user.length) {
          user = user[0];
          let vl = (user.first_name+" "+user.last_name).ucwords();
          this.setState({uid_filter: vl, uid: user.user_id, uidSearchDrop: false, taccounts: []});
          $("#uidSearchDrop").val(vl);
          this.getUaccs(user.user_id);
        }
      }
    } catch(e) {
      return e;
    }
  }

  closeCon = () => {
    if(window.WebSocketPlugged) {
      window.WebSocketPlug.send(JSON.stringify({
        "event": "CLOSE_CON"
      }));
    }
  }

  getUaccs = async (uid) => {
    this.closeCon();
    window.FAKE_UID = uid;
    try {
      let ac = await server.getUaccs(uid);
      let taccounts = ac.data.accounts;
      this.setState({taccounts});
      app.accounts(taccounts);
      if(taccounts.length > 0) {
        this.closeCon();
        app.account(taccounts[0]["account_name"]);
        $(".page-header.balance").css({opacity: 0.3});
        this.setState({taccount: taccounts[0]["account_name"]});
      }
    } catch(e) {
      return e;
    }
  }

  setVlv = (e) => {
    let vlv = e.target.value;
    if(isNaN(vlv)) {
      return;
    }
    if(vlv < 0) {
      return;
    } else {
      this.setState({ vlv });
      this.calc_margin(vlv);
    }
    // if(vlv >= 0.01) {
    //   this.setState({ vlv });
    //   this.calc_margin(vlv);
    // }
  }

  upVlv = () => {
    let vlv = (parseFloat(this.state.vlv) + 0.01).toFixed(2);
    this.setVlv({target: {value: vlv}});
    this.calc_margin(vlv);
  }

  downVlv = () => {
    let vlv = (parseFloat(this.state.vlv) - 0.01).toFixed(2);
    this.setVlv({target: {value: vlv}});
    this.calc_margin(vlv);
  }

  calc_margin = (lots = null, conversion = null, raw = false) => {
    if(!this.state.buys_pair) {
      return false;
    }
    let volume = lots ? lots : this.state.vlv;
    let info = this.pairData(this.state.buys_pair);
    let base2 = String(this.baseData(info.pair)["quote"]);
    let type = info.type.toLowerCase();
    let market_price = (this.state.buy_mode == "buy" ? info.ask : info.bid);
    // console.log(volume, base2, type, info, market_price, "xxxxxxx");
    let { conversion_1, conversion_2 } = this.state;

    if(conversion) {
      conversion_1 = conversion.conversion_1;
      conversion_2 = conversion.conversion_2;
    }

    if(!(type == "forex" || type == "crypto")) {
      let vol     = volume;
      let lev     = parseFloat(info.leverage) || 1;
      let ppl     = parseFloat(info.unit_per_lot) || 10;
      let unit    = parseFloat(info.pip_size) || 0.01;
      let cntr    = parseFloat(info.contract) || 10;
      // console.log(vol, lev, ppl, unit, cntr, info);
      let pip_str = ppl * (vol / 0.1);
      let lot_str = unit * (vol / 0.1);
      let margin  = vol * cntr * market_price / lev;
      if(ppl > 0 && lev > 0 && cntr > 0) {} else { return false;}
      let ret     = {analysis: true, pip_str: pip_str.toFixed(2), lot_str: lot_str.toFixed(2), required_m: margin.toFixed(2)};
      if(raw) { return ret; }
      this.setState(ret);
    } else if(conversion_1 > 0 && conversion_2 > 0) {
      if(type == "forex") {
        let lev     = 1 / app.leverage(false);
        let pips    = conversion_2 * (base2.toUpperCase() == "JPY" ? 1000 : 10) * volume;
        let units   = volume * 100000;
        let margin  = conversion_1 * units * lev;
        let ret     = {pip_str: pips.toFixed(2), lot_str: units.toFixed(2), required_m: margin.toFixed(2)};
        if(raw) { return ret; }
        this.setState(ret);
      } else if(type == "crypto") {
        let lev     = parseInt(info.leverage);
        let dpl     = Number(info.dollar_per_lot);
        let pips    = volume * dpl;
        let upl     = String(info.unit_per_lot).trim()
        let units   = volume * (upl.length ? Number(upl) : dpl);
        let margin  = market_price * conversion_2 * units / lev;
        let ret     = {pip_str: pips.toFixed(2), lot_str: units.toFixed(2), required_m: margin.toFixed(2)};
        if(raw) { return ret; }
        this.setState(ret);
      }
    }

    if(raw) {
      return false;
    }
  }

  loadAnalysis = async (pd = null) => {
    if(this.state.hotStocks.length == 0) {
      setTimeout(() => {
        this.loadAnalysis(pd);
      }, 2000);
      return false;
    }
    this.setState({loadedPair: pd, stop: false, take: true, only: false, active: "take", required_m: 0, trade_when: 0, stl_val: 0, tkp_val: 0, vlv: '0.01', lot_str: "", pip_str: "", analysis: false, conversion_1: 0, conversion_2: 0});
    let info = pd ? pd : this.pairData(this.state.buys_pair);
    let type = info.type.toLowerCase();
    let data = this.baseData(info.pair);
    let rtyp = !(type == "forex" || type == "crypto");

    if(window.WebSocketPlugged) {
      //  && (type == "forex" || type == "crypto" || type == "stock")

      let pl = {user: (app.isAdmin() ? this.state.uid : app.id()), account: app.account(), pair: info.pair, type: type, base1: data.base1.trim(), base2: data.base2.trim(), delimeter: data.delimeter, base_cur: app.currency(true)};
      
      let base_cur             =  pl.base_cur || "USD";
      let delimeter            =  pl.delimeter && pl.delimeter.length ? pl.delimeter+base_cur : "";
      let crate_pair           =  rtyp ? pl.base2 : pl.base1+delimeter;
      let cpair_data           =  this.state.hotStocks.filter((pair) => pair.pair.toLowerCase().match(crate_pair.toLowerCase()));

      // let conversion_1         =  pl.base1 == base_cur ? 1 : (await scrape_indexes(`${payload.base1}${delimeter}`)).ask;
      // let conversion_2         =  pl.base2 == base_cur ? 1 : (await scrape_indexes(`${payload.base2}${parameter}`)).ask;

      if(cpair_data.length) {
        let ret = {
          analysis:     true,
          conversion_1: (rtyp ? 1 : cpair_data[0]["ask"]),
          conversion_2: (rtyp ? cpair_data[0]["ask"] : 1)
        };
        setTimeout(() => {
          this.setState(ret);
          this.calc_margin(this.state.vlv, ret);
        }, 1000);
        // alert("PLAN A");
      } else {
        if(type == "forex") {
          cpair_data =  this.state.hotStocks.filter((pair) => pair.pair.toLowerCase().match(info.pair.toLowerCase()));
          let ret = {
            analysis:     true,
            conversion_1: (rtyp ? 1 : cpair_data[0]["rqp"]),
            conversion_2: (rtyp ? cpair_data[0]["rqp"] : 1)
          };
          setTimeout(() => {
            this.setState(ret);
            this.calc_margin(this.state.vlv, ret);
          }, 1000);
          // alert("PLAN B");
        } else {
          // alert("PLAN C");
          window.WebSocketPlug.send(JSON.stringify({"event": "GET_CONVERSION", "payload": pl}));
        }
      }
    }
  }

  baseData = (pair) => {
    let base = pair.trim(), base1, base2;
    let delimeter = "";
    if(base.indexOf("/") > -1) {
      base  = base.split("/");
      base1 = base[0];
      base2 = base[1] || "USD";
      delimeter = "/";
    } else if(base.indexOf("-") > -1) {
      base  = base.split("-");
      base1 = base[0];
      base2 = base[1] || "USD";
      delimeter = "-";
    } else {
      base  = base;
      base1 = "USD";
      base2 = base;
      delimeter = "/";
    }

    return {
      pair: pair,
      base:  base1.trim(),
      quote: base2.trim(),
      base1: base1.trim(),
      base2: base2.trim(),
      delimeter
    };
  }

  socketInit = () => {
    let loadA = false;
    if(this.state.loadedPair) {
      this.loadAnalysis(this.state.loadedPair);
    }
    this.setState({selectedAccount: app.accountDetail()});
    window.WebSocketPlug.addEventListener('message', ({data}) => {
      try {

        let message = JSON.parse(`${data}`);
        let payload = message.payload;

        if(message.event == "PAIR_DATA") {
          if(!this.state.buys_pair) {
            if(payload.length) {
              this.setState({buys_pair: payload[0].pair});
              if(!loadA) {
                loadA = true;
                this.loadAnalysis(payload[0]);
              }
            }
          }
          let alt_payload = [];
          payload.forEach((p) => {
            BUY_HIST[p.pair] = BUY_HIST[p.pair] ? BUY_HIST[p.pair] : [];
            if(BUY_HIST[p.pair]) {
              let hino = Math.floor(Math.random() * 10) > 6;
              let price = randomIntFromInterval(0.1, hino ? 60000 : 10000);
              let perc = price >= 10000 ? 100 : ((price/10000) * 100).toFixed(3);
              if(BUY_HIST[p.pair].length >= 12) {
                if(!p.closed) {
                  BUY_HIST[p.pair] = BUY_HIST[p.pair].slice((BUY_HIST[p.pair].length - 12), BUY_HIST[p.pair].length);
                  BUY_HIST[p.pair].push({price, perc, rate: p.ask});
                }
              } else {
                for(let _j = 0; _j < 12; _j++) {
                  price = randomIntFromInterval(0.1, 10000);
                  perc = ((price/10000) * 100).toFixed(3);
                  BUY_HIST[p.pair].push({price, perc, rate: guessTimate(p.ask, true)});
                }
              }
            }
            SELL_HIST[p.pair] = SELL_HIST[p.pair] ? SELL_HIST[p.pair] : [];
            if(SELL_HIST[p.pair]) {
              let hino = Math.floor(Math.random() * 10) > 6;
              let price = randomIntFromInterval(0.1, hino ? 60000 : 10000);
              let perc = price >= 10000 ? 100 : ((price/10000) * 100).toFixed(3);
              if(SELL_HIST[p.pair].length >= 12) {
                if(!p.closed) {
                  SELL_HIST[p.pair] = SELL_HIST[p.pair].slice((SELL_HIST[p.pair].length - 12), SELL_HIST[p.pair].length);
                  SELL_HIST[p.pair].push({price, perc, rate: p.bid});
                }
              } else {
                for(let _i = 0; _i < 12; _i++) {
                  price = randomIntFromInterval(0.1, 10000);
                  perc = ((price/10000) * 100).toFixed(3);
                  SELL_HIST[p.pair].push({price, perc, rate: guessTimate(p.bid, true)});
                }
              }
            }
            
            if(Object.keys(window.CS_RATE).length) {
              // let spread = app.spread(p);
              // if(window.CS_RATE[p.pair]) {
              //   let crt = window.CS_RATE[p.pair];
              //   p.bid = app.fxRate(crt[0].toLowerCase() == "buy" ? parseFloat(crt[1]) - parseFloat(spread) : parseFloat(crt[1]));
              //   p.ask = app.fxRate(crt[0].toLowerCase() == "buy" ? parseFloat(crt[1]) : parseFloat(crt[1]) + parseFloat(spread));
              // }
            }
            
            alt_payload.push(p);
          });
          
          this.setState({ hotStocks: alt_payload, showLoader: false, loaded: true, BUY_HIST, SELL_HIST });
        }

        if(message.event == "TRADE_HISTORY") {
          // console.log(message, "...")
            if(message.user == (app.isAdmin() ? this.state.uid : app.id()) && message.account == app.account()) {
              this.setState({ all_trades: payload });
              this.populateTrades(payload);
            }
        }

        if(message.event == "GET_FAVOURITES" || message.favourites) {
          if(message.user == (app.isAdmin() ? this.state.uid : app.id()) && message.account == app.account()) {
            let favs = [], _unfav = false;
            message.favourites.forEach((fav) => {
              if(fav) {
                let fv = this.state.hotStocks.filter((pair) => pair.pair.toLowerCase().match(fav.toLowerCase()));
                favs[favs.length] = fv[0];
                if(this.state._favouritePairs.length) {
                  let _fv = [];
                  Object.values(this.state._favouritePairs).forEach((_pair) => {
                    if(fav.toLowerCase() == _pair.pair.toLowerCase()) {
                      _unfav = true;
                    }
                  });
                }
              }
            });
            setTimeout(() => {
              if(_unfav) this.setState({_favouritePairs: []});
              this.setState({ favourites: favs, favouritePairs: message.favourites });
            }, 10);
          }
        }
        
        if(message.event == "GET_CONVERSION") {
          let bsp = this.state.buys_pair;

          if(typeof bsp != "string") {
            bsp = "";
          }
          if(payload.user == (app.isAdmin() ? this.state.uid : app.id()) && payload.account == app.account() && payload.pair.toLowerCase().trim() == bsp.toLowerCase().trim()) {
            this.setState({
              analysis:     true,
              conversion_1: payload.conversion_1,
              conversion_2: payload.conversion_2
            });
            this.calc_margin(this.state.vlv, payload);
          }
        }

      } catch (e) {
        throw e;
      }
    });
  }

  populateTrades = (trades = false) => {
    let all_trades = trades ? trades : this.state.all_trades;
    let open_trades = [];
    let closed_trades = [];
    let pending_trades = [];
    let open_pl = 0;
    let margin = 0;
    let cs_rt = [];

    all_trades.forEach((trade, i) => {
      let rate = this.state.hotStocks.filter((pair) => {
        if(pair.pair) {
          return pair.pair.toLowerCase().match(trade.instrument.toLowerCase()) || trade.instrument.toLowerCase() == pair.pair.toLowerCase();
        }
      });
      if(rate.length) {
        let brate = app.floatFormat(rate ? rate[0].ask : 0);
        let srate = app.floatFormat(rate ? rate[0].bid : 0);

        trade.current_rate = trade.mode == "buy" ? brate : srate;
        trade.real_rate = trade.current_rate;
        trade.is_closed = rate[0].closed;
        
        if(trade.custom && parseInt(trade.order_status) < 2) {
          cs_rt[trade.instrument] = [trade.mode, trade.specific_rate];
        }
        if(parseInt(trade.order_status) == 0) {
          open_pl += Number(trade.profit);
          open_trades.push(trade);
        }
        if(parseInt(trade.order_status) < 2) {
          margin += Number(trade.required_margin);
        }
        if(parseInt(trade.order_status) == 1) {
          pending_trades.push(trade);
        }
        if(parseInt(trade.order_status) == 2) {
          closed_trades.push(trade);
        }
      }
    });

    window.CS_RATE = cs_rt;

    this.setState({
      open_trades:       open_trades,
      pending_trades:    pending_trades,
      closed_trades:     closed_trades,
      open_pl:           open_pl.toFixed(2),
      margin:            Number(margin).toFixed(2),
      fmargin:           open_trades.length ? Number(this.state.selectedAccount.balance).toFixed(2) : 0,
      real_balance:      app.real(this.state.selectedAccount.balance, margin),
      equity:            open_trades.length
                      ?  (Number(this.state.selectedAccount.balance) + Number(open_pl) + Number(margin)).toFixed(2)
                      :  0
    });
  }

  addToFav = async (pair) => {
    if(this.state.favouritePairs.length > 4) {
      this.setState({errorMessage: "Favourites|You cannot add more than 5 instrument(s)."});
    } else if(window.WebSocketPlugged) {
      // this.setState({showSpinner: true});
      window.WebSocketPlug.send(JSON.stringify({"event": "ADD_FAVOURITE", "payload": {
        pair:    pair,
        user:    (app.isAdmin() ? this.state.uid : app.id()),
        account: app.account(),
      }}));
    }
  }

  remFav = async (pair) => {
    if(window.WebSocketPlugged) {
      // this.setState({showSpinner: true});
      window.WebSocketPlug.send(JSON.stringify({"event": "REMOVE_FAVOURITE", "payload": {
        pair:    pair,
        user:    (app.isAdmin() ? this.state.uid : app.id()),
        account: app.account(),
      }}));
    }
  }

  fetchFavs = async () => {
    if(this.realTimeListener && window.WebSocketPlugged) {
      this.setState({showSpinner: true});
      window.WebSocketPlug.send(JSON.stringify({"event": "GET_FAVOURITES", "payload": {
        user:    (app.isAdmin() ? this.state.uid : app.id()),
        account: app.account(),
      }}));
      this.setState({showSpinner: false});
    }
  }

  handleNavClick = () => {
    this.setState({ showNav: !this.state.showNav });
  }

  handleAccountChange = (e) => {
    let val = e.target.value;
    app.account(e.target.value);
    this.setState({
      accounts: app.accounts(),
      selectedAccountVal: e.target.value,
      selectedAccount: app.accountDetail(),
      favouritePairs: [],
      favourites: [],
      all_trades: [],
      open_trades: [],
      closed_trades: [],
      pending_trades: [],
      open_pl: 0,
      equity: 0,
      margin: 0
    });
  }

  numberWithCommas = (x) => {
    return app.coma(x);
    // return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  showPrice = (p) => {
    let cl, pr;
    if(p > 0) {
      pr = app.currency();
      cl = 'pl profit';
    } else if(p < 0) {
      pr = '-'+app.currency();
      cl = 'pl loss';
    } else {
      pr = app.currency();
      cl = '';
    }
    let price = p < 0 ? -1 * Number(p) : Number(p);
    return (
      <b className={cl}>{pr+this.numberWithCommas(price.toFixed(2))}</b>
    )
  }

  pairData = (p) => {
    if(p) {
      let r = this.state.hotStocks.filter((pd) => pd.pair == p);
      return r.length ? r[0] : this.state.hotStocks[0];
    } else {
      return this.state.hotStocks[0];
    }
  }
  
  autoTr = async (i = 0) => {
    let uid = app.profile().user_id;
    let start = new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone});
    if(app.isAdmin()) {
      uid = this.state.uid;
    }
    this.setState({showSpinner: true, start});
    const atr = await server.updateUserDetails(uid, {auto_trader: i > 0 ? start : ""});
    const gp = await server.getProfile();
    app.profile(gp.data.profile);
    this.setState({showSpinner: false});
  }

  placeOrder = async (ret = false) => {
    if(!app.isAdmin() && !app.isVerified()) {
      if(Number(app.data("byp")) == 0) {
        return this.setState({showCallBack: true, message: "ACCOUNT NOT VERIFIED|Please contact support to verify your documents."});
      }
    }
    if(app.frozen()) {
      return this.setState({showCallBack: true, message: "An error occured|Cannot place order please contact our support team."});
    }
    this.calc_margin();
    setTimeout(async () => {
      let bs    = this.state.buy_mode ? "buy" : "sell";
      let state = this.state;
      let info  = this.pairData(this.state.buys_pair);
      let crate = app.floatFormat(this.state.buy_mode ? info.ask : info.bid);
      let twhen = Number($("#trade_when_"+bs).val().trim());
      let order = {
          "mode"            : bs,
          "type"            : info.type,
          "instrument"      : info.pair,
          "rate"            : crate,
          "unit_pd"         : info.unit_per_lot,
          "auto_tr"         : app.isAdmin() ? Number(state.autoTr) : 0,
          "pip"             : state.pip_str,
          "lots"            : state.lot_str,
          "volume"          : state.vlv,
          "margin"          : state.required_m,
          "commission"      : Number((Number(info.commission) / 100) * state.required_m),
          "stop_loss"       : state.stop ? Number($("#stl_val_"+bs).val().trim()) : "",
          "take_profit"     : state.take ? Number($("#tkp_val_"+bs).val().trim()) : "",
          "trade_when"      : state.only ? (twhen == crate ? "" : twhen)  : "",
          "time"            : new Date().toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
        };
      if(app.isAdmin()) {
        order.uid = state.uid;
        order.acc = app.account();
      }
      if(ret) {
        this.setState({confirmOrder: true, confirMsg: order.mode.toUpperCase()+' '+order.volume+' volume of '+info.pair+' for '+app.currency()+order.margin});
      } else {
        if(!app.isAdmin() && app.daysToGo(true) < 0) {
          return this.setState({showCallBack: true, message: "An error occured|Cannot place order please contact our support team."});
        }
        this.setState({showSpinner: true, analysis: false});
        try {
          const place_order = await server.placeOrder(order, app.isAdmin());
          if(place_order.status == 200) {
            let stxt = "Your have successfully placed a "+bs+" order for "+info.pair;
            this.setState({showSufficient: true, btab: "order", message: stxt, orderCallback: true, orderText: stxt});
            if(app.isAdmin()) {
              this.refBal();
            } else {
              const gp = await server.getProfile();
              app.profile(gp.data.profile);
              $(".ndfxbal").trigger("_refresh");
            }
            this.loadAnalysis();
          } else {
            this.setState({showInssufficient: true, message: place_order.data.message});
          }
          this.setState({showSpinner: false, analysis: true});
          if(app.isAdmin()) {
            this.getUaccs(state.uid);
          }
        } catch (e) {
          this.setState({showSpinner: false, analysis: true});
          return e;
        }
      }
    }, 50);
  }

  crate = (r, id, type) => {
    if(type == "buy") {
      if(window.PREVIOUS_BUY_RATE[id]) {
        let up = parseFloat(r) > parseFloat(window.PREVIOUS_BUY_RATE[id]);
        let same = parseFloat(r) == parseFloat(window.PREVIOUS_BUY_RATE[id]);
        window.PREVIOUS_BUY_RATE[id] = r;
        window.PREVIOUS_BUY_ARROW[id] = same ? window.PREVIOUS_BUY_ARROW[id] : (up ? auw : adw);
        return (
          <>
            <span className="t">{app.l("BUY")}</span>
            <span className="r">{app.floatFormat(r)}</span>
            <img src={window.PREVIOUS_BUY_ARROW[id]} />
          </>
        );
      } else {
        window.PREVIOUS_BUY_RATE[id] = r;
        return (
          <>
            <span className="t">{app.l("BUY")}</span>
            <span className="r">{app.floatFormat(r)}</span>
            <img src={auw} />
          </>
        );
      }
    } else {
      if(window.PREVIOUS_SELL_RATE[id]) {
        let up = parseFloat(r) > parseFloat(window.PREVIOUS_SELL_RATE[id]);
        let same = parseFloat(r) == parseFloat(window.PREVIOUS_SELL_RATE[id]);
        window.PREVIOUS_SELL_RATE[id] = r;
        window.PREVIOUS_SELL_ARROW[id] = same ? window.PREVIOUS_SELL_ARROW[id] : (up ? auw : adw);
        return (
          <>
            <span className="t">{app.l("SELL")}</span>
            <span className="r">{app.floatFormat(r)}</span>
            <img src={window.PREVIOUS_SELL_ARROW[id]} />
          </>
        );
      } else {
        window.PREVIOUS_SELL_RATE[id] = r;
        return (
          <>
            <span className="t">{app.l("SELL")}</span>
            <span className="r">{app.floatFormat(r)}</span>
            <img src={adw} />
          </>
        );
      }
    }
  }

  render () {
    let { buy_mode, btab, stop, pow, start, autoTr, autoTrader, devMode, take, only, isAdmin, head, showCallBack, clist, users, uidSearchDrop, uid, uid_filter, taccount, taccounts, BUY_HIST, SELL_HIST, message, errorMessage, active, analysis, vlv, trade_when, pip_str, lot_str, stl_val, tkp_val, buys_pair, filter, required_m, conversion_1, hotStocks, loaded, all_trades, favouritePairs, _favouritePairs, showSpinner, fmargin, equity, margin, open_pl, real_balance, showInssufficient, showSufficient, confirmOrder, confirMsg, orderCallback, orderText } = this.state;
    let altBalance = app.real(this.state.selectedAccount.balance, this.state.selectedAccount.real_balance);
    let altCredit  = Number(this.state.selectedAccount.credit);
        buys_pair  = hotStocks.length ? this.pairData(buys_pair) : false;
    let pair_data  = hotStocks;
    let crate      = buys_pair ? app.floatFormat(buy_mode ? buys_pair.ask : buys_pair.bid) : "";

    let can_trade = true;

    if(filter.length) {
      filter = filter.toLowerCase();
      pair_data = hotStocks.length ? hotStocks.filter((h) => h.pair.toLowerCase().match(filter) || h.name.toLowerCase().match(filter) || h.type.toLowerCase().match(filter)) : [];
    }
    
    if(uid_filter.length) {
      users = users.filter((c) => {
        return (
          c.first_name.toLowerCase().match(uid_filter.toLowerCase()) ||
          c.last_name.toLowerCase().match(uid_filter.toLowerCase()) ||
          c.email.toLowerCase().match(uid_filter.toLowerCase()) ||
          c.phone_number.toLowerCase().match(uid_filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(uid_filter.toLowerCase()) ||
          (c.first_name + " " + c.last_name).toLowerCase().match(uid_filter.toLowerCase()) ||
          (c.last_name + " " + c.first_name).toLowerCase().match(uid_filter.toLowerCase())
        );
      });
    }

    if(buys_pair) {
      can_trade = (buys_pair.type.toLowerCase() === 'forex' || buys_pair.type.toLowerCase() === 'crypto' || parseInt(buys_pair.complete) === 1) && !buys_pair.closed;
    }

    if(buys_pair && analysis) {
      if(crate != window.LAST_CRATE) {
        let alt_data        = this.calc_margin(null, null, true);
        if(alt_data) {
          pip_str           = alt_data.pip_str;
          lot_str           = alt_data.lot_str;
          required_m        = alt_data.required_m;
        } window.LAST_CRATE = crate;
      }
    }

    let tkp, stl, etkp = 0, estl = 0, etwn = 0;
    if(buys_pair) {
      tkp = (108 / 100) * Number(crate);
      stl = (91 / 100) * Number(crate);

      tkp = Number(String(tkp).substr(0, String(crate).length));
      stl = Number(String(stl).substr(0, String(crate).length));

      if(tkp_val > 0) {
        tkp = tkp_val;
        stl = stl_val;
      } else {
        if(!buy_mode) {
          let _ = tkp; tkp = stl; stl = _;
        }
      }
    }
    
    if(buys_pair && required_m > 0) {
      if(buys_pair.type.toLowerCase() == "forex") {
        etkp = parseFloat(((Number(lot_str) * tkp / crate) - lot_str) * conversion_1).toFixed(2);
        estl = parseFloat(((Number(lot_str) * stl / crate) - lot_str) * conversion_1).toFixed(2);
        etwn = parseFloat(((Number(lot_str) * (trade_when > 0 ? trade_when : crate) / crate) - lot_str) * conversion_1).toFixed(2);
      } else {
        etkp = parseFloat((((crate * Number(lot_str) * tkp) / crate)) - (crate * Number(lot_str))).toFixed(2);
        estl = parseFloat((((crate * Number(lot_str) * stl) / crate)) - (crate * Number(lot_str))).toFixed(2);
        etwn = parseFloat((((crate * Number(lot_str) * (trade_when > 0 ? trade_when : crate)) / crate)) - (crate * Number(lot_str))).toFixed(2);
      }

      if(!buy_mode) {
        etkp = parseFloat(etkp * -1).toFixed(2);
        estl = parseFloat(estl * -1).toFixed(2);
        etwn = parseFloat(etwn * -1).toFixed(2);
      }

      let dol = ""; // "$";
      etkp = (etkp > 0 || etkp == 0.00 ? '' : '-')+dol+(etkp < 0 ? etkp * -1 : etkp);
      estl = (estl > 0 || estl == 0.00 ? '' : '-')+dol+(estl < 0 ? estl * -1 : estl);
      etwn = (etwn > 0 || etwn == 0.00 ? '' : '-')+dol+(etwn < 0 ? etwn * -1 : etwn);
      if(trade_when === 0) {
        etwn = 0.00;
      }
    }

    if(users != _users) {
      if(users.toString() != _users.toString()) {
        _users = users;
        _udkey = "gr-dropdown-for-admin-"+Math.random()+"-"+Math.random();
        console.log("Changed u", _udkey);
      }
    }

    if(taccounts != _taccounts) {
      if(taccounts.toString() != _taccounts.toString()) {
        _taccounts = taccounts;
        _acckeys = "t-accs-for-admin-"+Math.random()+"-"+Math.random();
      }
    }

    const FavList = () => {
      return (
        <ul className="favourite-list">
          {
            favouritePairs.length == 0 ? <li><i style={{color: "#666D8F"}}><small style={{color: "#666D8F"}}>No favourite instrument</small></i></li> : favouritePairs.map((fv, fk) => {
              fv = this.pairData(fv);
              if(fv) {
                return (
                  <li key={"fvk-"+fk}>
                    <img src={fstar} alt="" onClick={() => this.remFav(fv.pair)} />
                    <span onClick={() => {
                      this.setState({clist: false, buy_mode: true, btab: "bsell", buys_pair: fv.pair});
                      $("#market-menu").animate({ scrollTop: 0 }, "slow");
                      setTimeout(() => {
                        $(window).trigger("resetBuySellpair");
                        this.loadAnalysis();
                      }, 0);
                    }}>{fv.pair}</span>
                    <small className={Number(fv._change.replace("%", "")) > 0 ? "green" : "red"} onClick={() => {
                      this.setState({clist: false, buy_mode: true, btab: "bsell", buys_pair: fv.pair});
                      $("#market-menu").animate({ scrollTop: 0 }, "slow");
                      setTimeout(() => {
                        $(window).trigger("resetBuySellpair");
                        this.loadAnalysis();
                      }, 0);
                    }}>({fv._change})</small>
                  </li>
                )
              }
            })
          }
        </ul>
      )
    };

    const Ilist = (p = null) => {
      return pair_data.map((pair, k) => {
        let kiy = "bsacc-"+k;
        let fvv = favouritePairs.length && Object.values(favouritePairs).indexOf(pair.pair) > -1 || _favouritePairs.length && Object.values(_favouritePairs).indexOf(pair.pair) > -1;
        let cant_trade = !((pair.type.toLowerCase() === 'forex' || pair.type.toLowerCase() === 'crypto' || parseInt(pair.complete) === 1) && !pair.closed);
        return p == null || (p == pair.pair) ? (
          <li key={kiy} disabled={cant_trade} className={pair.closed ? 'p_closed' : ''} style={p == null ? null : {border: "0px solid transparent"}}>
            {p != null ? null :
              <div className="i-top">
                <span className="i-name" onClick={() => {
                  this.setState({clist: false, buy_mode: true, btab: "bsell", buys_pair: pair.pair});
                  $("#market-menu").animate({ scrollTop: 0 }, "slow");
                  setTimeout(() => {
                    $(window).trigger("resetBuySellpair");
                    this.loadAnalysis();
                  }, 0);
                }}>{pair.pair}</span>
                <span className="ic"><img src={fvv ? fstar : star} onClick={() => fvv ? this.remFav(pair.pair) : this.addToFav(pair.pair)} /></span>
              </div>}
              <div className="i-act">
                <button className="btn sell-act" onClick={() => {
                  this.setState({clist: false, buy_mode: false, btab: "bsell", buys_pair: pair.pair});
                  $("#market-menu").animate({ scrollTop: 0 }, "slow");
                  setTimeout(() => {
                    $(window).trigger("resetBuySellpair");
                    this.loadAnalysis();
                  }, 0);
                }}>
                    {this.crate(Number(pair.bid), "pair-"+pair.id+"-"+pair.pair, "sell")}
                </button>
                <span className="ait-act"><img src={ait} /></span>
                <button className="btn buy-act" onClick={() => {
                  this.setState({clist: false, buy_mode: true, btab: "bsell", buys_pair: pair.pair});
                  $("#market-menu").animate({ scrollTop: 0 }, "slow");
                  setTimeout(() => {
                    $(window).trigger("resetBuySellpair");
                    this.loadAnalysis();
                  }, 0);
                }}>
                    {this.crate(Number(pair.ask), "pair-"+pair.id+"-"+pair.pair, "buy")}
                </button>
              </div>
              <div className="i-down">
                  <span>L : {app.floatFormat(pair.low)}</span>
                  <span>S : {app.spread(pair)}</span>
                  <span>H : {app.floatFormat(pair.high)}</span>
              </div>
          </li>
        ) : null}
      );
    }

    const OrderBook = (mobile = false) => {
      return mobile ? (
        <div className={"order-book alt imobile"+(this.state.autoTrader ? " fhi" : "")}>
          <h5>{app.l("Order Book")}</h5>
          <div className="ob">
            {buys_pair && BUY_HIST && SELL_HIST ? <>
            <ul>
              <li><span className="rt-0">{buys_pair.pair}</span><span className="rt-0">Rate</span></li>
              {SELL_HIST[buys_pair.pair].map((sh, sk) => {
              return sk < 5 ? (<li><span className="pr">{app.currency()}{app.coma(Number(sh.price).toFixed(2))}</span><span className="rt-1">{app.floatFormat(sh.rate)}</span><div className="perc" style={{width: sh.perc+"%"}}></div></li>) : null
            })}</ul>
            <ul style={{borderBottom: "0px"}}>
              <li><span className="rt-0">{app.spread(buys_pair)}</span><span className="rt-0">Spread</span></li>
              {BUY_HIST[buys_pair.pair].map((bh, bk) => {
              return bk < 5 ? (<li><span className="pr">{app.currency()}{app.coma(Number(bh.price).toFixed(2))}</span><span className="rt-2">{app.floatFormat(bh.rate)}</span><div className="perc alt" style={{width: bh.perc+"%"}}></div></li>) : null
            })}</ul>
            </> : null}
          </div>
        </div>
      ) : (
        <div className="order-book not-imobile">
          <h5>{app.l("Order Book")}</h5>
          {buys_pair && BUY_HIST && SELL_HIST ? <>
          <ul>
            <li><span className="rt-0">{buys_pair.pair}</span><span className="rt-0">Rate</span></li>
              {SELL_HIST[buys_pair.pair].map((sh) => {
              return (<li><span className="pr">{app.currency()}{app.coma(sh.price)}</span><span className="rt-1">{app.floatFormat(sh.rate)}</span><div className="perc" style={{width: sh.perc+"%"}}></div></li>)
            })}</ul>
            <h6><span>{app.spread(buys_pair)}</span><span>Spread</span></h6>
            <ul style={{borderBottom: "0px"}}>
            {BUY_HIST[buys_pair.pair].map((bh) => {
              return (<li><span className="pr">{app.currency()}{app.coma(bh.price)}</span><span className="rt-2">{app.floatFormat(bh.rate)}</span><div className="perc alt" style={{width: bh.perc+"%"}}></div></li>)
            })}</ul>
          </> : null}
        </div>
      );
    };

    const _autoTrader = () => {
      return (
        <div className="full-auto">
          <button className={"autobt sh"} onClick={() => {
            if(pow) {
              this.autoTr(0);
              this.setState({
                autoTrader: !autoTrader,
                pow: !pow
              });
            } else {
              this.autoTr(1);
              this.setState({
                pow: !pow
              });
            }
          }}>
            <svg version="1.0" width="300.000000pt" height="300.000000pt" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={pow ? "#1FCF65" : "#ffffff"} stroke="none">
                  <path d="M1409 2972 c-43 -22 -59 -38 -81 -81 l-28 -53 0 -497 c0 -461 2 -499
                  19 -536 22 -48 54 -79 103 -100 74 -30 173 -10 225 46 51 56 53 75 53 588 0
                  279 -4 490 -10 510 -16 56 -52 100 -104 126 -65 32 -111 32 -177 -3z"/>
                  <path d="M2185 2440 c-111 -26 -177 -126 -153 -232 12 -58 26 -76 127 -177
                  194 -191 290 -415 291 -674 0 -266 -92 -491 -279 -678 -126 -125 -263 -205
                  -434 -252 -118 -32 -356 -32 -474 0 -542 148 -841 709 -661 1238 49 141 118
                  247 244 372 51 51 100 105 107 120 67 133 -26 282 -176 283 -75 0 -111 -22
                  -226 -137 -245 -247 -376 -537 -397 -879 -40 -660 418 -1254 1076 -1395 141
                  -30 393 -33 520 -5 191 41 353 108 510 211 114 75 284 249 368 378 296 455
                  294 1029 -5 1482 -81 122 -270 316 -323 333 -57 17 -83 20 -115 12z"/>
              </g>
            </svg>
            <label>{pow ? "Stop" : "Start"} Auto Trader</label>
          </button>
          {pow ?
            <Moment date={start} format="hh:mm:ss" durationFromNow interval={60} className="blink" />
          : <time>00:00:00</time>}
        </div>
      )
    }

    return (
        <Container>
          <Spinner showSpinner={showSpinner} />
          {showSufficient ? (
            <BsConfirmationModal
              imageUrl={con_buysell}
              text={message}
              cancelClick={() => this.setState({showSufficient: false})}
            />
          ) : null}

          <ConfirmModal
            head={"Place Order?"}
            text={confirMsg}
            show={confirmOrder}
            cancel={() => this.setState({confirmOrder: false})}
            confirm={() => { this.setState({confirmOrder: false}); this.placeOrder(false); }}
          />

          <MsgAlert message={errorMessage} cancel={() => this.setState({errorMessage: ''})} />
          <Insufficient message={message} show={showInssufficient} cancel={(e) => this.setState({showInssufficient: false})} />
          <CallBack show={orderCallback} cancel={(e) => this.setState({orderCallback: false})} head={"Order placed successfully!"} text={orderText} />
          {showCallBack ? <MsgAlert cancel={(e) => this.setState({showCallBack: false})} message={message} /> : null}

            <div className={"trade-page "+btab}>
                <div id="trade-body">
                  <div className={"page-header ndfxbal balance"+(app.isAdmin() ? " isa" : "")}>
                    {btab == "wallet" ?
                      <>
                        <ul className="fcl">
                          <li title="Free Margin + Closed trades P/L"><img src={bali} alt="" /><span>{app.l("Balance")}</span><b>{this.showPrice(altBalance)}</b></li>
                          <li title="Credit"><img src={crei} alt="" /><span>{app.l("Credit")}</span>{this.showPrice(isNaN(altCredit) ? 0 : altCredit)}</li>
                        </ul>
                        <ul className="fcd">
                          <li title="Open trades P/L"><span><img src={opl} alt="" />{app.l("Open P/L")}</span>{this.showPrice(open_pl)}</li>
                          <li title="Balance + Open trades P/L"><span><img src={eqt} alt="" />{app.l("Equity")}</span>{this.showPrice(equity)}</li>
                          <li title="Balance - Free margin"><span><img src={mg} alt="" />{app.l("Used Margin")}</span>{this.showPrice(margin)}</li>
                          <li title="Balance - Used margin"><span><img src={mg} alt="" />{app.l("Free Margin")}</span>{this.showPrice(fmargin)}</li>
                          <li title="(Equity/Used Margin) X 100"><span><img src={ml} alt="" />{app.l("M. Level")}</span><b>{equity > 0 && margin > 0 ? ((equity / margin) * 100).toFixed(2)+"%" : "0%"}</b></li>
                        </ul>
                      </> : <ul>
                          <li title="Free Margin + Closed trades P/L"><span>{app.l("Balance")}</span><b>{this.showPrice(altBalance)}</b></li>
                          <li title="Credit"><span>{app.l("Credit")}</span>{this.showPrice(isNaN(altCredit) ? 0 : altCredit)}</li>
                          <li title="Open trades P/L"><span>{app.l("Open P/L")}</span>{this.showPrice(open_pl)}</li>
                          <li title="Balance + Open trades P/L"><span>{app.l("Equity")}</span>{this.showPrice(equity)}</li>
                          <li title="Balance - Free margin"><span>{app.l("Used Margin")}</span>{this.showPrice(margin)}</li>
                          <li title="Balance - Used margin"><span>{app.l("Free Margin")}</span>{this.showPrice(fmargin)}</li>
                          <li title="(Equity/Used Margin) X 100"><span>{app.l("M. Level")}</span><b>{equity > 0 && margin > 0 ? ((equity / margin) * 100).toFixed(2)+"%" : "0%"}</b></li>
                      </ul>}
                      {app.isAdmin() ?
                      <div className="tu-filter">
                      <div className="form-group isg">
                        <input
                          className="form-control"
                          placeholder="Search"
                          id="uidSearchDrop"
                          type="text"
                          autoComplete="off"
                          onKeyUp={(e) => { this.setState({uidSearchDrop: true, uid_filter: e.target.value}); }}
                          onBlur={(e) => { setTimeout(() => this.setState({uidSearchDrop: false}), 500); }}
                          onFocus={(e) => { this.setState({uidSearchDrop: true}) }}
                        />
                        {uid_filter.length
                        ? <img src={cancel} onClick={() => {this.setState({uid_filter: ""});$("#uidSearchDrop").val("");$("#uidSearchDrop").focus();}} className="si" alt="" style={{opacity: 0.5}} />
                        : <img src={SearchIcon} className="si" alt="" />}
                        <React.Fragment>
                          <div className={"gr-dropdown accs"+(_users.length > 10 ? " _big" : "")+(uidSearchDrop ? " _active" : "")} id={_udkey} key={_udkey}>
                          {
                            _users.map((u) => (
                              <span className="cgt" id={`${u.user_id}-${_udkey}`} key={`${u.user_id}-${_udkey}`} onClick={() => {
                                let vl = (u.first_name+" "+u.last_name).ucwords();
                                this.setState({uid_filter: vl, uid: u.user_id, uidSearchDrop: false, taccounts: [], autoTr: (u.auto_trader?.length) ? true : false});
                                $("#uidSearchDrop").val(vl);
                                this.getUaccs(u.user_id);
                              }}
                              style={{color: u.leads == "0" ? "#fff" : "#c3c3c3", opacity: u.leads == "0" ? "1" : "0.8"}}
                              >{(u.first_name+" "+u.last_name).ucwords()}</span>
                            ))
                          }
                        </div></React.Fragment>
                      </div>
                      
                      <select
                        className="t-accs"
                        id={_acckeys}
                        key={_acckeys}
                        onChange={(e) => {
                          this.closeCon();
                          window.FAKE_UID = uid;
                          app.account(e.target.value);
                          $(".page-header.balance").css({opacity: 0.3});
                          this.setState({taccount: e.target.value});
                        }}
                        // style={{opacity: _taccounts.length ? 1 : 0.3}}
                        value={taccount}>
                        {_taccounts.length ? null : <option>Select trading account</option>}
                        {
                          _taccounts.map(({account_name, account_label, balance, real_balance}, ack) => {
                            return (
                              <option key={`${account_name+'-'+_acckeys}`} id={`${account_name+'-'+_acckeys}`} value={account_name}>{(account_label.length ? account_label : account_name.ucwords())+" ("+app.currency()+app.real(balance, real_balance)+")"}</option>
                            )
                          })
                        }
                    </select>
                    </div>: null}
                    </div>
                    <div className={"scrollable"+(app.isAdmin() && uidSearchDrop ? " sblur" : "")}>
                      <div className="scrollable-1">
                        {app.isAdmin() ? null : favouritePairs.length ?
                          <div className="favourites not-imobile">
                            <h4>{app.l("Favorites")}</h4>{FavList()}
                          </div> : <div className="favourites not-imobile" style={{marginTop: "54px"}}></div>}
                          <Chart hotStocks={hotStocks} pair={buys_pair} onPairChange={(p) => {
                            this.setState({buys_pair: p});
                            setTimeout(() => {
                              this.loadAnalysis();
                            }, 0);
                          }} auto={pow} />
                          <ul className="i-list imobile">
                            {Ilist(buys_pair.pair)}
                          </ul>
                          {OrderBook()}
                        </div>
                        <TradeHistory trades={all_trades} loaded={loaded} favs={favouritePairs.length} />
                    </div>
                </div>
                {btab == "order" ? <TradeHistory trades={all_trades} loaded={loaded} favs={favouritePairs.length} mobile={true} /> : null}
                <div id="market-menu" className={app.isAdmin() && uidSearchDrop ? " sblur" : ""}>
                    {buys_pair ?
                    <div className="bs-section">
                      <div className={"full"+(autoTrader ? " ac" : "")} style={Number(app.data("mat")) > 0 && autoTrader ? {height: "100%"} : null}>
                        {Number(app.data("mat")) > 0 && !app.isAdmin() ?
                        <ul className={"trader"+(autoTrader ? " ac" : "")}>
                          <li className="manual" onClick={() => { this.setState({autoTrader: false, pow: false}); }}>Manual Trader</li>
                          <li className="auto" onClick={() => { this.setState({autoTrader: true, pow: false}); }}>Auto Trader</li>
                          <span className={"aspan"+(autoTrader ? " auto" : "")}>{' '}</span>
                        </ul> : Number(app.data("mat")) > 0 && app.isAdmin() ? 
                        <p className='graftype-alt'>
                          <span style={{color: autoTr ? "#FFA500" : "#1FCF65"}}><b style={{position: "relative", top: 1, fontWeight: "normal"}}>{autoTr ? 'Auto' : 'Manual'} Trader:</b> </span>
                          <label className="switch-box" htmlFor="switch-box-001">
                            <input type="checkbox" className="switch-check" id="switch-box-001" checked={autoTr} onChange={(e) => {
                              this.setState({autoTr: !autoTr});
                            }} />
                            <span className={"slider round"+(autoTr ? '' : ' man')}></span>
                          </label>
                        </p>: null}
                        {autoTrader ? _autoTrader() : <>
                        <h3 className="i-name">{buys_pair.pair}</h3>
                        <div className="row irow">
                            <label onClick={() => this.setState({buy_mode: true})} className={"col ibuy"+(buy_mode ? " active" : "")}>{app.l("Buy")}</label>
                            <label onClick={() => this.setState({buy_mode: false})} className={"col isell"+(buy_mode ? "" : " active")}>{app.l("Sell")}</label>
                        </div></>}
                        {autoTrader ? null :
                        <div className={"block"+(Number(app.data("mat")) > 0 ? " matb" : "")}>
                            <ul>
                                <li><span>{app.l("Current rate")}:</span> <span className="alt" style={{fontWeight: "700", color: buy_mode ? "#1FCF65" : "#FF2E53"}}>{buy_mode ? buys_pair.ask : buys_pair.bid}</span></li>
                                <li><span>{app.l("Spread")}:</span> <span className="alt">{app.spread(buys_pair)}</span></li>
                                <li><span>{app.l("Change")}:</span> <span className="alt" style={{color: Number(buys_pair._change?.split("%")[0]) > 0 ? "#1FCF65" : "#FF2E53"}}>{buys_pair._change}</span></li>
                            </ul>
                            <label className="vll">{app.l("Volume (lots)")}</label>
                            <div style={{display: "flex", position: "relative", width: "100%"}}>
                              <input className="vli" type="number" value={vlv > 0 || vlv == 0 ? vlv : ''} onChange={this.setVlv} />
                              <img src={uvlv} alt="" className="ivlv u" onClick={this.upVlv} />
                              <img src={dvlv} alt="" className="ivlv d" onClick={this.downVlv} />
                            </div>
                            <ul style={{marginTop: "15px"}}>
                                <li><span>{app.l("Pip Value")}:</span> <span className="alt">{app.coma(pip_str)} {app.currency()}</span></li>
                                <li><span>{vlv} {app.l("lot"+(vlv > 1 ? 's' : ''))}:</span> <span className="alt">{app.coma(lot_str)} {buys_pair.type.toLowerCase() == "forex" ? this.baseData(buys_pair.pair)["quote"] : "units"}</span></li>
                                <li><span>{app.l("Required Margin")}:</span> <span className="alt">{app.coma(required_m)} {app.currency(true)}</span></li>
                                <li><span>{app.l("Commission")}:</span> <span className="alt">{app.coma(app.perc(Number(buys_pair.commission), required_m).toFixed(2))} {app.currency(true)}</span></li>
                            </ul>
                            <div className="form-group aamt">
                                <button type="button" onClick={() => this.setState({stop: !stop, active: !stop ? "stop" : (take ? "take" : "only")})} className={"btn auto-amt"+(stop ? " active" : "")+(buy_mode ? " _by" : " _sel")}><img src={stop ? checkP : closeP} alt='' className='cpc' />{app.l("Stop Loss")}</button>
                                <button type="button" onClick={() => this.setState({take: !take, active: !take ? "take" : (stop ? "stop" : "only")})} className={"btn auto-amt"+(take ? " active" : "")+(buy_mode ? " _by" : " _sel")}><img src={take ? checkP : closeP} alt='' className='cpc' />{app.l("Take Profit")}</button>
                                <button type="button" onClick={() => this.setState({only: !only, active: !only ? "only" : (take ? "take" : "stop")})} className={"btn auto-amt"+(only ? " active" : "")+(buy_mode ? " _by" : " _sel")}><img src={only ? checkP : closeP} alt='' className='cpc' />{app.l("Only "+(buy_mode ? "Buy" : "Sell"))}</button>
                            </div>
                            <form align="left" className="dform">
                                <div className={"form-group"+(active == "stop" ? "" : " hide")}>
                                    <label>{app.l("Estimated Rate")}</label>
                                    <input type="number" className="form-control stop" id={"stl_val_"+(buy_mode ? "buy" : "sell")} value={stl} onChange={(e) => this.setState({stl_val: e.target.value})} onFocus={(e) => this.setState({stl_val: e.target.value})} />
                                    <span className="grl">{app.l("Stop Loss", true)}</span>
                                </div>
                                <div className={"form-group"+(active == "stop" ? "" : " hide")}>
                                    <label>{app.l("Estimated Price")}</label>
                                    <input type="text" className="form-control stop-price" value={estl+"0 "+app.currency(true)} readOnly />
                                    <span className="grl">{app.l("Amount", true)}</span>
                                </div>
                                <div className={"form-group"+(active == "take" ? "" : " hide")}>
                                    <label>{app.l("Estimated Rate")}</label>
                                    <input type="number" className="form-control take" id={"tkp_val_"+(buy_mode ? "buy" : "sell")} value={tkp} onChange={(e) => this.setState({tkp_val: e.target.value})} onFocus={(e) => this.setState({tkp_val: e.target.value})} />
                                    <span className="grl">{app.l("Take Profit", true)}</span>
                                </div>
                                <div className={"form-group"+(active == "take" ? "" : " hide")}>
                                    <label>{app.l("Estimated Price")}</label>
                                    <input type="text" className="form-control take-price" value={etkp+" "+app.currency(true)} readOnly />
                                    <span className="grl">{app.l("Amount", true)}</span>
                                </div>
                                <div className={"form-group"+(active == "only" ? "" : " hide")}>
                                    <label>{app.l("Estimated Rate")}</label>
                                    <input type="number" className="form-control only" id={"trade_when_"+(buy_mode ? "buy" : "sell")} value={trade_when > 0 ? trade_when : crate} onChange={(e) => this.setState({trade_when: e.target.value})} onFocus={(e) => this.setState({trade_when: e.target.value})} />
                                    <span className="grl">{buy_mode ? "BUY" : "SELL"} WHEN</span>
                                </div>
                                <div className={"form-group"+(active == "only" ? "" : " hide")}>
                                    <label>{app.l("Estimated Price")}</label>
                                    <input type="text" className="form-control only-price" value={etwn+" "+app.currency(true)} readOnly />
                                    <span className="grl">{app.l("Amount", true)}</span>
                                </div>
                            </form>
                            {
                              can_trade
                              ? <button className={"btn btn-warning btn-lg btn-block app-btn "+(buy_mode ? "buy" : "sell")+"-btn"} disabled={!analysis || !(vlv>0)} onClick={() => this.placeOrder(true)} style={{opacity: analysis ? 1 : 0.5}}>{app.l(buy_mode ? "Buy" : "Sell")}</button>
                              : <button className={"btn btn-warning btn-lg btn-block app-btn "+(buy_mode ? "buy" : "sell")+"-btn mkic"}>{app.l("Market is closed")}</button>
                            }
                        </div>}
                        {btab == "bsell" ? OrderBook(true) : null}
                      </div>
                    </div>
                    : null}
                    
                    {btab == "market" ? <div className="favourites imobile">
                      <h4>{app.l("Favorites")}</h4>{FavList()}
                    </div> : null}
                    {hotStocks.length ?
                    <div className="form-group search-g">
                        <input type="text" className="form-control" placeholder="Search Instrument" onChange={(e) => this.setState({filter: e.target.value})} />
                        <img src={search} />
                    </div> :
                    <div className='loader-container' style={{ display : 'block' }}>
                        <div className='loader'></div>
                    </div>}
                    <ul className="i-list">
                      {Ilist()}
                    </ul>
                
                </div>
            </div>
            {btab == "wallet" ?
            <div className="widep">
              <Link to="/Transactions?t=withdraw" className="trans with">{app.l("Withdraw")}</Link>
              <Link to="/Transactions?t=deposit" className="trans dep">{app.l("Deposit")}</Link>
            </div> : null}
            <ul className="b-tabs">
              <li className={"btab"+(btab == "wallet" ? " _active" : "")} onClick={() => { this.setState({btab: "wallet"}); }}><img src={btab == "wallet" ? walletA : wallet} alt="" /><span>{app.l("Wallet")}</span></li>
              <li className={"btab"+(btab == "market" ? " _active" : "")} onClick={() => { this.setState({btab: "market"}); }}><img src={btab == "market" ? marketA : market} alt="" /><span>{app.l("Markets")}</span></li>
              <li className={"btab"+(btab == "trade" ? " _active" : "")} onClick={() => { this.setState({btab: "trade"}); }}><img src={btab == "trade" ? tradeA : trade} alt="" /><span>{app.l("Trade")}</span></li>
              <li className={"btab"+(btab == "bsell" ? " _active" : "")} onClick={() => { this.setState({btab: "bsell"}); }}><img src={btab == "bsell" ? bsellA : bsell} alt="" /><span>{app.l("Buy or Sell")}</span></li>
              <li className={"btab"+(btab == "order" ? " _active" : "")} onClick={() => { this.setState({btab: "order"}); }}><img src={btab == "order" ? orderA : order} alt="" /><span>{app.l("Orders")}</span></li>
            </ul>
        </Container>
    );
  }
}

export default withRouter(Trade);