import $ from 'jquery';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-2';
// import logo from '../../Assets/logo.svg';
// import  './assets/intlTelInput.css';
import {
  addUserInformation,
  setAccountType,
  setAccounts,
  saveUserProfile,
} from '../../redux/actions/index';
import "./assets/intlTelInput.css";
import "./assets/dbip.js";
import server from '../../services/server';
import app from '../../services/app';
import Spinner from '../../components/spinner/index';
import './Auth.scss';

let logo = app.custom("logo");

class Register extends Component {
    constructor(props) {
      super(props);
  
      let acc_types = app.data("atype").split(",");
      let languages = app.data("langs").split(",");

      this.state = {
        nn: 'sign-devfx',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: 'Male',
        country: '',
        password: '',
        iacc: false,
        refid: '',
        sreg: false,
        acc_type: acc_types[0].split(":")[0],
        acc_price: acc_types[0].split(":")[1],
        acc_types,
        lang: languages[0],
        languages,
        countryCode: '',
        confirmPassword: '',
        question: '',
        message: '',
        showSpinner: false,
        
        pt: "password",
        cpt: "password",
      };
    }

    
// When the user starts to type something inside the password field
  passwordErr = (str) => {
      let ret = "Password must contain ";

      // Validate lowercase letters
      var lowerCaseLetters = /[a-z]/g;
      if(str.match(lowerCaseLetters)) {} else {
        return ret+"small letters";
      }
      
      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g;
      if(str.match(upperCaseLetters)) {} else {
        return ret+"a capital letter";
      }

      // Validate numbers
      var numbers = /[0-9]/g;
      if(str.match(numbers)) {} else {
        return ret+"a number";
      }
      
      // Validate length
      if(str.length >= 6 && str.length < 13) {} else {
        return ret+"6 - 12 characters";
      }

      return "";
    }

    handleInputChange = (e) => {
      const { name, value } = e.target;
  
      if(name == "acc_type") {
        let acc_types = this.state.acc_types;
        let acc_price = acc_types.filter((ac) => ac.split(":")[0].toLowerCase() == value.toLowerCase())[0].split(":")[1];
        this.setState({ acc_price });
      }

      this.setState({
        [name]: value.trim()
      });

      if(name == "password" || name == "confirmPassword") {
        let passerr = this.passwordErr(value);
        if (passerr.length > 0) {
          this.setState({ message: passerr });
        } else {
          this.setState({ message: '' });
        }
      }
    };
  
    clearErrors = () => {
      this.setState({message: ''});
    };
  
    async componentDidMount () {
      $("title").html(app.custom("name")+" - REGISTER");
      let intlTelInput = require("../../../node_modules/intl-tel-input/build/js/intlTelInput.js");
      let utilsScript  = require("../../../node_modules/intl-tel-input/build/js/utils.js");
      var input = document.querySelector("#phone");
      intlTelInput(input, utilsScript);
      this.ccPhone();

      let rf = "";
      if(this.props.location.search.length) {
        if(this.props.location.search.indexOf("=") > -1) {
          let sp = this.props.location.search.split("=");
          if(sp[0].toLowerCase() == "?_rf") {
            rf = sp[1].trim();
            this.setState({refid: rf});
            $(".refid").val(rf);
          }
        }
      }
  
      $(".fc-number").keyup(function () {
      let val = $(this).val().trim();
          val = val.replace(/[^\d+]+/gi, '');
          $(this).val(val).focus();
      });
      
      $(".fc-number").mouseout(function () {
      let val = $(this).val().trim();
          val = val.replace(/[^\d+]+/gi, '');
          $(this).val(val).focus();
      });
      
      $(".fc-number").change(function () {
      let val = $(this).val().trim();
          val = val.replace(/[^\d+]+/gi, '');
          $(this).val(val).focus();
      });
  
    }
  
    ccPhone = () => {
      let that = this;
      setTimeout(() => {
        let code = "";
        $("li[data-country-code]").click(function () {
          let ct = $(this).find(".iti__country-name").text();
          let cc = $(this).find(".iti__dial-code").text();
          that.setState({
            country:      ct,
            countryCode:  cc
          });
        });
  
        $(document).keyup(function (e) {
          if($("li.iti__country.iti__highlight").length) {
            let ct = $("li.iti__country.iti__highlight .iti__country-name").text();
            let cc = $("li.iti__country.iti__highlight .iti__dial-code").text();
            // if(cc == "+1" && that.state.countryCode.length) {} else
            if(ct.length && e.which == 13) {
              that.setState({
                country:      ct,
                countryCode:  cc
              });
            }
          }
        });
   
        window.dbip.getVisitorInfo().then(info => {
         code = (info.countryCode || "").toLowerCase();
         if(code != null) {
          $(".iti__selected-flag, li[data-country-code="+code+"]").click();
          $(".iti__selected-flag, li[data-country-code="+code+"]").click();
         }
        });
        $(".iti__selected-flag").css({opacity: "1"});
      }, 0);
    }

    togglePT = (f, pt) => {
      this.setState({
        [f]: pt == "text" ? "password" : "text",
      });
    }
  
    submitForm = async (e) => {
      e.preventDefault();
      const {
        firstName,
        lastName,
        phone,
        country,
        countryCode,
        gender,
        email,
        lang,
        refid,
        acc_type,
        password,
        confirmPassword,
        question,
      } = this.state;
      const nameRegex = /^[a-zA-Z]{3,}$/;
      const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  
      this.clearErrors();

      let passerr = this.passwordErr(password);

      if (passerr.length > 0) {
        return this.setState({ message: passerr });
      }

      if (!nameRegex.test(firstName)) {
        return this.setState({ message: 'Invalid first name' });
      } else if (!nameRegex.test(lastName)) {
        return this.setState({ message: 'Invalid last name' });
      } else if (phone.length < 6) {
        return this.setState({ message: 'Invalid phone number' });
      } else if (password !== confirmPassword) {
        return this.setState({ message: 'Passwords do not match' });
      }
  
      if (
        nameRegex.test(firstName) &&
        nameRegex.test(lastName) &&
        email !== '' &&
        phone.length &&
        password === confirmPassword
      ) {  
        this.setState({ showSpinner: true });
        let nph = phone.trim();
            nph = nph.charAt(0) == 0 ? nph.slice(1) : nph;
        let phone_number = countryCode+""+nph;
        
        try {
          const response = await server.register({
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phone_number,
            password,
            gender,
            lang,
            refid,
            acc_type,
            source: question,
            role: 'user',
            country: country,
            country_code: countryCode,
          });
  
          this.setState({ sreg: true });
          const user        = response.data.profile;
          let msg           = response.data.message;
          this.setState({ showSpinner: false });
          window.location.href = '/Login?registered';
        } catch (error) {
          this.setState({ showSpinner: false, message: "An error occurred" });
          if (!error.response) {
            return error;
          }
          this.setState({ message: error.response.data.message });
        }
      }
    };

    render () {
    let { languages, showSpinner, message, sreg, acc_type, acc_types, acc_price, iacc, nn, pt, cpt } = this.state;
    return (
        <div className="App">
        <Spinner showSpinner={showSpinner} />
        <div className="App-body outside register-page">
            <a href={app.data("home")} target="_blank"><img className="App-logo" src={logo} alt="" /></a>
            <div className="container">
            <div className="row">
              <div className={"col sign-devfx"+(nn == "sign-devfx" ? " nn" : "")}>
                <h1 className="sign-head">{app.l("Sign up with "+app.custom("name").ucwords()+" in a few minutes")}</h1>
                <ul className="sign-todo">
                    <li><span className="ind active">1</span><h4>{app.l("Complete your application")}</h4><span className="txt-sm">{app.l("Complete our fast and secure application")}</span></li>
                    <li><span className="ind">2</span><h4>{app.l("Fund your account")}</h4><span className="txt-sm">{app.l("Fund your account fast and easy using our flexible funding methods")}</span></li>
                    <li><span className="ind">3</span><h4>{app.l("Confirm your ID")}</h4><span className="txt-sm">{app.l("Verify your identity online or upload your ID documents if required")}</span></li>
                    <li><span className="ind">4</span><h4>{app.l("Enjoy premium trading")}</h4><span className="txt-sm">{app.l("Start placing Live trades and gain access to multiple trading assets")}</span></li>
                </ul>
                <div className="row">
                  <h3 className="bac"><span>{acc_type.ucwords()} Account<br />{app.l("Minimum required deposit")}</span><b>{app.currency(false, true)}{acc_price}</b></h3>
                </div>
                <button className="btn btn-dark btn-lg btn-block bt-ctd" onClick={() => {
                  this.setState({nn: "sign-devfx"});
                  $("#App")[0].scrollTop = 0;
                }}>Continue</button>
              </div>
                <div className={"col sign-fill"+(nn == "sign-fill" ? " nn" : "")}>
                <h3 className="lop lop2">{app.l("Sign up with "+app.custom("name").ucwords()+" in a few minutes")}</h3>
                <div className="card s">
                <div class="card-header">
                    <div className="row">
                        <Link to="/Register" className="col label active">{app.l("Sign up")}</Link>
                        <Link to="/Login" className="col label">{app.l("Log in")}</Link>
                    </div>
                </div>
                <div className="card-body">
                    <form align="left" className="dform register-box" onSubmit={this.submitForm}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("First Name", true)}</label>
                                <input type="text" className="form-control" name="firstName" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Last Name", true)}</label>
                                <input type="text" className="form-control" name="lastName" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Gender", true)}</label>
                                <select className="form-control" name="gender" onChange={this.handleInputChange}>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Language", true)}</label>
                                <select className="form-control" name="lang" onChange={this.handleInputChange}>
                                    {
                                        languages.map((l, k) => {
                                            return (
                                                <option key={"lang-"+l+"-"+k}>{l.trim()}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Phone Number", true)}</label>
                                <div className="react-tel-input">
                                    <input type="text" name="phone" id="phone" className="form-control fc-number" onChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Referral ID (Optional)", true)}</label>
                                <input className="form-control refid" type="text" name="refid" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>{app.l("Email", true)}</label>
                        <input className="form-control" type="email" name="email" onChange={this.handleInputChange} />
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("Account Tyoe", true)}</label>
                                <select className="form-control" name="acc_type" onChange={this.handleInputChange}>
                                  {
                                    acc_types.map((a, j) => {
                                      return (
                                        <option key={"at-"+a+"-"+j}>{a.split(":")[0].trim()}</option>
                                      )
                                    })
                                  }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>{app.l("How did you find out about us?")}</label>
                                <select className="form-control" name="question" onChange={this.handleInputChange}>
                                    <option>Facebook ads</option>
                                    <option>Google ads</option>
                                    <option>Google Search</option>
                                    <option>From a friend</option>
                                    {/* {app.data("host") == "trade.exbitam.com" ? <option>MONEY MAKERS CLUB</option> : null} */}
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group" style={{position: "relative"}}>
                                <label>{app.l("Password", true)}</label>
                                <input type={pt} className="form-control" name="password" onChange={this.handleInputChange} />
                                <svg onClick={() => this.togglePT("pt", pt)} className="tb-action" style={{position: "absolute", bottom: "15px", cursor: "pointer", right: "10px"}} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#ffffffc2" />
                                </svg>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group" style={{position: "relative"}}>
                                <label>{app.l("Confirm Password", true)}</label>
                                <input type={cpt} className="form-control" name="confirmPassword" onChange={this.handleInputChange} />
                                <svg onClick={() => this.togglePT("cpt", cpt)} className="tb-action" style={{position: "absolute", bottom: "15px", cursor: "pointer", right: "10px"}} width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.99967 0.75C5.83301 0.75 2.27467 3.34167 0.833008 7C2.27467 10.6583 5.83301 13.25 9.99967 13.25C14.1663 13.25 17.7247 10.6583 19.1663 7C17.7247 3.34167 14.1663 0.75 9.99967 0.75ZM9.99967 11.1667C7.69967 11.1667 5.83301 9.3 5.83301 7C5.83301 4.7 7.69967 2.83333 9.99967 2.83333C12.2997 2.83333 14.1663 4.7 14.1663 7C14.1663 9.3 12.2997 11.1667 9.99967 11.1667ZM9.99967 4.5C8.61634 4.5 7.49967 5.61667 7.49967 7C7.49967 8.38333 8.61634 9.5 9.99967 9.5C11.383 9.5 12.4997 8.38333 12.4997 7C12.4997 5.61667 11.383 4.5 9.99967 4.5Z" fill="#ffffffc2" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    {message.length ? (<p className={sreg ? 'success' : 'error red'}>{message}</p>) : null}
                    
                    {app.data("host") == "trade.exbitam.com" ? <span>
                      <label htmlFor='iacc' style={{display: "flex", flexDirection: "row", marginTop: "8px"}}>
                        <input type="checkbox" id="iacc" name="iacc" onChange={(e) => {
                          this.setState({iacc: e.target.checked});
                        }} style={{display: "inline-flex", width: "17px", height: "17px", position: "relative", top: "4px"}} />
                        <span style={{cursor: "pointer", display: "inline-flex", justifyContent: "flex-start", paddingLeft: "10px", textAlign: "left", width: 'calc(100% - 23px)'}}>
                          <span className='crcp_14'>I've read and accept <a target='_blank' href='https://exbitam.com/wp-content/uploads/2022/12/1.-Exbitam_Client_Services_Agreement_v.2022-004.pdf'>Client Service Agreement</a>, <a target='_blank' href='https://exbitam.com/wp-content/uploads/2022/12/Exbitam-risk-disclosure.pdf'>Risk Disclosure</a>, <a target='_blank' href='https://exbitam.com/wp-content/uploads/2022/12/exbitam-conflicts-of-interest-policy.pdf'>Conflict of Interest Policy</a>, <a target='_blank' href="https://exbitam.com/wp-content/uploads/2022/12/5.-Exbitam_Privacy-Policy.pdf">Privacy policy</a></span>
                        </span>
                      </label>
                    </span> : null}

                    <div className="form-group" style={{marginTop: "1em"}} disabled={app.data("host") == "trade.exbitam.com" ? !iacc : false}>
                        <button type={app.data("host") != "trade.exbitam.com" || (app.data("host") == "trade.exbitam.com" && iacc) ? "submit" : "button"} className="btn btn-warning btn-lg btn-block app-btn">{app.l("Sign up now")}</button>
                    </div>

                    <div className="row r2">
                        <h3 className="bac"><span>{acc_type.ucwords()} Account<br />{app.l("Minimum required deposit")}</span><b>{app.currency(false, true)}{acc_price}</b></h3>
                    </div>
                    </form>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
    }
}

export default withRouter(Register);