import React, { Component } from 'react';
import $ from 'jquery';
import Container from '../container/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import RestrictionNav from '../../components/restrictionNav/index';
import Spinner from '../../components/spinner/index';
import CancelIcon from '../../themes/images/cancel.svg';
import country from 'country-list-js';
import permissions from '../../utils/permissions-all';
import server from '../../services/server';
import check from './icv.png';
import app from '../../services/app';
import './index.scss';

const unlimited = (x, y) => {
  return x == -1 ? "Unlimited" : x+" "+y+(x > 1 ? "s" : "");
}

class Pricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      client: null,
      showSpinner: false,
      cid: this.props.match.params.cid
    };
  }

  async componentDidMount() {
    this.getPricing();
    $("#sacli").show();
    $("#save-all-changes-btn").click(async () => {
      let data = {
        price_h1:               $("#price_h1").text(),
        price_h2:               $("#price_h2").text(),
        duration:               $(".durate").val(),

        buck_price:             $("#buck_price").val(),
        stack_price:            $("#stack_price").val(),
        brick_price:            $("#brick_price").val(),

        buck_trade:             $("#buck_trade").text() || 0,
        buck_trade_no:          $("#buck_trade_no").val() || 0,
        buck_admin_no:          $("#buck_admin_no").val() || 0,
        buck_instrument_no:     $("#buck_instrument_no").val() || 0,
        buck_account_no:        $("#buck_account_no").val() || 0,
        buck_asset:             this.assets("buck_asset", true),

        stack_trade:            $("#stack_trade").text(),
        stack_trade_no:         $("#stack_trade_no").val() || 0,
        stack_admin_no:         $("#stack_admin_no").val() || 0,
        stack_instrument_no:    $("#stack_instrument_no").val() || 0,
        stack_account_no:       $("#stack_account_no").val() || 0,
        stack_asset:            this.assets("stack_asset", true),

        brick_trade:            $("#brick_trade").text(),
        brick_trade_no:         $("#brick_trade_no").val() || 0,
        brick_admin_no:         $("#brick_admin_no").val() || 0,
        brick_instrument_no:    $("#brick_instrument_no").val() || 0,
        brick_account_no:       $("#brick_account_no").val() || 0,
        brick_asset:            this.assets("brick_asset", true),

        stick_trade:            $("#stick_trade").text(),
        stick_trade_no:         $("#stick_trade_no").val() || 0,
        stick_admin_no:         $("#stick_admin_no").val() || 0,
        stick_instrument_no:    $("#stick_instrument_no").val() || 0,
        stick_account_no:       $("#stick_account_no").val() || 0,
        stick_asset:            this.assets("stick_asset", true)
      };

      this.setState({showSpinner: true});
      try {
        let s = await server.updPricing(data, this.state.cid);
      } catch (e) {
        return e;
      }
      this.setState({showSpinner: false});
    });

    let dis = this;

    $(document).delegate(".price li", "dblclick", function () {
      let tg = $(this).data("toggle") || "", ct = $(this).text().toLowerCase();
      if(tg.length) {
        tg = tg.split("|");
        let cg = ct == tg[0].toLowerCase() ? tg[1] : tg[0];
        if(cg.toLowerCase() != "unlimited" && $(this).data("no") > -1) {
          let no = Number($(this).data("no"));
          let id = $(this).data("id");
          $(this).html(dis.noi(no, id, true)+cg);
        } else {
          $(this).html(cg);
        }
      }
    });

    $(document).delegate(".noi", "keyup", function () {
      let no = Number($(this).val());
      $(this).parents("li").data("no", no);
    });
  }

  noi = (x = null, id="", xx = false) => {
    x = x > 0 ? x : 2;
    return xx ? '<input type="number" id="'+id+'" value="'+x+'" class="noi" />' : (
      <input type="number" id={id} defaultValue={x} className="noi" />
    )
  }

  assets = (v = "", x = false) => {
    if(x) {
      let y = [];
      $("#"+v).find("input").each((k, v) => {
        if(v.checked) {
          y.push(v.value);
        }
      });
      return y.join(",");
    } else {
      let vl = v.toLowerCase().split(",");
      return (
        <>
          <p>
            <label><input type="checkbox" defaultChecked={vl.includes("forex")} value="forex" /> Forex</label>
            <label><input type="checkbox" defaultChecked={vl.includes("crypto")} value="crypto" /> Crypto</label>
            <label><input type="checkbox" defaultChecked={vl.includes("stock")} value="stock" /> Stocks</label>
          </p>
          <p>
            <label><input type="checkbox" defaultChecked={vl.includes("commodities")} value="commodities" /> Commodities</label>
            <label><input type="checkbox" defaultChecked={vl.includes("indices")} value="indices" /> Indices</label>
            <label></label>
          </p>
        </>
      );
    }
  }

  getPricing = async () => {
    this.setState({showSpinner: true});
    let p = await server.getPricing(this.state.cid), dis = this;
    this.setState({data: p.data.data[0], client: p.data.client[0], showSpinner: false});

    setTimeout(() => {
      $("[data-no]").each((k, v) => {
        let no = $(v).data("no"), id= $(v).data("id"), tg = $(v).data("toggle").split("|")[1];
        $(v).html(no == "0" ? "Unlimited" : dis.noi(no, id, true)+" "+tg);
      });
    }, 250);
  }

  duration = (v = 1) => {
    v = v == 0 || v == "" ? 1 : parseInt(v);
    return (
      <select className="durate" onChange={this.dChange} defaultValue={v} disabled>
        <option value="1">Monthly</option>
        <option value="3">Quarterly</option>
        <option value="6">Bi-annual</option>
        <option value="12">Annual</option>
      </select>
    )
  }

  dChange = (e) => {
    let val = $(e.target).val();
    $(".durate").each((k, v) => {
      v.value = val;
    });
    // $(".durate-alt").html()
  }

  blurable = (p, c) => {
    if(c) {
      return p.toLowerCase() == c.plan.toLowerCase() ? "columns" : "columns blured";
    } else {
      return "columns";
    }
  }


render() {
let { data, showSpinner, cid, client } = this.state;

return (
<Container>
<Spinner showSpinner={showSpinner} />
<div className='pricing-section'>
<Breadcrumbs breads={"Home, Clients, Pricing"} />
{
  data ? <>
    {
      cid > 0 ? <>
        <h1 style={{fontSize: "1.3em"}}>{client.name}</h1>
        <h3 style={{color: "#fff", fontWeight: "100", fontSize: "16px", margin: "0"}}>{client.email+" | "+(client.phone.trim().charAt(0) == "+" ? "" : "+")+client.phone}</h3>
        <h3 style={{color: "#fff", fontWeight: "100", fontSize: "16px", margin: "0"}}><a href={"http://"+client.domain} target="_blank" style={{position: "relative", color: "#00a880"}}><img src={check} className="veri" />&nbsp;{client.domain}</a><small style={{marginLeft: "10px", color: "#f9f9f9"}}>{"(CUSTOM "+client.plan.toUpperCase()+")"}</small></h3>
        <h3 style={{color: "#fff", fontWeight: "100", fontSize: "16px", margin: "0"}}>{client.create_time}</h3>
      </> : <>
        <h1 id="price_h1" contentEditable={true}>{data.price_h1}</h1>
        <h3 id="price_h2" style={{color: "#989898", fontWeight: "100", margin: "0"}} contentEditable={true}>{data.price_h2}</h3>
      </>
    }
    <div className="price-table full-screen">
      <div className="columns">
      <ul className="price price-r">
          <li className="header f">FXTMINATOR</li>
          <li>Duration</li>
          <li>Trade Edits</li>
          <li>Open Trades</li>
          <li>N<u>o</u> of Admins</li>
          <li>Instruments</li>
          <li>Trader Accounts</li>
          <li>Trading Assets</li>
      </ul>
      </div>

      <div className={this.blurable("BUCK", client)}>
          <ul className="price hover">
              <li className="header">BUCK<br className="br" /><small>(${this.noi(data.buck_price, "buck_price")} monthly)</small></li>
              <li className="plan_duration">{this.duration(data.duration)}</li>
              <li data-toggle="A-book|A & B-book" id="buck_trade">{data.buck_trade}</li>
              <li data-toggle="Unlimited|trades" data-no={data.buck_trade_no} data-id="buck_trade_no"></li>
              <li data-toggle="Unlimited|admins" data-no={data.buck_admin_no} data-id="buck_admin_no"></li>
              <li data-toggle="Unlimited|instruments" data-no={data.buck_instrument_no} data-id="buck_instrument_no"></li>
              <li data-toggle="Unlimited|accounts" data-no={data.buck_account_no} data-id="buck_account_no"></li>
              <li id="buck_asset" className="ali">
                {this.assets(data.buck_asset)}
              </li>
          </ul>
      </div>

      <div className={this.blurable("STACK", client)}>
          <ul className="price hover">
              <li className="header">STACK<br className="br" /><small>(${this.noi(data.stack_price, "stack_price")} monthly)</small></li>
              <li className="plan_duration">{this.duration(data.duration)}</li>
              <li data-toggle="A-book|A & B-book" id="stack_trade">{data.stack_trade}</li>
              <li data-toggle="Unlimited|trades" data-no={data.stack_trade_no} data-id="stack_trade_no"></li>
              <li data-toggle="Unlimited|admins" data-no={data.stack_admin_no} data-id="stack_admin_no"></li>
              <li data-toggle="Unlimited|instruments" data-no={data.stack_instrument_no} data-id="stack_instrument_no"></li>
              <li data-toggle="Unlimited|accounts" data-no={data.stack_account_no} data-id="stack_account_no"></li>
              <li id="stack_asset" className="ali">
                {this.assets(data.stack_asset)}
              </li>
          </ul>
      </div>

      <div className={this.blurable("BRICK", client)}>
          <ul className="price hover">
              <li className="header">BRICK<br className="br" /><small>(${this.noi(data.brick_price, "brick_price")} monthly)</small></li>
              <li className="plan_duration">{this.duration(data.duration)}</li>
              <li data-toggle="A-book|A & B-book" id="brick_trade">{data.brick_trade}</li>
              <li data-toggle="Unlimited|trades" data-no={data.brick_trade_no} data-id="brick_trade_no"></li>
              <li data-toggle="Unlimited|admins" data-no={data.brick_admin_no} data-id="brick_admin_no"></li>
              <li data-toggle="Unlimited|instruments" data-no={data.brick_instrument_no} data-id="brick_instrument_no"></li>
              <li data-toggle="Unlimited|accounts" data-no={data.brick_account_no} data-id="brick_account_no"></li>
              <li id="brick_asset" className="ali">
                {this.assets(data.brick_asset)}
              </li>
          </ul>
      </div>

      <div className={this.blurable("STICK", client)}>
        <ul className="price hover">
            <li className="header">STICK<br className="br" /><small>(${this.noi(data.stick_price, "stick_price")} monthly)</small></li>
            <li className="plan_duration">{this.duration(data.duration)}</li>
            <li data-toggle="A-book|A & B-book" id="stick_trade">{data.stick_trade}</li>
            <li data-toggle="Unlimited|trades" data-no={data.stick_trade_no} data-id="stick_trade_no"></li>
            <li data-toggle="Unlimited|admins" data-no={data.stick_admin_no} data-id="stick_admin_no"></li>
            <li data-toggle="Unlimited|instruments" data-no={data.stick_instrument_no} data-id="stick_instrument_no"></li>
            <li data-toggle="Unlimited|accounts" data-no={data.stick_account_no} data-id="stick_account_no"></li>
            <li id="stick_asset" className="ali">
              {this.assets(data.stick_asset)}
            </li>
        </ul>
      </div>
    </div>
  </> : null
}
    </div>
  </Container>
    );
  }
}

export default Pricing;
