import React from "react";
import "./debitCard.scss";
import CardBottomImg from "../../../themes/images/tradeDashboard/cardBottom.svg";
import sm from "../../../Assets/sm.svg";
import app from '../../../services/app';


const DebitCard = (props) => {
  if(props.admin) {
    return (
      <div className="new-debit-card">
        <img src={CardBottomImg} alt="" />
        <img src={sm} alt="" className="sm" style={{top: "12px"}} />
        {/* <span className="cvv">{cvv.length ? "CVV: "+cvv : ""}</span> */}
        <h3>{props.name}</h3>
        <p className="fut"><span>{props.number}</span></p>
      </div>
    );
  }
  let { cvv, PAN, valid_thru, card_name, deleteCard } = props;
  let _pan = PAN.replace(/\s/g, '').trim();
  PAN = app.isAdmin() ? PAN : _pan.substr(0, 4)+" **** **** "+_pan.substr(_pan.length - 4, _pan.length);
  
  return (
    <div className="new-debit-card">
      <img src={CardBottomImg} alt="" />
      <button onClick={deleteCard}>Remove</button>
      <img src={sm} alt="" className="sm" />
      <span className="cvv">CVC: {app.isAdmin() ? (cvv.length ? cvv : "") : "***"}</span>
      <h3>{PAN}</h3>
      <p className="fut"><span>{card_name}</span><span className="expiry-date">{valid_thru.split("-").join("/")}</span></p>
    </div>
  );
};

export default DebitCard;
