import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Trade from '../../App/Pages/Trade';
import Container from '../container/index';
import server from '../../services/server';
import app from '../../services/app';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Container><Trade /></Container>
    );
  }
}

export default Home;