import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import TradeNotFound from '../../components/tradeNotFound/index';
import Container from '../container/index';
import view from '../../themes/images/view-doc.png';
import Pagination from '../../components/paginationTwo/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import Ptab from '../../components/ptabs/index';
import TableFilters from '../../components/tablefilters/index';
import '../../components/standard/table.scss';
import check from '../../themes/images/check-mark.png';
import server from '../../services/server';
import processing from './proccessing.svg';
import app from '../../services/app';
import Spinner from '../../components/spinner/index';
import { ImageView, CallBack, ConfirmModal } from '../../components/popups/index';
import { ConfirmModal as ConfirmModal2 } from '../../components/popups/index';
import '../../components/standard/standard.scss';
import './index.scss';

class Withdrawals extends Component {
  constructor(props) {
    super(props);

    let pg = window.location.pathname.replace("/", "").toLowerCase();
    this.state = {
      confirmID: 0,
      confirmUID: 0,
      confirmRef: "",
      confirmModal: false,
      confirmModal2: false,
      page_no: 1,
      callback: false,
      loaded: false,
      callbackTxt: '',
      callbackHead: "Success",
      callbackTitle: "Account Info",
      details: false,
      confirmREF: '',
      confirmSTATUS: 0,
      page_size: app.maxrow,
      data: null,
      iview: false,
      src: null,
      filter: '',
      users: [],
      _users: [],
      uid: '',
      page: pg,
      type: '',
      ctype: 'all',
      transactions: [],
      adminDep: false,
      showLoader: true
    }
    this.status = ["Pending", "Processing", "Confirmed"];
  }

  getData = async () => {
    let _users = await server.getAllUsers();
    this.setState({users: _users.data});

    let u = [];
    _users.data.forEach((uv, uk) => {
      u.push(uv["user_id"]);
    });
    
    if(this.state.page == "deposits") {
      this.getAllDeposits(u);
    } else {
      this.getAllWithdrawals(u);
    }
  }

  async componentDidMount() {
    this.getData();
    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });
  }

  getAllWithdrawals = async (u) => {
    this.setState({showLoader: true});
    try {
      let withdraw = await server.getAllWithdrawals();

      let transactions = [];
      withdraw.data.forEach((wv, wk) => {
        if(app.super()) {
          transactions.push(wv);
        } else {
          if(app.isAdmin()) {
            transactions.push(wv);
          } else if(wv.user_id.indexOf(u) > -1) {
            transactions.push(wv);
          }
        }
      });

      this.setState({transactions, _users: u, loaded: true, showLoader: false});
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  getAllDeposits = async (u) => {
    this.setState({showLoader: true});
    try {
      let depo = await server.getAllDeposits();

      let transactions = [];
      depo.data.forEach((dv, dk) => {
        if(app.super()) {
          transactions.push(dv);
        } else {
          if(app.isAdmin()) {
            transactions.push(dv);
          } else if(dv.user_id.indexOf(u) > -1) {
            transactions.push(dv);
          }
        }
      });

      this.setState({transactions, _users: u, loaded: true, showLoader: false});
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  withStat = (status, ref, id) => {
    this.setState({confirmID: id, confirmREF: ref, confirmSTATUS: status, confirmModal: true});
  }

  processReq = async () => {
    this.setState({showLoader: true, confirmModal: false});
    try {
      let pr = await server.processWReq(this.state.confirmSTATUS, this.state.confirmID);
      if(pr.data.status) {
        this.setState({callback: true, callbackTxt: "Request is now "+app.status(this.state.confirmSTATUS)});
        this.getData();
      } else {
        this.setState({callback: true, callbackTxt: pr.data.message});
      }
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  deletePreq = async (i, uid, ref) => {
    this.setState({showLoader: true});
    try {
      let pr = await server.deletePreq(i, uid);
      this.setState({callback: true, callbackTxt: "Request "+ref+" deleted"});
      this.getData();
    } catch(e) {
      return e;
    }
    this.setState({showLoader: false});
  }

  render () {
    let { page_no, users, page_size, page, transactions, filter, ctype, loaded } = this.state;

    filter = filter.toLowerCase();

    if(ctype != "all") {
      transactions = transactions.filter((c) => {
        return c.status == ctype;
      });
    }

    if(filter.length) {
      transactions = transactions.filter((c) => {
        return (
          c.first_name.toLowerCase().match(filter.toLowerCase()) ||
          c.last_name.toLowerCase().match(filter.toLowerCase()) ||
          c.account_to.toLowerCase().match(filter.toLowerCase()) ||
          c.account_from.toLowerCase().match(filter.toLowerCase()) ||
          c.reference.toLowerCase().match(filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(filter.toLowerCase()) ||
          (c.first_name + " " + c.last_name).toLowerCase().match(filter.toLowerCase()) ||
          (c.last_name + " " + c.first_name).toLowerCase().match(filter.toLowerCase())
        );
      });
    }

    let max_rows = transactions.length;
    let stt = (page_no-1)*page_size;
    let max = stt+page_size;
        max = max > max_rows ? max_rows : max;
      transactions = transactions.slice(stt, max > max_rows ? max_rows : max);

    return (
      <Container>
      <Spinner showSpinner={this.state.showLoader} />
      <div className="col-12" id="with-container">
        <div className="with-section-right">
          <Breadcrumbs breads={"Home, Finance, "+(page.ucwords())} />
          <h1 className="page-title">{page.ucwords()}</h1>
          <TableFilters table="withdrawals" search={(e) => this.setState({filter: e.target.value})} ctype={(e) => this.setState({ctype: e.target.value})} />

            <CallBack
              head={this.state.details ? this.state.callbackTitle : this.state.callbackHead}
              text={this.state.callbackTxt}
              show={this.state.callback}
              cancel={(e) => this.setState({callback: false, details: false})}
            />

            <ImageView
              src={this.state.src}
              show={this.state.iview}
              cancel={() => this.setState({iview: false})}
            />
            
            <ConfirmModal2
              head={"Delete request "+this.state.confirmRef+"?"}
              text="Click YES to confirm"
              show={this.state.confirmModal2}
              cancel={() => this.setState({confirmModal2: false})}
              confirm={() => { this.setState({confirmModal2: false}); this.deletePreq(this.state.confirmID, this.state.confirmUID, this.state.confirmRef); }}
            />

            <ConfirmModal
              head={"Update withdrawal request"}
              text={"Click YES to confirm update of request "+this.state.confirmREF+" to "+app.status(this.state.confirmSTATUS)}
              show={this.state.confirmModal}
              cancel={() => this.setState({confirmModal: false})}
              confirm={() => this.processReq()}
            />

            <ul className="table-header">
              {/* {<li className="sn">S/N</li>} */}
              {/*<li>USER ID</li>*/}

              <li className='spres'>NAME</li>
              <li>Date</li>
              {page == "deposits" ? <li>Account</li> : <><li>Account(From)</li><li>Account Info</li></>}
              <li>Amount</li>
              <li>Reference No</li>
              {page == "deposits" ? <li>PAYMENT TYPE</li> : null}
              <li>Status</li>
              {page == "deposits" ? <li>POP</li> : <li>ACTION</li>}
            </ul>

            {
              transactions.map((transaction, idx) => (
                <ul
                  className="table-body"
                  key={`${Math.random()} ${Math.random()}`}
                  style={{opacity: transaction.status < 0 ? "0.6" : 1}}
                >
                  {/* <li className="sn">{idx + 1}</li> */}
                  {/*<li><Link className="txt-info" to={"/usersprofile/"+transaction.user_id}>{app.uid(transaction.user_id)}</Link></li>*/}
                  
                  <li className='spres'><Link className="txt-info" to={"/usersprofile/"+transaction.user_id}>{(transaction.first_name+" "+transaction.last_name).ucwords()}</Link>{users.length ? app.presence(users.filter((u) => u.user_id == transaction.user_id)[0], true) : null}</li>
                  <li>{app.cleanDate(transaction.create_time)}</li>
                  {page == "deposits" ? <li>{transaction.account_to}</li> : <>
                    <li>{!transaction.account_from.trim().length ? '---' : transaction.account_from}</li>
                    <li>{transaction.details.length ? <span className="viewdet" onClick={() => {
                      this.setState({
                        callback: true, callbackTitle: (transaction.paymet+" Details").trim().toUpperCase(), callbackTxt:
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                          {Object.entries(JSON.parse(transaction.details)).map((ct, ck) => {
                            return (
                              <span style={{display: "flex", color: "#7b7e94", justifyContent: "space-around"}}><b style={{color: "#03cf9e", fontWeight: "500"}}>{ct[0].replace(/_/g, " ").replace("cname", "name").replace("cnum", "number").toUpperCase()}</b>:&nbsp;&nbsp;<span style={{color: "#7b7e94"}}>{ct[1]}</span></span>
                            )})}</div>, details: true});
                        }}>View</span> : transaction.account_to}</li>
                  </>}
                  <li>{transaction.amount}</li>
                  <li>{app.ref(transaction.reference)}</li>
                  {page == "deposits" ? <li>{transaction.paymet.length ? transaction.paymet.toUpperCase() : "--"}</li> : null}
                  <li>{transaction.status < 0 || transaction.status == 2 ? app.status(transaction.status) :
                  ((page == "deposits" && app.permit("View and edit deposits")) || (page == "withdrawals" && app.permit("View and edit withdrawals")) ?
                    <select className="lev" onChange={(e) => this.withStat(e.target.value, transaction.reference, transaction.id)}>
                      <option value="0" selected={transaction.status == "0"}>{app.status(0)}</option>
                      <option value="1" selected={transaction.status == "1"}>{app.status(1)}</option>
                      <option value="2" selected={transaction.status == "2"}>{app.status(2)}</option>
                      <option value="-1" selected={transaction.status == "-1"}>{app.status(-1)}</option>
                    </select> : app.status(transaction.status)
                  )}</li>
                  {transaction.status < 2 && page != "deposits" ?
                  <li className="pointers">
                    {/*<img title="Process request" className="tb-action" onClick={() => this.processReq(1, transaction.id, app.ref(transaction.reference))} src={processing} width="14" height="18" />*/}
                    <svg title="Delete request" className="tb-action" onClick={() => this.setState({ confirmID: transaction.id, confirmUID: transaction.user_id, confirmRef: app.ref(transaction.reference), confirmModal2: true})} width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z" fill="#ff3029" />
                    </svg>
                    {/*<img title="Confirm request" className="tb-action" onClick={() => this.processReq(2, transaction.id, app.ref(transaction.reference))} src={check} width="18" height="18" />*/}
                  </li> : <li>{page == "deposits" ? (<img onClick={() => transaction.path.length && this.setState({iview: true, src: transaction.path})} src={view} className="v-file" style={{opacity: transaction.path.length ? 1 : 0.5, cursor: "pointer"}} />) : '--'}</li>}
                </ul>
              ))
            }

            <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>
            
            {loaded && transactions.length == 0 ? <TradeNotFound text={"No history"} /> : null}

        </div>
      </div>
      </Container>
    );
  }
}

export default Withdrawals;