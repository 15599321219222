import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Pagination from '../../components/paginationTwo/index';
import '../../components/standard/table.scss';
import download from '../../themes/images/download-doc.png';
import view from '../../themes/images/view-doc.png';
import server from '../../services/server';
import app from '../../services/app';
import TableFilters from '../../components/tablefilters/index';
import { ImageView } from '../../components/popups/index';

class DocumentsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      clients: [],
      page_no: 1,
      page_size: app.maxrow,
      iview: false,
      src: null,
      cfil: '',
      filter: '',
      showLoader: true
    }
  }

  async componentDidMount () {
    this.getAllDoc();
    if(app.master()) {
      await this.getAllClients();
    }

    window.NO_AUTO_PAGER = true;

    $(window).on("resetPager", () => {
      this.setState({page_size: app.page_size(), page_no: 1});
    });
  }

  getAllDoc = async () => {
    try {
      let users = await server.getAllUsers();
      this.setState({users: users.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data, showLoader: false});
    } catch(e) {
      return e;
    }
  }

  exportCsv = () => {
    const export_data = [["firstname", "lastname", "email", "phone", "country", "gender"]];
    this.state.users.forEach((v, k) => {
      export_data.push([v.first_name, v.last_name, v.email, v.phone, v.country, v.gender]);
    });
    let csvContent = "data:text/csv;charset=utf-8," + export_data.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  render () {
    let { page_no, page_size, cfil, clients, filter, users } = this.state;

    filter = filter.toLowerCase();

    cfil = cfil.toLowerCase();

    if(cfil.length && cfil != "all") {
      users = users.filter((c) => {
        return (
          c.host.toLowerCase().match(cfil)
        );
      });
    }

    if(filter.length) {
      users = users.filter((c) => {
        return (
          c.first_name.toLowerCase().match(filter.toLowerCase()) ||
          c.last_name.toLowerCase().match(filter.toLowerCase()) ||
          c.email.toLowerCase().match(filter.toLowerCase()) ||
          app.uid(c.user_id).toLowerCase().match(filter.toLowerCase()) ||
          (c.first_name + " " + c.last_name).toLowerCase().match(filter.toLowerCase()) ||
          (c.last_name + " " + c.first_name).toLowerCase().match(filter.toLowerCase())
        );
      });
    }

    let max_rows = users.length;
    let stt = (page_no-1)*page_size;
    let max = stt+page_size;
        max = max > max_rows ? max_rows : max;
      users = users.slice(stt, max > max_rows ? max_rows : max);

    return (
          <>
          <TableFilters
            table="docs"
            search={(e) => this.setState({filter: e.target.value})}
            clients={clients}
            cch={(e) => {
              this.setState({cfil: e.target.value});
            }}
            export={this.exportCsv}
          />

          <ImageView
            src={this.state.src}
            show={this.state.iview}
            cancel={() => this.setState({iview: false})}
          />

            <table border="0">
              <thead>
                <tr>
                  <th style={{width: "45px"}}></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>User ID</th>
                  <th>Proof of ID</th>
                  <th>Proof of Residency</th>
                  <th>DOD</th>
                  <th>Bank Card</th>
                </tr>
              </thead>
              <tbody>
                {users.map((doc) => (
                  <tr>
                    <td style={{width: "45px"}}>
                    {doc.profile_image.length ?
                      <img src={doc.profile_image} className="dc-na" style={{padding: "0px"}}/> :
                      <span className="dc-na r">{doc.first_name.charAt(0).toUpperCase()+"."+doc.last_name.charAt(0).toUpperCase()+"."}</span>
                    }
                    </td>
                    <td><span>{(doc.first_name+" "+doc.last_name).ucwords()}</span></td>
                    <td><span className="txt-light blurc">{app.blur(doc.email)}</span></td>
                    <td><div className='spres'><Link className="txt-info" to={"/usersprofile/"+doc.user_id}>{app.uid(doc.user_id)}</Link>{app.presence(doc, true)}</div></td>
                    <td style={{opacity: doc.identity_proof.length ? "1" : "0.5"}}><span className="txt-default">Download {doc.identity_proof.length && app.permit("View and download Documents") ? <a href={doc.identity_proof} download><img src={download} className="d-file"/></a> : <img src={download} className="d-file"/> } <img onClick={() => doc.identity_proof.length && this.setState({iview: true, src: doc.identity_proof})} src={view} className="v-file" /></span></td>
                    <td style={{opacity: doc.residence_proof.length ? "1" : "0.5"}}><span className="txt-default">Download {doc.residence_proof.length && app.permit("View and download Documents") ? <a href={doc.residence_proof} download><img src={download} className="d-file"/></a> : <img src={download} className="d-file"/> } <img onClick={() => doc.residence_proof.length && this.setState({iview: true, src: doc.residence_proof})} src={view} className="v-file" /></span></td>
                    <td style={{opacity: doc.dod.length ? "1" : "0.5"}}><span className="txt-default">Download {doc.dod.length && app.permit("View and download Documents") ? <a href={doc.dod} download><img src={download} className="d-file"/></a> : <img src={download} className="d-file"/> } <img onClick={() => doc.dod.length && this.setState({iview: true, src: doc.dod})} src={view} className="v-file" /></span></td>
                    <td style={{opacity: doc.bank_card.length ? "1" : "0.5"}}><span className="txt-default">Download {doc.bank_card.length && app.permit("View and download Documents") ? <a href={doc.bank_card} download><img src={download} className="d-file"/></a> : <img src={download} className="d-file"/> } <img onClick={() => doc.bank_card.length && this.setState({iview: true, src: doc.bank_card})} src={view} className="v-file" /></span></td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div
              className='loader-container'
              style={{ display: this.state.showLoader ? 'block' : 'none' }}
            >
              <div className='loader'></div>
            </div>

            <Pagination length={page_size} max_rows={max_rows} page_no={page_no} paginationChange={(p) => { this.setState({page_no: p}); }}/>
          </>
        );
  }
}

export default DocumentsTable;
