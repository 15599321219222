import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink as Link } from 'react-router-dom';
import Papa from 'papaparse';
import Container from '../container/index';
import { FileUploader } from "react-drag-drop-files";
import { CallBack } from '../../components/popups/index';
import Breadcrumbs from '../../components/breadcrumbs/index';
import RestrictionNav from '../../components/restrictionNav/users';
import Spinner from '../../components/spinner/index';
import CancelIcon from '../../themes/images/cancel.svg';
import country from 'country-list-js';
import permissions from '../../utils/permissions';
import server from '../../services/server';
import app from '../../services/app';
import './index.scss';

class CreateUser extends Component {
  constructor(props) {
    super(props);

    let leads = this.props.location.pathname.toLowerCase() == "/createlead";
    this.state = {
      
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      leads: leads ? 1 : 0,
      gender: 'Male',
      lang: '',
      host: '',
      source: '',
      country: '',
      password: '',
      countryCode: '',
      confirmPassword: '',
      showCallBack: false,
      showCallBackTxt: '',
      showCallBackHead: '',
      
      desks: [],
      alias: [],
      clients: [],
      leads_data: [],

      firstNameError: null,
      lastNameError: null,
      emailError: null,
      passwordError: null,
      phoneNumberError: null,
      confirmPasswordError: null,

      csv_user: 0,
      csv_users: [],
      csv_options: [],
      selectedTab: leads ? "Lead" : "User",
      _selectedTab: leads ? "Lead" : "User",
      country_names: [],
      showSpinner: false,
      showRestrictions: false,
    };

    this.showSpinner = false;
    this.profile = app.profile();
    this.id = this.profile.user_id;
    this.permissions = [];
  }

  shuffleCSV = (i) => {
    let ki = i-1;
    this.switchValues(this.state.csv_users[ki], ki);
  }

  switchValues = (v, i = 0) => {
    let al = this.state.alias;
    this.setState({
      csv_user: i,
      firstName: v[al['first_name']].ucwords(),
      lastName:  v[al['last_name']].ucwords(),
      email:     v[al['email']],
      phone:     v[al['phone']],
      country:   v[al['country']],
      source:    al['source'] == '- Not stated -' ? 'Imported' : v[al['source']]
    });
    
    $("[name=firstName]").val(v[al['first_name']].ucwords());
    $("[name=lastName]").val(v[al['last_name']].ucwords());
    $("[name=email]").val(v[al['email']]);
    $("[name=phone]").val(v[al['phone']]);
    $("[name=country]").val(v[al['country']]);
    $("[name=source]").val(v[al['source']]);
  }

  async componentDidMount() {
    await this.getDesks();
    if(app.master()) {
      await this.getAllClients();
    }
    
    var country = require('country-list-js');
    var country_names = country.names().sort();
    this.setState({country_names: country_names, country: country_names[0]});
  }

  toggleTabs = (e) => {
    this._toggleTabs(e.currentTarget.querySelector('div').innerHTML.trim());
  }

  createAdmin = () => {
    this.setState({showRestrictions: false});
    this.submitForm(false);
  }

  _toggleTabs = (tab) => {
    this.setState({
      selectedTab: tab.toLowerCase(),
      _selectedTab: tab
    });
  }

  getAllClients = async () => {
    try {
      let clients = await server.getAllClients();
      this.setState({clients: clients.data});
    } catch(e) {
      return e;
    }
  }

  getDesks = async () => {
    try {
      let d = await server.getDesks();
      this.setState({desks: d.data.desks});
      if(d.data.desks.length) {
        this.setState({lang: d.data.desks[0]["lang"]});
      }
    } catch(e) {
      return e;
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  submitForm = async (e) => {
    e && e.preventDefault();    
    let lds = this.props.location.pathname.toLowerCase() == "/createlead";
    if(this.state.csv_users.length) {
      this.setState({ showSpinner: !this.state.showSpinner });
      const response = await server.leads(this.state.leads_data);
      this.setState({ showSpinner: !this.state.showSpinner, showCallBack: true, showCallBackTxt: response.data.message, showCallBackHead: 'Uploaded' });
      setTimeout(() => {
        window.location.href = "";
      }, 1500);
      return false;
    }
    const {
      firstName,
      lastName,
      phone,
      country,
      gender,
      email,
      host,
      leads,
      lang,
      password,
      confirmPassword,
      selectedTab
    } = this.state;
    const nameRegex = /^[a-zA-Z]{3,}$/;
    const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    this.clearErrors();

    let err = false;
    if (!nameRegex.test(firstName)) {
      err = true;
      this.setState({ firstNameError: 'Invalid first name specified' });
    }
    if (!nameRegex.test(lastName)) {
      err = true;
      this.setState({ lastNameError: 'Invalid last name specified' });
    }
    if (email.length < 5) {
      err = true;
      this.setState({ emailError: 'Invalid email' });
    }
    if (phone.length < 6) {
      err = true;
      this.setState({ phoneNumberError: 'Invalid phone number' });
    }
    if(!lds) {
      if (!password.length) {
        err = true;
        this.setState({ passwordError: 'Invalid password' });
        if(!confirmPassword.length) {
            this.setState({ confirmPasswordError: 'Invalid confirm password' });
        }
      }
      if (password !== confirmPassword) {
        err = true;
        this.setState({ confirmPasswordError: 'Passwords must match' });
      }
    }

    if (!err) {

      this.setState({ showSpinner: !this.state.showSpinner });

      // let nph = phone.trim();
      //     nph = nph.charAt(0) == 0 ? nph.slice(1) : nph;
      // let phone_number = countryCode+""+nph;

      try {
        let submit = {
          first_name: firstName,
          last_name: lastName,
          email,
          phone_number: phone, // phone_number,
          password,
          gender,
          host,
          leads,
          lang,
          country: country,
          role: selectedTab,
          source: 'Admin',
          aid: app.userid(),
          auto: true
        };

        if(submit.leads > 0) {
          submit["utype"] = -1;
        }

        const response = await server.register(submit);
        window.location.href = "";
      } catch (error) {
        this.setState({ showSpinner: !this.state.showSpinner });
        if (!error.response) {
          return error;
        }

        const errorMessage = error.response.data.message;

        if (errorMessage.toLowerCase().match('password')) {
          this.setState({ passwordError: errorMessage });
        }

        if (errorMessage.toLowerCase().match('username')) {
          this.setState({ emailError: errorMessage });
        }
      }
    }
  }

  clearErrors = () => {
    this.setState({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      phoneNumberError: null,
      genderError: null,
      passwordError: null,
      confirmPasswordError: null,
    });
  }

  changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        if(results.data.length) {
          this.setState({csv_users: results.data, csv_options: Object.keys(results.data[0])});
          $(".match-mod-container").removeClass("hide");
          // this.switchValues(results.data[0], 0);
        }
      },
    });
  }

  MatchSel = ({label, ky}) => {
    let opts = this.state.csv_options;
    return (
      <div className='form-group' key={ky}>
        <label data-key={ky}>{label} :</label>
        <select className="form-control" id={ky+"_alias"}>
          {
            opts.map((otv, otk) => {
              return (
                <option>{otv}</option>
              )
            })
          }
          {ky == 'source' ? <option>- Not stated -</option> : null}
        </select>
      </div>
    )
  }

  matchAlias = () => {
    let leads_data = [];
    let alias = {
      last_name:    $("#last_name_alias").val(),
      first_name:   $("#first_name_alias").val(),
      email:        $("#email_alias").val(),
      phone:        $("#phone_number_alias").val(),
      country:      $("#country_alias").val(),
      source:       $("#source_alias").val()
    };
    this.state.csv_users.forEach((v, k) => {
      leads_data.push({
        first_name: v[alias['first_name']].ucwords(),
        last_name:  v[alias['last_name']].ucwords(),
        email:      v[alias['email']],
        phone:      v[alias['phone_number']],
        country:    v[alias['country']],
        source:     alias['source'] == '- Not stated -' ? 'Imported' : v[alias['source']]
      });
    });
    this.setState({alias, leads_data});
    setTimeout(() => {
      $(".match-mod-container").addClass("hide");
      this.switchValues(this.state.csv_users[0], 0);
    }, 10);
  }

  render() {
    let ps = this.permissions;
    let no = parseInt(ps.length / 2);
    let p1 = ps.slice(0, no);
    let p2 = ps.slice(no);
    let _islead = this.props.location.pathname.toLowerCase() == "/createlead";
    let showSpinner = this.state.showSpinner;

    return (
      <Container>
        {showSpinner ? <Spinner showSpinner={showSpinner} /> : null}
        <CallBack show={this.state.showCallBack} cancel={(e) => this.setState({showCallBack: false, showCallBackTxt: ''})} head={this.state.showCallBackHead} text={this.state.showCallBackTxt} error={null} />
        <div className='match-mod-container hide'>
          <div className='match-mod'>
            <h1>Please match the fields to corresponding data points</h1>
            <this.MatchSel label='First name *' ky='first_name' />
            <this.MatchSel label='Last name *' ky='last_name' />
            <this.MatchSel label='Email *' ky='email' />
            <this.MatchSel label='Phone number *' ky='phone_number' />
            <this.MatchSel label='Country *' ky='country' />
            <this.MatchSel label='Source' ky='source' />
            <div className='btn-group'>
              <button className='mmcant' onClick={() => {
                $(".match-mod-container").addClass("hide");
                window.location.href = "";
              }}>Cancel</button>
              <button className='mmcont' onClick={() => {
                this.matchAlias();
              }}>Continue</button>
            </div>
          </div>
        </div>
        <div className="col-12" id="cadmin-container">
          <div className='createadmin-section'>
            <Spinner showSpinner={this.showSpinner} />
            <Breadcrumbs breads={"Home, Create, Create "+(_islead ? "Lead" : "User")} />
            <RestrictionNav selectedTab={this.state.selectedTab} handleClick={this.toggleTabs} />

            {_islead ? <button className='ucsv' onClick={() => {
              $("[name=user_csv]").trigger("click");
            }}>+ Upload Csv</button> : null}
            <div className='createadmin-box'>
              <div className='deposit'>
                <form className='restriction-action' autoComplete="new-password" onSubmit={this.submitForm}>
                  <div style={{display: "none"}}>
                    <input
                      type="file"
                      name="user_csv"
                      accept=".csv"
                      onChange={this.changeHandler}
                    />
                  </div>
                  <div className='deposit-flex'>
                    <div className='deposit-flex-item-1'>
                      <label>Firstname</label>
                      <div className='bal-sym'>
                        <input type='text' className="form-control" name='firstName' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                      </div>
                      <p className='red'>{this.state.firstNameError ? `*${this.state.firstNameError}` : null}</p>
                    </div>


                    <div className='deposit-flex-item-1 ml-10'>
                      <label>Lastname</label>
                      <div className='bal-sym'>
                        <input type='text' className="form-control" name='lastName' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                      </div>
                      <p className='red'>{this.state.lastNameError ? `*${this.state.lastNameError}` : null}</p>
                    </div>

                    <div className='deposit-flex-item-1 ml-10'>
                      <label>Email</label>
                      <div className='bal-sym'>
                        <input type='email' className="form-control" name='email' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                      </div>
                      <p className='red'>{this.state.emailError ? `*${this.state.emailError}` : null}</p>
                    </div>
                  </div>

                  <div className='deposit-flex'>
                    <div className='deposit-flex-item-1'>
                      <label>Phone Number</label>
                      <div className='bal-sym'>
                        <input type='text' className="form-control" name='phone' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                      </div>
                      <p className='red'>{this.state.phoneNumberError ? `*${this.state.phoneNumberError}` : null}</p>
                    </div>

                    {
                      this.state.csv_users.length > 0 ? null :
                      <div className='deposit-flex-item-1 ml-10'>
                        <label>Gender</label>
                        <select className="form-control" name="gender" onChange={this.handleInputChange}>
                          <option>Male</option>
                          <option>Female</option>
                        </select>
                      </div>
                    }

                    <div className='deposit-flex-item-1 ml-10'>
                      <label>Country</label>
                      <select className="form-control" name="country" onChange={this.handleInputChange} value={this.state.country}>
                        {
                          this.state.country_names.map((cn) => (
                            <option key={Math.random()+'_'+Math.random()}>{cn}</option>
                          ))
                        }
                      </select>
                    </div>

                    {
                      this.state.csv_users.length > 0 ?
                      <div className='deposit-flex-item-1 ml-10'>
                        <label>Source</label>
                        <div className='bal-sym'>
                          <input type='text' className="form-control" name='source' readOnly={this.state.csv_users.length} onChange={this.handleInputChange} Value=""/>
                        </div>
                      </div> : null
                    }

                  </div>

                  {_islead ? null : <>
                    <div className='deposit-flex'>
                      <div className='deposit-flex-item-1'>
                        <label>Password</label>
                        <div className='bal-sym'>
                          <input type='password' className="form-control" name='password' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                        </div>
                        <p className='red'>{this.state.passwordError ? `*${this.state.passwordError}` : null}</p>
                      </div>

                      <div className='deposit-flex-item-1 ml-10'>
                        <label>Confirm Password</label>
                        <div className='bal-sym'>
                          <input type='password' className="form-control" name='confirmPassword' onChange={this.handleInputChange} autoComplete="new-password" Value=""/>
                        </div>
                        <p className='red'>{this.state.confirmPasswordError ? `*${this.state.confirmPasswordError}` : null}</p>
                      </div>

                      {app.master() ? <div className='deposit-flex-item-1 ml-10'>
                        <label>Client</label>
                        <select name="host" className="form-control" onChange={this.handleInputChange}>      
                        {
                          this.state.clients.map((cl) => (
                            <option key={"cl-"+cl.id}>{cl.domain}</option>
                          ))
                        }
                        </select>
                      </div> : <div className='deposit-flex-item-1 ml-10'>
                        <label>Desk</label>
                        <select name="lang" className="form-control" onChange={this.handleInputChange}>      
                        {
                          this.state.desks.map((de) => (
                            <option key={"de-"+de.id}>{de.lang}</option>
                          ))
                        }
                        </select>
                      </div>}
                    </div>
                    </>
                  }

                  <div className='deposit-flex' style={{justifyContent: "flex-start"}}>
                    <input type='button' value={(this.props.location.pathname.toLowerCase() == "/createlead" ? "SAVE" : "REGISTER")+(this.state.csv_users.length > 1 ? " ALL" : "")} className='restriction-submit-btn' onClick={(e) => this.submitForm(e)}/>
                    {
                      this.state.csv_users.length > 1 ? (
                        <div>
                          <label style={{marginLeft: "1em", marginRight: "10px"}}>Show: </label>
                          <select style={{width: "60px", textAlign: "center", height: "30px"}} defaultValue={this.state.csv_user} onChange={(e) => {
                            this.shuffleCSV(e.target.value);
                          }}>
                            {
                              this.state.csv_users.map((k, v) => (
                                <option>{(v+1)}</option>
                              ))
                            }
                          </select>
                        </div>
                      ) : null
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default CreateUser;