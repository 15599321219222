import React, { Component } from 'react';
import $ from 'jquery';
import { Link, withRouter } from 'react-router-dom';
import Container from '../Container/Index';
import Spinner from '../../components/spinner/index';
import server from '../../services/server';
import app from '../../services/app';
import { Created, MsgAlert } from '../../components/popups/index';
import AddAccount from '../../components/addAccount/index';
import './Accounts.scss';

class Accounts extends Component {

    constructor (props) {
        super(props);

        this.state = {
            showCreated: false,
            showSpinner: false,
            addAcc: false,
            loaded: false,
            accounts: app.accountList(),
            cid: "",
            ctype: "",
            message: "",
            showCallBack: "",
            email: app.email()
        }
    }

    async componentDidMount () {
        this.accountList();
    }

    accountList = async () => {
      try {
        this.setState({addAcc: false});
        await server.getProfile().then((gp) => {
          app.profile(gp.data.profile);
          this.setState({accounts: gp.data.profile.accounts})
        });
        this.setState({showSpinner: false});
      } catch(e) {
        this.setState({showSpinner: false});
        return e;
      }
    }

    render () {
        let { accounts, addAcc, showSpinner, showCreated, showCallBack, message } = this.state;
        return (
            <Container>
                { addAcc ?
                    <AddAccount
                        sending={() => this.setState({showSpinner : true})}
                        unsend={() => this.setState({showSpinner : false})}
                        sent={() => { this.accountList(); }}
                        showCreated={(i, t) => this.setState({showSpinner: false, showCreated: true, cid: i, ctype: t})}
                        cancelClick={(e) => this.setState({addAcc: false})}
                    />
                : null }
                {showCallBack ? <MsgAlert cancel={(e) => this.setState({showCallBack: false})} message={message} /> : null}
                <Created show={this.state.showCreated} type={this.state.ctype} id={this.state.cid} cancel={(e) => this.setState({showCreated: false})} />
                <Spinner showSpinner={showSpinner} />
                <div className="acc-page">
                    <h3 className="h-title">
                        <span>{app.l("Trading accounts")}</span>
                        <button className="btn app-btn add-acc not-imobile" onClick={() => {
                            if(app.isVerified()) {
                                this.setState({addAcc: true});
                            } else {
                                this.setState({showCallBack: true, message: "ACCOUNT NOT VERIFIED|Please contact support to verify your documents."});
                            }
                        }}>{app.l("Add Account")}</button>
                    </h3>

                    {accounts.length ?
                    <div className={"trade-history imobile"}>
                        <ul className="tmob-hist">
                            {accounts.map((acc, k) => {
                                let kiy  = "acc-"+k, dm = k%2 == 1;
                                let pre  = acc.account_name.split("-")[0];
                                let free = acc.balance;
                                let used = acc.real_balance > 0 ? app.real(acc.balance, acc.real_balance) : 0;
                                return (
                                    <li key={kiy}>
                                        <div>
                                            <span className="coll lft"><span><span className={"att "+pre.toLowerCase()} style={{top: "7px"}}>{pre.toUpperCase()}</span></span></span>
                                            <span className="coll rgt"><small>{app.l("ACCOUNT NAME")}</small><span>{acc.account_label.length ? acc.account_label : acc.account_name.toUpperCase()}</span></span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("LEVERAGE")}</small><b>{acc.leverage}</b></span>
                                            <span className="coll rgt"><small>{app.l("ACCOUNT TYPE")}</small><span>{acc.atype || "Basic"}</span></span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("FREE MARGIN")}</small><span>{app.symbol(acc.currency)}{free}</span></span>
                                            <span className="coll rgt"><small>{app.l("USED MARGIN")}</small><span>{app.symbol(acc.currency)}{used}</span></span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("BALANCE")}</small><span>{app.symbol(acc.currency)}{acc.balance}</span></span>
                                            <span className="coll rgt"><small>{app.l("CREDIT")}</small><span>{app.symbol(acc.currency)}{acc.credit}</span></span>
                                        </div>
                                        <div>
                                            <span className="coll lft"><small>{app.l("EQUITY")}</small><span>{"--"}</span></span>
                                            <span className="coll rgt"><small>{app.l("M. LEVEL")}</small><span>{"--"}</span></span>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>: null}

                    <h3 className="h-title imobile">
                        <button className="btn app-btn add-acc" onClick={() => {
                            if(app.isVerified()) {
                                this.setState({addAcc: true});
                            } else {
                                this.setState({showCallBack: true, message: "ACCOUNT NOT VERIFIED|Please contact support to verify your documents."});
                            }
                        }}>{app.l("Add Account")}</button>
                    </h3>

                    <div className="table-responsive not-imobile">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{app.l("ACCOUNT NAME")}</th>
                                    <th>{app.l("ACCOUNT TYPE")}</th>
                                    <th>{app.l("LEVERAGE")}</th>
                                    <th>{app.l("BALANCE")}</th>
                                    <th>{app.l("USED MARGIN")}</th>
                                    <th>{app.l("FREE MARGIN")}</th>
                                    <th>{app.l("EQUITY")}</th>
                                    <th>{app.l("OPEN P/L")}</th>
                                    <th>{app.l("CREDIT")}</th>
                                    <th>{app.l("M. LEVEL")}</th>
                                    <th style={{minWidth: "50px"}}>{app.l("ACTION")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accounts.map((acc, k) => {
                                    let kiy = "acc-"+k, dm = k%2 == 1;
                                    let pre = acc.account_name.split("-")[0];
                                    let used = parseFloat(acc.balance) - parseFloat(acc.real_balance);
                                    return (
                                        <tr key={kiy}>
                                            <td className="icent"><span className={"att "+pre.toLowerCase()}>{pre.toUpperCase()}</span></td>
                                            <td>{acc.account_label.length ? acc.account_label : acc.account_name.toUpperCase()}</td>
                                            <td>{acc.atype || "Basic"}</td>
                                            <td>{acc.leverage}</td>
                                            <td>{app.symbol(acc.currency)}{app.real(acc.balance, acc.real_balance)}</td>
                                            <td>{app.symbol(acc.currency)}{used}</td>
                                            <td>{app.symbol(acc.currency)}{acc.balance}</td>
                                            <td>{"--"}</td>
                                            <td>{"--"}</td>
                                            <td>{app.symbol(acc.currency)}{acc.credit}</td>
                                            <td>{"--"}</td>
                                            <td style={{minWidth: "50px"}}>
                                                <Link to={"/Transactions?a="+acc.account_name} className="btn app-btn dp-btn">{app.l("Deposit")}</Link>
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
        );
    }
}

export default withRouter(Accounts);