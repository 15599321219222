import React, { Component, createRef } from 'react';
import { createChart, CrosshairMode } from 'lightweight-charts';
import $ from 'jquery';
import moment from 'moment';
import moment_tz from 'moment-timezone';
import server from '../../services/server';
import app from '../../services/app';
import './switchbox.scss';
import './index.scss';

import candleGrf from './graph/candle.png';
import lineGrf from './graph/line.png';
import areaGrf from './graph/area.svg';
import barGrf from './graph/bar.png';
import histGrf from './graph/hist.png';

import tci from '../../Assets/tci.svg';
import Tarrow from '../../themes/images/tradeDashboard/t_arrow.svg';
import fullscreen from '../../themes/images/tradeDashboard/fullscreen.svg';
import efullscreen from '../../themes/images/tradeDashboard/exit-fullscreen.svg';
import Wave from '../../themes/images/tradeDashboard/wave.svg';
import Wave2 from '../../themes/images/tradeDashboard/wave.svg';

class ChartModule extends Component {
  constructor(props) {
    super(props);

    this.chartContainerRef = createRef();
    this.chart = createRef();
    this.resizeObserver = createRef();
    this.profile = app.profile();
    this.pair = this.props.pair;
    this.data = [];
    this.loadSeries = true;
    this.currentGrpahType = this.props.graph;
    this.lineDataSeries = [];
    this.historyData = [];
    this.seriesIterator = 0;
    this.loadHistory = 0;
    this.lastPlotable = {};
    this.duplicator = "";
    this.graphSwitcher = false;
    this.lastServerResponse = [];
    this.dataPlotSeries = [];
    this.historySeries = [];
    this.historySeriesPair = "";
    this.realTimeListener = true;
    this.currentPairData = null;
    this.lastFetch = null;
    this.destroyGraph = false;
    this.col = this.props.ki > 1 ? '6' : this.props.col;
    this.int_list = {
      "1D": "1m,2m,5m,15m,30m,1h",
      "1W": "1m,2m,5m,15m,30m,1h,1d,5d",
      "1M": "2m,5m,15m,30m,1h,1d,5d,1wk",
      "6M": "1h,1d,5d,1wk,1mo,3mo",
      "1Y": "1h,1d,5d,1wk,1mo,3mo",
      "2Y": "1d,5d,1wk,1mo,3mo"
    };

    this.int_name = {
      "1m": "1min",
      "2m": "2mins",
      "5m": "5mins",
      "15m": "15mins",
      "30m": "30mins",
      "1h": "1H",
      "1d": "1D",
      "5d": "5D",
      "1wk": "1WK",
      "1mo": "1M",
      "3mo": "3M"
    };

    this.historyLevel = this.props.historyLevel;
    this.intervalLevel = this.props.intervalLevel;
    let alp   = app.allPairs();
    let forex = Object.keys(alp)[0];

    this.state = {
      selectedOption: this.props.selectedOption,
      allPairs: alp,
      currentPairs: app.allPairs()[forex],
      pair: '',
      selectedPair: this.props.pair,
      currentPairData: null,
      buy: 0,
      sell: 0,
      spread: 0,
      high: 0,
      h: 0,
      beginner: this.props.beginner,
      low: 0,
      confirmtext: "",
      advance_loaded: false,
      intervalLevel: this.props.intervalLevel,
      historyLevel: this.props.historyLevel,
      buyandsellModal: false,
      buyandsellAct: 'buy',
      buyandsellConfirmed: false,
      showLoader: false,
      instruments: this.props.instruments,
      graphite: localStorage.getItem("avariz_graphite") || 0
    };

  }

  switchGraphTypeTo = async (type) => {
    this.props.changeGraph(type);
    this.currentGrpahType  = type;
    this.graphSwitcher     = true;
    this.chart.current.removeSeries(this.chartSeries);
    this.loadHistorical();
  }

  loadHistorical = async (h = null) => {
    h = h ? h : this.state.historyLevel;
    this.setGraphType(this.currentGrpahType, 0);

    setTimeout(() => {
      $(".instrument-icons li").removeClass("_active");
    }, 10);

    let upm = {"1d": [7, "days"], "1w": [7, "days"], "1m": [1, "months"], "6m": [6, "months"], "1y": [12, "months"], "2y": [2, "years"]};
    let ulm = {"1w": [1, "year"], "1m": [2, "years"], "6m": [2, "years"], "1y": [2, "years"], "2y": [4, "years"]};
        upm = upm[h.toLowerCase()];

    if(this.setGraphType(this.currentGrpahType, 3)) {
      this.setState({showLoader: true});
      this.loadSeries = false;
      this.setState({historyLevel: h});
      this.chart.current.applyOptions({
        timeScale: {
          rightOffset: 12,
          barSpacing: 3,
          fixLeftEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
          borderVisible: false,
          borderColor: '#fff000',
          visible: true,
          timeVisible: true,
          secondsVisible: false,
        },
      });

      try {

        const convertDateToAnotherTimeZone = (date, timezone) => {
          const dateString = date.toLocaleString('en-US', {
            timeZone: timezone
          });
          return new Date(dateString);
        }

        const getOffsetBetweenTimezonesForDate = (date, timezone1, timezone2) => {
          const timezone1Date = convertDateToAnotherTimeZone(date, timezone1);
          const timezone2Date = convertDateToAnotherTimeZone(date, timezone2);
          return timezone1Date.getHours() - timezone2Date.getHours();
        }

        let graphOffset = 0, graphOff = 0;
        let pairMaster  = this.treatPair(this.pair);

        this.historyData = [];
        this.historySeries = [];

        if(h.toLowerCase() != "1d") {
          this.historySeriesPair = "";

          this.loadSeries = false;
          this.seriesIterator = 0;

          let il = this.props.intervalLevel.toLowerCase().replace(/[0-9]+/g,'');

          if(il == "d" || il == "wk" || il == "mo") {
            upm = ulm[h.toLowerCase()];
          }

          if(this.props.intervalLevel.toLowerCase() == "3mo") {
            upm = [4, "years"];
          }

          let history = await server.historicalData(pairMaster, "1d", {
            from: moment().subtract(upm[0], upm[1]).unix(),
            to: moment().unix()
          }, this.props.intervalLevel);

          this.setState({showLoader: false});

          let data = history.data.result;
          
          let _nlc = 0, _lop = false, _ltm = "", _ltx = 0;

          const _off = getOffsetBetweenTimezonesForDate(new Date, Intl.DateTimeFormat().resolvedOptions().timeZone, data.meta.exchangeTimezoneName);
          graphOff  = (_off+1)*3600;

          for (let r = 0; r < data.timestamp.length; r++) {

            let _opn = data.indicators.quote[0].open[r];
            if(_opn == null) {
              if(_nlc == 0) {
                _ltm = data.timestamp[r];
                _ltx = r;
              }
              _nlc += 1;
            } else {
              _nlc = 0;
            }
            if(_nlc > 0 && data.indicators.quote[0].open[data.timestamp.length - 1] == null) {
              _lop = true;
            }

            let plt = {
              Date:    data.timestamp[r]+graphOff,
              Open:    data.indicators.quote[0].open[r],
              High:    data.indicators.quote[0].high[r],
              Low:     data.indicators.quote[0].low[r],
              Close:   data.indicators.quote[0].close[r],
              Volume:  data.indicators.quote[0].volume[r]
            }
            this.historyData.push(this.graphData2(plt, pairMaster));
          }

          _ltx = _ltx > 0 ? data.timestamp.length - _ltx : 0;

          if(il == "d" || il == "wk" || il == "mo") {
            _ltx = 0;
          }

          if(this.props.selectedOption.toLowerCase() == "crypto") {
            _ltx = 0;
          }

          this.chart.current.removeSeries(this.chartSeries);
          this.setGraphType(this.currentGrpahType, 0);
          this.plotGraph(this.historyData);
          // this.chart.current.timeScale().fitContent();
          this.chart.current.timeScale().setVisibleRange({
            from: data.timestamp[data.timestamp.length > 50 ? data.timestamp.length - 50 - _ltx : 0]+graphOff,
            to: data.timestamp[data.timestamp.length - 1 - _ltx]+graphOff
          });
          this.chart.current.timeScale().scrollToPosition(1);
          
          ++this.loadHistory;
          return true;
        }

        this.historySeriesPair = pairMaster;

        let check_for_update = async (pair, firstUpdate = false, _from = false, _pre = null) => {
          if(!this.realTimeListener || this.destroyGraph || this.historyData.length) {
            console.log("Update overuled");
            return true;
          }
          if(this.historySeriesPair == pair && this.realTimeListener && !this.destroyGraph) {
            setTimeout(async () => {
              // console.log("-- checking_for_update for", pair);
              if(this.historySeriesPair == pair) {
                try {
                  let _unit = _from.end;
                  if(_from) {
                    if(_from.end > moment().unix()) {
                      _unit = _pre.end;
                      // console.log("(-)", _from.end, "(>)", _unit);
                    }
                  }
                  if(firstUpdate) {
                    this.setState({showLoader: true});
                  }
                  let _history = this.graphSwitcher ? this.lastServerResponse : await server.historicalData(pair, "1m", {
                    from: _from ? _unit - (3600 * 6) : moment().subtract(7, "days").unix(),
                    to: _from ? _unit : moment().unix()
                  }, this.props.intervalLevel);
                  this.lastServerResponse = _history;
                  let _data               = _history.data;
                  if(_data && _data.result) {
                      _data               = _data.result;
                  this.graphSwitcher      = false;
                  } else {
                    return null;
                  }
                  this.setState({showLoader: false});

                  // console.log(_history.data.result.meta.currentTradingPeriod, _from);

                  const offset = getOffsetBetweenTimezonesForDate(new Date, Intl.DateTimeFormat().resolvedOptions().timeZone, _data.meta.exchangeTimezoneName);
                  graphOffset  = (offset+1)*3600;

                  let _plotHistory = () => {
                    if(this.historySeriesPair == pair) {
                      let prelength = this.historySeries.length;
                      this.historySeriesPair = pair;
                      this.historySeries = [];
                      let nlc = 0, lop = false, ltm = "", ltx = 0;
                      
                      try {
                        for (let x = 0; x < _data.timestamp.length; x++) {
                          let lastOpen = x == (_data.timestamp.length - 1);
                          let _plt;
                          
                          let opn = _data.indicators.quote[0].open[x];
                          if(opn == null) {
                            if(nlc == 0) {
                              ltm = _data.timestamp[x];
                              ltx = x;
                            }
                            nlc += 1;
                          } else {
                            nlc = 0;
                          }
                          if(nlc > 0 && _data.indicators.quote[0].open[_data.timestamp.length - 1] == null) {
                            lop = true;
                          }

                          if(lastOpen) {
                            let gst = app.guessTimate(_data.indicators.quote[0].open[x], lastOpen);
                            _plt = {
                              Date:  _data.timestamp[x]+graphOffset, Open: gst, High: gst, Low: gst, Close: gst, Volume: _data.indicators.quote[0].volume[x]
                            };
                          } else {
                            _plt = {
                              Date:  _data.timestamp[x]+graphOffset,
                              Open:  app.guessTimate(_data.indicators.quote[0].open[x], lastOpen),
                              High:  _data.indicators.quote[0].high[x],
                              Low:  _data.indicators.quote[0].low[x],
                              Close:  _data.indicators.quote[0].close[x],
                              Volume:  _data.indicators.quote[0].volume[x]
                            }
                          }
                          let plot = this.graphData2(_plt, pair);
                          this.historySeries.push(plot);
                        }
                      } catch (error) {
                        return null;
                      }

                      ltx = ltx > 0 ? _data.timestamp.length - ltx : 0;

                      if(this.props.selectedOption.toLowerCase() == "crypto") {
                        ltx = 0;
                      }

                      if(this.historySeries.length) {
                        this.loadSeries = true;
                        this.seriesIterator = 0;
                        this.chart.current.removeSeries(this.chartSeries);
                        this.setGraphType(this.currentGrpahType, 0);
                        this.plotGraph(this.historySeries);
                        // this.plotGraph(this.historyData.concat(this.historySeries));

                        if(firstUpdate) {
                          // if(_data.timestamp.length > 100) {
                            this.chart.current.timeScale().setVisibleRange({
                              from: _data.timestamp[_data.timestamp.length > 50 ? _data.timestamp.length - 50 - ltx : 0]+graphOffset,
                              to: _data.timestamp[_data.timestamp.length - 1 - ltx]+graphOffset
                            });
                          // } else if(_from) {
                          //   this.chart.current.timeScale().setVisibleRange({
                          //     from: _data.timestamp[_data.timestamp.length - 1]+graphOffset - (this.props.selectedOption.toLowerCase() == "forex" ? ((3600 * 4) - 1800) : 3600),
                          //     to: _data.timestamp[_data.timestamp.length - 1]+graphOffset - (this.props.selectedOption.toLowerCase() == "forex" ? ((3600 * 3) - 1800) : 0)
                          //   });
                          // } else {
                          //   this.chart.current.timeScale().setVisibleRange({
                          //     from: _data.timestamp[parseInt(_data.timestamp.length/2)]+graphOffset,
                          //     to: _data.timestamp[_data.timestamp.length - 1]+graphOffset
                          //   });
                          // }
                        } else {
                          if(prelength < this.historySeries.length) {
                            this.chart.current.timeScale().scrollToPosition(1, true);
                          }
                        }
                      }
                    }
                  }
                  if(this.historySeriesPair == pair) {
                    _plotHistory();
                    if(!this.historySeries.length && !_from) {
                      console.log("No data for", pair, "oo");
                      check_for_update(
                        pair,
                        true,
                        _history.data.result.meta.currentTradingPeriod.post,
                        _history.data.result.meta.currentTradingPeriod.pre
                      );
                    } else if(!_from) {
                      setTimeout(() => {
                        if(this.historySeriesPair == pair) {
                          _plotHistory();
                        }
                      }, 2.65 * 1000);
                      check_for_update(pair);
                    }
                  }
                } catch (e) {
                  setTimeout(() => {
                    check_for_update(pair);
                  }, 100);
                  console.log("-- Update ERR");
                  throw e;
                  return e;
                }
              }
            }, firstUpdate ? 0 : 5 * 1000);
          }
        }

        check_for_update(pairMaster, true);

      } catch(e) {
        this.setState({showLoader: false});
        return e;
      }
      this.setState({showLoader: false});
    }
  }

  setGraphType = async (type, no = 1) => {
    this.seriesIterator = 0;
    var option = {
      upColor: '#00B061',
      downColor: '#FF3031',
      scaleMargins: { bottom: 0.4, top: 0.4 },
      entireTextOnly: true,
      borderDownColor: '#FF3031',
      borderUpColor: '#00B061',
      wickDownColor: '#c4c4c4',
      wickUpColor: '#c4c4c4',
    };
    if(no) {
      this.chart.current.removeSeries(this.chartSeries);
    }
    this.currentGrpahType = type;
    if(type == "candle") {
      this.chartSeries = this.chart.current.addCandlestickSeries(option);
    } else if(type == "line") {
      this.chartSeries = this.chart.current.addLineSeries(option);
    } else if(type == "area") {
      this.chartSeries = this.chart.current.addAreaSeries(option);
    } else if(type == "bar") {
      this.chartSeries = this.chart.current.addBarSeries(option);
    } else if(type == "hist") {
      this.chartSeries = this.chart.current.addHistogramSeries(option);
    }

    this.chart.current.applyOptions({
        watermark: {
            color: 'rgba(67, 95, 118, 0.4)',
            visible: true,
            text: '',
            fontSize: 24,
            horzAlign: 'left',
            vertAlign: 'bottom',
        },
        priceScale: {
            autoScale: true,
            alignLabels: true,
            drawTicks: true,
            scaleMargins: { bottom: 0.1, top: 0.2 }
        },
        localization: {
          locale: 'en-US',
          priceFormatter: (price) => {
            return Number(String(price).substr(0, 7));
          }
        },
    });

    return true;
  }

  componentWillUnmount() {
    this.realTimeListener = false;
  }

  componentWillUpdate() {
    if(
        this.props.historyLevel != this.historyLevel
      ) {
      this.destroyGraph = true;
    }
    if(this.props.ki == 1 && this.col != this.props.col) {
      this.col = this.props.col;
      setTimeout(() => {
        this.chart.current.timeScale().fitContent();
        this.chart.current.timeScale().scrollToPosition(1);
        $(window).trigger("resize");
      }, 100);
    }
  }
  
  zoom = (s = true) => {
    let range = this.chart.current.timeScale().getVisibleRange();
    if(range) {
      let ag = ((range.to + range.from) / 2);
      let av = ((range.to - range.from) / 2);

      let fr_pl = ((ag + range.from) / 2);
      let to_pl = ((ag + range.to) / 2);

      let fr_mi = range.from - av;
      let to_mi = range.to + av;

      this.chart.current.timeScale().setVisibleRange({
        from: s ? fr_pl : fr_mi,
        to: s ? to_pl : to_mi
      });
    }
  }

  async componentDidMount() {
    this.realTimeListener = true;
    this.setState({ showLoader: true });
    this.chart.current = createChart(this.chartContainerRef.current, {
      width:  this.chartContainerRef.current.clientWidth,
      height: this.chartContainerRef.current.clientHeight,
      layout: {
        backgroundColor: '#141517',
        textColor: 'rgba(255, 255, 255, 0.9)',
      },
      grid: {
        vertLines: {
          color: '#353945',
        },
        horzLines: {
          color: '#353945',
        },
      },
      rightPriceScale: {
        visible: true,
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      priceScale: {
        borderColor: '#141517',
      },
      timeScale: {
        borderColor: '#353945',
        timeVisible: true,
        secondsVisible: false,
      },
    });

    if(!this.props.beginner) {
      this.loadTV();
    }

    $(window).on("loadTV", () => {
      this.loadTV(false);
    });

    $(window).on("destroyGraph", () => {
      this.destroyGraph = true;
    });

    $(document).on("click", (e) => {
      if(!$(e.target).hasClass("grd")) {
        $(".gr-dropdown").each((k, v) => {
          $(v).parent().removeClass("_active");
        });
      }
    });
    
    $('.trade-comp-container').on('fullscreenchange webkitfullscreenchange mozfullscreenchange', () => {
      if(!document.fullscreenElement) {
        $('.trade-comp-container').removeClass("exit");
        $(window).trigger("resize");
        this.resetGraph();
      }
    });

    $(window).resize(() => {
      if($("#hr-resizable").length) {
        ro.observe(document.getElementById("hr-resizable"));
      }
      if(this.realTimeListener && !this.destroyGraph) {
        let w = this.chartContainerRef.current.clientWidth;
        let h = this.chartContainerRef.current.clientHeight;
        this.chart.current.applyOptions({width: w, height: h});
        this.resetGraph();
      }
    });

    await this.loadHistorical();

    let last_height = 0;
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        
        if(!$(".hr-resizable").hasClass("rz")) {
          last_height = cr.height;
        } $(".hr-resizable").addClass("rz");

        if(!$('.trade-comp-container').hasClass("exit")) {
          localStorage.setItem("avariz_graphite", cr.height);
        }

        $(".main-chart, .main-chart-alt").css({height: `${cr.height}px`});
        $("#table-resi-resp").css({height: `${Number($(window).innerHeight() - cr.height - 363)}`});
        $(window).trigger("resize");
      }
    });

    // Observe one or multiple elements
    ro.observe(document.getElementById("hr-resizable"));
  }

  fullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      $('.trade-comp-container').removeClass("exit");
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.resetGraph();
    } else {
      var element = $('.trade-comp-container').get(0);
      $('.trade-comp-container').addClass("exit");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.resetGraph();
    }
  }

  resetGraph = () => {
    let range = this.chart.current.timeScale().getVisibleRange();
    this.chart.current.timeScale().fitContent();
    this.chart.current.timeScale().scrollToPosition(1);
    if(range) {
      this.chart.current.timeScale().setVisibleRange({
        from: range.from,
        to: range.to
      });
    }
  }

  loadTV = async (load = true) => {
    this.setState({showLoader: true});
    await setTimeout(async () => {
      let tvs = this.state.selectedPair.replace(/[^a-zA-Z]/g, '').replace(".", "");
      if(this.props.tvs) {
        if(this.props.tvs.length) {
          tvs = this.props.tvs;
        }
      }
      if(!window.TradingView) {
        return null;
      }
      new window.TradingView.widget(
        {
          "autosize": true,
          "symbol": tvs,
          "interval": "D",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "toolbar_bg": "#f1f3f6",
          "enable_publishing": false,
          "withdateranges": true,
          "hide_side_toolbar": false,
          "allow_symbol_change": true,
          "save_image": false,
          "container_id": "tradingview_c0f36"+this.props.ki
        }
      );
      this.setState({showLoader: false, advance_loaded: true});
    }, 1000);
  }

  treatPair = (pair) => {
    if(pair == undefined) {
      return this.pair;
    }
    return pair.indexOf(" ") > -1 ? pair.split(" ")[0].trim() : pair.trim();
  }

  handleOptionsChange = (e) => {
    this.props.changePair(this.state.allPairs[e.target.value.toLowerCase()][0], e.target.value.toLowerCase(), true);
  };

  handleOptionsChange2 = (v) => {
    this.props.changePair(this.state.allPairs[v.toLowerCase()][0], v.toLowerCase(), true);
  };

  setNewPairData = (e) => {
    this.props.changePair(e.target.value, this.state.selectedOption, true);
  }

  graphData2 = (data, pair) => {
    let ret = {
      time: data.Date,
      open:  parseFloat(data.Open),
      high:  parseFloat(data.High),
      low:   parseFloat(data.Low),
      close: parseFloat(data.Close),
      ask:   parseFloat(data.Open),
      spread:parseFloat(parseFloat(data.High) - parseFloat(data.Low)),
      bid: parseFloat(data.Open),
      pair: pair,
    };
    return ret;
  }

  plotGraph = (data) => {
    if (typeof data === 'object' && this.treatPair(data.pair) === this.treatPair(this.pair)) {
      let plot_data = data;
      if(this.currentGrpahType == "candle" || this.currentGrpahType == "bar") {
        this.seriesIterator += 1;
        this.chartSeries.setData(plot_data);
      } else {
        let plots = [];
        for (var i = 0; i < plot_data.length; i++) {
          let plot = {time: plot_data[i].time, value: plot_data[i].open, color: "#03cf9e"};
          plots.push(plot);
        }
        this.seriesIterator += 1;
        this.chartSeries.setData(plots);
      }
    }
  }

  canSketch = (p, s) => {
    if(!s.length) {
      s = "1m";
    }
    let cansk = this.int_list;
    // console.log(s, Object.values(cansk[p].split(",")), Object.values(cansk[p].split(",")).indexOf(s));
    return Object.values(cansk[p].split(",")).indexOf(s) !== -1;
  }

  showDropdown = function (_d) {
    let d = "#"+_d;
    if($(d).hasClass("_active")) {
      $(d).removeClass("_active");
    } else {
      $(d).addClass("_active");
    }
  }

  admUid = (e) => {
    let dis = $(e.target);
    window.current_acc = dis.val();
    let bal = this.props.accounts.filter((a) => { return a.account_name.toLowerCase() == dis.val().toLowerCase(); });
        bal = bal[0].balance;
        $("#trd-acc-bal-spa").html("$"+bal);
  }

  render() {
    let sop = this.props.selectedOption;
    let stocks = [];
    let accs = [];
    let auto = this.props.auto;

    if(window.hotStockData) {
      for (const [key, value] of Object.entries(window.hotStockData)) {
        stocks[value.pair] = value.name;
      }
    }

    if(this.props.accounts) {
      accs = this.props.accounts;
    }

    return this.destroyGraph ? null : (
      <div
        className={'col-md-'+(this.props.ki > 1 ? '6' : this.props.col)+' chart-section multiple-chart-section chart-section-'+this.props.ki}
        style={this.props.beginner ? null : {background: "#141721"}}
      >
        {auto ? <marquee className="marqau">Auto Trader is active</marquee> :
        <div className='chart-section-top'>
          <div className='chart-section-top-left imobile'>
            <label className='blue-select' htmlFor={"blue-select-"+this.props.ki} style={{"pointer-events": "none", marginRight: "10px"}}>{(stocks[this.state.selectedPair] || "").length ? stocks[this.state.selectedPair] : this.state.selectedPair} <img style={{backgroundColor: "transparent"}} src={Tarrow} alt='' className='t-arrow grd' /></label>
            <select className='blue-select' id={"blue-select-"+this.props.ki} onChange={this.setNewPairData} value={this.state.selectedPair} style={{marginLeft: this.props.admin ? "1.5em" : "0", position: "absolute", left: "6px", color: "transparent", opacity: 0}}> 
              {this.state.allPairs[sop].map((data, key) => (<option key={key} value={data}>{(stocks[data] || "").length ? stocks[data] : data}</option>))}
            </select>
            
            <div className="graftype">
              <span style={{color: this.props.beginner ? "var(--custom_color)" : "inherit"}}>{app.l("Basic")}</span>
              <label className="switch-box" htmlFor={"switch-box-"+this.props.ki+(this.props.beginner ? "1" : "0")}>
                <input type="checkbox" className="switch-check" id={"switch-box-"+this.props.ki+(this.props.beginner ? "1" : "0")} defaultChecked={!this.props.beginner} onChange={(e) => {
                  this.props.beginnerChange(e);
                }} />
                <span className="slider round"></span>
              </label>
              <span style={{color: !this.props.beginner ? "var(--custom_color)" : "inherit"}}>{app.l("Advanced")}</span>
              <img src={fullscreen} className="fullscreen" onClick={() => this.fullscreen()}/>
              <img src={efullscreen} className="fullscreen active" onClick={() => this.fullscreen()}/>
            </div>
          </div>
          <div className='chart-section-top-left'>
            {this.props.admin ? 
            <select className='blue-select' id="adm-uid-tra" onChange={this.admUid}>
              {accs.map((ac, ky) => (
                <option key={ky} selected={ac.account_name == window.current_acc} value={ac.account_name}>{ac.account_label.length ? ac.account_label : ac.account_name.toUpperCase()}</option>
              ))}
            </select> : null }
            <label className='blue-select not-imobile' htmlFor={"blue-select-"+this.props.ki} style={{"pointer-events": "none", marginRight: "10px"}}>{(stocks[this.state.selectedPair] || "").length ? stocks[this.state.selectedPair] : this.state.selectedPair} <img style={{backgroundColor: "transparent"}} src={Tarrow} alt='' className='t-arrow grd' /></label>
            <select className='blue-select not-imobile' id={"blue-select-"+this.props.ki} onChange={this.setNewPairData} value={this.state.selectedPair} style={{marginLeft: this.props.admin ? "1.5em" : "0", position: "absolute", left: "6px", color: "transparent", opacity: 0}}> 
              {this.state.allPairs[sop].map((data, key) => (<option key={key} value={data}>{(stocks[data] || "").length ? stocks[data] : data}</option>))}
            </select>
            {this.props.beginner ?
            <ul className="instrument-icons">
            <li className="four l150 grd" id={"gr-four-"+this.props.ki} onClick={() => this.showDropdown("gr-four-"+this.props.ki)}>
              <img src={Tarrow} alt='' className='t-arrow grd' /> <span className="grd">{app.l(sop.toUpperCase())}</span>
              <div className="gr-dropdown">
                {this.state.instruments.map((instr, key) => (
                  <span key={"cgt-"+key} onClick={(e) => this.handleOptionsChange2(instr)} className={"cgt"+(sop.toUpperCase() == instr.toUpperCase() ? " _active" : "")}>{app.l(instr.toUpperCase())}</span>
                ))}
              </div>
            </li>
            <li className="three l100 grd" id={"gr-three-"+this.props.ki} onClick={() => this.showDropdown("gr-three-"+this.props.ki)}>
              <img src={Tarrow} alt='' className='t-arrow grd' /> <span className="grd">{this.props.intervalLevel.length ? this.int_name[this.props.intervalLevel]+" ": <span className="grd">{app.l("Interval")}</span>}</span>
              <div className="gr-dropdown">
                {this.canSketch(this.state.historyLevel, "1m") ? <span onClick={(e) => this.props.changeInterval("1m")} className={"cgt"+(this.props.intervalLevel == "1m" ? " _active" : "")}> 1 min </span> : null}
                {this.canSketch(this.state.historyLevel, "2m") ? <span onClick={(e) => this.props.changeInterval("2m")} className={"cgt"+(this.props.intervalLevel == "2m" ? " _active" : "")}> 2 mins </span> : null}
                {this.canSketch(this.state.historyLevel, "5m") ? <span onClick={(e) => this.props.changeInterval("5m")} className={"cgt"+(this.props.intervalLevel == "5m" ? " _active" : "")}> 5 mins </span> : null}
                {this.canSketch(this.state.historyLevel, "15m") ? <span onClick={(e) => this.props.changeInterval("15m")} className={"cgt"+(this.props.intervalLevel == "15m" ? " _active" : "")}> 15 mins </span> : null}
                {this.canSketch(this.state.historyLevel, "30m") ? <span onClick={(e) => this.props.changeInterval("30m")} className={"cgt"+(this.props.intervalLevel == "30m" ? " _active" : "")}> 30 mins </span> : null}
                {this.canSketch(this.state.historyLevel, "1h") ? <span onClick={(e) => this.props.changeInterval("1h")} className={"cgt"+(this.props.intervalLevel == "1h" ? " _active" : "")}> 1 hour </span> : null}
                {this.canSketch(this.state.historyLevel, "1d") ? <span onClick={(e) => this.props.changeInterval("1d")} className={"cgt"+(this.props.intervalLevel == "1d" ? " _active" : "")}> 1 Day </span> : null}
                {this.canSketch(this.state.historyLevel, "5d") ? <span onClick={(e) => this.props.changeInterval("5d")} className={"cgt"+(this.props.intervalLevel == "5d" ? " _active" : "")}> 5 Days </span> : null}
                {this.canSketch(this.state.historyLevel, "1wk") ? <span onClick={(e) => this.props.changeInterval("1wk")} className={"cgt"+(this.props.intervalLevel == "1wk" ? " _active" : "")}> 1 Week </span> : null}
                {this.canSketch(this.state.historyLevel, "1mo") ? <span onClick={(e) => this.props.changeInterval("1mo")} className={"cgt"+(this.props.intervalLevel == "1mo" ? " _active" : "")}> 1 Month </span> : null}
                {this.canSketch(this.state.historyLevel, "3mo") ? <span onClick={(e) => this.props.changeInterval("3mo")} className={"cgt"+(this.props.intervalLevel == "3mo" ? " _active" : "")}> 3 Months </span> : null}
              </div>
            </li>
              <li className="one grd" id={"gr-one-"+this.props.ki} onClick={() => this.showDropdown("gr-one-"+this.props.ki)}>
                <img style={{opacity: this.currentGrpahType == "candle" ? "1" : "1"}} src={
                  this.currentGrpahType == "candle" ? candleGrf:
                  this.currentGrpahType == "line" ? lineGrf:
                  this.currentGrpahType == "area" ? areaGrf:
                  this.currentGrpahType == "bar" ? barGrf:
                  this.currentGrpahType == "hist" ? histGrf : Wave}
                  alt='' className='icon grd' />
                <img src={Tarrow} alt='' className='t-arrow grd' />
                <div className="gr-dropdown">
                  <span onClick={(e) => this.switchGraphTypeTo("candle")} className={"cgt"+(this.currentGrpahType == "candle" ? " _active" : "")}><img src={candleGrf} /> Candle</span>
                  <span onClick={(e) => this.switchGraphTypeTo("line")} className={"cgt"+(this.currentGrpahType == "line" ? " _active" : "")}><img src={lineGrf} /> Line</span>
                  <span onClick={(e) => this.switchGraphTypeTo("area")} className={"cgt"+(this.currentGrpahType == "area" ? " _active" : "")}><img src={areaGrf} /> Area</span>
                  <span onClick={(e) => this.switchGraphTypeTo("bar")} className={"cgt"+(this.currentGrpahType == "bar" ? " _active" : "")}><img src={barGrf} /> Bar</span>
                  <span onClick={(e) => this.switchGraphTypeTo("hist")} className={"cgt"+(this.currentGrpahType == "hist" ? " _active" : "")}><img src={histGrf} /> Histogram</span>
                </div>
              </li>
            </ul>
            : <ul className="instrument-icons">
            <li className="four l150 grd" id={"gr-four-"+this.props.ki} onClick={() => this.showDropdown("gr-four-"+this.props.ki)}>
              <img src={Tarrow} alt='' className='t-arrow grd' /> <span className="grd">{sop.toUpperCase()}</span>
              <div className="gr-dropdown">
                {this.state.instruments.map((instr, key) => (
                  <span key={"cgt-"+key} onClick={(e) => this.handleOptionsChange2(instr)} className={"cgt"+(sop.toUpperCase() == instr.toUpperCase() ? " _active" : "")}>{instr.toUpperCase()}</span>
                ))}
              </div>
            </li></ul>}
          </div>












          <div className="chart-section-top-right not-imobile">
            <div className="graftype">
              <span style={{color: this.props.beginner ? "var(--custom_color)" : "inherit"}}>{app.l("Basic")}</span>
              <label className="switch-box" htmlFor={"switch-box-"+this.props.ki+(this.props.beginner ? "1" : "0")}>
                <input type="checkbox" className="switch-check" id={"switch-box-"+this.props.ki+(this.props.beginner ? "1" : "0")} defaultChecked={!this.props.beginner} onChange={(e) => {
                  this.props.beginnerChange(e);
                }} />
                <span className="slider round"></span>
              </label>
              <span style={{color: !this.props.beginner ? "var(--custom_color)" : "inherit"}}>{app.l("Advanced")}</span>
              <img src={fullscreen} className="fullscreen" onClick={() => this.fullscreen()}/>
              <img src={efullscreen} className="fullscreen active" onClick={() => this.fullscreen()}/>
            </div>
          </div>
        </div>}

        
        {this.props.beginner ?
          <>
            <div className="gr-hist-level imobile">
              <div className="tz-name"><img src={tci} alt="" /><span>{app.ntz()}</span></div>
              <span onClick={(e) => this.props.changeLevel("1D", this.int_list["1D"].split(",")[0])} className={"cgt"+(this.state.historyLevel == "1D" ? " _active" : "")}> 1D </span>
              <span onClick={(e) => this.props.changeLevel("1W", "1d")} className={"cgt"+(this.state.historyLevel == "1W" ? " _active" : "")}> 1W </span>
              <span onClick={(e) => this.props.changeLevel("1M", "1d")} className={"cgt"+(this.state.historyLevel == "1M" ? " _active" : "")}> 1M </span>
              <span onClick={(e) => this.props.changeLevel("6M", "1d")} className={"cgt"+(this.state.historyLevel == "6M" ? " _active" : "")}> 6M </span>
              <span onClick={(e) => this.props.changeLevel("1Y", "1d")} className={"cgt"+(this.state.historyLevel == "1Y" ? " _active" : "")}> 1Y </span>
              <span onClick={(e) => this.props.changeLevel("2Y", "1d")} className={"cgt"+(this.state.historyLevel == "2Y" ? " _active" : "")}> 2Y </span>
            </div>
          </> : null
        }
        
        <div className='hr-resizable hr-chart' id='hr-resizable' style={Number(this.state.graphite) > 0 ? {height: `${this.state.graphite}px`}: null}>&nbsp;</div>
        <div className={'chart main-chart'+(this.props.beginner ? "" : " hide")} ref={this.chartContainerRef} style={Number(this.state.graphite) > 0 ? {height: `${this.state.graphite}px`}: null}>
          <div
            className='loader-container'
            style={{ display: this.state.showLoader ? 'block' : 'none', width: "100%" }}
          >
            <div className='loader'></div>
          </div>
          <div className="zoom-controls">
            <span onClick={() => this.zoom(false)}>&#8211;</span>
            <span onClick={() => this.zoom(true)}>&#43;</span>
          </div>
        </div>
        <div id={"tradingview_c0f36"+this.props.ki} className={'main-chart-alt trdv'+(this.props.beginner ? " hide" : "")} style={Number(this.state.graphite) > 0 ? {height: `${this.state.graphite}px`}: null}>
          <div
            className='loader-container'
            style={{ display: this.state.showLoader ? 'block' : 'none', width: "100%" }}
          >
              <div className='loader'></div>
            </div>
        </div>
        {this.props.beginner ?
          <>
            <div className="gr-hist-level not-imobile">
              <span onClick={(e) => this.props.changeLevel("1D", this.int_list["1D"].split(",")[0])} className={"cgt"+(this.state.historyLevel == "1D" ? " _active" : "")}> 1D </span>
              <span onClick={(e) => this.props.changeLevel("1W", "1d")} className={"cgt"+(this.state.historyLevel == "1W" ? " _active" : "")}> 1W </span>
              <span onClick={(e) => this.props.changeLevel("1M", "1d")} className={"cgt"+(this.state.historyLevel == "1M" ? " _active" : "")}> 1M </span>
              <span onClick={(e) => this.props.changeLevel("6M", "1d")} className={"cgt"+(this.state.historyLevel == "6M" ? " _active" : "")}> 6M </span>
              <span onClick={(e) => this.props.changeLevel("1Y", "1d")} className={"cgt"+(this.state.historyLevel == "1Y" ? " _active" : "")}> 1Y </span>
              <span onClick={(e) => this.props.changeLevel("2Y", "1d")} className={"cgt"+(this.state.historyLevel == "2Y" ? " _active" : "")}> 2Y </span>
              <div className="tz-name"><img src={tci} alt="" /><span>{app.ntz()}</span></div>
            </div>
          </> : null
        }
      </div>
    );
  }
}

export default ChartModule;