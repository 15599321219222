import React, { Component } from 'react';
import $ from 'jquery';
import validateInfo from './validateInfo';
import CloseBtn from '../../../themes/images/close.svg';
import MasterCard from '../../../themes/images/mastercard.svg';
import server from '../../../services/server';
import Spinner from '../../../components/spinner/index';
import './creditCard.scss';
import app from '../../../services/app';

var valid = require("card-validator");

class CreditCard extends Component {
  constructor(props) {
    super(props);

    let cname = '';
    let cnum = '';
    let card = '';
    let month = '';
    let year = '';
    let cvc = '';

    this.state = {
      cname, cnum, card, month, year, cvc, showSpinner: false, type: ''
    };

    this.profile = app.profile();
  }

  setData = () => {
      let d = window.card_details;
      let type = '';

      var numberValidation = valid.number(d.PAN.replace(/\s/g, ""));
      if (numberValidation.card) {
        type = numberValidation.card.type;
      }
      this.setState({
        cname : d.card_name,
        cnum  : d.PAN,
        month : d.valid_thru.split("-")[0],
        year  : d.valid_thru.split("-")[1],
        cvc   : d.cvv,
        type
      });
  }

  setNumber = (e) => {
    $(".smol.err").css({display: "none"});
    let cnum = e.target.value;
        cnum = cnum.replace(/\s/g, "").replace(/[^\d]/g, "");
    var num = String(cnum);
    var result = "";
    var gap_size = 4;

    while (num.length > 0) {
        result = result + " " + num.substring(0, gap_size);
        num = num.substring(gap_size);
    }

    result = result.trim();
    let raw = Number(result.replace(/\s/g, ""));
    if(String(raw).length <= 16) {
        this.setState({ cnum: result, card: raw });
    } else {
        $(".cc-mm").focus();
    }
    var numberValidation = valid.number(raw);
    if (numberValidation.card) {
        this.setState({type: numberValidation.card.type});
    }
  }

  setMM = (e) => {
    $(".smol.err").css({display: "none"});
    let mm = e.target.value;
        mm = mm.replace(/\s/g, "").replace(/[^\d]/g, "");
        mm = mm > 1 && mm < 10 ? "0"+mm : mm;
    if(String(mm).length <= 2 && mm <= 12) {
        this.setState({ month: mm });
    } else {
        $(".cc-yy").focus();
    }
  }

  setYY = (e) => {
    $(".smol.err").css({display: "none"});
    let yy = e.target.value;
        yy = yy.replace(/\s/g, "").replace(/[^\d]/g, "");
    if(String(yy).length <= 4) {
        this.setState({ year: yy });
    } else {
        $(".cc-cvc").focus();
    }
  }

  setCvc = (e) => {
    $(".smol.err").css({display: "none"});
    let c = e.target.value;
        c = c.replace(/\s/g, "").replace(/[^\d]/g, "");
    if(String(c).length <= 3) {
        this.setState({ cvc: c });
    } else {
        $(".cc-name").focus();
    }
  }

  setName = (e) => {
    $(".smol.err").css({display: "none"});
    let n = e.target.value;
        n = n.replace(/[^\sa-zA-Z]/g, "");
    this.setState({ cname: n });
  }

  async componentDidMount () {
    $(window).on("preCard", () => {
        this.setData();
    });
  }

  submitForm = async () => {
      let {cname, cnum, type, month, year, cvc} = this.state;
      let values = {
        cardName: cname,
        cardNumber: cnum,
        cardType: type,
        cardExpiration: month+"/"+(String(year).length > 2 ? String(year).substring(String(year).length-2, String(year).length) : year),
        cardSecurityCode: cvc,
        cardPostalCode: '',
        focus: ''
    }
    let result = validateInfo(values);

    $("#ccpbt").css({opacity: 0.4});
    if(result.variant != "success") {
        $(".smol.err").text(result.message);
        $(".smol.err").css({display: "flex"});
    } else {
        $(".smol.err").css({display: "none"});
        this.props.submit({cname, cnum, month, year, cvc});
    }
  }
  
  render() {
    const { showAddCardModal, handleClick } = this.props;
    const { showSpinner, cname, cnum, month, year, cvc, type } = this.state;

    return (
        <form className='credit-modal' autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            this.submitForm();
        }}>
            <div className="row" style={{marginLeft: "0px", borderRadius: "10px"}}>
                {type.length ? <span className='cctype'>{type}</span> : null}
                <div class="col-9" style={{paddingTop: "50px"}}>
                    <div className='account-form-section'>
                        <input type="text" name='cnum' id="card-cnum" placeholder='Card number' minLength="16" maxLength="19" className="form-control bgw cc-num" value={cnum} onChange={this.setNumber} required />
                    </div>
                    <div className='account-flex-2'>
                        <div className='account-form-section sub'>
                            <span>Valid</span>
                            <input type='text' name='month' id='card-month' placeholder='MM' minLength="2" className="form-control ct cc-mm" value={month} onChange={this.setMM} required />
                            <span>/</span>
                            <input type='text' name='year' id='card-year' placeholder='YYYY' minLength="2" className="form-control ct cc-yy" value={year} onChange={this.setYY} required />
                        </div>
                    </div>
                    
                    <div className='account-form-section'>
                        <input type='text' name='cname' id='card-cname' spellCheck="false" placeholder='Card holder' minLength="5" className="form-control bgw cc-name" value={cname} onChange={this.setName} required />
                    </div>
                </div>

                <div className="col-3" style={{background: "#282828", paddingTop: "50px", paddingLeft: "10px", paddingRight: "10px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}}>
                    <div className='account-form-section' style={{height: "42px", backgroundColor: "#202020", marginLeft: "-10px", width: "calc(100% + 20px)"}}>
                    </div>
                    <div className='account-form-section cvv'>
                        <input type='text' name='cvv' id='card-cvv' className="form-control ct cc-cvc" minLength="3" placeholder="CVC" value={cvc} onChange={this.setCvc} required />
                    </div>
                </div>
            </div>
            <input type="submit" id="submit_card_details" value="submit" style={{display: "none"}} />
        </form>
    );
  }
}

export default CreditCard;